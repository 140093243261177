import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import NonExistPage from '../404-page/NonExistPage'
import SubscriptionsOffers from './offers-page'

function Subscriptions(): ReactElement {
	const { type } = useParams<{ type: string }>()

	if( type === 'personal-offers' || type === 'commercial-offers')
		return <SubscriptionsOffers />

	return <NonExistPage />
}
export default Subscriptions