/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import { WineImporterInfoComponent } from '../styles'
import { validateURL } from '../../../../../utils/ValidateUrl'
import { addHttpsIfNeeded } from '../../trade-partners/constants'

type Props = {
    importerInfo:any
}

export function WineImporterInfo({importerInfo}:Props):ReactElement{
	return <WineImporterInfoComponent>
		<p>{importerInfo.display_name}</p>
		{importerInfo?.contact_numbers?.phone1 && `Tel: ${importerInfo?.contact_numbers?.phone1}`}
		<p>{importerInfo.email}</p>
		{importerInfo.website_url && <a href={validateURL(importerInfo.website_url) ? `${addHttpsIfNeeded(importerInfo.website_url)}` : ''} target='_black' rel="noopener noreferrer">{importerInfo.website_url}</a>}
	</WineImporterInfoComponent>
}