/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react'
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion'

import moment from 'moment'


// Styles
import { AccordionContainer, AccordionController } from './styles'

// Services
import { useGetEditorialCalendarQuery } from '../../../../app/services'

import SectionSeparator from '../../sections/section-separator'
import { APP_CONFIG } from '../../../../configs'
import { isMobile } from 'react-device-detect'
import LoadingOverlay from '../../../../components/Loading-overlay'

function EditorialCalendar(): ReactElement {

	const { data, isLoading } = useGetEditorialCalendarQuery('')
	const [open, setOpen] = useState<any>({})

	const handleSetOpen = (index: any) => {
		setOpen({ [index]: !open[index] })
	}

	return (
		<div className="single-entry">
			<div className="mnmd-block mnmd-block--fullwidth mnmd-block--contiguous page-heading page-heading--has-background">
				<div className="container">
					{isMobile ? <h2 className="page-heading__title" style={{ paddingTop: '20px' }}>Editorial Calendar</h2> : <h2 className="page-heading__title">Editorial Calendar</h2>}
					{data && data?.success ?
						<div className='page-heading__subtitle'>Last revised {moment(data?.data?.reviewer_data[0].updated_at).format(APP_CONFIG.DEFAULTS.DATE_FORMAT).toString()}</div>
						: ''
					}
				</div>
			</div>
			<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
				<div className="container">
					<div className="row">
						<div className="mnmd-sub-col mnmd-sub-col--left sidebar col-12" >
							{isLoading ? (<LoadingOverlay />) : (

								<div style={isMobile ? {} : { width: '968px' }}>
									<AccordionContainer>
										<Accordion>
											{data && data?.data.groupings.map((grouping: CalendarGrouping, accordion_item_key: number) => {
												if (grouping.hidden) {
													return (<></>)
												}

												return (
													<AccordionItem key={accordion_item_key} onClick={() => handleSetOpen(accordion_item_key)} dangerouslySetExpanded={open[accordion_item_key]}>
														<AccordionItemHeading>
															<AccordionItemButton>
																<AccordionController>
																	<div className={'group-head'} >
																		<div className={'group-label'} >{grouping.label}</div>
																		<div className={'group-sublabel'} >{grouping.sublabel}</div>
																	</div>
																	<div>
																		{open[accordion_item_key] ? <img src={'/img/icons/expand-less-2.png'} /> : <img src={'/img/icons/expand-more-2.png'} />}
																	</div>
																</AccordionController>
																<div className='m-y-md'>
																	<SectionSeparator position={'horizontal'} ></SectionSeparator>
																</div>
															</AccordionItemButton>
														</AccordionItemHeading>
														<AccordionItemPanel>
															{data && data?.data.reviewer_data.map((reviewer: CalendarReviewerData, reviewer_data_map_key: number) => {
																const assignmentsContainer = reviewer.columns.filter((assignmentData: CalendarAssignments) => (assignmentData.key === grouping.key && assignmentData.assignments && assignmentData.assignments.length > 0))[0]

																const reviewerProfile = reviewer.author[0]?.profile
																return (
																	<>
																		<div className={'calendar_entries'} key={reviewer_data_map_key} >
																			{reviewerProfile !== undefined && <>
																				<div className={'entry_author'} >{`${reviewerProfile?.title ? reviewerProfile.title.toUpperCase() : ''} ${reviewerProfile?.name?.first_name.toUpperCase()} ${reviewerProfile?.name?.last_name.toUpperCase()}`}</div>
																			</>}
																			
																			{assignmentsContainer != undefined ? (
																				<>
																					{assignmentsContainer.assignments.map((assignment: { label: string, sublabel: string }) => (
																						<>
																							<div className={'entry_label'} >{assignment.label}</div>
																							<div className={'entry_sublabel'} >{assignment.sublabel}</div>
																						</>
																					))}
																				</>
																			) : null}

																		</div>
																		<div className='m-y-md'>
																			<SectionSeparator position={'horizontal'} ></SectionSeparator>
																		</div>
																	</>
																)

															})}
														</AccordionItemPanel>
													</AccordionItem>
												)

											})}

										</Accordion>
									</AccordionContainer>
								</div>
							)}

						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EditorialCalendar