/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import { ChartContainer } from '../styles'

type Props = {
	data?: { label: string | number; count: number }[],
	reference: string
}

function RangeChart({data = [], reference}: Props):ReactElement {

	let finalData = data

	if(reference === 'rating_computed'){
		const sortedData: {label: string | number, count: number}[] = []

		for(const ratingData of data) {
			if(parseFloat(ratingData.label as string) >= 80 ){
				sortedData.push(ratingData)
			}
		}
		finalData = sortedData
	}
	
	const computedHeight = (count: number) => {
		const maxNumber = finalData.reduce((max, current) => {
			return current.count > max ? current.count : max
		}, -Infinity)

		return Math.ceil((count/maxNumber) * 100)
	}

	const sortedValue = finalData.sort((a, b) => parseFloat(a.label as string) - parseFloat(b.label as string))

	return <ChartContainer>
		{sortedValue.map((finalData, index) => <div key={index} style={{ height: `${computedHeight(finalData.count)}%` }} />)}
	</ChartContainer>
}

export default RangeChart
