import styled from 'styled-components'

export const PaginationItem = styled.span<{ selected: boolean }>`
	cursor: pointer;
	width: 34px;
	height: 34px;
	margin: 0 5px 0 0;
	padding: 7px 12.5px 6px;
	background-color: ${(props) => (props.selected ? '#a08436' : '#f0f0f0')};
	&:hover {
		background-color: #a08436;
	}

	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	color:  ${(props) => (props.selected ? '#fff' : '#333')};


`
export const PaginationNextPrev = styled.span`
	cursor: pointer;
	width: 34px;
	height: 34px;
	margin: 0 5px 0 0;
	padding: 7px 12.5px 6px;
	background-color: #f0f0f0;

	&:hover {
		background-color: #e01a00;
	}
`

export const PaginationContainer = styled.div`
	text-align: center;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`

