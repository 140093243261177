/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { ReactElement, useEffect, useState } from 'react'

import { getAlgoliaResults } from '@algolia/autocomplete-js'
import { getAlgoliaFacets } from '@algolia/autocomplete-preset-algolia'
import algoliasearch from 'algoliasearch'
import { useHistory, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import parse from 'html-react-parser'

import { APP_CONFIG, RP_ENV } from '../../../../configs'
import { ClearActiveAdvanceFilters, ClearKeyWords, ClearRangeFilters, SetActiveFilters, SetFacetsFilters, SetKeywords } from '../../../../app/slices/SearchFilters'
import { getTypes } from '../../../../utils/SearchUtil'
import { Autocomplete } from '../../../content/pages/search-results/AutoComplete'
import {
	Footer, Header, HeaderContainer,
	ResultItemContainer, ResultItem, ResultItemSub, ResultItemSubSpan
} from './styles'
import { slugify } from '../../../../utils/ArticlesUtil'
import { reverseObfuscateConstant } from '../../../../utils/Constant'


function SearchBoxAutoComplete(props: any): ReactElement {
	const history = useHistory()
	const { search, pathname } = useLocation()
	const dispatch = useDispatch()
	const [initialQuery, setInitialQuery] = useState<any>('')
	const screenWidth = window.screen.width
	// const { triggerTrackingHandler } = useArticleTracking()

	const queryStrings = queryString.parse(search)
	
	useEffect(() => {
		if(!window.location.href.includes('search/wine')){
			dispatch(SetKeywords(''))
		}
	},[window.location.href])


	useEffect(() => {
		if(queryStrings?.keyword)
			setInitialQuery(queryStrings?.keyword)
	}, [queryStrings])

	const removeDetachOverlay = () => {
		const overlay = document.querySelectorAll('.aa-DetachedOverlay')
		const container = document.querySelectorAll('.aa-DetachedContainer')
		
	
		overlay.forEach(element => {
			element.classList.remove('aa-DetachedOverlay')
		})

		container.forEach(element => {
			element.classList.remove('aa-DetachedContainer')
		})
	}

	const screenChecker = () => {
		
		if(screenWidth > 680 && screenWidth < 992){
			removeDetachOverlay()
			props.hideSearch()
		}
	}

	const resetFacitsFilter = () => {
		dispatch(ClearKeyWords())
		dispatch(ClearRangeFilters())
		dispatch(ClearActiveAdvanceFilters())
		dispatch(SetFacetsFilters({
			facetFilters: [],
			activeFilters: {}
		}))
		screenChecker()
	}

	const hadleSubmit = (event: any) => {
		event.setQuery(event.state.query)
		event.setIsOpen(false)
		const type = getTypes(pathname)

		resetFacitsFilter() 

		if (event.state.query !== '') {

			if(isMobile){
				window.location.href = `/search/${type}?keyword=${event.state.query}`
			} else {
				window.location.href = `/search/${type}?keyword=${event.state.query}`
			}

			dispatch(SetActiveFilters({}))
			dispatch(SetKeywords({
				key: event.state.query,
			}))
		}
		else{window.location.href=`/search/${type}`} 
	}

	// const handleCountrySubmit = (country: any, setQuery: any, setIsOpen: any) => {
	// 	const value = country
	// 	setQuery(value)
	// 	setIsOpen(false)
	// 	history.push(`/search/wine?country=${value}`)
	// 	resetFacitsFilter()
	// }

	const locationSearcher = (value: string) => {
		let type = 'region'
		if(/id="sub_region"/.test(value)){
			type = 'sub_region'
		}else if(/id="appellation"/.test(value)){
			type = 'appellation'
		}else if(/id="sub_appellation"/.test(value)){
			type = 'sub_appellation'
		}
		return `/search/wine?${type}`
	} 

	const handleRegionSubmit = (region: any, highlightResult: string, setQuery: any, setIsOpen: any, ) => {
		const value = region
		setQuery(value)
		setIsOpen(false)
		let route
		if (['North Coast', 'Cote de Beaune'].includes(region)) {
			route = `/search/wine?sub_region=${value}`
		} else if (['Napa Valley', 'Sonoma County'].includes(region)) {
			route = `/search/wine?appellation=${value}`
		} else if (['Russian River Valley', 'Sonoma Coast'].includes(region)) {
			route = `/search/wine?sub_appellation=${value}`
		} else {
			route = `${locationSearcher(highlightResult)}=${value}`
		}
		window.location.href = (route)

		resetFacitsFilter()
	}

	const handleSubRegionSubmit = (subRegion: any, setQuery: any, setIsOpen: any) => {
		const value = subRegion
		setQuery(value)
		setIsOpen(false)
		window.location.href = `/search/wine?sub_region=${value}`
		resetFacitsFilter()
	}

	const handleAppellationSubmit = (appellation: any, setQuery: any, setIsOpen: any) => {
		const value = appellation
		setQuery(value)
		setIsOpen(false)
		window.location.href = `/search/wine?appellation=${appellation}`
		resetFacitsFilter()
	}

	const handleSubAppellationSubmit = (subAppellation: any, setQuery: any, setIsOpen: any) => {
		const value = subAppellation
		setQuery(value)
		setIsOpen(false)
		window.location.href = `/search/wine?sub_appellation=${value}`
		resetFacitsFilter()
	}

	const handleProducerItemSubmit = (item: any, setQuery: any, setIsOpen: any) => {
		const value = item.label
		setQuery(value)
		setIsOpen(false)
		window.location.href = `/search/wine?producer=${encodeURIComponent(value)}`
		resetFacitsFilter()
	}

	const handleWineItemSubmit = (item: any, setQuery: any, setIsOpen: any) => {
		const value = item.display_name
		setQuery(value)
		setIsOpen(false)
		window.location.href = `/wines/${item.objectID}/${slugify(item.name + '-' + item.vintage)}`
		resetFacitsFilter()
	}

	const handleArticleItemSubmit = (item: any, setIsOpen: any) => {
		// triggerTrackingHandler(item.objectID)
		window.location.href = `/articles/${item.objectID}`
		resetFacitsFilter()
		setIsOpen(false)
	}

	const handleArticleAuthorSubmit = (author: any, setQuery: any, setIsOpen: any) => {
		setQuery(author)
		setIsOpen(false)
		window.location.href = `/search/article?author.name=${author}`
		resetFacitsFilter()
	}

	const handleSeeAll = (query: string, setQuery: any, setIsOpen: any, type: string) => {
		setQuery(query)
		setIsOpen(false)
		window.location.href = `/search/${type}?keyword=${query}`
		resetFacitsFilter()
	}

	const handleReset = (event: any) => {
		event.setQuery('')
		// event.setIsOpen(false)
		// resetFacitsFilter()
		dispatch(ClearKeyWords())
	}

	const handleChangeState = (event: any) => { // for mobile
		if (!event.state.isOpen && screenWidth < 681 && props.hideSearch !== undefined) {
			removeDetachOverlay()
			props.hideSearch()
		}
	}

	const appId = reverseObfuscateConstant(RP_ENV.ALGOLIA_APPLICATION_ID) as string
	const apiKey = reverseObfuscateConstant(RP_ENV.ALGOLIA_API_KEY) as string
	const searchClient = algoliasearch(appId, apiKey)
	const wineIndex = reverseObfuscateConstant(RP_ENV.WINE_INDEX) || ''
	const articleIndex = reverseObfuscateConstant(RP_ENV.ARTICLE_INDEX) || ''
	//set initial query

	return (
		<Autocomplete
			autoHighlight='true'
			placeholder='Search for producers/wines/articles'
			onStateChange={handleChangeState}
			onReset={handleReset}
			onSubmit={hadleSubmit}
			type={getTypes(pathname)}
			openOnFocus={true}
			style={{ position: 'relative', zIndex: 200, flex: 1 }}
			initialState={{
				// This uses the `search` query parameter as the initial query
				query: initialQuery,
				isOpen: props.isOpen,
			}}
			getSources={({ query, setQuery, setIsOpen }: any) => [
				{
					sourceId: 'producers',
					getItems() {
						return getAlgoliaFacets({
							searchClient,
							queries: [
								{
									indexName: wineIndex,
									query: query,
									facet: 'producer',
									params: {
										facetQuery: query,
										maxFacetHits: 3,
										highlightPreTag: '<span classname="highlight-algolia">',
										highlightPostTag: '</span>',
									},
								}
							],
							transformResponse({ facetHits }) {
								return facetHits
							},
						})
					},
					templates: {
						header() {
							return (
								<HeaderContainer className="aa-header">
									<Header className="aa-header">
										<img style={{
											width: '16px',
											height: '16px',
											margin: '2px 10px 1px 0',
											objectFit: 'contain'
										}} src={'img/icons/dark-store.png'} />
										PRODUCERS</Header>
								</HeaderContainer>
							)
						},
						item({ item }: any) {
							return (
								<ResultItemContainer onClick={() => handleProducerItemSubmit(item, setQuery, setIsOpen)}>
									<div>
										<ResultItem>{item._highlightResult && item._highlightResult.label && item._highlightResult.label.value ? parse(item._highlightResult.label.value) : item.label}</ResultItem>
									</div>
								</ResultItemContainer>
							)
						},
					},
				},
				{
					sourceId: 'wines',
					getItems() {
						return getAlgoliaResults({
							searchClient,
							queries: [
								{
									indexName: reverseObfuscateConstant(RP_ENV.WINE_INDEX) as string,
									query: query,
									params: {
										hitsPerPage: 3,
										highlightPreTag: '<span classname="highlight-algolia">',
										highlightPostTag: '</span>',
									},
								}
							],
						})
					},
					templates: {
						header() {
							return (
								<HeaderContainer className="aa-header">
									<Header className="aa-header">
										<img style={{
											width: '16px',
											height: '16px',
											margin: '2px 10px 1px 0',
											objectFit: 'contain'
										}} src={'img/icons/dark-tasting-notes.png'} />
										WINES
									</Header>
								</HeaderContainer>
							)
						},
						item({ item }: any) {
							return (
								<ResultItemContainer>
									<div>
										<ResultItem onClick={() => handleWineItemSubmit(item, setQuery, setIsOpen)}>
											<span>
												{
													item._highlightResult && item._highlightResult.display_name && item._highlightResult.display_name.value
														? parse(item._highlightResult.display_name.value)
														: (item.producer === item.name ? item.name : item.display_name)
												}
											</span>
										</ResultItem>
										{item.sub_region || item.appellation || item.sub_appellation ?
											<ResultItemSub>
												{item.sub_region ? <ResultItemSubSpan title={'Show wines from ' + item.sub_region} onClick={() => handleSubRegionSubmit(item.sub_region, setQuery, setIsOpen)}>{'' + item.sub_region}</ResultItemSubSpan> : ''}
												{item.sub_region && item.appellation ? ', ' : ''}
												{item.appellation ? <ResultItemSubSpan title={'Show wines from ' + item.appellation} onClick={() => handleAppellationSubmit(item.appellation, setQuery, setIsOpen)}>{item.appellation}</ResultItemSubSpan> : ''}
												{item.appellation && item.sub_appellation ? ', ' : ''}
												{item.sub_appellation ? <ResultItemSubSpan title={'Show wines from ' + item.sub_appellation} onClick={() => handleSubAppellationSubmit(item.sub_appellation, setQuery, setIsOpen)}>{item.sub_appellation}</ResultItemSubSpan> : ''}
											</ResultItemSub>
											: ''
										}
									</div>
								</ResultItemContainer>
							)
						},
						footer() {
							return (
								<Footer className="aa-footer" onClick={() => handleSeeAll(query, setQuery, setIsOpen, APP_CONFIG.SEARCH.TYPES.WINE)}>
									See all wine results
								</Footer>
							)
						},
					},
				},
				{
					sourceId: 'articles',
					getItems() {
						return getAlgoliaResults({
							searchClient,
							queries: [
								{
									indexName: articleIndex,
									query: query,
									params: {
										hitsPerPage: 3,
										highlightPreTag: '<span classname="highlight-algolia">',
										highlightPostTag: '</span>',
									},
								},
							],
						})
					},
					templates: {
						header() {
							return (
								<HeaderContainer className="aa-header">
									<Header className="aa-header">
										<img style={{
											width: '16px',
											height: '16px',
											margin: '2px 10px 1px 0',
											objectFit: 'contain'
										}} src={'img/icons/dark-article.png'} />
										ARTICLES</Header>
								</HeaderContainer>
							)
						},
						item({ item }: any) {
							return (
								<span>
									<ResultItemContainer>
										<div>
											<ResultItem onClick={() => handleArticleItemSubmit(item, setIsOpen)}>
												{item._highlightResult && item._highlightResult.title && item._highlightResult.title.value ? parse(item._highlightResult.title.value) : item.title}
											</ResultItem>
											<ResultItemSub>
												{item.author && item.author.name ? <ResultItemSubSpan title={'Show articles from ' + item.author.name} onClick={() => handleArticleAuthorSubmit(item.author.name, setQuery, setIsOpen)}>{'' + item.author.name}</ResultItemSubSpan> : ''}
											</ResultItemSub>
										</div>
									</ResultItemContainer>
								</span>
							)
						},
						footer() {
							return (
								<Footer className="aa-footer" onClick={() => handleSeeAll(query, setQuery, setIsOpen, APP_CONFIG.SEARCH.TYPES.ARTICLE)}>
									See all article results
								</Footer>
							)
						},
					},
				},
				{
					sourceId: 'regions',
					getItems() {
						return getAlgoliaFacets({
							searchClient,
							queries: [
								{
									indexName: wineIndex,
									query: query,
									facet: 'region',
									params: {
										facetQuery: query,
										maxFacetHits: 2,
										highlightPreTag: '<span id="region" classname="highlight-algolia">',
										highlightPostTag: '</span>',
									},
								},
								{
									indexName: wineIndex,
									query: query,
									facet: 'sub_region',
									params: {
										facetQuery: query,
										maxFacetHits: 2,
										highlightPreTag: '<span id="sub_region" classname="highlight-algolia">',
										highlightPostTag: '</span>',
									},
								},
								{
									indexName: wineIndex,
									query: query,
									facet: 'appellation',
									params: {
										facetQuery: query,
										maxFacetHits: 2,
										highlightPreTag: '<span id="appellation" classname="highlight-algolia">',
										highlightPostTag: '</span>',
									},
								},
								{
									indexName: wineIndex,
									query: query,
									facet: 'sub_appellation',
									params: {
										facetQuery: query,
										maxFacetHits: 2,
										highlightPreTag: '<span id="sub_appellation" classname="highlight-algolia">',
										highlightPostTag: '</span>',
									},
								},
							],
							transformResponse({ facetHits }) {
								return facetHits
							},
						})
					},
					templates: {
						header() {
							return (
								<HeaderContainer className="aa-header">
									<Header className="aa-header">
										<img style={{
											width: '16px',
											height: '16px',
											margin: '2px 10px 1px 0',
											objectFit: 'contain'
										}} src={'img/icons/dark-location.png'} />
										REGIONS</Header>
								</HeaderContainer>
							)
						},
						item({ item }: any) {
							return (
								<ResultItemContainer className="aa-header" onClick={() => handleRegionSubmit(item.label, item?._highlightResult?.label?.value, setQuery, setIsOpen, )}>
									<ResultItem className="aa-header">{item._highlightResult && item._highlightResult.label && item._highlightResult.label.value ? parse(item._highlightResult.label.value) : item.label}</ResultItem>
								</ResultItemContainer>
							)
						},
					},
				},
			]}
		/>
	)
}

export default SearchBoxAutoComplete