/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

// Services

// i18n
import moment from 'moment'
import { TranslationsUtil } from '../../../../utils'
import englishTranslation from './translations/en.json'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
import { useGetArticleByAuthorQuery } from '../../../../app/services'
const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

function MoreArticlesByAuthorMongo(props: { authorDetails: any }): ReactElement {

	const translate = translationUtil.getTranslator()
	// const { triggerTrackingHandler } = useArticleTracking()

	const { authorDetails } = props

	const {data: authorArticles, isLoading} = useGetArticleByAuthorQuery({authorId: authorDetails?.id, offset: 0, limit: 3}, { skip: !authorDetails.id})

	return (
		<div className="related-posts single-entry-section">
			<div className="block-heading block-heading--line">
				<h4 className="block-heading__title">{`${translate('More articles by')} ${authorDetails.name}`}</h4>
			</div>
			<div className="row row--space-between">
				{isLoading ? (<span>Loading ...</span>) : null}
				{authorArticles && authorArticles?.data.map((article: any, key: number) => (
					<div className="col-xs-12 col-sm-4" key={key}>
						<article className="post--vertical cat-4">
							<div className="post__thumb">
								<Link to={`/articles/${article.articleId}`}>
									{article.banner?.url ? <>
										<CloudImageUtils imgSrc={article.banner?.url} alt="image" ratio={0} params=''/>
									</>:<>
										<img src={'img/icons/default_article_banner_sm.jpg'} alt="image" />
									</>}
									
									
								</Link>
							</div>
							<div className="post__text">
								<h3 className="post__title typescale-1">
									<Link to={`/articles/${article.articleId}`}>{article.title}</Link>
								</h3>
								<div className="post__meta">
									<div className='publish-date'>
										<img src={'img/icons/clock.png'} />&nbsp;
										<span>{moment(article.published_date).format('MMM DD, YYYY').toString()}</span>
									</div>
								</div>
							</div>
						</article>
					</div>

				))}

			</div>
		</div>
	)
}

export default MoreArticlesByAuthorMongo