import styled from 'styled-components'

const commonTextStyles = `	
  font-family: Libre Franklin;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
`

export const BannerContainer = styled.div<{ backgroundImage: string }>`
	mix-blend-mode: multiply;
	border: solid 1px #979797;
	height: 331px;
	margin: 0px 0 40px;
	background: url('${(props) => props.backgroundImage}') no-repeat center;
`


export const ContentParagraph = styled.p`
	padding: 0 15px;
	font-size: 24px;
	color: #333;
	${commonTextStyles}

	@media (max-width: 550px){
		& {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
`

export const FeatureContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-left: 80px;
	padding-right: 80px;

	&.reverse{
		padding-top: 40px;
		padding-bottom: 5px;
	}

	& div.content {
		& span {
			font-size: 24px;
			font-weight: bold;
			color: #333;
			${commonTextStyles}
		}

		& p {
			margin: 20px 0px 20px 0px;
			max-width: 567px;
			font-size: 16px;
			font-weight: normal;
			color: #333;

			${commonTextStyles}
		}
	}

	@media (max-width: 768px) {
		flex-direction: column;
		gap:1rem;

		&.reverse{
			flex-direction: column-reverse;
		}

		& div.illustration {
			margin: auto;
		}
	}

	@media (max-width: 550px){
		& {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
`

export const OrangeButton = styled.button`
  	height: 33px;
	border-radius: 8px;
	background-color: #73333f;
	color: #fff;
	cursor: pointer;
	text-align: center;
	font-weight: bold;
	font-size: 14px;
	${commonTextStyles}
	border-color: transparent;
	padding: 6px 16px;

	&:hover {
		background-color: #8E4250;
	}
`

export const PageTitle = styled.span`
	font-size: 40px;
	font-weight: bold;
	color: #333;
	margin-bottom: 20px;
	${commonTextStyles}
`
