import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'

import { SUSTAINABILITY_PAGES } from '../../../../../configs'
import { ActionButton, GreenEmblemCriteria, HalvedContent, ManifestoContent, ManifestoHeader, ParagraphContainer, SectionHeader } from './styles'
import { CloudImageUtils, imageConvertion } from '../../../../../utils/CloudImageUtils'


function GreenEmblemManifesto(): ReactElement {
	const history = useHistory()

	return <div className="single-entry">
		<div className="mnmd-block mnmd-block--fullwidth featured-with-overlap-posts mnmd-block--contiguous">

			<article className="main-post post cat-1">
				<div className="main-post__inner">
					<div className="background-img background-img--darkened" style={{ backgroundImage: `url('${ imageConvertion(SUSTAINABILITY_PAGES.BANNER )}')` }}></div>
					<div className="post__text inverse-text">
						<div className="container">
							<div className="post__text-inner max-width-sm text-center" style={{ paddingBottom: '200px' }}>
								<h3 className="post__title typescale-5">Green Emblem Mission & Objectives</h3>
							</div>
						</div>
					</div>
				</div>
			</article>
		</div>

		<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
			<ManifestoHeader className="container">
				<div className='manifesto-title'>
					<h3>Robert Parker Green Emblem:</h3>
					<h3>Celebrating Sustainable Excellence in the Wine Industry</h3>
				</div>
				<img src={SUSTAINABILITY_PAGES.GREEN_EMBLEM_LOGO} height={200} width={200} />
			</ManifestoHeader>
		</div>

		<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
			<div className="container">
				<div className='manifesto-'>
					<SectionHeader>
                        WHAT IS THE ROBERT PARKER GREEN EMBLEM?
					</SectionHeader>
					<ParagraphContainer fontSize='18px' marginBottom='20px' >
                        The Robert Parker Green Emblem is a prestigious award created three years ago to recognize and celebrate the most extraordinary cases of sustainable efforts in the wine industry. It is a symbol of honor and distinction granted to wine producers that have demonstrated outstanding achievements in sustainability and environmental stewardship.
					</ParagraphContainer>
				</div>
			</div>
		</div>

		<div className="mnmd-block mnmd-block--fullwidth featured-with-overlap-posts mnmd-block--contiguous">
			<div>
				<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
					<div className="container">
						<ManifestoContent className="row flex-wrap">
							<HalvedContent>
								<div className='content content_left' >
									<SectionHeader>
										OUR MISSION
									</SectionHeader>
									<ParagraphContainer fontSize='18px' marginBottom='20px' >
										At <i>Robert Parker Wine Advocate</i>, we believe in the power of sustainability to drive positive change in the wine industry and protect our planet for future generations. The Green Emblem is our way of acknowledging and endorsing wineries that go above and beyond to embrace environmentally friendly practices throughout their viticulture, winemaking and organizational processes.
									</ParagraphContainer>
								</div>
								<div className='illustration'>
									<CloudImageUtils imgSrc={SUSTAINABILITY_PAGES.HOW_REWARDS_PRESENTED_LUSTRATION} alt="image" ratio={1.5} params=''/>
								</div>
							</HalvedContent>
							<GreenEmblemCriteria>
								<h2 className='title' >
                                    Criteria For The Green Emblem
								</h2>
								<p className='sub-title'>
                                To earn the Robert Parker Green Emblem, wineries must meet stringent criteria in three key areas:
								</p>
								<div className='section_content' >
									<div className='content' >
										<div className='title-child'>Viticulture and Winemaking</div>
										<p>We look for wineries that demonstrate a deep commitment to sustainable practices in their viticulture and winemaking methods. This includes adopting environmentally friendly farming techniques, protecting soil health, promoting biodiversity and minimizing the use of synthetic chemicals while maintaining wine quality.</p>
										<div className='title-child'>Organization and Facility</div>
										<p>Sustainability is not just about practices; it is a holistic approach to the entire organization. The Green Emblem recognizes wineries that prioritize sustainability within their organization and facilities. This includes the use of renewable energy sources, implementing recyclable packaging, practicing water-saving measures, managing waste responsibly and actively working to reduce their carbon footprint.</p>
										<div className='title-child'>Commitment and Ambassadorial Role</div>
										<p>Sustainable excellence extends beyond actions; it’s about fostering a culture of sustainability. We seek out wineries with dedicated teams that are passionate about sustainability, offer training on sustainable approaches and initiatives and share the producer’s ethos with customers, local communities and industry peers.</p>
									</div>
								</div>
							</GreenEmblemCriteria>
							<HalvedContent style={{ marginTop: '2rem' }}>
								<div className='illustration'>
									<CloudImageUtils imgSrc={SUSTAINABILITY_PAGES.WHAT_QUALIFIES_GREEN_EMBLEM_ILLUSTRATION} alt="image" ratio={1.5} params=''/>
								</div>
								<div className='content content_right' >
									<SectionHeader>
                                        A RARE AND CAREFULLY AWARDED DISTINCTION
									</SectionHeader>
									<ParagraphContainer fontSize='14px' marginBottom='20px' >
                                        The Robert Parker Green Emblem is not given out lightly. It is awarded only after careful consideration of first-hand reviewer knowledge. Our expert team thoroughly evaluates each candidate to ensure that only the most deserving wineries receive this prestigious distinction.
									</ParagraphContainer>
									<SectionHeader>
                                        THE IMPACT OF THE GREEN EMBLEM
									</SectionHeader>
									<ParagraphContainer fontSize='14px' marginBottom='20px' >
                                        Once a winery is honored with the Robert Parker Green Emblem, all of their wines on RobertParker.com will carry this distinguished symbol going forward, until changes at the winery may result in the disctinction being rescinded. The emblem serves as a visible testament to the winery’s commitment to sustainability, allowing consumers to make informed choices and support environmentally responsible practices.
									</ParagraphContainer>
									<ParagraphContainer fontSize='14px' marginBottom='20px' >
                                        This recognition provides well-deserved visibility to our sustainability champions and encourages others to join the movement toward a greener wine industry.
									</ParagraphContainer>
								</div>
							</HalvedContent>
							<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap" style={{ marginTop: '2rem' }}>
								<div className="container">
									<div className='manifesto-'>
										<SectionHeader>
                                            JOINING THE GREEN EMBLEM COMMUNITY
										</SectionHeader>
										<ParagraphContainer fontSize='18px' marginBottom='20px' >
                                            We invite wineries from all corners of the world to become part of our esteemed community of sustainability champions. Embrace environmentally friendly practices, showcase your commitment to sustainability and be considered for the Robert Parker Green Emblem—a symbol of honor and recognition for your dedication to a greener future.
										</ParagraphContainer>
										<ParagraphContainer fontSize='18px' marginBottom='20px' >
                                            Together, let&apos;s create a positive impact and foster a more sustainable and environmentally conscious wine industry.
										</ParagraphContainer>
									</div>
								</div>
							</div>
							<hr />
							<div className='manifesto-action'>
								<ActionButton onClick={() =>  history.push('/sustainability-and-wine/green-emblem-list')}>BROWSE THE WINERIES</ActionButton>
							</div>
						</ManifestoContent>
					</div>
				</div>
			</div>
		</div>

		
	</div>
}

export default GreenEmblemManifesto
