import React, { ReactElement, ReactNode } from 'react'
import { BackButton, ProceedButton, RemoveModal } from './styles'

type ModalType = {
	isOpen: boolean;
	onClose: () => void;
	children: ReactNode;
	onProceed: () => void;
	width?: number;
    leftButtonLabel?: string;
    rigthButtonLabel?: string;
}

function CustomModal({ isOpen, onClose, children, onProceed, width, leftButtonLabel, rigthButtonLabel }: ModalType): ReactElement {

	return (
		<RemoveModal className={`${isOpen ? 'open' : 'closing'}`}>
			<div style={{ width }} className="modal-container">
				<div className='mobile-content'>
					<div className='modal-title'>
						<span className="close-btn" onClick={onClose}>&times;</span>
					</div>
					<div className='modal-body'>
						{children}
					</div>
				</div>
				<div className='modal-footer'>
					<BackButton onClick={onClose}>{leftButtonLabel || 'CANCEL'}</BackButton>
					<ProceedButton onClick={onProceed}>{rigthButtonLabel || 'CONFIRM'}</ProceedButton>
				</div>
			</div>
		</RemoveModal>
	)
}

export default CustomModal
