/* eslint-disable react/prop-types */
import React, { ReactElement } from 'react'
import { Control, Controller, FieldValues, RegisterOptions } from 'react-hook-form'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { DropdownContainer, ErrorMessage } from '../styles'

interface Option {
    label: string;
    value: string;
}

interface MultiSelectProps {
    name: string;
    control: Control<FieldValues>;
    options: Option[];
    placeholder?: string;
    rules?: RegisterOptions;
	disabled?: boolean;
}

function MultiSelect({
	name,
	control,
	options,
	placeholder = 'Select business type',
	rules,
	disabled
}: MultiSelectProps): ReactElement {

	const animatedComponents = makeAnimated()

	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ field: { value, onChange }, fieldState: { error } }) => (
				<DropdownContainer isError={error ? true : false}>
					<Select
						isDisabled={disabled}
						classNamePrefix="custom-selector"
						className='multi-select'
						onChange={onChange}
						closeMenuOnSelect={false}
						components={animatedComponents}
						isMulti
						options={options}
						placeholder={placeholder}
						value={value || ''}
					/>
					{error ? <ErrorMessage>{error?.message as string}</ErrorMessage> : <p style={{ paddingTop: 7 }}></p>}
				</DropdownContainer>
			)}
		/>
	)
}

export default MultiSelect