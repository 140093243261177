/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS, RP_ENV } from '../../configs'
import { GetBaseQuery } from '../../utils/'
import { MAX_RETRIES } from './constant'
import { reverseObfuscateConstant } from '../../utils/Constant'

export const UserGroupAPI = createApi({
	reducerPath: 'UserGroupAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2), 'application/json'),
	endpoints: (builder) => ({
		getCompanyGroups: builder.query<any, string>({
			query: (managerID: string) => `${API_ENDPOINTS.get_company_group}?managerId=${managerID}`,
		}),
		removeUserFromCompanyGroup: builder.mutation({
			query: (params: any) => {
				return {
					url: API_ENDPOINTS.remove_user_from_group,
					method: 'POST',
					body: params
				}
			}
		}),
		addUserFromCompanyGroup: builder.mutation({
			query: (params: any) => {
				return {
					url: API_ENDPOINTS.add_user_from_group,
					method: 'POST',
					body: params
				}
			}
		}),
		getJoinGroupInvitationNewUser: builder.query({
			query: (tokenID: string) => `${API_ENDPOINTS.join_group_invitation}?token=${tokenID}`,
		}),
		joinCompanyGroupNewUser: builder.mutation({
			query: (params: any) => {
				return {
					url: API_ENDPOINTS.join_group_submit_new,
					method: 'POST',
					body: params
				}
			}
		}),
		getJoinGroupInvitationExistingUser: builder.query({
			query: (tokenID: string) => `${API_ENDPOINTS.join_group_invitation}?token=${tokenID}`,
		}),
		reInviteGroupUser: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return {
					url: API_ENDPOINTS.reinvite_group_user,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		}),
	}),
})


export const {
	useGetCompanyGroupsQuery,
	useRemoveUserFromCompanyGroupMutation,
	useAddUserFromCompanyGroupMutation,
	useGetJoinGroupInvitationNewUserQuery,
	useJoinCompanyGroupNewUserMutation,
	useGetJoinGroupInvitationExistingUserQuery,
	useReInviteGroupUserMutation
} = UserGroupAPI