import { ContentRenderer } from '../interfaces/ContentRenderer'
import moment from 'moment'
import { ArticleWriter } from '../interfaces/ArticleWriter'
import Reviewer from '../classes/Reviewer'

import { store } from '../../app/store'
export default class Content implements ContentRenderer {
	protected published_date = ''

	GetAuthor(
		authorPlaceholder: AuthorReference,
		pool: (ReviewerJSON | SommelierJSON)[]
	): ArticleWriter {
		const match = pool.filter(
			(data) =>
				data.id === authorPlaceholder.id || data.name === authorPlaceholder.name
		)[0]

		return new Reviewer({
			id: match?.id ? match.id : '',
			bio: match?.bio ? match.bio : '',
			base: '',
			associated_regions: '',
			name: match?.name ? match.name : '',
			profile_image: {
				id: match && match.profile_image ? match.profile_image.id : '',
				url: match && match.profile_image ? match.profile_image.url : '',
			},
			type: match?.type ? match.type : '',
			social: match?.social ? match.social : '',
		})
	}

	getFormattedDate(input: string, format: string): string {
		return moment(input).format(format).toString()
	}

	getPublishedDate(format: 'display' | 'title'): string {
		const dateFormats: ContentDateFormats = {
			title: 'MMM DD, YYYY at hh:mm a',
			display: 'MMM DD, YYYY',
		}

		return this.getFormattedDate(this.published_date, dateFormats[format])
	}

	IsUserAuthorized(): boolean {
		const { User } = store.getState().Authentication
		return User.accessToken ? true : false
	}
}
