// Styles
import styled from 'styled-components'

export const HeaderContainer = styled.div`
	@media only screen and (max-width: 991px) {
		padding-left: 5px;
		height: fit-content;
	}
	width: 100%;
	margin: 10px 0 0;
	padding: 9px 0px 10px 0;
	background-color: #e5e5e5;
`

export const Header = styled.div`
	height: 19px;
	margin: 0 0 0 10px;
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: var(--black-two);
`

export const Footer = styled.div`

	margin: 10px 0px 10px 0px;
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #a08436;
	cursor: pointer;
	transition: font-weight 0.1s ease-in-out;

	&:hover {
		font-weight: bold;
	}

	@media only screen and (max-width: 991px) {
		margin-left: 40px;
	}

	
`

export const ResultItemContainer = styled.div`
	@media only screen and (max-width: 991px) {
		padding-left: 38px;
		height: fit-content;
	}
	width: 100%;
	padding: 2px 0px 2px 32px;
	background-color: var(--white-three);
`

export const ResultItem = styled.div`
	@media only screen and (max-width: 510px) {
		height: fit-content;
		margin-right: 6px;
	}
	@media only screen and (max-width: 350px) {
		height: fit-content;
		margin-right: 6px;
	}

	height: auto;
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #333;
	margin-bottom: 2px;
`

export const ResultItemSub = styled.div`
	height: 19px;
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #777;
`

export const ResultItemSubSpan = styled.span`
	&:hover {
		text-decoration: underline;
	}
`

export const CategoryItemSpace = styled.div`
	padding-top: 10px;
	padding-bottom: 10px;
`