/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
type FilterData = Record<string, string[]>

function convertObject(obj: any): FilterData {
	const convertedObj: FilterData = {}
	for (const key in obj) {
		const values = Object.keys(obj[key]).map(subKey => subKey.toLowerCase())
		convertedObj[key] = values
	}
	return convertedObj
}

function removePrefix(obj: FilterData): FilterData {
	const result: FilterData = {}

	for (const key in obj) {
		if (key in obj) {
			const values = obj[key].map(value => value.split(':')[1])
			result[key] = values
		}
	}

	return result
}

function intersection(objA: FilterData, objB: FilterData): FilterData {
	const result: FilterData = {}

	for (const key in objA) {
		if (key in objA && key in objB) {
			const valuesA = objA[key]
			const valuesB = objB[key]
			const commonValues = valuesA.filter(value => valuesB.includes(value))
			if (commonValues.length > 0) {
				result[key] = commonValues.map(value => `${key}:${value}`)
			}
		}
	}
	return result
}

function convertToString(obj: FilterData): Record<string, string> {
	const result: Record<string, string> = {}

	for (const key in obj) {
		if (obj[key] !== undefined) {
			result[key] = obj[key].join(', ')
		}
	}

	return result
}

export {
	convertObject,
	intersection,
	removePrefix,
}