import Appellation from './Appellation'
import Content from './Content'
export default class Wine extends Content {
	id: string

	appellation: Appellation

	certified: string

	color_class: ColorClass = {
		label: null,
		value: null,
	}

	country: Country

	drink_date: string

	dryness: Dryness

	image: Image

	importers: string[]

	locale: Locale

	location: WineLocation

	maturity: Maturity

	name: string

	producer: Producer

	publication_code: string

	rating: Rating

	region: Region

	site: Site

	tasting_note_count: number | null

	type: WineType

	url: string

	varieties: Variety[] | null

	vintage: string
	
	published_date: any

	constructor(data: WineJSON) {
		super()

		this.id = data.id

		this.appellation = new Appellation(data.appellation?.id)

		this.certified = data.certified

		this.color_class.label = data.color_class?.label
		this.color_class.value = data.color_class?.value

		this.country = data.country

		this.drink_date = data.drink_date

		this.dryness = data.dryness

		this.image = data.image

		this.importers = data.importers

		this.locale = data.locale

		this.location = data.location

		this.maturity = data.maturity

		this.name = data.name

		this.producer = data.producer

		this.publication_code = data.publication_code

		this.rating = data.rating

		this.region = data.region

		this.site = data.site

		this.tasting_note_count = data.tasting_note_count

		this.type = data.type

		this.url = data.url

		this.varieties = data.varieties

		this.vintage = data.vintage
	}

	GetRating(): Rating | null {
		if (super.IsUserAuthorized()) {
			return this.rating
		}

		return null
	}

	GetVarietiesDisplay(): string {
		const labels = this.varieties?.map((variety) => variety.label)

		return labels ? labels?.join(',') : ''
	}
}
