/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useState, useEffect, ReactNode } from 'react'
import { ContentContainer, ModalBody, ModalFooter, Separator } from './styles'
import { isMobile } from 'react-device-detect'
import { useGetWinesByLocationQuery } from '../../../app/services'
import { validateURL } from '../../../utils/ValidateUrl'
import { useAppSelector } from '../../../app/hooks'
import { Users as UsersFromStore} from '../../../app/slices/Users'

const getWebLayout = (id: string, price_listing_key: number, priceDetail: any, data: any) => (
	<div key={id + price_listing_key}>
		<div className='price-list-item-container' key={id + price_listing_key}>
			<div style={{ width: '400px' }}>
				<div><a href={validateURL(`${priceDetail['link']}`) ? `${priceDetail['link']}` : ''} target='_blank' className="name" rel="noreferrer">{priceDetail['merchant-name']}</a></div>
				<div className='retailer-detail'>{priceDetail['country']}</div>
				<div className='retailer-detail'>{priceDetail['physical-address']}</div>
			</div>
			<div className='bottle-size' style={{ width: '80px', padding: '0 0.5rem' }}>
				{priceDetail['bottle-size']}
			</div>
			<div>
				<span className='price-container'>{data['list-currency-code']}<br />{priceDetail['price']}</span>
			</div>
		</div>
		{price_listing_key < (data['prices-and-stores'].length - 1) ? (<Separator></Separator>) : null}
	</div>
)

const getMobileLayout = (id: string, price_listing_key: number, priceDetail: any, data: any) => (
	<div className='container' key={id + price_listing_key}>
		<div className='price-list-item-container' key={id + price_listing_key}>
			<div style={{ width: '400px' }}>
				<div><a href={validateURL(`${priceDetail['link']}`) ? `${priceDetail['link']}` : ''} target='_blank' className="name" rel="noreferrer">{priceDetail['merchant-name']}</a></div>
				<div className='retailer-detail'>{priceDetail['country']}</div>
				<div className='retailer-detail'>{priceDetail['physical-address']}</div>
			</div>
			<div className='bottle-size' style={{ width: '80px', padding: '0 0.5rem' }}>
				{priceDetail['bottle-size']}
			</div>
			<div>
				<span className='price-container'>{data['list-currency-code']}<br />{priceDetail['price']}</span>
			</div>
		</div>
		{price_listing_key < (data['prices-and-stores'].length - 1) ? (<Separator></Separator>) : null}
	</div>
)


/**
 * NOTE: 
 * This "any" definition on toggleFilter needs to be changed to a more appropriate type. 
 * Figure it out dude @joshua by @joshua
 * @param props 
 * @returns 
 */

const ModalContainer = ({ id, children }: { id: string, children: ReactNode }) => {
	return(
		<div id={`price-listing-modal_${id}`}>
			{children}
			<ModalFooter>
				<div className='logo'>
					<img src='/img/icons/wine-searcher.png' />
					<div>
						<span className='sub'>Powered by</span>
						<span className='title'>wine-searcher</span>
					</div>
				</div>
			</ModalFooter>
		</div>
		
	) 
}

interface PriceListingsModalProps {
	wine: any;
	isModalOpen: (value: boolean) => void;
  }

function PriceListingsModal({ wine, isModalOpen }: PriceListingsModalProps): ReactElement {
	const { objectID, vintage, id } = wine
	const wineID = objectID || id

	const [updatedParams, setUpdatedParams] = useState<string>('')
	const { data: wineData, isLoading, isFetching } = useGetWinesByLocationQuery(updatedParams, { skip: updatedParams === '' })
	const { userData } = useAppSelector(UsersFromStore)

	useEffect(() => {
		const wineName = wine && wine.display_name ? wine.display_name : wine && wine.name ? wine.name : null

		if (wine && wineName && vintage) {
			let parameter = `${encodeURIComponent(wineName)}&vintage=${vintage}`

			let countryName = userData?.profile?.address?.country?.name || userData?.profile?.preferences?.country?.name
			
			if(!countryName)
				countryName = 'United States'

			if (countryName)
				parameter = parameter + '&location=' + countryName

			// const regionName = wine.region && wine.region.name ? wine.region.name : wine.region ? wine.region : null
			// if (regionName)
			// 	wineNameParam = wineNameParam + ' ' + regionName

			// const subRegionName = wine.sub_region && wine.sub_region.name ? wine.sub_region.name : wine.sub_region ? wine.sub_region : null
			// if (subRegionName)
			// 	wineNameParam = wineNameParam + ' ' + subRegionName

			// const appellationName = wine.appellation && wine.appellation.name ? wine.appellation.name : wine.appellation && typeof wine.appellation === 'object' ? null : wine.appellation
			// if (appellationName)
			// 	wineNameParam = wineNameParam + ' ' + appellationName

			// const subAppellationName = wine.sub_appellation && wine.sub_appellation.name ? wine.sub_appellation.name : wine.sub_appellation ? wine.sub_appellation : null
			// if (subAppellationName)
			// 	wineNameParam = wineNameParam + ' ' + subAppellationName


			setUpdatedParams(parameter)
		}
	}, [wineID])
	
	const modalCloseHandler = () => {
		isModalOpen(false)
	}

	if (isLoading || isFetching) return <ContentContainer id={wineID}>
		<ModalContainer id={wineID}>
			<div className='pricelist-container'>
				<div className='header'>
					<div className='login-tab'>
						<div>FIND IT ONLINE</div>
					</div>
					<span className='modal-close' onClick={()=> modalCloseHandler()}><img src="img/icons/close.png" /></span>
				</div>
				<div className="tab-content" style={{ width: '600px' }}>
					<ModalBody className="modal-body" maxHeight={'400px'}>
						<div className="modal-title text-center">
							<br />
							<span>Loading...</span>
							<br />
							<br />
						</div>
					</ModalBody>
				</div>
			</div>
		</ModalContainer>
	</ContentContainer>
		
	return (
		<ContentContainer id={wineID}>
			<ModalContainer id={wineID}>
				{wineData && wineData.success ? 
					<>
						<div className='pricelist-container'>
							<div className='header' style={{padding: 24}}>
								<div className='modal-title'>
									<div>FIND IT ONLINE</div>
								</div>
								<span className='modal-close' onClick={()=> modalCloseHandler()}><img src="img/icons/close.png" /></span>
							</div>
							<div className="tab-content">
								<div style={{ paddingBottom:'10px', paddingLeft: 14 }}>All {wineData.data['prices-and-stores'].length} results</div>
								{wineData.data['prices-and-stores'].map((priceDetail: any, price_listing_key: number) =>
									isMobile
										? getMobileLayout(wineID, price_listing_key, priceDetail, wineData.data)
										: getWebLayout(wineID, price_listing_key, priceDetail, wineData.data)
								)}
							</div>
						</div>
					</> : (wineData && !wineData.success && wineData.message === 'No prices are found' && (
						<div className='pricelist-container'>
							<div className='header'>
								<div className='modal-title'>
									<div>FIND IT ONLINE</div>
								</div>
								<span className='modal-close' onClick={()=> modalCloseHandler()}><img src="img/icons/close.png" /></span>
							</div>
							<div className="tab-content" style={{ width: '600px' }}>
								<ModalBody className="modal-body" maxHeight={'400px'}>
									<div className="modal-title text-center">
										<br />
												No Available list...
										<br />
										<br />
									</div>
								</ModalBody>
							</div>
						</div>
					)
					)}
			</ModalContainer>
		</ContentContainer>
	)
}
export default PriceListingsModal
