import { createSlice } from '@reduxjs/toolkit'
import { BridgeAPI } from '../services'
import { RootState } from '../store'

const initialState: ProducerFetchResults = {
	companies: [],
	total: 0,
}

const ProducerSlice = createSlice({
	name: 'ProducerSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addMatcher(
				BridgeAPI.endpoints.getProducersFromId.matchPending,
				(state, action) => {
					// placeholder
				}
			)
			.addMatcher(
				BridgeAPI.endpoints.getProducersFromId.matchFulfilled,
				(state, action) => ({
					...state,
					companies: action.payload,
					total: action.payload ? action.payload.length : 0,
				})
			)
			.addMatcher(
				BridgeAPI.endpoints.getProducersFromId.matchRejected,
				(state, action) => {
					// placeholder
				}
			)
	},
})

export default ProducerSlice.reducer

export const Producers = (state: RootState): ProducerFetchResults =>
	state.Producers
