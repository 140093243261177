import React, { ReactElement } from 'react'

import { Content, PageContent, Quote } from './styles'
import { Link } from 'react-router-dom'
import { validateURL } from '../../../../../utils/ValidateUrl'

const QuoteHighlight = (): ReactElement => (
	<div className='bar'>
		<div className={'bar-highlight'} >&nbsp;</div>
	</div>
)

export const About = (): ReactElement => (
	<div className="single-entry">
		<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
			<div className="container">
				<PageContent>
					<Content>
						<div className={'content-title'} >
							The Wine Advocate, RobertParker.com & Editorial Calendar
						</div>
						<p style={{paddingTop:'10px'}}>
							<em>The Wine Advocate</em> has traditionally been a bi-monthly publication
							and Robert Parker Wine Advocate continues this tradition, although
							we now publish articles and reviews every week. At our core, we
							publish regionally focused reports, which mainly offer wine reviews
							of new wine releases, but we also produce important vintage
							retrospectives and occasionally single-label verticals and producer
							profiles.
						</p>
						<p style={{paddingTop:'10px'}}>
							Until 2002, <em>The Wine Advocate</em> was exclusively available in print
							format and available only to subscribers. While we still operate a
							subscriber-only model, in 2002 we launched our highly successful
							eRobertParker.com website, which has since become simply
							RobertParker.com.
						</p>
						<p style={{paddingTop:'10px'}}>
							Since 2019, Robert Parker Wine Advocate has been a wholly owned subsidiary of the Michelin Group.
						</p>
						<p style={{paddingTop:'10px'}}>Key RobertParker.com features include:</p>
						<ul>
							<li>
								An online database housing over 450,000 Wine Reviews and
								Ratings—more than any other wine reviews website!
							</li>
							<li>
								Reviews of wines from more than 25 different countries and over
								500 different regions around the wine world.
							</li>
							<li>
								Instant online access to every <em>
									Wine Advocate / Robert Parker Wine
									Advocate issue
								</em> dating back to 1992.
							</li>
							<li>
								Fast access to the most up-to-date reviews on new wine releases
								and older vintages produced by our team of renowned, unbiased
								regional wine authorities, each of whom works exclusively for
								<em>Robert Parker Wine Advocate</em>.
							</li>
							<li>
								The most flexible and robust Wine Reviews and Articles search
								capabilities of any wine criticism website
							</li>
							<li>
								Our “Advanced Search” capabilities allow subscribers to search our
								database with unrivaled precision and ease, utilizing more filters
								than any other wine reviews database, including by: Vintage,
								Author, Issue, Article, Region, Producer, Wine Type, Color,
								Sweetness, Rating and Price.
							</li>
							<li>
								Our incredibly refined Regional searches allow users to filter
								across up to five regional levels—more than any other wine reviews
								database—and encompassing wines.
							</li>
							<li>
								Access to our RP Cellar management tool and our famous Vintage
								Guide, plus much, much more!
							</li>
						</ul>
						<p style={{paddingTop:'10px'}}>
							The number of wine reviews published on our RobertParker.com website
							every month varies, ranging from 2,000 to 6,000+, depending on the
							Editorial Calendar for that month. The Editorial Calendar looks to
							include the whole world of wine regions, which is indeed a tall
							order. Most of the major wine regions of the world are covered
							annually, although some smaller regions will be reviewed every two
							to three years. <em>The Wine Advocate</em> Editorial Calendar is available to
							view online in the <Link to="/editorial-calendar" title="Resources"><u>Resources</u></Link> section of our website.
						</p>
						<p style={{paddingTop:'10px'}}>
							We now also offer smaller, online-only issues in between our
							regularly scheduled monthly issues. This offers our readers even more timely access to tasting notes of newly released wines.
						</p>
					</Content>
				</PageContent>
			</div>
		</div>
	</div>
)

export const Origins = (): ReactElement => (
	<div className="single-entry">
		<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
			<div className="container">
				<PageContent>
					<Content>
						<div className={'content-title'}>
							The Origins of The Wine Advocate
						</div>
						<p style={{paddingTop:'10px'}}>
							The origins of <em>The Wine Advocate</em> can be traced back to as far as 1967, when Robert M. Parker Jr. took a brief break from American college life to make his first trip to France on the heels of a young lady friend who was studying in Alsace. That lady was soon to become Mrs. Patricia Parker and remains so to this day. Apart from getting the girl, Parker had discovered wine during this first trip to France and developed a taste for it, which quickly turned into an obsession. In the mid to late 1960s, the concept of <em>The Wine Advocate</em> was just a twinkle in Robert Parker’s eyes, but one that his friends and family, bent on him becoming a lawyer, discouraged as fanciful. He graduated from University of Maryland School of Law in 1973 and joined a firm as a practicing lawyer near where he and his young wife had grown up, in Baltimore Maryland. But his professional heart was already lost to wine, and in 1978 Parker decided to put his money where his mouth was and began publishing his own wine guide, <em>The Baltimore-Washington Wine Advocate</em>.
						</p>
						<p style={{paddingTop:'10px'}}>
							In the early 1970s, when Parker was conceiving of writing his own
							wine guide, he was taken with the work of Ralph Nader, an American
							political activist who sought to {'"out"'} corporate and political
							corruption by challenging compromised propaganda. Parker recognized
							that much of what was then being written about wine was compromised
							by the financial agendas of many of the famous wine writers of the
							day. He dreamt of a publication that could be free of financial ties
							to wineries and merchants, a guide that would produce wholly
							unbiased views on wines and that served only the interests of wine
							consumers. This would be a magazine that would be funded purely by
							subscribers-the people that buy, read and use it. And so, <em>The Wine Advocate</em> started and remains true to this day.
						</p>
						<p style={{paddingTop:'10px'}}>
							In 1979, the name of Robert Parker’s magazine was changed to <em>The
								Wine Advocate</em>. In 1983, Parker’s controversial glowing reviews of
							the 1982 Bordeaux vintage, tasted from barrels in the wineries,
							created a stir among most other major wine writers who felt the
							vintage was too ripe and the wines wouldn’t age. When Parker turned
							out to be right about the greatness of this vintage, his reputation
							and subscriber base soared. By 1984 he was able to leave his law
							career to focus on <em>The Wine Advocate</em> and wine full-time.
						</p>
						<p style={{paddingTop:'10px'}}>
							Today, Robert Parker Wine Advocate has subscribers in every state in
							the United States, and in more than 40 countries throughout the wine
							world. Virtually every knowledgeable observer agrees that <em>The Wine
								Advocate</em> exerts the most significant influence on the serious wine
							consumer’s buying habits and trends not only in America but also in
							France, England, Switzerland, Japan, Hong Kong, Singapore, Russia,
							Mexico, Brazil, China and every other major wine market of the world.
						</p>
					</Content>
				</PageContent>
			</div>
		</div>
	</div>
)

export const Reports = (): ReactElement => (
	<div className="single-entry">
		<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
			<div className="container">
				<PageContent>
					<Content>
						<div className={'content-title'} >
							The Wine Advocate Reports
						</div>
						<p style={{paddingTop:'10px'}}>
							The format of <em>The Wine Advocate</em> reports usually includes an
							introductory article accompanied by the wine reviews. The
							introductory article offers an in-depth view of the recent vintages
							or vintage that is being reviewed. This take on the vintage(s) is
							not copied from regional and/or producer marketing collateral. The
							vintage reports are our reviewers’ expert opinions on the vintage,
							based predominately on their tastings and taking into consideration
							their regional visits and interviews with
							winemakers/viticulturalists (grape growers). The introductory
							article may also contain information about current trends within the
							region, the recent happenings at key producers and the
							pricing/current state of the market(s) for those regions’ wines.
						</p>
						<div className={'content-title spaced'} >Wine Reviews</div>
						<p style={{paddingTop:'10px'}}>
							Each of our wine reviews includes a tasting note that describes the
							style, character/personality and quality of the wine, a rating and a
							recommended drinking window. And, if available, the wine review will
							list the suggested retail price of the wine as reported by the
							wineries and/or importers as well as naming the importer(s). Often
							our wine reviews will also include a comment on whether or not the
							wine offers good value in the context of the market. In short, we
							aim to convey all the relevant information our readers require in
							order to make an informed purchasing choice to suit their wine
							needs.
						</p>
						<div className={'content-title spaced'} >Code of Ethics</div>
						<p style={{paddingTop:'10px'}}>
							Robert Parker sites two principal forces that shaped his view of a
							wine critic’s responsibilities. Firstly, he was significantly
							influenced by the independent philosophy of consumer advocate Ralph
							Nader. And secondly, he was marked by the indelible impression left
							by his law school professors, who in the post Watergate era pounded
							into their {'students\''} heads a broad definition of conflict of
							interest. These two forces have governed the purpose and soul of
							<em>The Wine Advocate</em>.
						</p>
						<p style={{paddingTop:'10px'}}>
							We firmly believe that the role of the critic is to render judgments
							that are reliable. They should be based on extensive experience and
							on a trained sensibility for whatever is being reviewed. In
							practical terms, this means critics should be, above and beyond all
							else, independent. This demands that <em>The Wine Advocate</em>, Inc. covers
							all travel, accommodation and sustenance costs associated with
							reviewing wines for <em>The Wine Advocate</em> reports. Our reviewers are not
							allowed to accept any gifts or gifts in kind from wineries or any
							person associated with the sale, distribution or promotion of wines.
							Furthermore, <em>Robert Parker Wine Advocate</em> and the website,
							RobertParker.com, are and will remain subscriber funded and
							supported. We do not permit any advertising in these publications.
						</p>
						<div className={'content-title spaced'} >How We Review Wines</div>
						<p style={{paddingTop:'10px'}}>
							The majority of our critical review tastings for <em>The Wine Advocate</em>
							are done as large-scale annual tastings that encompass the wines of
							a major region and the recent vintages of that region, which are
							just being released or will soon be released for consumer purchase
							opportunities. Our reviewers conduct the bulk of their tastings
							under professional office tasting room conditions, where they can
							control important tasting factors such as room temperature, bottle
							temperature, lighting, glassware type / cleanliness and ambient
							smells. We taste from full bottles that are opened and prepared by
							the reviewers themselves or members of our team. And we taste the
							wine in their peer-groups, such that the same or similar regions,
							grape varieties, styles and vintages are tasted together in flights.
						</p>
						<p style={{paddingTop:'10px'}}>
							There are exceptions to our core means of tasting for <em>
								The Wine
								Advocate
							</em>, which include tastings performed during the annual winery
								visits our reviewers conduct each year for each major region in
								order to gain a more in-depth and unbiased understanding of what is
								happening in the regions. The other major exception is when we
								conduct barrel tastings, as is the case with the production of our
								annual Bordeaux en primeur report. The wineries that are selected to
								be tasted in this manner are chosen solely at the discretion of our
								review team.
						</p>
						<Quote>
							<QuoteHighlight />
							<div className={'quote-content'}>
								We do not usually taste wines for <em>The Wine Advocate</em> reports blind.
							</div>
							<QuoteHighlight />
						</Quote>
						<p style={{paddingTop:'10px'}}>
							{'Here’s'} why: Professionally speaking, blind tastings can serve as a
							rigorous means of testing tasting skills, experience and wine
							knowledge, as demonstrated by institutions that perform annual blind
							tasting exams for their students such as the Institute of Masters of
							Wine or the Court of Master Sommeliers as part of their
							accreditation processes. Beyond this, blind tasting can help to
							dispel any preconceptions that the taster may have about a
							particular grape, label, region or vintage, for examples, although
							presumably such preconceptions {'shouldn’t'} exist among unbiased
							professionals.
						</p>
						<p style={{paddingTop:'10px'}}>
							At <em>The Wine Advocate</em>, our reviewers are highly experienced tasters,
							specially trained to understand and recognize wine quality in a
							glass. They do not allow terms like {'"Grand Cru"'} on the label to
							persuade them to believe a wine is necessarily better than one that
							does not bear these words. We clearly understand that the major and
							perhaps the most valid justification for blind tasting among
							professionals is to preclude the possibility of being swayed by the
							financial agendas of the taster’s employing publication or company
							or any other personal bias considerations that may exist (disclosed
							or not). This is not an issue for us at <em>The Wine Advocate</em>. (Refer to
							our {'"Code of Ethics"'} section.)
						</p>
						<p style={{paddingTop:'10px'}}>
							What are the shortcomings of blind tasting? When done strictly for
							examination purposes or fun, none. But as professional critics, it
							is our belief that knowing what the wine is can often help an
							unbiased, professional taster understand the quality and potential
							of a wine a little better. Having considerable experience of tasting
							a particular wine when it is young / first released and knowing
							intimately how that wine tends to develop in bottle is a rare and
							valuable asset possessed by the best wine critics. All this
							knowledge and experience is tossed out the window when a wine is
							tasted blind. Judging a wine in the context of a critic’s recalled
							experience of other vintages of that wine ultimately results in a
							better assessment of the wine than knowing nothing at all. The wine
							world is built on the premise that singular wines develop singularly
							– so why should they all be professionally assessed as one of the
							herd?
						</p>
						<Quote>
							<QuoteHighlight />
							<div className={'quote-content'}>Sample Call-Outs</div>
							<QuoteHighlight />
						</Quote>
						<p style={{paddingTop:'10px'}}>
							With regards to samples for reviews, these are either purchased or
							requested by our reviewers, specifically for the annual reports for
							their areas of responsibility. For ethical reasons, unsolicited
							samples are not accepted for review. Our target audience is the wine
							consumer, not the wine trade. While it is important to maintain a
							professional relationship with the trade, we believe the independent
							stance required of a consumer advocate often, not surprisingly,
							results in an adversarial relationship with the wine trade. It can
							be no other way. In order to pursue this independence effectively,
							it is imperative that our reviewers maintain a distance from the
							trade. While this may be misinterpreted as aloofness, such
							independence guarantees hard hitting, candid, and uninfluenced
							commentary.
						</p>
						<p style={{paddingTop:'10px'}}>
							This all said, if a winery or importer would like their wines to be
							brought to the attention of the relevant regional reviewer so that
							the wines may be considered for review, we do offer a <a href={validateURL('https://goo.gl/forms/mCwWEUoJY0vvj7DU2') ? 'https://goo.gl/forms/mCwWEUoJY0vvj7DU2' : ''} target="_blank" title="Wine Submission Form" rel="noreferrer"><u>Wine Submission</u></a> form on our RobertParker.com website. We cannot however
							guarantee that by submitting this form, the wine will be included in
							the next sample call-out for that region. The choice of samples for
							Robert Parker Wine Advocate tastings remains solely at the
							discretion of our reviewers.
						</p>
					</Content>
				</PageContent>
			</div>
		</div>
	</div>
)