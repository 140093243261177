import React, { ReactElement } from 'react'
import './style.css'
import { isMobile } from 'react-device-detect'

const LoadingOverlay = () => {
	const loaderClass = `load-${isMobile ? 'mobile ' : ''}load-3`
	const textClass = isMobile ? 'text-mobile' : ''

	// Create an array of line divs
	const lines = Array.from({ length: 5 }, (_, index) => (
		<div key={index} className="line"></div>
	))

	return (
		<div className='overlay'>
			<div className="load">
				<div className={loaderClass}>
					{lines}
				</div>
				<p className={textClass}>Loading please wait...</p>
			</div>
		</div>
	)
}

export default LoadingOverlay