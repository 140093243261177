
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { Container, DisplayView, MainContainer, PageSubTittle, SearchBar, VerticalSeperator } from './styles'

// Page Contents
import MainContent from './MainContent'
import lookingGlass from '../../../../../images/looking-glass.png'
import vocabulary from './vocabulary.json'
import _ from 'lodash'
import { isMobile } from 'react-device-detect'

function ResourcesAboutWines(): ReactElement {
	const [sticky, setSticky] = useState('')
	const [main, setMain] = useState('')
	const [vocabularyData, setVocabularyData] = useState(vocabulary)

	useEffect(() => {
		window.addEventListener('scroll', mySideBar)
		return () => { window.removeEventListener('scroll', mySideBar) }
	}, [])

	function mySideBar() {
		const scrollTop = window.scrollY
		const stickyClass = scrollTop >= 290 ? 'sticky-sidebar' : ''
		const mainContent = scrollTop >= 290 ? 'padding-left' : ''
		setSticky(stickyClass)
		setMain(mainContent)
	}

	function scrollView(val: string) {
		setVocabularyData(val === 'ALL' ? vocabulary : vocabulary.filter(word => word.name.toString().toUpperCase().startsWith(val)))
		window.scrollTo({ top: 140, behavior: 'smooth' })
	}

	const debouncedChangeHandler = useMemo(
		() => _.debounce(handleSearchTerm, 100)
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [])

	function handleSearchTerm() {
		const searchBox = document.getElementById('search-term') as HTMLInputElement | null  //"prevent error 'object is possibly null"
		if (searchBox != null) {
			if (searchBox.value === '') {
				setVocabularyData(vocabulary)
			} else {
				setVocabularyData(vocabulary.filter(word => word.name.toString().toUpperCase().startsWith(searchBox.value.toString().toUpperCase())))
			}

		}
	}

	const classes = `${sticky}`
	const content = `${main}`

	return (
		<>
			<div className="mnmd-block mnmd-block--fullwidth mnmd-block--contiguous page-heading page-heading--has-background">
				<div className="container">
					{isMobile ? <h2 className="page-heading__title" style={{ paddingTop: '20px' }}>Glossary of Terms</h2> : <h2 className="page-heading__title">Glossary of Terms</h2>}
				</div>
			</div>
			<div className="mnmd-block mnmd-block--fullwidth single-entry">
				<MainContainer>
					<Container>
						<div className={classes}>
							<SearchBar>
								<div>
									<img src={lookingGlass} />
								</div>
								<input type="text" id='search-term' placeholder="Search for a term" onKeyDown={debouncedChangeHandler}></input>
							</SearchBar>
							<div className="mnm-dsub-col mnmd-sub-col--left" role="complementary">
								<PageSubTittle>Jump to a specific alphabet</PageSubTittle>
								<ul>
									<li onClick={() => scrollView('A')}>A</li>
									<li onClick={() => scrollView('B')}>B</li>
									<li onClick={() => scrollView('C')}>C&nbsp;</li>
									<li onClick={() => scrollView('D')}>D</li>
									<li onClick={() => scrollView('E')}>E&nbsp;</li>
									<li onClick={() => scrollView('F')}>F</li>
									<li onClick={() => scrollView('G')}>G</li>
									<li onClick={() => scrollView('H')}>H</li>
									<li onClick={() => scrollView('I')}>I</li>
									<li onClick={() => scrollView('J')}>J</li>
									<li onClick={() => scrollView('K')}>K</li>
									<li onClick={() => scrollView('L')}>L</li>
									<li onClick={() => scrollView('M')}>M</li>
									<li onClick={() => scrollView('N')}>N</li>
									<li onClick={() => scrollView('O')}>O</li>
									<li onClick={() => scrollView('P')}>P</li>
									<b>Q</b>
									<li onClick={() => scrollView('R')}>R</li>
									<li onClick={() => scrollView('S')}>S</li>
									<li onClick={() => scrollView('T')}>T</li>
									<li onClick={() => scrollView('U')}>U</li>
									<li onClick={() => scrollView('V')}>V</li>
									<li onClick={() => scrollView('W')}>W</li>
									<b>X</b>
									<b>Y</b>
									<b>Z</b>
									<b>#</b>
									<li onClick={() => scrollView('ALL')}>ALL</li>
								</ul>
							</div>
						</div>
						<hr />
						<DisplayView className={content}>
							<VerticalSeperator />
							<MainContent data={vocabularyData} />
						</DisplayView>
					</Container>
				</MainContainer>
			</div>
		</>
	)
}

export default ResourcesAboutWines