import React from 'react'

import { SOCIAL_MEDIA_LINKS } from '../../../../configs'
import { validateURL } from '../../../../utils/ValidateUrl'

const Social: React.FC = () => (
	<div>
		<div className="widget__title" style={{ marginBottom: '2rem' }}>
			<h4 className="widget__title-text">Social</h4>
		</div>
		<div className="mnmd-widget-social-counter__inner">
			<ul className="list-unstyled list-space-xs">
				<li>
					<a href={validateURL(`${SOCIAL_MEDIA_LINKS.FACEBOOK.url}`) ? `${SOCIAL_MEDIA_LINKS.FACEBOOK.url}` : ''} style={{ height: '7.9rem' }} target="_blank" rel="noreferrer" className="social-tile social-facebook facebook-theme-bg">
						<div className="social-tile__icon"><i className="mdicon mdicon-facebook"></i></div>
						<div className="social-tile__inner flexbox">
							<div className="social-tile__left flexbox__item">
								<h5 className="social-tile__title meta-font">Facebook</h5>
								<span className="social-tile__count">77k followers</span>
							</div>
							<div className="social-tile__right">
								<i className="mdicon mdicon-arrow_forward"></i>
							</div>
						</div>
					</a>
				</li>
				<li>
					<a href={validateURL(`${SOCIAL_MEDIA_LINKS.INSTAGRAM.url}`) ? `${SOCIAL_MEDIA_LINKS.INSTAGRAM.url}` : ''} style={{ height: '7.9rem' }} target="_blank" rel="noreferrer" className="social-tile social-instagram instagram-theme-bg">
						<div className="social-tile__icon"><i className="mdicon mdicon-instagram"></i></div>
						<div className="social-tile__inner flexbox">
							<div className="social-tile__left flexbox__item">
								<h5 className="social-tile__title meta-font">Instagram</h5>
								<span className="social-tile__count">276k followers</span>
							</div>
							<div className="social-tile__right">
								<i className="mdicon mdicon-arrow_forward"></i>
							</div>
						</div>
					</a>
				</li>
				<li>
					<a href={validateURL(`${SOCIAL_MEDIA_LINKS.YOUTUBE.url}`) ? `${SOCIAL_MEDIA_LINKS.YOUTUBE.url}` : ''} style={{ height: '7.9rem' }} target="_blank" rel="noreferrer" className="social-tile social-youtube youtube-theme-bg">
						<div className="social-tile__icon"><i className="mdicon mdicon-youtube"></i></div>
						<div className="social-tile__inner flexbox">
							<div className="social-tile__left flexbox__item">
								<h5 className="social-tile__title meta-font">Youtube</h5>
								<span className="social-tile__count">3.44k subscribers</span>
							</div>
							<div className="social-tile__right">
								<i className="mdicon mdicon-arrow_forward"></i>
							</div>
						</div>
					</a>
				</li>
			</ul>
		</div>
	</div>
)

export default Social