import React, { ReactElement } from 'react'
import Article from '../../structure/classes/Article'

// i18n
import { TranslationsUtil } from '../../utils'
import englishTranslation from './translations/en.json'
const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

import { Link } from 'react-router-dom'
import { slugify } from '../../utils/ArticlesUtil'
import { imageConvertion } from '../../utils/CloudImageUtils'

function CategorizedPreview(props: { article: Article, authorName: string, authorId: string }): ReactElement {
	const { article, authorName, authorId } = props

	// const { triggerTrackingHandler } = useArticleTracking()

	const translate = translationUtil.getTranslator()

	return (
		<article className="post post--card post--card-sm cat-1">
			<div className="post__thumb">
				<Link to={`/articles/${article.id}/${slugify(article.title)}`}>
					<div className="background-img" style={{ backgroundImage: `url(${imageConvertion(article.image?.url)})` }}></div>
				</Link>
				{/* <Link to={'/article/categories'} className="post__cat post__cat--bg post__cat--overlap cat-theme-bg">{article.category.name}</Link> */}
				<span className="post__cat post__cat--bg post__cat--overlap cat-theme-bg">{article.category.name}</span>
			</div>
			<div className="post__text text-center">
				<h3 className="post__title typescale-1">
					<Link to={`/articles/${article.id}/${slugify(article.title)}`}>{article.title}</Link>
				</h3>
			</div>
			<div className="post__excerpt">
				{article.excerpt}
			</div>
			<div className="post__footer">
				<div className="post__footer-left post__meta">
					<span className="entry-author">{translate('By')}&nbsp;
						<Link to={`/author/${authorId}`} className="entry-author__name">{authorName}</Link>
					</span>
				</div>
				<div className="post__footer-right post__meta">
					<time className="time published" dateTime={article.published_date} title={article.getPublishedDate('title')}><i className="mdicon mdicon-schedule"></i>{article.getPublishedDate('display')}</time>
				</div>
			</div>
		</article>
	)
}

export default CategorizedPreview