import React, { ReactElement } from 'react'
import { Button } from '../styles'

type Props = {
    type?: 'submit' | 'reset' | 'button' | undefined;
    name: string | undefined;
    onClick?: () => void;
    bgColor?: string;
    width?: string;
    textColor?: string; 
    height?: string;
};

function CheckoutButton({type, name, bgColor, width, textColor, height, onClick}: Props): JSX.Element {

	const isSubmit = type === 'submit'

	return isSubmit ? <Button color={textColor} width={width} height={height} bgColor={bgColor} type={type}>{ name }</Button> : <Button color={textColor} width={width} height={height} bgColor={bgColor} onClick={onClick} type={type}>{ name }</Button>
}

export default CheckoutButton
