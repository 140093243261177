/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

interface ProfileActionProps {
	onSelect: (data: string) => void;
}

const profileActionOptions = [
	'Edit Profile',
	'Update Password',
	'Preferences',
]

function ProfileActionDropdown({ onSelect }:ProfileActionProps): ReactElement {
	const [action, setAction] = useState('')
	const {type} = useParams<{type: string}>()

	useEffect(() => {
		if(type === 'preferences'){
			setAction('Preferences')
			onSelect('Preferences')
		}
	}, [type])

	const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
		setAction(e.target.value)
		onSelect(e.target.value)
	}

	return (
		<select value={action} onChange={handleChange}>
			{profileActionOptions.map((option, index) => (
				<option key={index} value={option}>
					{option}
				</option>
			))}
		</select>
	)
}

export default ProfileActionDropdown