/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import {
	PageTitle,
	Separator,
	ImageHolder,
	Description,
	Title,
	Label,
	LeftContent,
	WebLink,
	// MapContainer,
	// MapTitle,
	ProfileContainer
} from './styles'
import LoadingOverlay from '../../../../../../components/Loading-overlay'
import { addHttpsIfNeeded } from '../../constants'
import { CloudImageUtils } from '../../../../../../utils/CloudImageUtils'
import { useGetTradeDirectoryDetailsQuery } from '../../../../../../app/services'
import { validateURL } from '../../../../../../utils/ValidateUrl'
// import Maps from './Maps'

const companyAddress = (data: any): string => {
	const address = (data: string, type='notCountry') => {
		if(type === 'country'){
			return  data === undefined ? '' : `${data}`
		}
		return  data === undefined ? '' : `${data},`
	}
	
	return `${address(data?.address?.street)} ${address(data?.address?.state)} ${address(data?.address?.city)} ${address(data?.address?.country?.name, 'country')}`
}

function DirectoryProfile(): ReactElement {

	const { companyId } = useParams<{ companyId: string }>()
	const [data, setData] = useState<any>()
	const [isDetailsLoading, setIsDetailsLoading] = useState<boolean>(true)
	const {data: tradeDirectoryData, isError} = useGetTradeDirectoryDetailsQuery(companyId, {skip: !companyId})

	useEffect(() => {
		if(tradeDirectoryData){
			if(tradeDirectoryData.success){
				setData(tradeDirectoryData.data)
			}
			setIsDetailsLoading(false)
		}
		
		if(isError){
			console.log('Something went wrong. Please try again')
			setIsDetailsLoading(false)
		}
	},[tradeDirectoryData])

	return (
		<>
			{isDetailsLoading && <LoadingOverlay />}
			<div className="single-entry">
				{data && <div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
					<header className="container">
						<Link to="/trade-partners/trade-directory" className="entry-cat cat-theme cat-5">Trade directory</Link>
						<PageTitle>{data?.name}</PageTitle>
					</header>
					<ProfileContainer className='container'>
						<div className='profile-info'>
							<div>
								<ImageHolder>
									{/* <img alt='' src={data?.logo?.url} /> */}
									<CloudImageUtils imgSrc={data?.logo?.url} alt="image" ratio={0} params='func=fit'/>
								</ImageHolder>
							</div>
							<LeftContent>
								<Title margin={'0 0 8px'}>Business type</Title>
								<Label margin={'8px 0 0'}>{data?.type.join(', ')}</Label>
								<Title margin={'9px 0 8px'}>Company address</Title>
								<Label margin={'8px 0 0'}>{companyAddress(data)}</Label>
								<Title margin={'15px 0 8px'}>Contact person</Title>
								<Label margin={'8px 80px 25px 0'}>{data?.primary_contact}</Label>
								<Title margin={'25px 80px 20px 0'}>Get in touch</Title>
								{validateURL(`${addHttpsIfNeeded(data?.website_url)}`) &&
									<WebLink href={validateURL(`${addHttpsIfNeeded(data?.website_url)}`) ? `${addHttpsIfNeeded(data?.website_url)}` : ''} target="_blank" rel="noreferrer" >Go to website</WebLink>
								}
								<Separator />
							</LeftContent>
						</div>
						<div className='misc-info'>
							{data?.about && <div>
								<Title margin={'0 0 20px'}>About Us</Title>
								<Description>
									{data?.about}
								</Description>
								<Separator />
							</div>} 
							{/* <div>
								<MapTitle>Location</MapTitle>
								<MapContainer>
									<Maps address={companyAddress(data)} />
								</MapContainer>
							</div> */}
						</div>
					</ProfileContainer>
				</div>}
			</div>
		</>
	)
}

export default DirectoryProfile