import React, { ReactElement } from 'react'
import { isMobile } from 'react-device-detect'
import { Control, FieldValues } from 'react-hook-form'

import Input from '../../../../../../components/form-components/input/Input'

import { GiftCardContainer, GiftCardFormBody } from '../../styles'
import DatePickerComponent from '../../../../../../components/form-components/date-picker/DatePicker'

type Props = {
    control: Control<FieldValues>
}

function RecipientDetails({ control }: Props): ReactElement {
	return <GiftCardContainer isMobile={isMobile}>
		<GiftCardFormBody isMobile={isMobile}>
			<div className='head-body'>
				<h3>2. Recipient details</h3>
			</div>
			<div className='form'>
				<div className='first-input'>
					<Input control={control} name='recipientFirstName' type='text' placeholder='*First Name' rules={{ required: 'Recipient\'s first name is required' }} />
					<Input control={control} name='recipientLastName' type='text' placeholder='*Last Name' rules={{ required: 'Recipient\'s last name is required' }} />
				</div>
				<div className='first-input'>
					<Input control={control} name='recipientEmail' type='email' placeholder='*Email Address' rules={{ required: 'Recipient\'s email address is required' }} />
				</div>
				<div className='first-input'>
					<Input control={control} name='message' type='textarea' placeholder='*Custom Message' rules={{ required: 'Custom message is required' }} />
				</div>
				<p className='date-text'>
					Select the date you would like recipient to receive the gift.
				</p>
				<div className='first-input'>
					<DatePickerComponent rules={{ required: 'Date of receiving the gift is required.' }} control={control} name='sendDate' placeholder='*Select a date' />
				</div>
			</div>
		</GiftCardFormBody>
	</GiftCardContainer>
}

export default RecipientDetails
