/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TagManager from 'react-gtm-module'

// Selectors
import { useAppSelector } from '../../../../app/hooks'
import { Authentication as AuthFromStore } from '../../../../app/slices/Authentication'
import { Users as UsersFromStore } from '../../../../app/slices/Users'

import { WIDGET_CONFIG } from '../../../../configs'
import { IsUserAuthenticated, TranslationsUtil } from '../../../../utils'

import { BuyOnlineButton, NameContainer, StyledRatingIcon, WineSummary } from './styles'

//i18n
import englishTranslation from './translations/en.json'
const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

// Structure
import { slugify } from '../../../../utils/ArticlesUtil'
import { isFreeUser } from '../../../../utils/AuthenticationUtil'
import PriceListingsModal from '../../../modals/price-listings'
import { ClearActiveAdvanceFilters, SetActiveFilters, SetSearchDisplayType } from '../../../../app/slices/SearchFilters'
import CommonModalContainer from '../../../modals/ModalContainer'

function WinesFromArticle(props: { articleId: string, winesInJSON: any, totalWines: number }): ReactElement {

	const translate = translationUtil.getTranslator()
	const { articleId, winesInJSON, totalWines } = props
	const authentication = useAppSelector(AuthFromStore)
	const { userData } = useAppSelector(UsersFromStore)
	const [findPriceOnline, setFindPriceOnline] = useState(false)
	const dispatch = useDispatch()

	const [wines, setWines] = React.useState<any>()

	const [findWineOnline, setFindWineOnline] = useState({
		wineId: '',
		state: false
	})

	const showRating = (rating: any | null): ReactElement => {
		if (rating) {
			return (<div className="media-right media-middle">
				<StyledRatingIcon>
					{rating}
				</StyledRatingIcon>
			</div>)
		}
		return (<></>)
	}

	useEffect(() => {
		setWines(winesInJSON)
	}, [authentication.User.accessToken, winesInJSON])

	const buyOnlineTrigger = (wine: any) => {
		setFindPriceOnline(true)
		setFindWineOnline({ wineId: wine.objectID, state: true })
		TagManager.dataLayer({
			dataLayer: {
				event: 'check_price',
				page_origin: 'article',
				objectID: wine?.objectID,
				country: wine?.country,
				region: wine?.region,
				subregion: wine?.sub_region,
				appellation: wine?.appellation,
				subappellation: wine?.sub_appellation,
				producer: wine?.producer,
				name: wine?.name,
				date_last_tasted: Math.floor(wine?.date_last_tasted / 1000).toString(),
				last_reviewer: wine?.last_reviewer,
				rating_computed: wine?.rating_computed.toString(),
				vintage: wine?.vintage.toString()
			}
		})
	}

	const articleDispatch = () => {
		dispatch(ClearActiveAdvanceFilters())
		dispatch(SetSearchDisplayType('default'))
	}

	const modalHandler = (value: boolean) => {
		setFindPriceOnline(value)
	}

	return (
		<>
			<div className="widget__title">
				<h4 className="widget__title-text"><i className="mdicon mdicon-thumbs_up"></i>
					<span>
						<span className="first-word">{translate('WINES')}:&nbsp;</span>{winesInJSON.length > WIDGET_CONFIG.WINES.MAX_PER_PAGE ? WIDGET_CONFIG.WINES.MAX_PER_PAGE : winesInJSON.length} {translate('of')} {totalWines}
					</span>
				</h4>
			</div>

			<div className="mnmd-widget-most-commented mnmd-widget widget widget__inner">
				<ol className="posts-list list-space-md list-seperated list-unstyled">
					{wines ? wines.map((wine: any, key: number) => (
						<li key={key}>
							<article className="post cat-4">
								<div className="media">
									<WineSummary className="media-body media-middle">
										<NameContainer className="name-container post__title typescale-0" isAuthenticated={authentication.User.accessToken ? true : false}>
											<div className='name'>
												<Link to={`/wines/${wine.objectID}/${slugify(wine.name + '-' + wine.vintage)}`}>{wine.display_name}</Link>
											</div>
											{wine?.rating_display === '100' ? '' :
												!authentication.User.accessToken || isFreeUser(userData) ? (
													<div className='indicator'>
														<img src='img/icons/lock.png' style={{ float: 'right' }} />
													</div>
												) : null
											}
										</NameContainer>
										<div className="post__meta">
											{!authentication.User.accessToken || isFreeUser(userData) ? '' : 
												<BuyOnlineButton onClick={() => buyOnlineTrigger(wine)} className="buy-button post__cat post__cat--bg cat-theme-bg">{translate('Buy Online!')}</BuyOnlineButton>
											}
										</div>
									</WineSummary>
									{(!isFreeUser(userData) && (authentication?.User?.accessToken || IsUserAuthenticated())) || wine?.rating_display === '100' ? (showRating(wine.rating_display)) : null}
								</div>
							</article>
							{wine && wine.objectID === findWineOnline.wineId && findWineOnline.state && 
							<CommonModalContainer isOpen={findPriceOnline} onClose={()=> setFindPriceOnline(false)}>
								<PriceListingsModal wine={wine} isModalOpen={modalHandler}/>					
							</CommonModalContainer>}
						</li>
					)) : null}
				</ol>
			</div>
			<nav onClick={()=> dispatch(SetActiveFilters({}))} className="mnmd-pagination text-center" style={{ marginTop: '-30px' }}>
				<Link to={`/search/wine?article_id=${articleId}`} onClick={articleDispatch} className="btn btn-default">{translate('View All Wines')}<i className="mdicon mdicon-arrow_forward mdicon--last"></i></Link>
			</nav>
		</>
	)
}

export default WinesFromArticle