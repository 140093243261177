import styled from 'styled-components'

export const PrimaryButton = styled.div`
	// margin: 20px 251px 0 252px;
	// width: 142px;
	height: 21px;
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;

	& > span {
		color: #fff;
		background-color: #73333f;

		&:hover {
			color: #fff;
			background-color: #8E4250;
		}
	}
`

export const PreviewContent = styled.div`

	&.text-limit {
		& .post__title.typescale-2{
			height: 4rem;
		}

		& .post__excerpt {
			& a {
				display: inline-block;
				height: 5.5rem;
			}
		}
	}

	& .post__excerpt {
		max-height: 100%;
		overflow: hidden;
		height: 100px !important;
	}
	
	& .post__meta.post__meta_display {

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: auto;
			right: 0;
			bottom: 100%;
			left: 0;
			height: 10px;
			width: 100%;
			margin-bottom: 1px;
			background: linear-gradient(0deg, white 0, rgba(255, 255, 255, 0.7) 70%, rgba(255, 255, 255, 0) 100%);
			}
		}
	}

	@media(max-width: 992px) {
		position: relative;

		&.text-limit {
			& .post__excerpt {
				& a {
					display: inline-block;
					height: 51px;
				}
			}
		}
	}

	@media(max-width: 768px) {
		&.text-limit {
			& .post__title.typescale-2{
				height: 1rem;
			}

			& .post__excerpt {
				& a {
					display: inline-block;
					height: 75px;
				}
			}
		}
	}

	@media(max-width: 500px) {
		&.text-limit {
			& .post__title.typescale-2{
				height: 1.5rem;
				max-width: 500px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
`