import React, { MouseEvent, ReactElement } from 'react'
import { isMobile } from 'react-device-detect'
import { Control, FieldValues, RegisterOptions, UseFormGetValues, UseFormHandleSubmit, UseFormSetValue } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import Input from '../../../../../../components/form-components/input/Input'
import Dropdown from '../../../../../../components/form-components/dropdown/Dropdown'
import Checkbox from '../../../../../../components/form-components/checkbox/Checkbox'
import CheckoutButton from '../../../../../../components/form-components/buttons/CheckoutButton'
import ReCAPTCHA from 'react-google-recaptcha'
import { reverseObfuscateConstant } from '../../../../../../utils/Constant'
import { RP_ENV } from '../../../../../../configs'

import { GiftCardContainer, GiftCardFormBody } from '../../styles'

type Props = {
    control: Control<FieldValues>;
    handleSubmit: UseFormHandleSubmit<FieldValues>,
    onSubmit: (data: FieldValues) => void;
    getValues: UseFormGetValues<FieldValues>;
    countries: string[];
    setValue: UseFormSetValue<FieldValues>;
    openLoginModal: () => void;
}

function NewAccount({ control, handleSubmit, onSubmit, getValues, countries, setValue, openLoginModal }: Props): ReactElement {

	const history = useHistory()

	const passwordRules: RegisterOptions = {
		required: 'Password is required',
		minLength: {
			value: 10,
			message: 'Password must be at least 10 characters long'
		}
	}

	const confirmPasswordRules: RegisterOptions = {
		required: 'Confirm password is required',
		validate: (value) => {
			return value === getValues('password') || 'The passwords do not match'
		}
	}

	const countryDropdownRules: RegisterOptions = {
		required: 'Country of residence is required',
		validate: (value) => value !== '' || 'Please select a country'
	}

	const aggrementCheckboxRules: RegisterOptions = {
		required: 'Please check aggrement to proceed.',
		validate: (value) => value !== false || 'Please check the aggrement'
	}

	const triggerLink = (e: MouseEvent<HTMLAnchorElement>, url: string) => {
		e.preventDefault()
		history.push(url)
	}

	return <GiftCardContainer isMobile={isMobile}>
		<GiftCardFormBody isMobile={isMobile}>
			<div className='head-body'>
				<h3 style={{ fontSize: '1.5rem', fontWeight: 800 }}>You have been gifted a 1 Year Personal Subscription.</h3>
			</div>
			<div style={{ fontSize: 17 }}>
				<p style={{ margin: 0 }}>Create an account below. Already have an account?</p>
				<a href='#!' onClick={e => {
					e.preventDefault()
					openLoginModal()
				}}>Sign in to extend your subscription</a>
			</div>
			<form className='form' onSubmit={handleSubmit(onSubmit)}>
				<div className='first-input'>
					<Input control={control} name='firstName' type='text' placeholder='*First Name' rules={{ required: 'First name is required' }} />
					<Input control={control} name='lastName' type='text' placeholder='*Last Name' rules={{ required: 'Last name is required' }} />
				</div>
				<div className='first-input'>
					<Input control={control} name='email' type='email' placeholder='*Email Address' rules={{ required: 'Email address is required' }} />
				</div>
				<div className='first-input'>
					<Input control={control} name='password' placeholder='*Password' rules={passwordRules} type='password' />
					<Input control={control} name='passwordConfirm' placeholder='*Confirm Password' rules={confirmPasswordRules} type='password' />
				</div>
				<div className='first-input'>
					<Dropdown control={control} rules={countryDropdownRules} options={countries} placeholder='*Country of Residence' name='country' />
				</div>
				<br />
				<div className='first-input'>
					<Checkbox name='isNewsLetter' control={control}>
						<span>Set up a weekly alert to notify me about newly published ratings and articles.</span>
					</Checkbox>
				</div>
				<div className='first-input'>
					<Checkbox name='isMarketing' control={control}>
						<span>Include me in your mailing list to receive exclusive promotions, updates, and wine recommendations from Robert Parker Wine Advocate.</span>
					</Checkbox>
				</div>
				<div className='first-input'>
					<Checkbox name='isAggrement' control={control} rules={aggrementCheckboxRules}>
						{/* <span>I have read and agreed to the terms of the <a href='/privacy-notice' onClick={(e) => triggerLink(e, '/privacy-notice')}>Privacy Notice</a> and the <a href='/subscription-agreement' onClick={(e) => triggerLink(e, '/subscription-agreement')}>Personal Subscription Agreement</a>.</span> */}
						<span>
							By checking this box and subscribing, I agree to the Robert Parker Wine Advocate <a className='bold-link' href='/subscription-agreement' onClick={(e) => triggerLink(e, '/subscription-agreement')}>Personal Subscription Agreement</a>.
							I understand that I am requesting immediate access to digital content and therefore waive my right to withdraw from the subscription.<br /><br />
							To find out how we use and process the data you entrust to us, please refer to our <a className='bold-link italic-link' href='/privacy-notice' onClick={e => triggerLink(e, '/privacy-notice')} > privacy policy</a>.
						</span>
					</Checkbox>
				</div>
				<div style={{ display:'flex', justifyContent:'center' }}>
					<div className='action-input'>
						<div className='recaptcha-style'>
							<i>Please check the box below to proceed.</i>
							<ReCAPTCHA
								sitekey={`${reverseObfuscateConstant(RP_ENV.CAPTCHA_KEY)}`}
								onChange={(token) => setValue('recaptcha', token)}
							/>
						</div>
						<CheckoutButton name='PROCEED TO CHECKOUT' type='submit' />
					</div>
				</div>
			</form>
		</GiftCardFormBody>
	</GiftCardContainer>
}

export default NewAccount
