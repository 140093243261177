import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`
export const FadeInAnimation = styled.div`
    opacity: 0;
    animation: ${fadeIn} 0.3s ease-in forwards;
`

const bounce = keyframes`
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
`

export const BounceAnimation = styled.div`
    animation: ${bounce} 1s ease;
`

const slideRight = keyframes`
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
`

export const SlideRightAnimation = styled.div`
    animation: ${slideRight} 0.5s ease;
`