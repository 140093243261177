import styled from 'styled-components'

// const commonFontAttributes = `
// 	font-family: Libre Franklin;
// 	font-stretch: normal;
// 	font-style: normal;
// 	line-height: 1.2;
// 	letter-spacing: normal;
// `

export const SubscriptionContainer = styled.div`
	width: 750px;
	height: 259px;
	margin: 46px 134px 40px 91px;
	padding: 40px 40px 42px;
	border: solid 1px #e5e5e5;
	background-color: #fafafa;
`

export const InviteMessageContainer = styled.div`
	width: 670px;
	height: 36px;
	margin: 0 0 46px;
	font-family: Libre Franklin;
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	color: var(--black);
`

export const MainMessageContainer = styled.div`
	width: 670px;
	height: 42px;
	margin: 46px 0 20px;
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	color: var(--black);
`

export const GetSubscriptionContainer = styled.div`
	width: 167px;
	height: 33px;
	margin: 20px 251px 0 252px;
	padding: 6px 12.5px;
	border-radius: 8px;
	background-color: #e01a00;
	color: var(--white-three);
`

export const GetSubscriptionButton = styled.div`
	// margin: 20px 251px 0 252px;
	// width: 142px;
	height: 21px;
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;

	& > span {
		color: #fff;
		background-color: #73333f;

		&:hover {
			color: #fff;
			background-color: #8E4250;
		}
	}
`

export const LoginLinkSpan = styled.span`
	color: #A38C55;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`