import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

import {
	PageTitle,
	SectionContainer,
	SectionDescription,
	Title,
	SubscriptionsBlurb,
	TextStyle1,
	TextStyle3
} from './common'

function PersonalSubscription(): ReactElement {

	return (
		<div role="main">
			<PageTitle>Personal Subscription Agreement</PageTitle>
			<SubscriptionsBlurb>

				Last modified: <TextStyle1>February 2021</TextStyle1>
				<br /><br />
				This Personal Subscription Agreement (the &quot;Agreement&quot;) is made by and between The Wine Advocate, Inc. (&quot;The Wine Advocate,&quot; &quot;we&quot; or &quot;us&quot;) and the Personal Subscriber (as defined below).
				By signing up for a Personal Subscription with RobertParker.com (&quot;Site&quot;), you are deemed to accept the terms of this Agreement, including the terms relating to use of the Site and its content,
				which refer to all visual and editorial content including wine reviews and scores, posted currently and in the past on the Site (&quot;Content&quot;).
				<br /><br />
				The Personal Subscription is solely for natural persons or entities who wish to read and consult the Content but not to repost any of the Content in print or digital supports or in supports of any form.
				Wine and beverage industry professionals wishing to mention, share, post wine scores and reviews, which are part of our content, in their marketing and communication supports of any form,
				do not qualify for a Personal Subscription.
				<br /><br />
				<b>If you do not qualify for a Personal Subscription, then no right to the Content is granted to you under this Agreement.</b>
				If you do not qualify as a Personal Subscriber and wish to use our Content for professional or any other use, please check the <TextStyle3>Commercial Subscription Agreement.</TextStyle3>
				If you do not qualify for a Commercial Subscription we request that you contact us at <TextStyle3>commercial@robertparker.com</TextStyle3> for alternative solutions for the use of our Content.
			</SubscriptionsBlurb>

			<SectionContainer>
				<Title>A. HIGHLIGHTS</Title>
				<SectionDescription>
					<ol>
						<li>
							The following highlights are intended only to convey the key points of the Agreement and are not a substitution for the full Agreement. The Personal Subscriber is required to read all the terms stated in the Agreement and abide by them.
						</li>
						<li>
							The terms and conditions of this Agreement apply only to Personal Subscribers. Personal Subscribers are natural persons or entities who use the Site Content for their knowledge. Personal Subscribers are not allowed to repost, copy, publish,
							archive, republish, reproduce, distribute, transmit, disseminate, license, sub-license in part or in full any of the Content, modify or make any derivative works from the Content, in any manner and place and in any printed or digital support
							or in supports of any form including social media. The Content includes all wine scores and notes available in the present and past on the Site.
						</li>
						<li>
							The Personal Subscription is not transferable to any third party. If you require specific group subscription plans, please contact us at <TextStyle3>commercial@robertparker.com</TextStyle3>.
						</li>
						<li>
							The rights acquired through this Personal Subscription are not transferrable to any third party. As such any score or tasting note posted by a wine supplier (winery or wine merchant) on any marketing support (website, social media, catalogue,…)
							visible to its clients or to the general public, cannot be reposted by any other party unless they are themselves Commercial Subscribers and abide by the Commercial Subscription agreement.
						</li>
						<li>
							As an example, a winery or wine merchant may reproduce the scores and notes of their wines in their marketing material if they are a Commercial Subscriber. However, any of their clients, or member of the general public, willing to share,
							post, communicate on these scores and notes in any manner, will need to be a Commercial Subscriber.
						</li>
					</ol>
				</SectionDescription>

				<Title>B. THE SERVICE</Title>
				<SectionDescription>
					<ol>
						<li>
							The Site provides access to wine tasting notes and other information about wine, including information that may have been previously published by Robert M. Parker, Jr. in the print edition of The <em>Wine Advocate®.</em>
							The Site may also include other features and services of interest to wine drinkers and collectors and to those in the wine trade, including access to The <em>Wine Advocate.</em> Vintage Guide. The choice of such features and
							services is made at the sole discretion of The <em>Wine Advocate.</em> and may change from time to time without notice. The wine tasting notes, reviews and scores available on the Site, as well as The <em>Wine Advocate.</em> Vintage Guide, are collectively referred to herein as the &quot;Content.&quot;
						</li>
						<li>
							The database of information will be updated from time to time at the discretion of The <em>Wine Advocate.</em> throughout the year.
						</li>
					</ol>
				</SectionDescription>

				<Title>C. YOUR SUBSCRIPTION</Title>
				<SectionDescription>
					<ol>
						<li>
							This Personal Subscription allows you and you alone to access the Site via a personal computer or a mobile electronic device that provides access to the Internet (a &quot;Device&quot;).
						</li>
						<li>
							Each Personal Subscriber will be assigned 1 log-in upon registration. A log-in is non-transferable.
						</li>
						<li>
							You must select a log-in name and password at the time you subscribe (choice of log-in name is subject to availability).
							Sharing your log-in name and password with others is a violation of this Agreement and is grounds for immediate termination of your subscription, without a full or partial refund of your subscription fee.
						</li>
						<li>
							You may not make concurrent or simultaneous use of a log-in on more than one Device at any one time. In other words,
							if you want to access the Site on more than one Device at the same time, you will need to obtain an additional subscription for each concurrent usage, which will require a separate subscription.
						</li>
					</ol>
				</SectionDescription>

				<Title>D. INTELLECTUAL PROPERTY AND USAGE LIMITATIONS</Title>
				<SectionDescription>
					<ol>
						<li>
							For the purposes of this Agreement, &quot;Intellectual Property Rights&quot; shall mean trademarks and service marks, business names and domain names, rights in get-up and trade dress, goodwill and the right to sue for passing off or unfair competition, copyright and related rights to the Content, moral rights, rights in designs, rights in computer software, database rights, rights to use, and protect the confidentiality of, Confidential Information (including know-how and trade secrets) and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part of the world.
						</li>
						<li>
							The Wine Advocate, Inc. alone (and its licensors, where applicable) shall own all right, title and interest, including all related Intellectual Property Rights in and to the Site and the Content and any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by you or any other party relating to your subscription. This Agreement does not convey to you any rights of ownership in relation to the Intellectual Property Rights, trademarks and/or Content.
						</li>
						<li>
							The Wine Advocate, Inc. does not grant you any Intellectual Property Rights in respect of the Content and/or trademarks on the Site. You shall not reproduce partially or in full any Content such as an article published in The Wine Advocate and place it in any printed or digital support.
						</li>
						<li>
							Your subscription is limited to your use and not transferable or sub-licensable to, or usable by, any other person, business or entity.
						</li>
						<li>
							In addition, access to the Site using computer programs, software or routines, or any other means, including but not limited to, web robots, web wanderers, crawlers or spiders, that are designed to systematically download the Content in any manner, is specifically and strictly prohibited.
						</li>
						<li>
							<TextStyle1>
								Any violation of this Agreement or use of the Content beyond what is specifically permitted in this Agreement is grounds for immediate suspension or termination of your subscription without notice and without a right of refund of your subscription fee, and may constitute an infringement of our intellectual property rights, for which we reserve the right to seek damages, legal fees and costs.
							</TextStyle1>
						</li>
					</ol>
				</SectionDescription>

				<Title>E. TERM OF YOUR SUBSCRIPTION</Title>
				<SectionDescription>
					<ol>
						<li>
							Unless otherwise previously terminated, subscriptions are for one month, three months or one year depending on your subscription plan. Using the information you provided when you registered, we will attempt to notify you several weeks before your subscription expires so as to remind you of the renewal. Your subscription will then be automatically renewed unless you turn off the automatic renewal by visiting the online &quot;Manage Subscription&quot; page and change your account information. Personal subscription fees are strictly not refundable.
						</li>
						<li>
							Annual Personal Subscriptions may be promoted by &quot;Referrors,&quot; third parties listed as Trusted Retailers or Trusted Wine Schools on RobertParker.com. Only parties listed as such are authorized to promote our Personal Subscriptions as Referrors. The promotion of a Personal Subscriptions may involve a referral code and gift with purchase benefit such as a cash voucher, bottle of wine or other service and product provided by the Referror, the party organizing the promotion, to his clients. A client may purchase a Personal Subscription on RobertParker.com with a referral code which will entitle the client to claim from the Referror the benefit promised by the Referror. The redemption and delivery of this benefit remain strictly a matter between the Referror and the client without any involvement of Robert Parker Wine Advocate. Any dispute relative to this benefit does not involve the mediation and responsibility of Robert Parker Wine Advocate. Should you acquire a subscription with a benefit provided by Robert Parker Wine Advocate or by a third party such as a Referror, a strict and absolute no refund policy applies on the subscription purchased and on the benefit.
						</li>
					</ol>
				</SectionDescription>

				<Title>F. TERMINATION OF YOUR SUBSCRIPTION</Title>
				<SectionDescription>
					<ol>
						<li>
							If you breach any of the terms of this Agreement, we reserve the right to suspend or terminate your subscription and access to the Site with notice and without a right of refund of your subscription fee.
						</li>
					</ol>
				</SectionDescription>

				<Title>G. YOUR PERSONAL INFORMATION</Title>
				<SectionDescription>
					<ol>
						<li>
							By subscribing to the Site, you hereby consent to the collection and processing of personal or corporate data provided at registration for the purposes of managing your subscription. Our full Privacy Notice may be accessed here.
						</li>
						<li>
							We may occasionally allow other companies to provide information to our subscribers about their wine-related products or services.
							We do not provide any information about you to these companies other than your email address and will do so only if you agree to it at the time you register. You may withdraw your consent at any time.
						</li>
						<li>
							We shall endeavor to protect the security of your personal information. We have implemented procedures which are intended to protect your data from loss, misuse, unauthorized access or disclosure,
							alteration, or destruction. Your personal information is not shared by us without your permission. Your data is stored in password-controlled secure servers, and your information will be stored and processed in the United States and Singapore.
						</li>
						<li>
							You also have a significant role in protecting your information. No one can see or edit your subscription information without knowing your Username and Password, so do not share these with others.
						</li>
						<li>
							We shall not be responsible for any loss, destruction, or alteration of information or data stored under your Subscriber’s account,
							such data includes your wine inventory and related information that you may register under the &quot;Cellar&quot; function of the website.
						</li>
					</ol>
				</SectionDescription>

				<Title>H. USE OF COOKIES</Title>
				<SectionDescription>
					<ol>
						<li>
							When you sign onto our Site, you are assigned a personal identification number which is sent to your Device in the form of a small text file called a &quot;cookie.&quot; This cookie allows us to monitor your use of our Site. This is done only for the purpose of protecting us and protecting you should your Username and Password become available to someone other than you.
						</li>
						<li>
							If you choose to not have your browser accept cookies from the RobertParker.com Site, you will not be able to access the Site. Our full Cookie Policy may be accessed <Link to={' '}> <TextStyle3>here.</TextStyle3></Link>
						</li>
					</ol>
				</SectionDescription>

				<Title>I. LIMITATION OF LIABILITY</Title>
				<SectionDescription>
					<ol>
						<li>
							We will use commercially reasonable efforts to ensure the accuracy, responsiveness and availability of the Site to its subscribers.
							Under no circumstances, will we be liable for any direct or indirect damages with respect to the accuracy of the information,
							the availability of the Site, the responsiveness of the Site or your use of information provided to you by the Site.
						</li>
					</ol>
				</SectionDescription>

				<Title>J. COMPLETE AGREEMENT</Title>
				<SectionDescription>
					<ol>
						<li>
							This is the complete Agreement with respect to your use of the Site as a Personal Subscriber. Nothing published or stated elsewhere should be viewed as a modification of this Agreement.
						</li>
					</ol>
				</SectionDescription>

				<Title>K. GOVERNING LAW AND JURISDICTION</Title>
				<SectionDescription>
					<ol>
						<li>
							This Agreement shall be construed, interpreted and determined in accordance with the laws of the Republic of Singapore. Any dispute arising out of or in connection with this Agreement,
							including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration in Singapore in accordance with the Arbitration Rules of
							the Singapore International Arbitration Centre (&quot;SIAC Rules&quot;) for the time being in force, which rules are deemed to be incorporated by reference in this clause.
							The Tribunal shall consist of 1 (one) arbitrator. The language of the arbitration shall be English.
						</li>
					</ol>
				</SectionDescription>

				<Title>L. INJUNCTIVE RELIEF</Title>
				<SectionDescription>
					<ol>
						<li>
							Your breach of this Agreement may cause irreparable damage that cannot be readily remedied by monetary damages in an action at law and may also constitute an infringement of
							trademark rights and/or copyright rights. As such, you agree to the entry of an immediate injunction, in addition to any other remedies available at law or in equity, to stop or
							prevent any irreparable harm, loss or dilution as result of a breach of this Agreement.
						</li>
					</ol>
				</SectionDescription>

				<Title>M. CHANGES TO THIS AGREEMENT</Title>
				<SectionDescription>
					<ol>
						<li>
							We may occasionally update the terms and conditions of this Agreement. When we do, we will also revise the &quot;last updated&quot; date at the top of the copy of the Agreement posted on the Site.
							For changes to this Agreement we consider material, RobertParker.com will notify you by placing a notice on the Site. Such changes will be effective when posted by us.
							Your continued use of the Site after the date upon which this Agreement is changed, and the changes are posted, will constitute your continued acceptance of this Agreement.
							Your only alternative, should you no longer wish to be bound by this Agreement, is termination of your use of the Site. No full or partial refunds will be offered in such an event.
						</li>
					</ol>
				</SectionDescription>

			</SectionContainer>
		</div>
	)
}

export default PersonalSubscription