import React, { ReactElement } from 'react'
import { Control, FieldValues } from 'react-hook-form'
import Input from '../../../../../components/form-components/input/Input'
import { isMobile } from 'react-device-detect'
import { GiftCardFormBody } from '../../gift-subscription/styles'

interface UpdateProfileFormProps {
	control: Control<FieldValues>;
}

const currentPasswordRules = {
	required: 'Current password is required',
}

const newPasswordRules = {
	required: 'New password is required',
	minLength: {
		value: 10,
		message: 'New Password must be at least 10 characters.'
	}
}

const confirmPasswordRules = {
	required: 'Confirm password is required',
}

const UpdateProfileForm = ({ control }: UpdateProfileFormProps): ReactElement => {

	return (
		<GiftCardFormBody isMobile={isMobile}>
			<div className=''>
				<div className='first-input profile-container'>
					<Input name='currentPassword' type='password' placeholder='Current Password' disabled={false} control={control} rules={currentPasswordRules} />
					<Input name='newPassword' type='password' placeholder='New Password' disabled={false} control={control} rules={newPasswordRules} />
					<Input name='confirmPassword' type='password' placeholder='Confirm Password' disabled={false} control={control} rules={confirmPasswordRules} />
				</div>
			</div>
		</GiftCardFormBody>
	)
}

export default UpdateProfileForm