import React, { ReactElement, useEffect, useState } from 'react'
import { Control, Controller, FieldValues, RegisterOptions } from 'react-hook-form'
import { ErrorMessage, InputContainer, StyledInput, StyledTextArea } from '../styles'

interface InputFieldProps {
	name: string;
	type?: string;
	placeholder?: string;
	rules?: RegisterOptions;
	control?: Control<FieldValues>;
	disabled?: boolean;
	isError?:boolean;
}

const Input = ({
	name,
	type = 'text',
	placeholder = '',
	rules = {},
	control,
	disabled = false,
	isError =  true
}: InputFieldProps): JSX.Element => {
	const [inputType, setInputType] = useState<string>(type)

	useEffect(() => {
		setInputType(type)
	}, [type])
	
	return (
		<Controller 
			name={name} 
			control={control} 
			rules={rules}
			render={({ field, fieldState: { error } }) => (
				<InputContainer>
					<div className='password-input'>
						{type === 'textarea' ? 
							<StyledTextArea
								{...field}
								value={field.value || ''}
								placeholder={placeholder}
								isError={error ? true : false}
								rows={4}
								cols={50}
							/> : 
							<StyledInput
								id={name}
								{...field}
								value={field.value || ''}
								type={inputType}
								placeholder={placeholder}
								isError={error ? true : false}
								disabled={disabled}
							/>}
						
						{inputType === 'password' && (name === 'password' || name === 'passwordConfirm' || name === 'currentPassword' || name === 'newPassword' || name === 'confirmPassword') && <img alt='icon' src='/img/icons/invisible-icon.png' onClick={() => setInputType('text')} />}
						{inputType === 'text' && (name === 'password' || name === 'passwordConfirm' || name === 'currentPassword' || name === 'newPassword' || name === 'confirmPassword') && <img alt='icon' src='/img/icons/visible-icon.png' onClick={() => setInputType('password')} />}
					</div>
					{error ? <ErrorMessage>{error?.message as string}</ErrorMessage> : isError ? <p style={{ paddingTop: name === 'redemptionCode' ? 21 : 7 }}></p> : null}
				</InputContainer>
			)}
		>

		</Controller>
	)
}

export default Input
