/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, PageContainer } from '../styles'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import {Issues as IssueFromStore} from '../../../../../app/slices/Issues'
import { useAppSelector } from '../../../../../app/hooks'

function NonExistPage(): ReactElement {

	const [latestIssue, setLatestIssue] = useState<any>({})
	const [isLoading, setIsLoading] = useState(false)

	const history = useHistory()

	const {latestIssue: newIssue} = useAppSelector(IssueFromStore)

	useEffect(() => {
		if(newIssue._id) {
			setIsLoading(true)
			setLatestIssue(newIssue)
			setIsLoading(false)
		}
	}, [newIssue])
	

	if(isLoading) return <LoadingOverlay />

	return <div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
		<PageContainer className="container">
			<div className='page-info'>
				<h1>Sorry, the page you were looking for was not found</h1>
				<p>The page you are looking for is not available, likely due to the following reasons:</p>
				<ul>
					<li>The link may be incorrect or expired</li>
					<li>You may have bookmarked a page that has moved</li>
				</ul>
				<div className='page-actions'>
					<Button onClick={() => history.push('/')} buttonType='main'>return to homepage</Button>
					<Button onClick={() => history.push('/issues/latest')} buttonType='sub'>read the latest issue</Button>
					<Button onClick={() => window.location.href = 'mailto:support@robertparker.com'} buttonType='sub'>report the issue to us</Button>
				</div>
			</div>
			<img src='/img/404-wine.jpg' alt='' />
		</PageContainer>
	</div>
		
}

export default NonExistPage
