/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { WineSelectedType } from './types'
import { APP_CONFIG } from '../../../../../configs'
import WineResults from '../WineResults'
import AlternateWineResults from '../AlternateWineResults'
import ArticleResults from '../ArticleResults'
import SelectionActions from './SelectionActions'
import ProducersResults from '../ProducersList'
import { useDispatch, useSelector } from 'react-redux'
import { AddSelectedWines, RemoveSelectedWineById } from '../../../../../app/slices/SearchResults'
import { RootState } from '../../../../../app/store'

type Props = {
    type:string;
	resultState: {
		wines:any[];
		articles: any[];
		filters: any;
	}
	allResults: any[];
	onWineListView: (type:string) => void;
	wineDisplay: string;
	producersList: { name: string; count: number }[]
}

const SearchDisplay = ({type, resultState, allResults, onWineListView, wineDisplay, producersList}: Props): ReactElement => {
	const [wineIds, setWineIds] = useState<WineSelectedType[]>([])
	const [selectedCounts, setSelectedCounts] = useState(0)
	const [checkData, setCheckData] = useState(false)
	
	const dispatch = useDispatch()
	const selectedWines = useSelector((state: RootState) => state.SearchResults.Wines.selectedWines)

	useEffect(() => {
		const uniqueWines = Array.from(new Map(selectedWines.map(item => [item.id, item])).values())
		const wines = resultState.wines?.map(wine => {
			return {
				id: wine._id || wine.objectID
			}
		})
		const result = wines.every(item => {
			const matchingItem = uniqueWines.find(a1Item => a1Item.id === item.id)
			return matchingItem && matchingItem.checked
		})

		setCheckData(result)
	}, [resultState.wines, selectedWines])

	useEffect(() => {
		let count = 0
		if(selectedWines){
			const uniqueWines = Array.from(new Map(selectedWines.map(item => [item.id, item])).values())
			for(const wine of uniqueWines){
				if(wine.checked){
					count ++
				}
			}
			setSelectedCounts(count)
		}

	}, [selectedWines])

	const toggleChecked = (event: React.ChangeEvent<HTMLInputElement>, isAll: boolean) => {
		const {checked, value} = event.target
		const findWine = wineIds.find(wine => wine.id === value)
		
		if(!isAll){
			if(findWine) {
				const newList = [...wineIds]
				let updateWine = newList.find(wine => wine.id === value)
				if(updateWine) 
					updateWine = {
						...updateWine,
						checked,
						wine_details: checked ? resultState.wines.find((wine: any)=> wine.objectID === value || wine._id === value) : {}
					}
				setWineIds(newList)
			} else 
				setWineIds(prevState => [...prevState, {
					id:value,
					checked,
					wine_details: resultState.wines.find((wine: any)=> wine.objectID === value || wine._id === value)
				}])

			if(checked) {
				if(!selectedWines.some(item => item.id === value))
					dispatch(AddSelectedWines({
						id:value,
						checked,
						wine_details: resultState.wines.find((wine: any)=> wine.objectID === value || wine._id === value)}))
			} else {
				dispatch(RemoveSelectedWineById(value))

				if(value !== 'on' )
					setCheckData(false)
			}

		}else{
			let allChecked: WineSelectedType[] = []
			if(checked){

				allChecked = allResults.map((wine: any) => ({
					id: wine.objectID,
					checked: true,
					wine_details: wine
				}))

				allChecked.forEach(wine => {
					dispatch(AddSelectedWines(wine))
				})

				if(value === 'on')
					setCheckData(true)
			}else{
				
				allResults.map((wine: any) => {			
					dispatch(RemoveSelectedWineById(wine.objectID))
				})
				allChecked = []

				
				if(value === 'on')
					setCheckData(false)
			}
			setWineIds(allChecked)
		}
	}
	// GET ALL ARTICLE IDS
	// useEffect(() => {
	// 	const idArray: string[] = resultState.articles.map(obj => obj.id)
	// 	const articlesIds = {
	// 		'articlesIds': idArray
	// 	}
	// 	getCommentCount(articlesIds)
	// },[resultState])

	// const getCommentCount = async (idArray: any) => {
	// 	try {
	// 		const articleId = { articleIDs: idArray}
	// 		const url = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/article/list-by-ids`
	// 		const response = await fetch(url, {
	// 			method: 'POST',
	// 			headers: {
	// 				'x-api-key': RP_ENV.API_KEY,
	// 				'Content-Type': 'application/json'
	// 			},
	// 			body: JSON.stringify(articleId)
	// 		})
	// 		await response.json()
	// 	} catch (error) {
	// 		console.log(error)
	// 	}
	// }
	
	return <>
		
		<SelectionActions type={type} selectedCounts={selectedCounts} wineListView={onWineListView} wineData={selectedWines} toggleChecked={toggleChecked} wineDisplay={wineDisplay} checkValue={checkData} setCheckValue={setCheckData} />
		{ wineDisplay === 'producer' && <ProducersResults producersData={producersList} onWineListView={onWineListView} />}
		<div>
			{type === APP_CONFIG.SEARCH.TYPES.WINE && wineDisplay === 'default' && resultState.wines.length && <WineResults toggleChecked={toggleChecked} results={resultState.wines} />}
		</div>
		<div>
			{type === APP_CONFIG.SEARCH.TYPES.WINE && wineDisplay === 'wineList' && resultState.wines.length && <AlternateWineResults toggleChecked={toggleChecked} results={resultState.wines}/>}
		</div>
		<div>
			{type === APP_CONFIG.SEARCH.TYPES.ARTICLE && resultState.articles.length && <ArticleResults results={resultState.articles} />}
		</div>
		
	</>
}

export default SearchDisplay