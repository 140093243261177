import { UseFormSetValue } from 'react-hook-form'

const updateFormValues = (setValue: UseFormSetValue<any>, data: any) => {
	setValue('_id', data[0]?.user_owned_companies[0]?._id || '')
	setValue('name', data[0]?.user_owned_companies[0]?.name || '')
	setValue('contact_person', data[0]?.user_owned_companies[0]?.contact_person || '')
	setValue('email', data[0]?.user_owned_companies[0]?.email || '')
	setValue('website', data[0]?.user_owned_companies[0]?.website_url || '')
	setValue('about', data[0]?.user_owned_companies[0]?.about || '')
	setValue('phone', data[0]?.user_owned_companies[0]?.phone || '')
	setValue('street', data[0]?.user_owned_companies[0]?.address?.street || '')
	setValue('street_optional', data[0]?.user_owned_companies[0]?.address?.street_optional || '')
	setValue('city', data[0]?.user_owned_companies[0]?.address?.city || '')
	setValue('state', data[0]?.user_owned_companies[0]?.address?.state || '')
	setValue('zip', data[0]?.user_owned_companies[0]?.address?.zip || '')
	setValue('country', data[0]?.user_owned_companies[0]?.address?.country?.name || '')
	setValue('type', data[0]?.user_owned_companies[0]?.type?.map((t: string) => ({ value: t, label: t })) || [])
	setValue('vatId', data[0]?.user_owned_companies[0]?.vat_number || '')
}

export default updateFormValues
