/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS, RP_ENV } from '../../configs'
import { GetBaseQuery } from '../../utils/'
import { MAX_RETRIES } from './constant'
import { reverseObfuscateConstant } from '../../utils/Constant'

// import { sprintf } from 'sprintf-js'

export const CarouselAPI = createApi({
	reducerPath: 'CarouselAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2)),
	endpoints: (builder) => ({
		getListHomeBanners: builder.query<any, void>({
			query: () => API_ENDPOINTS.get_list_home_banners,
		}),
		getFeaturedBanner: builder.query<any, void>({
			query: () => API_ENDPOINTS.get_featured_banner,
		}),
	}),
})

export const {
    useGetListHomeBannersQuery,
	useGetFeaturedBannerQuery
} = CarouselAPI