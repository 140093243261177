import { ARTICLE_PUBLICATION_SHORTNAMES } from '../../configs'
export default class Publication {
	id = ''
	title = ''
	constructor(id: string, title: string) {
		this.id = id
		this.title = title
	}

	getPublicationShortName(): string {
		if (this.id) {
			const match = ARTICLE_PUBLICATION_SHORTNAMES.find(
				(entry) => entry.id == this.id
			)

			return match?.shortName ? match.shortName : ''
		}

		return ''
	}
}
