import styled from 'styled-components'

export const CancelAndShowContainer = styled.div`
	display: flex;

	@media(max-width: 1000px){
		flex-direction: column;
		gap: 1rem;
		
		& button, & div {
			width: 100%;
			margin: 0;
			text-align: center;
		}

		& button {
			font-weight: bold;
			color: #b19b5a;
		}
	}
`

export const CancelButton = styled.button`
	width: 78px;
	height: 33px;
	margin: 0 10px 0 0;
	padding: 6px 12px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: var(--white-three);

	&:hover {
		background-color: #eee;
	}
`

export const CheckboxGrouping = styled.div`
	margin-top: 20px;
	& div.options-container {
		display: flex;
	}

	& div > div {
		width: 252px;
		margin: 0px 20px 20px 0;
		padding: 0 0.5px 0 0;
	}
`

export const CheckboxLabelCombo = styled.div<{ isBoldedLabel: boolean }>`
	flex-basis: 50%; 
	& input {
		margin-right: 10px;
	}

	& label {
		font-weight: ${(props) => (props.isBoldedLabel ? 'bold' : 'normal')};
		cursor: pointer;
	}
`

export const FilterAndResultsWrapper = styled.div`
	display: flex;

	@media only screen and (max-width: 600px) {
		flex-flow: row wrap;
	}

	& div.filters-container {
		width: 262px;
	}

	& div.filters-container > div.header {
		display: flex;
		justify-content: space-between;
	}

	@media(max-width: 1000px){
		& div.filters-container {
			display: none;
		}
	}
`

export const FilterAndResultWrapperMobile = styled.div`
	display: flex;
	flex-direction: column;

	& span{
		font-weight: bold;
		margin-bottom: 0.5rem
	}

	& button {
		margin-top: 1rem;
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: bold;
		text-align: right;
		color: #b19b5a;
		border: none;
		background: transparent;
		padding: 0;

	}

	@media(min-width: 1000px){
		display: none;
	}
`

export const FilterOptions = styled.div`
	display: flex;
	flex-direction: column;

	& div {
		display: flex;
	}
`

export const FilterOption = styled.div<{ isActive: boolean }>`
	& div.marker {
		width: 2px;
		height: 40px;
		margin: 0 14px 0 0;
		background-color: ${(props) => (props.isActive ? '#333' : '#fff')};
	}

	& div.menu {
		margin: 0;
		padding: 0;
		background-color: var(--white-three);
		padding: 10px 0;
		font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
		cursor: pointer;
	}
`

export const KeywordAndHardcodedFilters = styled.div`
	& div {
		width: 554px;
		margin-right: auto;
		margin-left: auto;
	}

	& .search-container {
		display: flex;
		gap: 1.5rem;
		align-items: flex-start;

		& .show-result {
			width: fit-content;
			height: 68px;
			padding: 1rem;
			border-radius: 8px;
			background-color: #73333f;
			cursor: pointer;
			color: #fff;
			text-align: center;
			display: flex;
			align-items: center;

			& span {
				width: 8rem;
				display: inline-block;
			}
	
			&:hover {
				background-color: #8E4250;
			}
		}
	}

	& div.hardcoded-filters {
		display: flex;

		& div > input {
			height: 16px;
			margin: 4px 8px 4px 0;
			border-radius: 8px;
			border: solid 1px #ccc;
			background-color: var(--white-three);
		}

		& div > label {
			height: 24px;
			margin: 0 0 0 8px;
			font-family: Libre Franklin;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			color: #333;
		}
	}

	& .show-result {
		width: 100%;
		padding: 0.6rem;
		border-radius: 8px;
		background-color: #73333f;
		cursor: pointer;
		color: #fff;
		text-align: center;
		display: none;
		align-items: center;
		justify-content: center;

		& span {
			width: 8rem;
			display: inline-block;
		}

		&:hover {
			background-color: #8E4250;
		}
	}

	@media(max-width: 992px) {
		& .search-container {
			width: 100%;

			& .show-result {
				display: none;
			}
		}

		& div.hardcoded-filters {
			margin-bottom: 1rem;
			margin-left: 0;
		}

		& .show-result {
			display: flex;
			margin-bottom: 1rem;
		}
	}
`

export const PageTitle = styled.div`
	margin: 46px 50px 20px 0px;
	font-family: Libre Franklin;
	font-size: 22px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #333;
`

export const ResetButton = styled.button`
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: right;
	color: rgb(160, 132, 54);
	border: none;
	background: transparent;

	&:hover {
		text-decoration: underline;
	}
`

export const TitleResetContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 12px;
`

export const ResultsContainer = styled.div`
	margin-top: 20px;
	display: flex;

	& div > div {
		width: 252px;
		margin: 0px 20px 20px 0;
		padding: 0 0.5px 0 0;
	}
`

export const SearchAndCheckboxFilterContainer = styled.div`
	width: 100%;

	& div > input.search-filter-field {
		width: 262px;
		height: 33px;
		margin: 0px 554px 7px 0px;
		padding: 6px 8px 6px 12px;
		border-radius: 8px;
		border: solid 1px #ccc;
		background-color: var(--white-three);
	}

	@media(max-width: 1000px){
		& div.separator{
			display: none;
		}
	}
`

export const SearchAndCheckboxOptionsContainer = styled.div`
	& div.vintage-container {
		display: flex;
		row-gap: 0.7rem;
		flex-wrap: wrap;

		& div > div {
			width: 252px;
			height: 21px;
			margin: 20px 20px 20px 0;
			padding: 0 0.5px 0 0;
		}
	}

	@media(max-width: 1000px){
        & div.vintage-container {

			& div > div {
				width: 100%;
				margin: 20px 0px 0px 0px;
				padding:0;
			}
        }
    }

	@media(max-width: 600px){
		& div.vintage-container {
            & div {
				display: flex;
				align-items: center;
				column-gap: 0.7rem;

				& input {
					margin: 0;
					padding: 0;
				}

				& label {
					margin: 0;
					padding: 0;
				}
            }
		}
	}

	@media(max-width: 350px){
		& div.vintage-container {
            & div {
				flex-basis: 100%;
            }
		}
	}
`

export const SearchFieldContainer = styled.div`
	display: flex;
	margin-top: 10px;

	& input {
		width: 406px;
		height: 33px;
		margin: 10px 10px 20px 0px;
		padding: 6px 13px 6px 12px;
		border-radius: 8px;
		border: solid 1px #ccc;
		background-color: var(--white-three);
	}

	& button {
		width: 66px;
		height: 33px;
		margin: 10px 334px 20px 10px;
		padding: 6px 15.5px;
		border-radius: 8px;
		background-color: #73333f;
		border: none;
		color: #fff;

		&:hover {
			background-color: #8E4250;
		}
	}

	@media(max-width: 1000px){
		width: 100%;
		align-items: center;
		gap: 1rem;
		margin-bottom: 2.5rem;

		& input {
            width: 100%;
			margin: 0
        }

		& button {
			display: none;
		}
	}
`

export const SearchFieldContainerDesktop = styled.div`
	display: flex;
	margin-top: 10px;

	& input {
		width: 406px;
		height: 33px;
		margin: 10px 10px 20px 0px;
		padding: 6px 13px 6px 12px;
		border-radius: 8px;
		border: solid 1px #ccc;
		background-color: var(--white-three);
	}

	& button {
		width: 66px;
		height: 33px;
		margin: 10px 334px 20px 10px;
		padding: 6px 15.5px;
		border-radius: 8px;
		background-color: #73333f;
		border: none;
		color: #fff;

		&:hover {
			background-color: #8E4250;
		}
	}

	@media(max-width: 1000px){
		display: none;
	}
`

export const SearchFieldContainerMobile = styled.div`
	display: none;

	& input {
		width: 406px;
		height: 33px;
		margin: 10px 10px 20px 0px;
		padding: 6px 13px 6px 12px;
		border-radius: 8px;
		border: solid 1px #ccc;
		background-color: var(--white-three);
	}

	& button {
		width: 66px;
		height: 33px;
		margin: 10px 334px 20px 10px;
		padding: 6px 15.5px;
		border-radius: 8px;
		background-color: #73333f;
		border: none;
		color: #fff;

		&:hover {
			background-color: #8E4250;
		}
	}

	@media(max-width: 1000px){
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		margin-bottom: 2.5rem;
		border: 1px solid grey;
		border-radius: 10px;

		& i {
			padding-left: 0.5rem;
		}

		& input {
			width: 100%;
			margin: 0;
			border: none;
			
			&:focus {
				outline: none;
				border: none;
			}
		}
	}
`

export const ShowButton = styled.div`
	height: 33px;
	margin: 0 0 0 10px;
	padding: 6px 12px;
	border-radius: 8px;
	background-color: #73333f;
	cursor: pointer;
	color: #fff;
	text-align: center;

	&:hover {
		background-color: #8E4250;
	}
`

export const Title = styled.div<{ margin: string }>`
	display: flex;
	align-items: center;
	gap: 0.4rem;
	margin: ${(props) => props.margin};
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
	& input {
		margin-right: 10px;
	}

	& .certified-logo{
		height: 18px;
		width: 18px;
	}
`

export const RangeFilterContainer = styled.div`
	max-width: 700px;

	& .reset-container {
		display: flex;
		align-items: end;
		justify-content: flex-end;
	}

	& .ErrorTitle{
		display: flex;
		flex-direction: column;
		line-height: 5px;
	}

	& .ratingError {
		font-weight: bold;
		font-size: 14px;
		color: red
	}

	& .ratingErrorSub{
		font-style: italic;
		font-size: 14px;
		color: red
	}
`

export const RatingFilterContainer = styled.div`
	width: 50%;
	margin-top: 10px;
	margin-left: 20px;

	& .title {
		font-weight: bold;
	}

	& .slider-container {
		margin-top: 20px;
	}

	& .validation {
		color: red;
		font-style: italic;
	}
	@media(max-width: 1000px){
		width: 100%;
	}
`

export const RangeInputs = styled.div`

	display: flex;
	justify-content: space-between;
	padding-bottom: 15px;

	& .range-field {
		font-size: 14px;
		width: 70px;
	}
	.input-container {
		padding-top: 5px;
		position: relative;
	}

	& .indicator {
		position: absolute;
		top: 50%;
		left: 10px;
		transform: translateY(-50%);
		font-style: normal;
		font-weight: bold;
	}

	& .rp-input {
		padding-left: 30px;
	}

	& .rPrice-input {
		padding-left: 20px;
		width: 80px;
	}

	& .divider {
		margin-top: 20px;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	}

	/* Firefox */
	input[type=number] {
	-moz-appearance: textfield;
}`

export const SliderCotainer = styled.div`
	margin-bottom: 20px;
`

export const ChartContainer = styled.div`
	height: 200px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	& div {
		width: 1%;
		background-color: rgba(217, 217, 217);
	}

`

export const PublicationDateFormContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: auto;

	& .sub-title{
		font-size: 12px;
		font-style: italic;
	}

	& span {
		margin: 0 0 6px;
		font-family: Libre Franklin;
		font-size: 14px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #333;
	}

	& .from-field {
		margin-bottom: 20px;

		& span{
			padding-bottom: 6px;
		}

		& input {
			width: 262px;
			height: 33px;
			margin: 6px 0 0;
			padding: 5px 5px 4px 12px;
			border-radius: 8px;
			border: solid 1px #ccc;
			background-color: var(--white-three);
		}
	}

	& .to-field {		
		margin-bottom: 10px;

		& span{
			padding-bottom: 6px;
		}

		& input {
			width: 262px;
			height: 33px;
			margin: 6px 0 0;
			padding: 5px 5px 4px 12px;
			border-radius: 8px;
			border: solid 1px #ccc;
			background-color: var(--white-three);
		}
	}

	& .applyBTN{
		display: flex;
		justify-content: end;
	}

	& .errorContainer {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: red
	}
`