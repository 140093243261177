/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import SectionSeparator from '../../../sections/section-separator'
import { WineAlternativeContainer, WineRowInfo, InfoDisplayContainer, WineRowContainer} from './styles'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { slugify } from '../../../../../utils/ArticlesUtil'
import { TranslationsUtil } from '../../../../../utils'

import englishTranslation from '../../wine/translations/en.json'
import { WineSelectedType } from '../common/types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../app/store'
import { isMobile } from 'react-device-detect'

const english: LanguageDefinitions = englishTranslation

const translationUtil = new TranslationsUtil(english)

type InfoDisplayType = {
	title: string;
	content: ReactNode;
}

const capitalized = (str:string) => {
	if(str){
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
	}else{
		return ''
	}
} 

const wineProperties = (wine: any) => {
	const winePropertiesLabel: string[] = []
	const winePropertiesValues: string[] = []

	if (wine.color_class) {
		winePropertiesLabel.push('Color')
		winePropertiesValues.push(wine.color_class)
	}

	if (wine.maturity) {
		winePropertiesLabel.push('Maturity')
		winePropertiesValues.push(wine.maturity)
	}

	if (wine.type) {
		winePropertiesLabel.push('Type')
		winePropertiesValues.push(wine.type)
	}

	if (wine.dryness) {
		winePropertiesLabel.push('Sweetness')
		winePropertiesValues.push(capitalized(wine.dryness))
	}
	
	return {
		label: winePropertiesLabel,
		values: winePropertiesValues
	}
}

function InfoDisplay ({title, content}: InfoDisplayType): ReactElement{
	const translate = translationUtil.getTranslator()
	return <InfoDisplayContainer>
		<span className='title'>{translate(title)}:</span>
		<span className='content'>{content}</span>
	</InfoDisplayContainer>
}

function WineLayout({wine, checkedWine, wineIds}: {wine: any, checkedWine: (e: React.ChangeEvent<HTMLInputElement>, isAll: boolean) => void, wineIds: WineSelectedType[] }): ReactElement {
	let wineData: WineSelectedType = {
		id: '',
		checked: false,
		wine_details:{}
	}
	const findWine = wineIds.find(wineArr => wineArr.id === wine.objectID)
	const [dateValue, setDateValue] = useState<string>()

	if(!findWine){
		wineData = {
			id: wine.objectID,
			checked: false,
			wine_details:{}
		}
	}else{
		wineData = findWine
	}

	useEffect(() => {
		if(wine?.latest_note?.published_at.$date){
			const convertDate = JSON.stringify(wine?.latest_note?.published_at.$date)
			const regex = /"([^"]+)"/
			const match = convertDate.match(regex)
			const extractedDate = match ? match[1] : ''
			setDateValue(extractedDate)
		} else { 
			setDateValue(wine?.latest_note?.published_at)
		}
	},[wine])

	return  <WineRowContainer>
		<div className='name' style={{padding: isMobile ? 24 : 32}}>
			<div className='title' style={{alignItems: 'center'}}>
				<input checked={wineData.checked} name={wine.name} type='checkbox' id={`${wine.name} - ${wine.objectID}`} onChange={(e) => checkedWine(e, false)} value={wine.objectID} />
				<label htmlFor={`${wine.name} - ${wine.objectID}`}>{wine.display_name}</label>
			</div>
			<div>
				{wine.eco_distinction ? <span className="icon-container" title='RP Green Emblem'>
					<img style={{ height: '19px' }} src='img/icons/icon-rpwa-eco.svg' />
				</span>
					: null}

				{wine.certified ? <span className="icon-container" title={wine.certified ? wine.certified : ''}>
					<img src='img/icons/elements-icons-certified.png' />
				</span>
					: null}

				{wine.color_class ? <span className="icon-container" title={wine.color_class}>
					<img src={`img/icons/${wine.color_class?.toLowerCase()}.svg`} />
				</span> : null}
			</div>
		</div>
		<WineRowInfo isMobile={isMobile}>
			<div className='details'>
				<InfoDisplay title='Rating' content={wine.rating_display} />
				<InfoDisplay title='Release Price' content={wine.price_low ? `$${wine.price_low}`  : 'N/A'} />
				<InfoDisplay title='Drink Date' content={wine.drink_date_low ? <span>{wine.drink_date_low} - {wine.drink_date_high}</span> : 'N/A'} />
			</div>
			<div>{wine?.latest_note?.content}</div>
			<div className='details'>
				<InfoDisplay title='Reviewed by' content={wine?.last_reviewer ? <Link to={`/author/${wine?.latest_note?.reviewer?._id ?? wine?.latest_note?.reviewer?.objectID }`}>{wine?.last_reviewer}</Link> : <a>N/A</a>} />
				<InfoDisplay title='Source' content={<Link to={`/issues/${wine?.latest_note?.issue?._id ?? wine?.latest_note?.issue?.objectID}`}>{wine.issue_appearances.length && wine.issue_appearances[wine.issue_appearances.length - 1]}</Link>} />
				<InfoDisplay title='Published date' content={<span>{moment(dateValue).format('MMM DD, YYYY')}</span>} />
			</div>
			<SectionSeparator position={'horizontal'} />
			<div className='details'>
				<InfoDisplay title='Producer' content={wine.name} />
				<InfoDisplay title='Wine regions' content={<span>
					{wine.region}
					{wine.sub_region ? ', ' + wine.sub_region : ''}
					{wine.appellation ? ', ' + wine.appellation : ''}
					{wine.sub_appellation ? ', ' + wine.sub_appellation : ''}
				</span>} />
				<InfoDisplay title={wineProperties(wine).label.join('/')} content={<span>{wineProperties(wine).values.join('/')}</span>} />
			</div>
			<div className='footer'>
				<InfoDisplay title='Variety' content={wine.varieties?.length ? wine?.varieties[0] : 'N/A' } />
				{wine.slug ? <Link to={`/wines/${wine.objectID}/${slugify(wine.slug)}`}>View wine</Link> : <Link to={`/wines/${wine.objectID}`}>View wine</Link> }
			</div>
		</WineRowInfo></WineRowContainer>
}

function AlternateWineResults(props:{results:any, toggleChecked: (e: React.ChangeEvent<HTMLInputElement>, isAll: boolean) => void}): ReactElement {
	const {results, toggleChecked} = props

	const [wineData, setWineData] = useState<any[]>([])
	const selectedWines = useSelector((state: RootState) => state.SearchResults.Wines.selectedWines)

	useEffect(() => {
		if(selectedWines){
			setWineData(selectedWines)
		}	
	},[selectedWines])
	
	return <><WineAlternativeContainer>
		<div className='result-container'>
			{results.map((wine:any, index: number) => <WineLayout wineIds={wineData} checkedWine={toggleChecked} key={index} wine={wine} />)}
		</div>
	</WineAlternativeContainer>
	</>
}

export default AlternateWineResults