import React, { ReactElement } from 'react'
import { Control, FieldValues } from 'react-hook-form'
import Input from '../../../../../components/form-components/input/Input'
import { isMobile } from 'react-device-detect'

interface EditProfileFormProps {
	control: Control<FieldValues>;
}

const firstNameRules = {
	required: 'First name is required',
}

const lastNameRules = {
	required: 'Last name is required',
}

const emailRules = {
	required: 'Email address is required',
	pattern: {
		value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
		message: 'Invalid email address'
	}
}

const EditProfileForm = ({control}: EditProfileFormProps): ReactElement => {

	// const DefaultImage = (): ReactElement => {
	// 	return (
	// 		<div
	// 			style={{
	// 				backgroundColor: 'gray',
	// 				width: 100,
	// 				height: 100,
	// 				display: 'flex',
	// 				justifyContent: 'center',
	// 				alignItems: 'center',
	// 				fontSize: '36px',
	// 				color: 'white',
	// 				borderRadius: '100%'
	// 			}}
	// 		>
	// 			+
	// 		</div>
	// 	)
	// }

	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginBottom: 32 }} onClick={() => console.log('ey!')}>
				{/* <DefaultImage /> */}
				{/* <img src='img/icons/default_article_banner_sm.jpg' alt="+" style={{ height: 120, width: 120, objectFit: 'cover', borderRadius: '100%', border: 'solid 1px black', cursor: 'pointer' }} /> */}
			</div>
			<div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
				<div style={{ width: isMobile ? '100%' : '48%' }}>
					<Input name='firstName' type='text' placeholder='First Name' disabled={false} control={control} rules={firstNameRules} />
				</div>
				<div style={{ marginRight: isMobile ? 0 : 26 }} />
				<div style={{ width: isMobile ? '100%' : '48%' }}>
					<Input name='lastName' type='text' placeholder='Last Name' disabled={false} control={control} rules={lastNameRules} />
				</div>
			</div>
			<Input name='email' type='text' placeholder='Email address' disabled={false} control={control} rules={emailRules} />
		</div>
	)
}

export default EditProfileForm