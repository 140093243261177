import styled from 'styled-components'

export const RemoveModal = styled.div`
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 99;
	transition: opacity 0.3s ease-in-out;

	&.open {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		padding: 8rem 1rem;
	}

	&.closing {
		opacity: 0;
	}

	.modal-container {
		background-color: #fff;
		border-radius: 12px;
		max-width: 564px;
		width: 100%;
		overflow-y: auto;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
		animation: fadeIn 0.3s ease-in-out;
		padding: 40px;

		.modal-title {
			display: flex;
			justify-content: flex-end;
			padding-bottom: 15px;

			.close-btn {
                cursor: pointer;
                width: 24px;         
                height: 24px;        
                font-size: 60px;     
                color: black;
                display: flex;      
                align-items: center; 
                justify-content: center; 
            }
		}

		.modal-body {
			font-size: 16px;
			line-height: 1.5;
            padding: 0;
		}

		.modal-footer {
			display: flex;
			justify-content: center;
			column-gap: 1rem;
            border: none;
            padding: 0;
		}
	}

	@keyframes fadeIn {
		from { opacity: 0; }
		to { opacity: 1; }
	}

	@media (max-width: 550px) {
		&.open {
			padding: 0 !important;
		}

		.close-btn {
            margin-right: 10px; 
        }
		
		.modal-container {
			height: inherit;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 40px 25px 25px 25px;
			border-radius: 0px;
		}

		.modal-footer {
			display: flex;
			justify-content: center;
			flex-direction: column;
			row-gap: 15px;
            border: none;
            padding: 0;
		}
	}


	// @media (max-width: 508px) {
	// 	.modal-container {
	// 		height: 100%;
	// 		display: flex;
	// 		flex-direction: column;
	// 		justify-content: space-between;
	// 	}

	// 	.modal-footer {
	// 		margin-bottom: 10px;
	// 		border-top: 1px solid lightgrey;
	// 	}
	// }
`
export const BackButton = styled.button`
    background: transparent;
    border: 1px solid #73333f;
    color: black;
    padding: 15px 20px; 
    font-size: 16px; 
    border-radius: 4px !important;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 600;

	@media (max-width: 550px) {
		width: 100%;
	}
    
    // &:hover {
    //     background: #9A6C6D;
    //     color: white;
    // }
`

export const ProceedButton = styled.button`
    background-color: #73333f;
    border: none; 
    color: white;
    padding: 15px 20px;
    font-size: 16px;
    border-radius: 4px !important;
    cursor: pointer;
    transition: all 0.3s ease;

	@media (max-width: 550px) {
		width: 100%;
	}
    
    // &:hover {
    //     background: #9A6C6D;
    // }
`