/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, LegacyRef, ReactElement, SetStateAction, useEffect, useRef, useState } from 'react'
import {  ViewWineListButton, WineSelectionActions } from '../AlternateWineResults/styles'
import { useReactToPrint } from 'react-to-print'
import PrintNotes from '../AlternateWineResults/PrintNotes'
import { APP_CONFIG } from '../../../../../configs'
import { WineSelectedType } from './types'
import { IsUserAuthenticated } from '../../../../../utils'
import { isFreeUser } from '../../../../../utils/AuthenticationUtil'
import { useAppSelector } from '../../../../../app/hooks'
import {Users as UsersFromStore} from '../../../../../app/slices/Users'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import { ChevronDown } from './icons/Icons'

import './style.css'
import WineListModal from '../../../../../components/wine-list-modal'
import moment from 'moment'

type Props = {
	type: string;
	wineListView: (type:string) => void;
	selectedCounts: number;
	toggleChecked: (e: React.ChangeEvent<HTMLInputElement>, isAll: boolean) => void;
	wineDisplay: string;
	wineData: WineSelectedType[];
	checkValue: boolean;
	setCheckValue: Dispatch<SetStateAction<boolean>>;
}

type SelectToPrintType = {
	title: string;
	count: number;
	onSelectedType:(type:string) => void;
}

const SelectToPrint = ({ title, count, onSelectedType }: SelectToPrintType) => {
	const { userData } = useAppSelector(UsersFromStore)
	return <div>
		<DropdownMenu trigger={({ triggerRef, isSelected, testId, ...providedProps  }) => (
			<button className='select-button' disabled={count === 0} type="button" {...providedProps} ref={triggerRef as LegacyRef<HTMLButtonElement>} >
				{title} ({count}) <ChevronDown width='15px' height='15px' className='select-icon' />
			</button>
		)}>
			<DropdownItemGroup>
				<DropdownItem onClick={()=> onSelectedType('true')}>{`${'VIEW LIST'} (${count})`}</DropdownItem>
				<DropdownItem onClick={()=> onSelectedType('1x1')}>PRINT NOTE</DropdownItem>
				{userData?.subscription?.planId === 'commercial-1-year' && 
				<>
					<DropdownItem onClick={()=> onSelectedType('2x2')}>PRINT 2 x 2 FORMAT</DropdownItem>
					<DropdownItem onClick={()=> onSelectedType('3x1')}>PRINT 3 x 1 FORMAT</DropdownItem>
				</>			
				}
				<DropdownItem onClick={()=> onSelectedType('shopping-list')}>PRINT SHOPPING LIST</DropdownItem>				
			</DropdownItemGroup>
		</DropdownMenu>
	</div>
}

const SelectionActions = ({type, wineListView, selectedCounts, wineData, toggleChecked, wineDisplay, checkValue, setCheckValue }: Props):ReactElement => {
	const { userData } = useAppSelector(UsersFromStore)
	const componentRef = useRef<null | HTMLDivElement>(null)
	const [printType, setPrintType] = useState('1x1')
	const [showModal, setShowModal] = useState(false)
	const [isPrinting, setIsPrinting] = useState(false)
	const dateToday = new Date()

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: 'Robert Parker - ' + (printType === '1x1' ? 'Print Note' : printType) + '_' + moment(dateToday).format('YYYYMMDD')
	})

	const changeWineDisplay = () => {
		if(wineDisplay === 'default') wineListView('wineList')
		else wineListView('default')
	}

	const selectTypeHandler = (type:string) => {
		if (type !== 'true') {
			if (type === 'shopping-list'){
				setPrintType('Shopping List')
			} else {
				setPrintType(type)
			}
			setIsPrinting(true)
		} else {
			setIsPrinting(false)
			setShowModal(true)
		}
	}

	useEffect(() => {
		if (isPrinting) {
			handlePrint()
			setIsPrinting(false)
		}
	}, [isPrinting])

	if(type === APP_CONFIG.SEARCH.TYPES.ARTICLE || (isFreeUser(userData) || !IsUserAuthenticated()) || wineDisplay === 'producer'){
		return <></>
	}

	return <>
		<WineSelectionActions>
			<div className='checkbox-all'>
				<input checked={checkValue} type='checkbox' id='toggle-all-wine' onChange={(e) => toggleChecked(e, true)} />
				<label htmlFor='toggle-all-wine'>Select all</label>
			</div>
			<div className='actions'>
				<SelectToPrint count={selectedCounts} title='WINES SELECTED' onSelectedType={selectTypeHandler} />
				<ViewWineListButton onClick={changeWineDisplay}>VIEW {wineDisplay === 'default' ? 'TASTING NOTES' : 'WINE LIST'}</ViewWineListButton>
			</div>
		</WineSelectionActions>
		<div className='print-hide'>
			<PrintNotes type={printType} wineData={wineData} refs={componentRef} />
		</div>
		{showModal && <WineListModal setShowModal={setShowModal} setCheckValue={setCheckValue} />}
	</>
}

export default SelectionActions
