import Content from './Content'
export default class Issue extends Content {
	id = ''
	publish_date = new Date()
	title = ''
	constructor(id: string, publish_date: string, title?: string) {
		super()
		this.id = id
		super.published_date = publish_date ? new Date(publish_date).toISOString() : ''
		this.title = title ? title : ''
	}
}
