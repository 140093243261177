/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'

function TastingNotesPaginator(props: { tastingNoteCount: number, activeNumber:number, actionEvent: any }): ReactElement {
	const { activeNumber, actionEvent, tastingNoteCount } = props

	return (
		<>
			<nav className="mnmd-pagination">
				<div className="mnmd-pagination__links text-center">
					{[...Array(tastingNoteCount? tastingNoteCount:1 )].map((value: number, index: number) => {
						const pageNUmber = index + 1
						return (
							<span key={ index } >
								<span onClick={() => {
									actionEvent(pageNUmber)
								}} className={ activeNumber === pageNUmber? 'mnmd-pagination__item mnmd-pagination__item-current': 'mnmd-pagination__item'}>{ pageNUmber }</span>
							</span>
						)
					})}
					{/* <a className="mnmd-pagination__item mnmd-pagination__item-next" href="#"><i className="mdicon mdicon-arrow_forward"></i></a> */}
				</div>
			</nav>
		</>
	)
}

export default TastingNotesPaginator