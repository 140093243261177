import styled from 'styled-components'

const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
`

export const AuthorSummary = styled.div`
	cursor: pointer;
`

export const BioContainer = styled.div`
	& div.content {
		margin: 0 18px;
		color: #777;
		${commonTextStyle}

		padding-bottom: 5px;
		max-height: 120px;
		overflow: hidden;
		text-overflow: ellipsis;
		content: '';
		position: relative;
	}

	& div.content:before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: linear-gradient(transparent 20px, #fafafa);
	}

	& div.profile-link {
		margin-top: 20px;
		display: flex;
		justify-content: right;
	}
`
