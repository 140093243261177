import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ClearPublicationDateFilters, ClearRangeFilters, SetFacetsFilters, SetSearchDisplayType, SetSort } from '../../../../app/slices/SearchFilters'

import { APP_CONFIG } from '../../../../configs'

import {
	NavHighlight,
	NavSeparator,
	SearchTabs,
	TabbedMenu as DivTabbedMenu,
} from './common/styles'
import { isMobile } from 'react-device-detect'

function TabbedMenu(props: { searchType: string }): ReactElement {

	const { searchType } = props

	const history = useHistory()
	const dispatch = useDispatch()

	const switchSearchScope = (type: string) => {
		
		dispatch(SetFacetsFilters({
			facetFilters: [],
			activeFilters: {}
		}))
		dispatch(SetSort(''))
		dispatch(SetSearchDisplayType('default'))
		if(type === 'article'){
			dispatch(ClearRangeFilters())
			dispatch(ClearPublicationDateFilters())
		}
		// history.push(`/search/${type}`)
		window.location.href = `/search/${type}`
	}

	return (
		<DivTabbedMenu>
			{isMobile ? <br/> : ''}
			<div className='menus'>
				<SearchTabs onClick={() => switchSearchScope('wine')}>WINES</SearchTabs>
				<SearchTabs onClick={() => switchSearchScope('article')}>ARTICLES</SearchTabs>
			</div>
			<div className='indicator'>
				<NavSeparator>
					<NavHighlight isHighlighted={searchType === APP_CONFIG.SEARCH.TYPES.WINE ? true : false}></NavHighlight>
					<NavHighlight isHighlighted={searchType === APP_CONFIG.SEARCH.TYPES.ARTICLE ? true : false}></NavHighlight>
				</NavSeparator>
			</div>
		</DivTabbedMenu>
	)
}

export default TabbedMenu