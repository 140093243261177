import styled from 'styled-components'

const commonTextStyles = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
`

export const FilterContainer = styled.div`
	display: flex;
	justify-content: space-between;

	& div.filter {
		display: flex;
		align-items: center;
		& label {
			margin-right: 10px;
		}
	}

	& div.counter {
		font-size: 16px;
		font-weight: bold;
		color: #333;
		${commonTextStyles}
	}
`

export const ListContainer = styled.div`
	& div.list-row {
		display: flex;
	}

	@media (max-width: 1200px) {
		& div.list-row {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}


`

export const ListItem = styled.div<{ placement: 'left' | 'right' }>`
	margin-top: 20px;

	padding-left: ${(props) => (props.placement === 'right' ? '10px' : '0px')}; 
	padding-right: ${(props) => (props.placement === 'left' ? '10px' : '0px')}; 

	&.single-emblem {
		& .description {
			width: 100%;
		}
	}

	& div.photo {
		width: 565px;
		height: 190px;
		margin-bottom: 20px;
		mix-blend-mode: multiply;
		background-image: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 0.5),
			rgba(0, 0, 0, 0.5)
		);
	}

	& div.country {
		margin-top: 20px 
		margin-bottom: 10px;
		font-size: 12px;
		font-weight: bold;
		color: #777;
	}

	& div.name {
		margin-top: 10px; 
		margin-bottom: 10px;
		font-size: 21px;
		font-weight: bold;
		
		${commonTextStyles}
		& a {
			color: #333;
		}
	}

	& div.description {
		
		margin-top: 10px;
		margin-bottom: 19px;
		font-size: 14px;
		font-weight: normal;
		color: #333;
		
    padding-bottom: 5px;
    max-height:120px;
    overflow:hidden;
    text-overflow: ellipsis;
    content: "";
    position:relative;
	}

	& div.description:before {
		content:'';
    width:100%;
    height:100%;    
    position:absolute;
    left:0;
    top:0;
    background:linear-gradient(transparent 20px, white);
	}

	@media(min-width: 1200px) {
		&.single-emblem {
			& .description {
				width: 50%;
			}
		}
	}

	@media (max-width: 1200px){
		& div.photo {
			width: 100%;
			height: 100%;

			& img{
				width: 100%;
			}
		}

		& {
			padding: 0
		}
	}
`

export const PageTitle = styled.h5`
	font-size: 40px;
	font-weight: bold;
	color: #333;
	${commonTextStyles}
	margin-bottom: 30px;
`

export const SubTitle = styled.span`
	font-size: 24px;
	font-weight: normal;
	color: #333;
	${commonTextStyles}
	margin-bottom: 30px;
`

export const OrangeButton = styled.div`
	max-width: 380px;
	height: 33px;
	padding: 6px 15px;
	border-radius: 8px;
	background-color: #e01a00;
	color: #fff;
	font-weight: bold;
	cursor: pointer;
	text-align: center;
	margin-bottom: 30px;
	margin-top: 30px;
`
export const GoldButton = styled.div`
	max-width: 380px;
	height: 33px;
	padding: 6px 15px;
	border-radius: 8px;
	background-color: #a08436;
	color: #fff;
	font-weight: bold;
	cursor: pointer;
	text-align: center;
	margin-bottom: 30px;
	margin-top: 30px;
`

export const BlueButton = styled.div`
	width: fit-content;
	height: 33px;
	padding: 6px 15px;
	border-radius: 8px;
	background-color: #73333f;
	color: #fff;
	font-weight: bold;
	cursor: pointer;
	text-align: center;
	margin-bottom: 30px;
	margin-top: 30px;
`

export const BlueButtonMobile = styled.div`
	width: fit-content;
	padding: 6px 9px;
	border-radius: 8px;
	background-color: #73333f;
	color: #fff;
	font-weight: bold;
	cursor: pointer;
	text-align: center;
	margin-bottom: 30px;
	margin-top: 30px;
`

export const PageLink = styled.div`
	cursor: pointer;
	margin-bottom: 15px;
	& a {
		color: #73333f;

		&:hover {
			color: #73333f;
		}
	}

	@media(max-width: 992px){
		margin: 20px 0 10px 0;
	}
`