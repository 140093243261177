/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import { TranslationsUtil } from '../../../../utils'
import { debounce } from 'lodash'

// Services

// Styles
import { IssueSearchField } from './styles'

// i18n
import englishTranslation from './translations/en.json'
import { useGetIssuesQuery } from '../../../../app/services'
const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

function IssueArchives(): ReactElement {
	const [issueKeyword, setIssueKeyword] = useState('')
	
	const translate = translationUtil.getTranslator()
	const {data: issues, error, isLoading } = useGetIssuesQuery({limit: 5, offset: 0, keyword: issueKeyword})

	const debouncedSearch = debounce(async (query) => {
		setIssueKeyword(query)
	}, 600)

	const handleFilter = (keyword: string) =>{
		debouncedSearch(keyword)
	}

	return (
		<>
			<div className="widget__title">
				<h4 className="widget__title-text">{ translate('Archives') }</h4>
			</div>
			<IssueSearchField>
				<div><span>Search for an issue</span></div>
				<div className='search-box'>
					<input className='component-design' type='text' onChange={(event) => { handleFilter(event.target.value)} } />
				</div>
				
			</IssueSearchField>
			{ error? (<span>Error in fetching the archives...</span>):null }
			{ isLoading? (<span>Loading...</span>):null }
			<ul>
				{issues && issues.map((issue: any, issueList_key: number) => (
					<li key={ issueList_key }>
						<Link to={ `/issues/${ issue._id }` } >{ issue.title }<span>{ issue.count?.tasting_notes }</span></Link>
					</li>
				))}
			</ul>
		</>
	)
}
export default IssueArchives