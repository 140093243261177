import React, { ReactElement } from 'react'
import { RedeemCardFormBody } from '../../styles'
import { isMobile } from 'react-device-detect'
import CheckoutButton from '../../../../../../components/form-components/buttons/CheckoutButton'

type Props = {
	planIdDisplay: (planId: string) => string;
	planId: string
	confirmHandler: () => void
};

function RedeemAccountPageLogged({ planIdDisplay, planId, confirmHandler }: Props): ReactElement {
	return <RedeemCardFormBody isMobile={isMobile}>
		<div className='head-body'>
			<h3>You&apos;re getting a {planIdDisplay(planId)} Subscription.</h3>
			<p>Please click the Confirm button below to have your current subscription extended.</p>
		</div>
		<div className='form' style={{ alignContent: 'center', flexWrap: 'wrap', paddingBottom: '1.5rem' }}>
			<div className='first-input' style={{ width: '12rem' }}>
				<CheckoutButton onClick={confirmHandler} name='CONFIRM' type='button' />
			</div>
		</div>
	</RedeemCardFormBody>
}

export default RedeemAccountPageLogged
