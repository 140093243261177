import styled from 'styled-components'

export const ArticleContainer = styled.div`
	height: 155px;
	margin-bottom: 5px;

	& img {
		height: 135px;
    object-fit: cover;
	}

  & div.post__excerpt{
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media(max-width: 767px){
    height: 100%;
    margin-bottom: 0;
    padding:0;

    & img {
      height: 100%;
    }
  }
`

export const Truncate = styled.div`
  .truncate-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`