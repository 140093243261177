/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, ReactElement } from 'react'
import { Redirect, Route } from 'react-router-dom'

// Router related 
import { useHistory } from 'react-router-dom'

// Slices
import { useAppSelector } from '../../app/hooks'
import { clearCookie, getCookie } from '../../utils/CookieUtils'
import { ClearUserSession, SaveAuthenticationSession } from '../../utils'
import { useDispatch } from 'react-redux'
import { Users as UsersFromStore } from '../../app/slices/Users'
import { Authentication as AuthStore, UpdateUserAuthDetails } from '../../app/slices/Authentication'
import moment from 'moment'
import { useRefreshAccessTokenMutation } from '../../app/services/Users'
import { ClearSelectedWines } from '../../app/slices/SearchResults'
import { removeAccessLocalStorage, ValidateAccessToken } from '../../utils/ValidateAccessToken'
import { APP_CONFIG } from '../../configs'

function TitleRoute(props: any): ReactElement {
	const history = useHistory()

	const [ userRefreshAccessMutation, {data: refreshData}] = useRefreshAccessTokenMutation()

	const dispatch = useDispatch()
	const { title, isAuthRequired,...rest } = props

	const sessionData = getCookie(APP_CONFIG.AUTHENTICATION.SESSION_LABEL)

	const { userData } = useAppSelector(UsersFromStore)
	const { User }  = useAppSelector(AuthStore)
	const { params } = rest?.computedMatch
	const accessToken = localStorage.getItem('accessToken')

	const location = window.location

	useEffect(() => {
		const finalTitle = 'Robert Parker ' + (title !== ' ' ? ('- ' + title) : title)
		document.title = finalTitle
	})

	useEffect(() => {
		const rootUrl = window.location.origin
		const robotTag = document.querySelector('meta[name="robots"]')

		if (rootUrl === 'https://rp-wine-v2.jam247.dev' && !robotTag) {
			const metaTag = document.createElement('meta')
			metaTag.name = 'robots'
			metaTag.content = 'noindex'
			document.head.appendChild(metaTag)
		}
	}, [])
	
	useEffect(() => {

		if(userData && userData?._id && accessToken){

			const isOptin = () => {
				if(userData.profile.preferences && userData.profile.preferences.receive_newsletter)
					return userData.profile.preferences.receive_newsletter === true || userData.profile.preferences.receive_newsletter === 1 ? true : false
	
				return false
			}
	
			window.dataLayer = window.dataLayer || []
	
			const event = {
				'event': 'virtualPageview',
				'pageUrl': location.href,
				'pageTitle': 'Robert Parker - Article',
				'isSignedIn': true,
				'isBusiness': false,
				'isOptin': isOptin(),
				'customerid': userData?._id,
				'articleid': params?.articleID
			}
				
			// console.log('event on logged account:', event)
			window.dataLayer.push(event)
		}
	
		if((!userData || !userData?._id) && !accessToken){
			window.dataLayer = window.dataLayer || []
	
			const event = {
				'event': 'virtualPageview',
				'pageUrl': location.href,
				'pageTitle': 'Robert Parker - Article',
				'isSignedIn': false,
				'isBusiness': false,
				'isOptin': false,
				'customerid': userData?._id,
				'articleid': params?.articleID
			}
				
			// console.log('event on not logged account:', event)
			window.dataLayer.push(event)
		}

		history.listen(() => {
			const LastLogin = getCookie('LastLogin')
			if (new Date() >= new Date(LastLogin)) {
				ClearUserSession(dispatch)
			}
		})
		
	},[title, userData, window.location.href, accessToken])

	const fetchRefreshToken = async () => {
		try {
			const { refreshToken, accessToken, user } = User
			await userRefreshAccessMutation({ refreshToken, accessToken, userId: user._id})
		} catch (error) {
			console.log(error)
		}
	}

	const execLogout = () => {
		clearCookie()
		ClearUserSession(dispatch)
		dispatch(ClearSelectedWines())
		history.push('/')
		removeAccessLocalStorage()
	}

	const validateAccess = async () => {
		const accessToken = localStorage.getItem('accessToken')
		const userId = User?.user?._id
		const result = await ValidateAccessToken(accessToken, userId)

		if(!result.success){
			console.log('Logging out due to:', result)
			execLogout()
		}
	}

	useEffect(() => {
		if(User && User.accessTokenExpiresAt){
			const dayBeforeExpiredDate = moment(User.accessTokenExpiresAt)
			const isAfterExpiration = dayBeforeExpiredDate.isAfter(moment(new Date()).add(45, 'days'))
			const localStorageAccessTokenExp: string | null = localStorage.getItem('accessTokenExpiresAt')
			const currentDate = new Date()
			const accessTokenExpiresAt = new Date(User.accessTokenExpiresAt)

			if (dayBeforeExpiredDate.isSame(moment()) || dayBeforeExpiredDate.isBefore(moment()) || isAfterExpiration)
				fetchRefreshToken()
			if (localStorageAccessTokenExp == null) 
				execLogout()
			if (accessTokenExpiresAt < currentDate) 
				removeAccessLocalStorage()

			validateAccess()
		}
	},[props.path, User])

	useEffect(() => {
		if(refreshData && refreshData.success){
			const { user, client, refreshTokenExpiresAt } = User
			
			const userPayload = {
				token: refreshData.data.token,
				tokenExpiry: refreshData.data.expirationAt,
				refreshToken: refreshData.data.refreshToken,
				refreshTokenExpiry: refreshTokenExpiresAt,
				clientId: client._id,
				country: user.country,
				userId: user._id
			}

			SaveAuthenticationSession(
				'user',
				refreshData.data.token,
				refreshData.data.expirationAt,
				refreshData.data.refreshToken,
				'',
				client._id,
				user._id,
				user.country
			)

			dispatch(UpdateUserAuthDetails(userPayload))
			window.location.reload()
		}

		if(refreshData && !refreshData.success && refreshData.message === 'Refresh token is invalid.'){
			clearCookie()
			ClearUserSession(dispatch)
			dispatch(ClearSelectedWines())
			window.location.href = '/login?AuthExpired=true'
			
		}
		
	}, [refreshData])
	
	if(isAuthRequired && !sessionData.token) return <Redirect to='/login' />

	if (props.path === '/sign-in') return <Redirect to='/login' />

	return <Route {...rest} />
}

export default TitleRoute
