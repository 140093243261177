import React, { ReactElement } from 'react'

type Props = {
    height : string;
    width : string;
    onClick: () => void;
    className?: string;
}

const VisibleIcon = ({ height, width, onClick }: Props): ReactElement => {
	return <svg fill="none" onClick={onClick} height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg"><path d="M12 7C7.91992 7 5.1716 10.5514 4.29842 11.8582C4.10438 12.1486 4.10438 12.5181 4.29842 12.8085C5.1716 14.1153 7.91992 17.6667 12 17.6667C16.0801 17.6667 18.8284 14.1153 19.7016 12.8085C19.8956 12.5181 19.8956 12.1486 19.7016 11.8582C18.8284 10.5514 16.0801 7 12 7Z" stroke="black"/><path d="M14.6667 12.3333C14.6667 13.8061 13.4728 15 12 15C10.5273 15 9.33334 13.8061 9.33334 12.3333C9.33334 11.9309 9.4225 11.5492 9.58214 11.2071C9.83966 10.6552 10.2806 10.2061 10.8265 9.93808C11.1806 9.76426 11.5789 9.66666 12 9.66666C13.4728 9.66666 14.6667 10.8606 14.6667 12.3333Z" stroke="black"/></svg>
}

const InvincibleIcon = ({height, width, onClick}: Props): ReactElement => {
	return <svg onClick={onClick} height={height} version="1.1" viewBox="0 0 32 32" width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title/><desc/><defs/><g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1"><g fill="#929292" id="icon-21-eye-hidden"><path d="M8.10869891,20.8913011 C4.61720816,18.8301147 3,16 3,16 C3,16 7,9 16,9 C17.3045107,9 18.5039752,9.14706466 19.6014388,9.39856122 L18.7519017,10.2480983 C17.8971484,10.0900546 16.9800929,10 16,10 C8,10 4.19995117,16 4.19995117,16 C4.19995117,16 5.71472808,18.3917225 8.84492713,20.1550729 L8.10869891,20.8913011 L8.10869891,20.8913011 L8.10869891,20.8913011 Z M12.398561,22.601439 C13.4960246,22.8529356 14.6954892,23.0000001 16,23 C25,22.999999 29,16 29,16 C29,16 27.3827918,13.1698856 23.8913008,11.1086992 L23.1550727,11.8449273 C26.2852719,13.6082776 27.8000488,16 27.8000488,16 C27.8000488,16 24,21.999999 16,22 C15.019907,22.0000001 14.1028515,21.9099455 13.2480981,21.7519019 L12.398561,22.601439 L12.398561,22.601439 L12.398561,22.601439 Z M19.8986531,15.1013469 C19.9649658,15.3902115 20,15.6910144 20,16 C20,18.2091391 18.2091391,20 16,20 C15.6910144,20 15.3902115,19.9649658 15.1013469,19.8986531 L16,19 C16.7677669,19.0000001 17.5355339,18.7071068 18.1213203,18.1213203 C18.7071068,17.5355339 19.0000001,16.7677669 19,16 L19.8986531,15.1013469 L19.8986531,15.1013469 L19.8986531,15.1013469 Z M16.8986531,12.1013469 C16.6097885,12.0350342 16.3089856,12 16,12 C13.7908609,12 12,13.7908609 12,16 C12,16.3089856 12.0350342,16.6097885 12.1013469,16.8986531 L13,16 C12.9999999,15.2322331 13.2928932,14.4644661 13.8786797,13.8786797 C14.4644661,13.2928932 15.2322331,12.9999999 16,13 L16.8986531,12.1013469 L16.8986531,12.1013469 L16.8986531,12.1013469 Z M24,7 L7,24 L8,25 L25,8 L24,7 L24,7 Z" id="eye-hidden"/></g></g></svg>
}

const CalendarIcon = ({height, width, onClick, className = ''}: Props): ReactElement => {
	return <svg className={className} onClick={onClick} height={height} id="Layer_1" version="1.1" viewBox="0 0 512 512" width={width} xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path d="M448.9,64H416v40.7c0,22.5-23.2,39.3-47.2,39.3S320,127.2,320,104.7V64H192v40.7c0,22.5-24,39.3-48,39.3s-48-16.8-48-39.3  V64H63.1C45.9,64,32,77.3,32,93.4v357.5C32,467,45.9,480,63.1,480h385.8c17.2,0,31.1-13,31.1-29.2V93.4C480,77.3,466.1,64,448.9,64z   M432,419.9c0,6.6-5.8,12-12.8,12L92.7,432c-7-0.3-12.7-5.6-12.7-12.2V188.3c0-6.9,5.9-12.3,13.3-12.3h325.5  c7.3,0,13.2,5.3,13.2,12.1V419.9z"/><path d="M176,96c0,17.7-14.3,32-32,32l0,0c-17.7,0-32-14.3-32-32V64c0-17.7,14.3-32,32-32l0,0c17.7,0,32,14.3,32,32V96z"/><path d="M400,96c0,17.7-14.3,32-32,32l0,0c-17.7,0-32-14.3-32-32V64c0-17.7,14.3-32,32-32l0,0c17.7,0,32,14.3,32,32V96z"/></svg>
}

export default { InvincibleIcon, VisibleIcon, CalendarIcon }
