import React, { ReactElement, ReactNode, useState } from 'react'
import { BackButton, ProceedButton, RemoveModal } from '../styles'

type ModalType = {
	isOpen: boolean;
	onClose: () => void;
	children: ReactNode;
	title: string;
	onProceed: () => void;
	width?: number;
}

function ConfirmJoinModal({ isOpen, onClose, children, onProceed, width }: ModalType): ReactElement {

	const [isClosing, setIsClosing] = useState(false)

	const handleModalClose = () => {
		setIsClosing(true)
		setTimeout(() => {
			onClose()
			setIsClosing(false)
		}, 300)
	}

	return (
		<RemoveModal className={`${isOpen ? 'open' : ''} ${isClosing ? 'closing' : ''}`}>
			<div style={{ width }} className="modal-container">
				<div className='mobile-content'>
					<div className='modal-title'>
						<span className="close-btn" onClick={handleModalClose}>&times;</span>
					</div>
					<div className='modal-body'>
						{children}
					</div>
				</div>
				<div className='modal-footer'>
					<BackButton onClick={handleModalClose}>CANCEL</BackButton>
					<ProceedButton onClick={onProceed}>CONFIRM</ProceedButton>
				</div>
			</div>
		</RemoveModal>
	)
}

export default ConfirmJoinModal
