/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS, RP_ENV } from '../../configs'

import { GetBaseQuery } from '../../utils/'
import { MAX_RETRIES } from './constant'
import { reverseObfuscateConstant } from '../../utils/Constant'

export const UserCompaniesAPIv2 = createApi({
	reducerPath: 'UserCompaniesAPIv2',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2)),
	endpoints: (builder) => ({
		getUserCompanyByManager: builder.query<any, string>({
			query: (id) => `${API_ENDPOINTS.get_user_company_by_manager}?managerId=${id}`,
		}),
	}),
})

export const EditCompanyAPI = createApi({
	reducerPath: 'EditCompanyAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2), 'application/json'),
	endpoints: (builder) => ({
		editUserCompanyDetails: builder.mutation({
			query: (arg: { params: any, userID: any }) => {
				const { params, userID } = arg
				return {
					url: `${API_ENDPOINTS.edit_user_company}?userId=${userID}`,
					method: 'POST',
					body: params
				}
			}
		}),
	}),
})

export const {
	useEditUserCompanyDetailsMutation
} = EditCompanyAPI

export const {
	useGetUserCompanyByManagerQuery
} = UserCompaniesAPIv2

