import styled from 'styled-components'

const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
`

export const BannerContainer = styled.div<{ image?: string }>`
	cursor: pointer;
	${commonTextStyle}
	width: 100%;
	height: 150px; /* Updated height */
	margin-bottom: 20px;
	background: url('${(props) => props.image}') no-repeat;
	background-size: 100% 100%; /* Updated background-size */

	@media (max-width: 1000px) {
		& {
			width: 100%;
			height: 90px; /* Updated height */
			margin-bottom: 20px;
			background: url('${(props) => props.image}') no-repeat;
			background-size: 100% 100%; /* Updated background-size */
			}
	}
`

export const BannerContainerMobile = styled.div<{ image?: string }>`
	cursor: pointer;
	${commonTextStyle}
	width: 100%;
	height: 90px;
	margin-bottom: 20px;
	background: url('${(props) => props.image}') no-repeat;
	background-size: 100% 100%;
`