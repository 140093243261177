import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { store } from './app/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'

import TagManager, { TagManagerArgs } from 'react-gtm-module'
import { RP_ENV } from './configs'
import { reverseObfuscateConstant } from './utils/Constant'

interface TagManagers {
	gtmId: string
}

const tagManagerArgs: TagManagers = {
	gtmId: reverseObfuscateConstant(RP_ENV.GTM_ID) as string
}

TagManager.initialize(tagManagerArgs as TagManagerArgs)

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>
	,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
