/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState, ReactElement } from 'react'
import Img from 'react-cloudimage-responsive'

export const imageConvertion = (imgSrc: string): string => {
	if(imgSrc){
		if (imgSrc.startsWith('https://robert-parker-content-prod.s3.amazonaws.com/')) {
			const regex = /https:\/\/robert-parker-content-prod\.s3\.amazonaws\.com(\/[^"\s]+)/
			const match = imgSrc.match(regex)
			if (match) {
				return 'https://cexuavomia.cloudimg.io/_rpimgcontent_' + match[1]
			}
		} else if(imgSrc.startsWith('https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/')) {
			const regex = /https:\/\/robert-parker-the-wine-advocate\.s3-us-west-2\.amazonaws\.com\/(.+)/
			const match = imgSrc.match(regex)
			if (match) {
				return 'https://cexuavomia.cloudimg.io/_rpimgWA_/' + match[1]
			}
		} else if(imgSrc.startsWith('https://s3-us-west-2.amazonaws.com/robert-parker-the-wine-advocate/')) {		
			const regex = /https:\/\/s3-us-west-2\.amazonaws\.com\/robert-parker-the-wine-advocate(.+)/
			const match = imgSrc.match(regex)
			if (match) {
				return 'https://cexuavomia.cloudimg.io/_rpimgWA_' + match[1]
			}
		} else if(imgSrc.startsWith('https://s3-us-west-2.amazonaws.com/robert-parker-the-wine-advocate-staging/')) {
			const regex = /https:\/\/s3-us-west-2\.amazonaws\.com\/robert-parker-the-wine-advocate-staging(.+)/
			const match = imgSrc.match(regex)
			if (match) {
				return 'https://cexuavomia.cloudimg.io/_rpimgSTAGING_' + match[1]
			}
		} else if(imgSrc.startsWith('https://robert-parker-the-wine-advocate.s3.amazonaws.com/')) {
			const regex = /https:\/\/robert-parker-the-wine-advocate\.s3\.amazonaws\.com(.+)/
			const match = imgSrc.match(regex)
			if (match) {
				return 'https://cexuavomia.cloudimg.io/_rpimgWA_' + match[1]
			}
		}
	}

	return imgSrc
}

export function CloudImageUtils(props: { imgSrc: string, alt: string, ratio: number, params: string, onClick?: () => void}): ReactElement {
	const { imgSrc, alt, ratio, params, onClick } = props

	const [imgURL, setImgURL] = useState<string>('')

	useEffect (() => {
		const convertedImage = imageConvertion(imgSrc)
		setImgURL(convertedImage)
	},[imgSrc])

	return <Img onClick={onClick} src={imgURL} alt={alt} doNotReplaceURL={true} lazyLoading={false} ratio={ratio} params={params}/>
}

