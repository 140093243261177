import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import CommercialSubscription from './CommercialSubscription'
import PersonalSubscription from './PersonalSubscription'

import { MenuOption, MenuOptionContainer } from './common'
import SelectionMenu from '../../Common/SelectionMenu'

function SubscriptionAgreement(): ReactElement {
	const [selectedPlan, setSelectedPlan] = useState('commercial')

	const handleMenuChange = (value: string) => {
		setSelectedPlan(value === selectedPlan ? 'personal' : 'commercial')
	}

	const selectMenuHandler = (event: ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target
		handleMenuChange(value)
	}

	const handleChangePlanFromUrl = () => {
		const urlParams = new URLSearchParams(window.location.search)
		const url:any = urlParams.get('selectedPlan')
		setSelectedPlan(url)
	}

	useEffect(() => {
		handleChangePlanFromUrl()
	}, [])

	return (
		<div className="single-entry">
			<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
				<MenuOptionContainer className="container">
					<MenuOption selected={(selectedPlan == 'personal') ? true : false} onClick={() => setSelectedPlan('personal')} >
							PERSONAL SUBSCRIPTION AGREEMENT
						<div className={'line'}></div>
					</MenuOption>
					<MenuOption selected={(selectedPlan == 'commercial') ? true : false} onClick={() => setSelectedPlan('commercial')} >
							COMMERCIAL SUBSCRIPTION AGREEMENT
						<div className={'line'}></div>
					</MenuOption>
				</MenuOptionContainer>
				<div className='container'>
					<SelectionMenu title='SUBSCRIPTION AGREEMENT' onSelectionMenu={selectMenuHandler} defaultValue={selectedPlan}>
						<option value='personal'>PERSONAL SUBSCRIPTION AGREEMENT</option>
						<option value='commercial'>COMMERCIAL SUBSCRIPTION AGREEMENT</option>
					</SelectionMenu>
				</div>
				
				<div className="container" style={{ paddingLeft: '15px', paddingRight: '15px' }}>
					{selectedPlan === 'personal' ? <PersonalSubscription /> : <CommercialSubscription />}
				</div>
			</div>
		</div>
	)
}

export default SubscriptionAgreement