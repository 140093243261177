import styled from 'styled-components'

export const Container = styled.div`
    margin: 0 4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .info-container{
        display: flex;
        justify-content: start;
        flex-direction: column;

        & > h1{
            font-weight: 600;
            font-size: 35px;
            margin-bottom: 2rem;
        }
        
        & > p {
            margin-bottom: 0;
            font-size: 20px;
            font-weight: 500;
            max-width: 750px
        }
        
        & > a {
            font-size: 20px;
            margin: 0.5rem 0;
        }
    }
`