/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS, RP_ENV } from '../../configs'
import { GetBaseQuery } from '../../utils'

import { sprintf } from 'sprintf-js'
import { MAX_RETRIES } from './constant'
import { reverseObfuscateConstant } from '../../utils/Constant'

const URL_V2 = reverseObfuscateConstant(RP_ENV.API_URL_V2)

export const TastingNotesV2API = createApi({
	reducerPath: 'TastingNotesV2API',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, URL_V2),
	endpoints: (builder) => ({
		getTastingNotesFromWine: builder.query<any, string>({
			query: (wineID: string) =>
				sprintf(API_ENDPOINTS.get_tasting_notes_from_wine_v2, wineID),
		}),
	}),
})


export const { useGetTastingNotesFromWineQuery: useGetTastingNotesFromWineV2Query } = TastingNotesV2API
