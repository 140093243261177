import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'

import styled from 'styled-components'
import { validateURL } from '../../../../utils/ValidateUrl'
import { sanitizeUrl } from '@braintree/sanitize-url'

const indendtedListItem = `
& > li {
	margin-left: 30px;
}
`
// const IncidentListContainer = styled.div`
// 	padding-top: 30px;
// `

const IndentedList = styled.ul<{ noBullet: boolean }>`
	${props => props.noBullet ? 'list-style-type: none;' : ''}
	& > li {
		margin-left: 30px;
	}

	${indendtedListItem}
`

const IndentedOrderedList = styled.ol`
	${indendtedListItem}
`
function addMargin() {
	window.scrollTo(0, window.pageYOffset - 150)
}

window.addEventListener('hashchange', addMargin)

const PrivacyPolicy: React.FC = () => {

	const privacyDisclosure = window.location.hash.substring(1)
	
	useEffect(() => {
		if(privacyDisclosure !== 'exerciseyourprivacyrights'){
			return
		}

		const targetElement = document.getElementById(`${privacyDisclosure}`)

		if (targetElement) {
			const navbarHeight = 10
			const targetOffset = targetElement.offsetTop - navbarHeight
		
			window.scrollTo({
				top: targetOffset,
				behavior: 'smooth',
			})
		}

	},[privacyDisclosure])

	const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, id: string) => {
		event.preventDefault()
		const targetElement = document.getElementById(`${id}`)

		if (targetElement) {
			const navbarHeight = 100
			const targetOffset = targetElement.offsetTop - navbarHeight
		
			window.scrollTo({
				top: targetOffset,
				behavior: 'smooth',
			})
		}
	}

	const tableStyle = {
		border: '1px solid black',
	}

	const rowStyle = {
		border: '1px solid black',
	}

	const cellStyle = {
		border: '1px solid black',
		padding: '8px',
	}

	return (
		<div className='single-entry'>
			<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
				<div className="container">
					<div role="main">
						<h2 id="title" style={{ textAlign: 'center' }}><strong>Robert Parker Wine Advocate Privacy Notice</strong></h2>
						<br/>
						<p><strong>Last modified:</strong> December 2023</p>
						<p>
						This Privacy Notice explains how we collect, use, disclose and other process your personal data in connection with our Services. Please read the following Privacy Notice carefully to understand our practices regarding your personal data.
						</p>
						<p>
							<strong><i>Region-Specific Privacy Disclosures</i></strong>
						</p>
						<p>
						Depending on your country, region, or state of residence, certain rights may apply to you. Please refer below for disclosures that may be applicable to you:
						</p>
						<IndentedList noBullet={false}>
							<li>
								<p>
							If you are a resident of the State of California, Colorado, Connecticut, Nevada, Utah or Virginia in the United States, please click <a href="privacy-policy/#privacydisclosures" onClick={(e) => handleClick(e, 'privacydisclosures')}>here</a> for additional U.S. state-specific privacy disclosures.
								</p>
							</li>
							<li>
								<p>
								If you are located in the European Union (“EU”), Switzerland, or the United Kingdom (“UK”), please click <a href="privacy-policy/#privacy10" onClick={(e) => handleClick(e, 'privacy10')}>here</a> for the rights that may be applicable to you.
								</p>
							</li>
						</IndentedList>
						<IndentedOrderedList>
							<li><a href="privacy-policy/#privacy1" onClick={(e) => handleClick(e, 'privacy1')}><u>{'What is Personal Data?'}</u></a></li>
							<li><a href="privacy-policy/#privacy2" onClick={(e) => handleClick(e, 'privacy2')}><u>{'Who We Are and Our Services'}</u></a></li>
							<li><a href="privacy-policy/#privacy3" onClick={(e) => handleClick(e, 'privacy3')}><u>{'Our Websites Covered by This Privacy Notice'}</u></a></li>
							<li><a href="privacy-policy/#privacy4" onClick={(e) => handleClick(e, 'privacy4')}><u>{'How We Collect and Use Personal Data'}</u></a></li>
							<li><a href="privacy-policy/#privacy5" onClick={(e) => handleClick(e, 'privacy5')}><u>{'Where We Store Your Personal Data'}</u></a></li>
							<li><a href="privacy-policy/#privacy6" onClick={(e) => handleClick(e, 'privacy6')}><u>{'Our Disclosure of personal data'}</u></a></li>
							<li><a href="privacy-policy/#privacy7" onClick={(e) => handleClick(e, 'privacy7')}><u>{'Your Choices'}</u></a></li>
							<li><a href="privacy-policy/#privacy8" onClick={(e) => handleClick(e, 'privacy8')}><u>{'International Transfers of Personal Data'}</u></a></li>
							<li><a href="privacy-policy/#privacy9" onClick={(e) => handleClick(e, 'privacy9')}><u>{'Data Retention'}</u></a></li>
							<li><a href="privacy-policy/#privacy10" onClick={(e) => handleClick(e, 'privacy10')}><u>{'Your Rights Under EU, Switzerland and UK Data Protection Legislation'}</u></a></li>
							<li><a href="privacy-policy/#privacy11" onClick={(e) => handleClick(e, 'privacy11')}><u>{'About Data Security'}</u></a></li>
							<li><a href="privacy-policy/#privacy12" onClick={(e) => handleClick(e, 'privacy12')}><u>{'Children’s Data'}</u></a></li>
							<li><a href="privacy-policy/#privacy13" onClick={(e) => handleClick(e, 'privacy13')}><u>{'Links to Third-Party Websites and Services'}</u></a></li>
							<li><a href="privacy-policy/#privacy14" onClick={(e) => handleClick(e, 'privacy14')}><u>{'Changes to our Privacy Notice'}</u></a></li>
							<li><a href="privacy-policy/#privacy15" onClick={(e) => handleClick(e, 'privacy15')}><u>{'Contact Us'}</u></a></li>
						</IndentedOrderedList>
						<br />
						<hr className="solid-plank" />
						<br />

						<h2 id="privacy1">1. What is Personal Data?</h2>
						<p>
						When we use the term “<strong>personal data</strong>” in this Privacy Notice, we mean information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, to a person or household.  It does not include aggregated or de-identified information that is maintained in a form that is not reasonably capable of being associated with or linked to a person.
						</p>
						<br />
						<h2 id="privacy2">2. Who We Are and Our Services</h2>
						<p>
						Our full company name is Wine Advocate Pte Ltd d/b/a Robert Parker Wine Advocate (“<strong>RPWA</strong>”, “<strong>we,</strong>” “<strong>us,</strong>” or “<strong>our</strong>”). This Privacy Notice explains how RPWA collects, uses, shares, and otherwise processes personal data in connection with providing our products and services, as well as our online services, events, electronic mail and related content, platform, and our websites or other websites owned, operated or controlled by us, including the websites listed in the “Our Websites Covered by This Privacy Notice” section below (collectively, the “<strong>Services</strong>”). This Privacy Notice does not address our privacy practices relating to RPWA job applicants, employees, and other personnel. Please note this Privacy Notice is not a contract and does not create any legal rights or obligations. We are the Data Controller of the personal data in connection with this Privacy Notice.
						</p>
						<br />
						<h2 id="privacy3">3. Our Websites Covered by This Privacy Notice</h2>
						<p>
						The information set out in this Privacy Notice is applicable to the following websites:
						</p>
						<IndentedList noBullet={false}>
							<li>
							www.robertparker.com
								<IndentedList noBullet={false}>
									<li>
									website available&nbsp;<Link to={'/'}><u>here</u></Link>&nbsp;and
									through the local extensions accessible from the home
									page
									</li>
									<li>
									mobile application available on market mobile
									applications stores
									</li>
								</IndentedList>
							</li>
						</IndentedList>
						<br />
						<h2 id="privacy4">4. How We Collect and Use Personal Data</h2>
						<p>
							<strong><i>Personal Data Collected from Individuals</i></strong>
						</p>
						<p>
						The categories of personal data we collect that are submitted to us by individuals through the Services may include:
						</p>
						<p>
							<u>Marketing Data</u>
						</p>
						<p>
						If you request further information from us or our partners by signing up to receive any newsletters or marketing communications (“<strong>Marketing Data</strong>”).
						</p>
						<br />
						<IndentedList noBullet={false}>
							<li>
								<p>
								Purpose/Activity:  Marketing Data is processed for the purposes of sending you the relevant notifications and/or newsletters and/or any marketing communication.
								</p>
							</li>
							<li>
								<p>
								Type of Data:  We will ask you for your email address and your country location so we can send you marketing communications.
								</p>
							</li>
							<li>
								<p>
								Lawful basis for processing: Where required by applicable law, the legal basis for this processing is your consent.
								</p>
							</li>
							<li>
								<p>
							Retention Period:  RPWA will retain this data until you request for your data to be deleted from our database, in accordance with our retention policies, or as otherwise required by applicable law.
								</p>
							</li>
						</IndentedList>
						<br />
						<p>
							<u>Review Data</u>
						</p>
						<p>
						Information you provide to us when you complete a survey or respond to a questionnaire or submit a review or feedback of a product featured on our websites(“<strong>Review Data</strong>”).
						</p>
						<br />
						<IndentedList noBullet={false}>
							<li>
								<p>
								Purpose/Activity:  To collect information about a specific matter, for example, our services to you or reviews of a product you have tried.
								</p>
							</li>
							<IndentedList noBullet={true}>
								<li>
								In many cases, surveys and questionnaires are anonymous or we only collect personal data for statistical purposes.  If this is not the case, we will let you know at the time of collecting the information.
								</li>
								<br/>
							</IndentedList>
							<li>
								<p>
								Type of Data:  Information may include your full name, your email address and other personal data requested or provided by you.
								</p>
							</li>
							<li>
								<p>
								Lawful basis for processing: Where required by applicable law, we will use our legitimate interests in the monitoring and improvement of our products and services to our customers and users as the legal basis for processing Review Data.
								</p>
							</li>
							<li>
								<p>
								Retention Period:  RPWA will retain data of your reviews until you request for your data to be deleted from our database or as otherwise required by applicable law.
								</p>
							</li>
							<IndentedList noBullet={true}>
								<li>
								Any information collected pursuant to a survey or questionnaire will be deleted within 3 years of you submitting your responses.  Any information retained beyond that period will be aggregated, statistical data only.
								</li>
								<br/>
							</IndentedList>
						</IndentedList>
						<br />
						<p>
							<u>Communication Data</u>
						</p>
						<p>
					Information you provide us when you communicate with us, e.g. by email or contact us form via our website(“<strong>Communication Data</strong>”).
						</p>
						<br />
						<IndentedList noBullet={false}>
							<li>
								<p>
								Purpose/Activity:  This information is used to enable us to respond to or follow up on your comments, queries or questions.
								</p>
							</li>
							<li>
								<p>
								Type of Data:  Information may include your full name, your email address and other personal data you provide us to enable us to deal with your communication.
								</p>
							</li>
							<li>
								<p>
								Lawful basis for processing: Where required by applicable law, we will use our legitimate interests in assisting you and answering your communications as the legal basis for processing Communication Data.
								</p>
							</li>
							<li>
								<p>
								Retention Period:  We will hold on to Communication Data for as long as it is necessary to enable us to deal with the matter relating to that communication, in accordance with our retention policies, or as otherwise required by applicable law.
								</p>
							</li>
						</IndentedList>
						<br/>
						<p>
							<u>Booking Data</u>
						</p>
						<p>
					Information you give to us when you book tickets for our events (“<strong>Booking Data</strong>”).
						</p>
						<br />
						<IndentedList noBullet={false}>
							<li>
								<p>
								Purpose/Activity:  This information is used to enable us to provide you with ticket booking services and complete your ticket purchase for our events.
								</p>
							</li>
							<IndentedList noBullet={true}>
								<li>
								We will also use your Booking Data to enhance your website experience and to provide you with all of our products and services at our events.
								</li>
								<br/>
							</IndentedList>
							<li>
								<p>
								Type of Data:  We will ask you for your name, surname, e-mail address, phone number and any special requests you have in relation to your booking, as well as any other registration details or information you provide to us.
								</p>
							</li>
							<li>
								<p>
								Lawful basis for processing: Where required by applicable law, the legal basis for this processing is to fulfil the agreement between you and us in relation to your booking.
								</p>
							</li>
							<li>
								<p>
								Retention Period:  RPWA will retain this data until you request for your data to be deleted from our database, in accordance with our retention policies, or as otherwise required by applicable law.
								</p>
							</li>
						</IndentedList>
						<br/>
						<p>
							<u>Voluntary Information</u>
						</p>
						<p>
						When you provide us information (for example, the information set out in the scenarios above), in some cases we may ask you for additional, voluntary information.  This information will be identified as optional and is used to help us better understand our customers and tailor our services to them. In addition, if you provide us with other types of information that you provide to us online, by phone or in person, we will use this information to respond to your request, provide you the requested services, and inform our marketing and advertising campaigns. We ask that you do not send us unsolicited proprietary or other confidential information through our Services.
						</p>
						<p>
						If you sign up for one of our Services requiring payment, we collect the information provided in connection with such payment. Please note that we use third-party payment processors to process credit card payments made to us. As such, we do not receive or retain any personally identifiable financial information in connection with credit card payments, such as credit card numbers. Rather, all such information is provided directly by you to our third-party processor. The payment processor’s use of your personal data is governed by their privacy notice. 
						</p>

						<p>
							<i><strong>Personal Data Automatically Collected</strong></i>
						</p>
						<p>
						We and our third-party providers also collect certain personal data automatically when you visit or interact with our Services: 
						</p>

						<br/>
						<p>
							<u>Location Data</u>
						</p>
						<p>
						We may collect your location data (“<strong>Location Data</strong>”).
						</p>
						<br />
						<IndentedList noBullet={false}>
							<li>
								<p>
								Purpose/Activity:  This data is used by us to provide our location-based services (such as research of nearby restaurants).
								</p>
							</li>
							<li>
								<p>
								Type of Data:  General geographic location based on Usage and Device Data (such as IP Address) or more precise location information from the GPS functionality of your mobile device where permitted by law.
								</p>
							</li>
							<li>
								<p>
								Lawful basis for processing: Where required by applicable law, the legal basis for this processing is your consent.
								</p>
							</li>
							<li>
								<p>
								Retention Period: RPWA will retain this data until you request for your data to be deleted from our database, in accordance with our retention policies, or as otherwise required by applicable law.
								</p>
							</li>
						</IndentedList>

						<br/>
						<p>
							<u>Usage and Device Data</u>
						</p>
						<p>
						We may collect information from our web servers’ logs (“<strong>Usage and Device Data</strong>”).
						</p>
						<br />
						<IndentedList noBullet={false}>
							<li>
								<p>
								Purpose/Activity:  This information is used by us for system administration and to analyze how people are using our websites and digital platforms.  It also helps us to provide the best online infrastructure for your online activities.
								</p>
							</li>
							<li>
								<p>
							Type of Data:  Information may include your connexon log, IP address, operating system and browser type, language preferences.
								</p>
							</li>
							<IndentedList noBullet={true}>
								<li>
									<p>
									We may also collect information on the searches you carry out on our website and your interaction with communications we send you, for example, when you open email communications.
									</p>
								</li>	
							</IndentedList>
							<li>
								<p>
								Lawful basis for processing: Where required by applicable law, the legal basis for this processing is our legitimate interests, namely monitoring, improving our website, products and services and growing our business.
								</p>
							</li>
							<li>
								<p>
								Retention Period: RPWA will retain this data for 3 years or until you request for your data to be deleted from our database, in accordance with our retention policies, or as otherwise required by applicable law.
								</p>
							</li>
						</IndentedList>

						<br/>
						<p>
							<u>Activity and Behavioral Data</u>
						</p>
						<p>
						We and our business partners collect information using cookies or other similar technologies stored on your device (“<strong>Behavioral Data</strong>”).
						</p>
						<br />
						<IndentedList noBullet={false}>
							<li>
								<p>
								Purpose/Activity:  This information is used to analyze how you use our website, digital products and services.  They help us to improve our websites and deliver a better and more personalized service to you.
								</p>
							</li>
							<IndentedList noBullet={true}>
								<li>
									<p>
									We may match your Behavioral Data with your Marketing Data to send you personalized notifications and/or newsletters.
									</p>
								</li>	
							</IndentedList>
							<li>
								<p>
								Type of Data:  Cookies contain information that is transferred to your computer’s hard drive.  Information may include your display and search preferences, your search history, your location, the way you are surfing on (using our websites, products or newsletters such as mouse move, display duration and clicks) and your interests.
								</p>
							</li>
							<li>
								<p>
								Lawful basis for processing:  The legal basis for this processing is your consent or, where relevant, our legitimate interests, namely monitoring and improving our website, products and services.
								</p>
							</li>
							<li>
								<p>
								Retention Period:  RPWA will keep this Data until you withdraw your consent or request that the data be deleted.
								</p>
							</li>
						</IndentedList>

						<p>
						For more information on our use of automated data collection technologies, please see our <a href='/cookie-notice'>Cookie Notice</a>. For information about our and our third-party partners’ use of cookies and related technologies to collect information automatically, and the choices you have in relation to its collection, please visit the <strong>Third Party Data Collection and Online Advertising</strong> section below.
						</p>

						<p><i><strong>Personal Data from Third Parties</strong></i></p>

						<p>
						We also obtain personal data from third parties which we often combine with personal data we collect automatically or directly from an individual.
						</p>

						<p>
							<u>
							Affiliate Data
							</u>
						</p>
						<p>
						We may receive personal data from other companies and brands owned or controlled by RPWA, and other companies owned by or under common ownership with RPWA.
						</p>

						<p>
							<u>
							Employer / Company Data
							</u>
						</p>
						<p>
						If you engage in our Services through your employer or company, we may receive information from the company such as your name and contact information.
						</p>
						<p>
							<u>
							Data from Other Users or Individuals who Interact with our Services
							</u>
						</p>

						<p>
						We may receive your information from other users or other individuals who interact with our Services.
						</p>

						<p>
							<u>
							Service Provider Data
							</u>
						</p>

						<p>
						Our service providers that perform services solely on our behalf, such as our marketing and analytics providers, collect personal data and often share some or all of this information with us. The information may include contact information, demographic information, payment information, and information about your communications and related activities. We may use this information to administer and facilitate our services and our marketing activities.
						</p>

						<p><u>Purchase Data</u></p>
						<p>We and our business partners collect information about your in-store purchases (“<strong>Purchase Data</strong>”)</p>
						<IndentedList noBullet={false}>
							<li>
								<p>
								Purpose/Activity:  This information is used to fulfil your instore orders.  We may match your Purchase Data to send you personalized notifications or newsletters.
								</p>
							</li>
							<li>
								<p>
								Type of Data:  Information may include your address, your order, and payment details.
								</p>
							</li>
							<li>
								<p>
								Lawful basis for processing: Where required by applicable law, the legal basis for this processing is your consent or, where relevant, our legitimate interests, namely monitoring and improving our website, products and services.
								</p>
							</li>
							<li>
								<p>
								Retention Period:  This data is stored in accordance with our supplier’s retention policy.
								</p>
							</li>
						</IndentedList>

						<p>
							<u>
							Social Media Data
							</u>
						</p>
						<p>
						When an individual interacts with our Services through various social media networks, such as when someone “Likes” us on Facebook or follows us or shares our content on Google, Facebook, LinkedIn, or other social networks, we may receive some information about individuals that they permit the social network to share with third parties. The data we receive is dependent upon an individual’s privacy settings with the social network, and may include your profile information, profile picture, gender, username, user ID associated with your social media account, age range, language, country, and any other information you permit the social network to share with third parties. Individuals should always review and, if necessary, adjust their privacy settings on third-party websites and social media networks and services before sharing information and/or linking or connecting them to other services. We use this information to operate, maintain, and provide to you the features and functionality of the Services, as well as to communicate directly with you, such as to send you email messages about products and services that may be of interest to you. 
						</p>

						<i><strong>Statistical Information</strong></i>
						<p>
						We also collect and use aggregated data such as statistical or demographic data (“<strong>Aggregated Data</strong>”).  Aggregated Data may be derived from your personal data but does not reveal your identity in any way.  For example, we may aggregate your Usage and Device Data to calculate the percentage of our website users accessing a specific feature of our website.
						</p>
						<br/>

						<p>
							<i><strong>Other Uses of Personal Data</strong></i>
						</p>
						<p>
						In addition to the above, we may use personal data in the following ways or for the following purposes:
						</p>
						<IndentedList noBullet={false}>
							<li>For marketing purposes;</li>
							<li>Review and respond to inquiries;</li>
							<li>Verify your identity;</li>
							<li>Operate, evaluate, and improve our business and product and service offerings;</li>
							<li>Administer and analyze our websites and effectiveness;</li>
							<li>Perform security logging and analysis;</li>
							<li>Handle and communicate orders, billing and payment, delivery of products and services;</li>
							<li>Comply with all legal and regulatory requirements;</li>
							<li>Process orders, request for further information, maintain records and to provide pre- and after-sales service;</li>
							<li>Pass to another organization to supply/deliver products or services you have purchased;</li>
							<li>Pass onto our partners in order to follow-up on any webinars and events to which you have registered or attended;</li>
							<li>Perform obligations arising from any contracts entered into by you and us;</li>
							<li>Perform security checks of information you provided to protect us from credit risk and both you and us from fraudulent transactions;</li>
							<li>Assist third parties to perform certain activities, such as processing and sorting data, monitoring how customers use our site and issuing our emails for us;</li>
							<li>Seek your views or comments on the services we provide;</li>
							<li>Notify you of changes to our Services;</li>
							<li>Send you communications which you have requested and that may be of interest to you, including information about product updates, newsletters, events, webinars;</li>
							<li>Perform security logging and analysis;</li>
							<li>Inform you of various promotions, goods and services that may be of interest to you, either by post, email, telephone, or such other means with marketing communications we deem relevant to you;</li>
							<li>To protect against and prevent fraud claims and other liabilities and to comply with or enforce applicable legal requirements, industry standards, and our policies and terms;</li>
							<li>To protect, exercise or defend our legal rights, or when we are required to do so by law that applies to us;</li>
							<li>To help maintain the safety, security and integrity of our property and Services, technology assets and business;</li>
							<li>Facilitate corporate business transactions, such as a merger, acquisition, joint venture, or financing or sale of company assets, or in the event of insolvency, bankruptcy or receivership;</li>
							<li>For any other purpose we may describe when you provide the information.</li>
						</IndentedList>	
						<br/>				
						<h2 id="privacy5">5. Where We Store Your Personal Data</h2>
						<p>RPWA is an international group with databases in the various countries in which it operates.  RPWA may transfer your personal data within the group to one of its databases or to its external partners located outside of your home country.</p>
						<br />

						<h2 id="privacy6">6. Our Disclosure of Personal Data</h2>
						<p>
						We may disclose your personal data in the following ways:
						</p>
						<IndentedList noBullet={false}>
							<li>
								<p>
									<strong>Within Our Family of Companies</strong> - We may disclose your personal data to any member of our family of companies, which means our subsidiaries, our ultimate holding company, Michelin, and its subsidiaries and related entities to the extent necessary for the same purposes of the initial processing set out in this Privacy Notice.
								</p>
							</li>
							<li>
								<p>
									<strong>Service Providers</strong> - We may share your personal data with subcontractors that we may use to process your request or provide the Services.  These subcontractors act as data processors on our behalf and provide use services, such as IT and system administration, hosting and email and SMS services.
								</p>
							</li>
							<li>
								<p>
									<strong>Business Transaction or Reorganization</strong> - We may take part in or be involved with a corporate business transaction, such as a merger, acquisition, joint venture, or financing or sale of company assets.  We may disclose personal data to a third-party during negotiation of, in connection with or as an asset in such a corporate business transaction.  Personal data may also be disclosed in the event of insolvency, bankruptcy, or receivership, in compliance with applicable law.
								</p>
							</li>
							<li>
								<p>
									<strong>For Legal Reasons</strong> - If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms and conditions and other agreements; or to protect the rights, property, or safety of us, our customers, or others. We also may disclose personal data to third parties to detect, suppress, or prevent fraud, or as otherwise required by applicable law.
								</p>
							</li>
							<li>
								<p>
									<strong>On the Basis of Your Consent or Direction</strong> - We may disclose personal data about you to certain other third parties with your consent or at your direction.  For example, if you engage with the social plugins (or “buttons”) available on our Services, these buttons may automatically share personal data with the social network that corresponds to the button you selected (such as Facebook, LinkedIn or YouTube). Please see our <a href='/cookie-notice'>Cookie Notice</a> for more information.
								</p>
							</li>
						</IndentedList>
						<IndentedList noBullet={true}>
							<li>
								<p>
								If you consented to receive offers from our partners, we can also transfer your Marketing Data to third parties, in order to send you newsletters and marketing communications as mentioned above
								</p>
							</li>
						</IndentedList>
						<br />

						<h2 id="privacy7">7. Your Choices</h2>
						<p>
							<strong>Email Communications</strong>. From time to time, we may send you emails regarding updates to our Services, notices about our organization, or information about products/services we offer (or promotional offers from third parties) that we think may be of interest to you, including emails on behalf of one of our clients.  
						</p>
						<p>
						If you wish to unsubscribe from such emails, simply click the “unsubscribe link” provided at the bottom of the email communication.  Note that you cannot unsubscribe from certain services-related email communications (e.g., account verification, confirmations of transactions, technical or legal notices).
						</p>

						<p>
							<strong>Modifying and Deleting your Information:</strong> If you have an account with us, you may update your account information and adjust your account settings by logging into your account. Please note that changes to your settings may require some time to take effect.  
						</p>

						<p>
							<strong>Access to Device Information.</strong> You may control the Services’ access to your device information through your “Settings” app on your device.  For instance, you can withdraw permission for the Services to access your network devices and geolocation (if applicable).
						</p>

						<p>
							<strong>Cookies.</strong> You may configure your cookie preferences by managing your settings in our Cookie Management Solution. In addition, most browsers will also allow you to adjust your browser settings to:  (i) notify you when you receive a cookie, which lets you choose whether or not to accept it; (ii) disable existing cookies; or (iii) set your browser to automatically reject cookies. You may also set your email options to prevent the automatic downloading of images that may contain technologies that would allow us to know whether you viewed or engaged with our emails. Please note that blocking or deleting cookies may negatively impact your experience using the Services, as some features and functions on our Services may not work properly.  Depending on your device and operating system, you may not be able to delete or block all cookies or tracking technologies.  In addition, if you want to reject cookies across all your browsers and devices, you will need to do so on each browser on each device you actively use. Please see our <a href='/cookie-notice'>Cookie Notice</a> for more information. 
						</p>
					
						<br />
						<h2 id="privacy8">8. International Transfers of Personal Data</h2>
						<p>
						For transfers within our family of companies (the Michelin Group), the company has adopted internal rules governing the transfer of personal data from the European Union or European Economic Area.  By clicking on <strong>BCRs</strong>, you can find the details of these rules.
						</p>
						<p>
						Transfers of data outside the group are governed either by the BCR of the subcontractor or by contracts containing the clauses of the European Commission for suppliers established outside the EU designed to guarantee a similar level of protection as that of your home country.
						</p>
					
						<br />
						<h2 id="privacy9">9. Data Retention</h2>

						<p>
						As a rule, we will not hold your personal data for any purpose or purposes for longer than is necessary to fulfil the purposes we collect it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. For more specific retention periods, please see above.
						</p>

						<p>
						To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, as well as our legal, regulatory, tax, accounting and other applicable obligations.
						</p>

						<p>
						Therefore, we retain personal data for as long as the individual continues to use our services for the purposes explained in the <strong><a onClick={(e) => handleClick(e, 'privacy4')}>How We Collect and Use Personal Data</a></strong> section in our Privacy Notice. When an individual discontinues the use of our services, we will retain their personal data for as long as necessary to comply with our legal obligations, to resolve disputes and defend claims, as well as, for any additional purpose based on the choices they have made, such as to receive marketing communications. In particular, we will retain call recordings, the personal data supplied when joining our services, including complaints, claims and any other personal data supplied during the duration of an individual’s contract with us for the services until the statutory limitation periods have expired, when this is necessary for the establishment, exercise or defense of legal claims.
						</p>

						<p>
						Once retention of the personal data is no longer necessary for the purposes outlined above, we will either delete or deidentify the personal data or, if this is not possible (for example, because personal data has been stored in backup archives), then we will securely store the personal data and isolate it from further processing until deletion or deidentification is possible.
						</p>

						<p>
						In some circumstances you can ask us to delete your data:; see Section 10 below and the U.S. Privacy Disclosures for more information.
						</p>
				
						<br />
						<h2 id="privacy10">10. Your Rights Under EU, Switzerland and UK Data Protection Legislation</h2>
						<p>
						This section applies to individuals located in the EU, Switzerland and the UK. Data protection legislation provides you with certain rights when it comes to the processing of your information.  These are the right to:
						</p>
					
						<IndentedList noBullet={false}>
							<li>
								<p>
								The right to be informed
								</p>
							</li>
							<li>
								<p>
								The right of access your information
								</p>
							</li>
							<li>
								<p>
								The right to correct your information
								</p>
							</li>
							<li>
								<p>
							T	he right to delete your information
								</p>
							</li>
							<li>
								<p>
								The right to restrict processing
								</p>
							</li>
							<li>
								<p>
								The right to data portability (transfer your information)
								</p>
							</li>
							<li>
								<p>
								The right to object to processing
								</p>
							</li>
						</IndentedList>	
						<p>For further information on your rights <strong>The right to be informed</strong></p>

						<p>You have the right to be informed about how we collect and process your personal data, including who we are, how we use your personal data and your rights in relation to your personal data.  We have gathered all the information you need in this privacy notice.</p>

						<p><strong>The right of access your information</strong></p>
						<p>You have the right to access the personal data we have on you.  We can confirm whether your information is being processed and provide you with access to your personal data.</p>


						<p><strong>The right to correct your information</strong></p>
						<p>If your personal data is inaccurate or incomplete, you are entitled to have that information corrected and updated.  If we have disclosed your information to any third parties, where possible, we will inform them of any updates you provide to us.  You can update the personal data we hold about you by contacting us using any of the contact methods details in this Privacy Notice.</p>

						<p><strong>The right to delete your information</strong></p>
						<p>You have the right to request the deletion or removal of your personal data where there is no reason for us to continue to process it.  The circumstances in which you can request the right to be forgotten includes, where the information is no longer necessary in relation to the purpose for which it was originally collected or processed and where you have withdrawn your consent to processing.</p>
						<p>There are some limited circumstances where the right to be forgotten does not apply – if you want to know, just ask.</p>

						<p><strong>The right to restrict processing</strong></p>
						<p>You also have the right to ‘block’ or suppress the processing of your personal data.  If you request us to suppress your personal data, we will stop any further processing of it.</p>

						<p><strong>The right to data portability (transfer your information)</strong></p>
						<p>If you want to move or transfer your personal data to a different service provider or copy your information for your own purposes, you have the right to have your information transferred to another person.</p>

						<p><strong>The right to object</strong></p>
						<p>You have the right to ask us not to process your personal data for marketing purposes.  We will usually inform you (before collecting your data) if we intend to use your information for such purposes or if we intend to disclose your information to any third party for such purposes.  You can exercise your right to prevent such processing by checking certain boxes on the forms we use to collect your information.</p>

						<p>You can also exercise the right at any time by contacting us at <a href={sanitizeUrl('mailto:privacy@robertparker.com')}>privacy@robertparker.com</a></p>

						<p><strong>For further information on your rights</strong></p>

						<p>
						You can find further information on your data protection rights from your local supervisory authority website.
						</p>

						<p><strong>What can you do if you’re not happy</strong></p>

						<p>
						We are committed to protecting and respecting your privacy.  However, if you’re not happy with how we collect or process your information or just want some further information on your rights, you can contact us in the following ways:
						</p>

						<IndentedList noBullet={false}>
							<li>
								<p>email us at <a href={sanitizeUrl('mailto:privacy@robertparker.com')}>privacy@robertparker.com</a></p>
							</li>
							<li>
								<p>You can send us a letter addressed to the Privacy Department at 78 Shenton Way #23-02 Singapore 079120</p>
							</li>
						</IndentedList>

						<p>
						You can also contact us using the contact form on the bottom right of the page. We will work with you to resolve any issues you may have and aim to answer any question raised by you as soon as possible.
						</p>

						<p>
						You can also make a complaint to ICO (further information can be found here) or your local supervisory authority website.
						</p>

						<br />
						<h2 id="privacy11">11. About Data Security</h2>
						<p>
						RPWA has implemented commercially reasonable measures designed to protect the privacy, security and integrity of your personal data. 
						</p>


						<br />
						<h2 id="privacy12">12. Children’s Data</h2>
						<p>
						We do not knowingly solicit information from children, and we do not knowingly market the site or our Services to children.  No one age 16 or under should submit any personal data to us and/or the sites.  If we become aware that a person submitting personal data to us through any part of the sites is a child, the information will be deleted promptly.  If a parent discovers that we have inadvertently retained information on his or her child before we have discovered and deleted it, then we will honor any requests that the information be deleted.
						</p>
				

						<br />
						<h2 id="privacy13">13. Links to Third-Party Websites and Services</h2>
						<p>
						For your convenience, our Services may provide links to third-party websites or services that we do not own or operate. We are not responsible for the practices employed by any websites or services linked to or from the services, including the information or content contained within them. Your browsing and interaction on any other website or service are subject to the applicable third party’s rules and policies, not ours. If you are using a third-party website or service, you do so at your own risk. We encourage you to review the privacy policies of any site or service before providing any personal data.
						</p>
					
						<br/>
						<h2 id="privacy14">14. Changes to Our Privacy Notice</h2>
						<p>
						We may change our Privacy Notice from time to time.  When we make changes to this Privacy Notice, we will change the date at the beginning of this Privacy Notice. If we make material changes to this Privacy Notice, we will notify individuals by email to their registered email address, by prominent posting on our Services, or through other appropriate communication channels. All changes shall be effective from the date of publication unless otherwise provided.
						</p>
						<br />
						<h2 id="privacy15">15. Contact Us</h2>
						<p>
						If you have any questions or comments about this Privacy Notice or any issue relating to how we collect, use, or disclose personal data, or if you would like us to update information, we have about you or your preferences, you may:
						</p>
						<IndentedList noBullet={false}>
							<li>
								<p>email us at <a href={sanitizeUrl('mailto:privacy@robertparker.com')}>privacy@robertparker.com</a></p>
							</li>
							<li>
								<p>You can send us a letter addressed to the Privacy Department at 78 Shenton Way, #23-02 Singapore 079120</p>
							</li>
						</IndentedList>

						<br/>

						<h2 id="privacydisclosures" style={{ textAlign: 'center' }}>ADDITIONAL U.S. STATE PRIVACY DISCLOSURES</h2>
						<p><strong>Last Update:</strong> December 2023</p>

						<p>
							<strong>For residents of the States of California, Colorado, Connecticut, Nevada, Utah and Virginia:</strong> These Additional U.S. State Privacy Disclosures (“<strong>U.S. Privacy Disclosures</strong>”) supplement the information contained in our Privacy Notice by providing additional information about our personal data processing practices relating to individual residents of these States. For a detailed description of how we collect, use, disclose, and otherwise process personal data in connection with our Services, please visit our Privacy Notice. Unless otherwise expressly stated, all terms defined in our Privacy Notice retain the same meaning in these U.S. Privacy Disclosures.
						</p>
						<p><strong>Personal Data Disclosures</strong></p>
						<p>
						We collect various categories and types of personal data from a variety of sources and for a variety of purposes. We also disclose personal data to a number of recipients. Please review the <strong><a onClick={(e) => handleClick(e, 'privacy4')}>How We Collect and Use Personal Data</a></strong> and <strong><a onClick={(e) => handleClick(e, 'privacy6')}>Our Disclosure of Personal Data</a></strong> sections of our Privacy Notice to learn more. We do not “sell” personal data or share or otherwise process personal data for the purpose of displaying advertisements that are selected based on personal data obtained or inferred over time from an individual’s activities across businesses or distinctly branded websites, applications, or other services (otherwise known as “targeted advertising” or “cross-context behavioral advertising”). 
						</p>
						<p><strong><i>Sensitive Information</i></strong></p>
						<p>
						The following personal data elements we collect may be classified as “sensitive” under certain privacy laws (“<strong>sensitive information</strong>”):
						</p>

						<IndentedList noBullet={false}>
							<li>
								<p>Account name and password</p>
							</li>
							<li>
								<p>Credit/debit card number plus expiration date and security code (CVV)</p>
							</li>
							<li>
								<p>Precise geolocation data</p>
							</li>
						</IndentedList>

						<p>
						As described in our Privacy Notice, we use account name and password, as well as payment card information to provide certain of our products and services. We also use precise geolocation data to provide our Services with geolocation capabilities.
						</p>
						<p>
						We do not sell sensitive information, and we do not process or otherwise share sensitive information for the purpose of targeted advertising. 
						</p>

						<p><strong><i>Deidentified Information</i></strong></p>
						<p>
						We may at times receive, or process personal data to create, deidentified information that can no longer reasonably be used to infer information about, or otherwise be linked to, a particular individual or household. Where we maintain deidentified information, we will maintain and use the information in deidentified form and not attempt to reidentify the information except as required or permitted by law.
						</p>

						<p><strong>Your Privacy Rights</strong></p>
						<p>Depending on your state of residency and subject to certain legal limitations and exceptions, you may be able to exercise some or all the following rights:</p>


						<table style={tableStyle}>
							<tr style={rowStyle}>
								<td style={cellStyle}><strong>The Right to Know</strong></td>
								<td style={cellStyle}>
								The right to confirm whether we are processing personal data about you and, under California law only, to obtain certain personalized details about the personal data we have collected about you, including:
									<IndentedList noBullet={false}>
										<li>The categories of personal data collected;</li>
										<li>The categories of sources of the personal data;</li>
										<li>The purposes for which the personal data were collected;</li>
										<li>The categories of personal data disclosed to third parties (if any), and the categories of recipients to whom this personal data were disclosed;</li>
										<li>The categories of personal data sold (if any), and the categories of third parties to whom the personal data were sold; and</li>
										<li>The categories of personal data shared for targeted advertising purposes (if any), and the categories of recipients to whom the personal data were disclosed for these purposes.</li>
									</IndentedList>
								</td>
							</tr>
							<tr style={rowStyle}>
								<td style={cellStyle}><strong>The Right to Access & Portability</strong></td>
								<td style={cellStyle}>
								The right to obtain access to the personal data we have collected about you and, where required by law, the right to obtain a copy of the personal data in a portable and, to the extent technically feasible, readily usable format that allows you to transmit the data to another entity without hindrance.
								</td>
							</tr>
							<tr style={rowStyle}>
								<td style={cellStyle}><strong>The Right to Correction</strong></td>
								<td style={cellStyle}>
								The right to correct inaccuracies in your personal data, taking into account the nature of the personal data and the purposes of the processing of the personal data.
								</td>
							</tr>
							<tr style={rowStyle}>
								<td style={cellStyle}><strong>The Right to Control Over Sensitive Information</strong></td>
								<td style={cellStyle}>
								The right to direct us to limit the use of your sensitive personal data to certain purposes, including to perform the services or provide the goods reasonably by an average consumer who requests those goods or services, or to otherwise withdraw your consent to processing.
								</td>
							</tr>
							<tr style={rowStyle}>
								<td style={cellStyle}><strong>The Right to Request Deletion</strong></td>
								<td style={cellStyle}>
								The right to request the deletion of personal data that we maintain about you, subject to certain exceptions.
								</td>
							</tr>
							<tr style={rowStyle}>
								<td style={cellStyle}><strong>The Right to Non-Discrimination</strong></td>
								<td style={cellStyle}>
								The right not to receive retaliatory or discriminatory treatment for exercising any of the rights described above.
								However, please note that if the exercise of the rights described above limits our ability to process personal data (such as in the case of a deletion request), we may no longer be able to provide you our products or services or engage with you in the same manner.
								In addition, the exercise of the rights described above may result in a different price, rate, or quality level of product or service where that difference is reasonably related to the impact the right has on our relationship or is otherwise permitted by law.
								</td>
							</tr>
						</table>
						<br/>
					
						<p id="exerciseyourprivacyrights"><strong>How to Exercise Your Privacy Rights</strong></p>
						<p><strong><i>To Exercise Your Privacy Rights</i></strong></p>
						<p>Please submit a request by:</p>
					
						<IndentedList noBullet={false}>
							<li>Filling out our <a href={validateURL('https://privacyportal.onetrust.com/webform/0cc1194c-e1fe-4240-a982-e1c88c30ac81/dee06e8b-9555-4725-a813-3f1827e83ba3') ? 'https://privacyportal.onetrust.com/webform/0cc1194c-e1fe-4240-a982-e1c88c30ac81/dee06e8b-9555-4725-a813-3f1827e83ba3' : ''}>online form</a>; or</li>
							<li>Calling 1-888-971-3804.</li>
						</IndentedList>

						<p>
						We will need to verify your identity and confirm you are a resident of a state that offers the request right(s) before processing your request.  To verify your identity, we will generally either require the successful login to your account or the matching of sufficient information you provide us to the information we maintain about you in our systems. As a result, we require requests to include name, email address, and state of residency. Although we try to limit the personal data collected in connection with a request to exercise any of the above rights, certain requests may require us to obtain additional personal data from you.  In certain circumstances, we may decline a request, particularly where we are unable to verify your identity or locate your information in our systems, or where you are not a resident of one of the eligible states.
						</p>

						<p><i><strong>To Exercise Your Right to Limit the Use of Your Sensitive Information</strong></i></p>
						<p>
						Depending on your state of residency, unless you have exercised your right to limit the use and disclosure of your sensitive information or otherwise withdrawn your consent to processing, we may use your sensitive information for the purposes described in our Privacy Notice, as further described in the <strong>Sensitive Information</strong> section of these U.S. State Privacy Disclosures. To exercise this right, or to withdraw your consent to processing, please update your device settings to turn off precise geolocation data collection and fill out our <a href={validateURL('https://privacyportal.onetrust.com/webform/0cc1194c-e1fe-4240-a982-e1c88c30ac81/dee06e8b-9555-4725-a813-3f1827e83ba3') ? 'https://privacyportal.onetrust.com/webform/0cc1194c-e1fe-4240-a982-e1c88c30ac81/dee06e8b-9555-4725-a813-3f1827e83ba3' : ''}>online form</a> or call 1-888-971-3804.
						</p>

						<p><i><strong>Authorized Agents</strong></i></p>
						<p>
						In certain circumstances, you are permitted to use an authorized agent to submit requests on your behalf through the designated methods set forth above where we can verify the authorized agent’s authority to act on your behalf. To verify the authorized agent’s authority, we generally require evidence of either (i) a valid power of attorney or (ii) a signed letter containing your name and contact information, the name and contact information of the authorized agent, and a statement of authorization for the request. Depending on the evidence provided and your state of residency, we may still need to separately reach out to you to confirm the authorized agent has permission to act on your behalf and to verify your identity in connection with the request.
						</p>

						<p><i><strong>Appealing Privacy Rights Decisions</strong></i></p>
						<p>
						Depending on your state of residency, you may be able to appeal a decision we have made in connection with your privacy rights request. All appeal requests should be submitted by emailing us at <a href={sanitizeUrl('mailto:privacy@robertparker.com')}>privacy@robertparker.com</a>  with the subject line, “Privacy Request Appeal.” 
						</p>

						<p><i><strong>Minors Under Age 16</strong></i></p>
						<p>
						We do not sell personal data or share or otherwise process personal data for the purpose of targeted advertising, including personal data of consumers we know to be less than 16 years of age.
						</p>

						<p><strong>California-Specific Disclosures</strong></p>
						<p>
						The following disclosures only apply to residents of the State of California. 
						</p>

						<p><i><strong>Personal Data Collection</strong></i></p>
						<p>
						In the last 12 months, we may have collected the following categories of personal data:
						</p>

						<IndentedList noBullet={false}>
							<li><p><strong>Identifiers</strong>, such as name, email address, telephone number, address, zip code, account username and password, or other similar identifiers.</p></li>
							<li><p>Calling 1-888-971-3804.</p></li>
							<li><p><strong>California Customer Records (Cal. Civ. Code § 1798.80(e))</strong>, such as credit/debit card information, bank account information or other payment information if you engage with one of our clients.</p></li>
							<li><p><strong>Commercial Information</strong>, such information about products or services purchased or considered, whether you used a particular coupon code or promotion, and information about your use of the Services.</p></li>
							<li><p><strong>Internet/Network Information</strong>, such as your browsing history, log and analytics data, search history and information regarding your interaction with our website.</p></li>
							<li><p><strong>Geolocation Data</strong>, such as information about your physical location collected from geolocation features on your device, including your IP address and GPS (e.g., latitude and/or longitude).</p></li>
							<li><p><strong>Sensory Information</strong>, such as pictures you provide or upload in connection with the Services, and the content and audio recordings of phone calls between you and RPWA and/or you and our service providers, that we record where permitted by law.</p></li>
							<li><p><strong>Professional/Employment Information</strong>, such as job title, department, employer or business.</p></li>
							<li><p><strong>Other Personal Data</strong>, such as information and content you post to the Services and other information you submit to us, including inquiry and communication information when you contact us.</p></li>
							<li><p><strong>Inferences</strong>, including information generated from your use of the Services reflecting predictions about your interests and preferences.</p></li>
						</IndentedList>

						<p>
						For more information about our collection of personal data, the sources of personal data, and how we use this information, please see the <strong><a onClick={(e) => handleClick(e, 'privacy4')}>How We Collect and Use Personal Data</a></strong> section of our Privacy Notice.
						</p>

						<p><i><strong>Disclosure of Personal Data</strong></i></p>
						<p>
						As described in the <strong><a onClick={(e) => handleClick(e, 'privacy6')}>Our Disclosure of Personal Data</a></strong> section of our Privacy Notice, we disclose personal data to a variety of third parties for business purposes. We do not “sell” your personal data or share or otherwise process your personal data for targeted advertising purposes.
						</p>

						<p><strong>Updates to These U.S. Privacy Disclosures</strong></p>
						<p>
						We will update these U.S. Privacy Disclosures from time to time.  When we make changes to these U.S. Privacy Disclosures, we will change the “Last Updated” date at the beginning of these U.S. Privacy Disclosures.  If we make material changes to these U.S. Privacy Disclosures, we will notify you by email to your registered email address, by prominent posting on our online services, or through other appropriate communication channels.  All changes shall be effective from the date of publication unless otherwise provided in the notification.
						</p>

						<p><strong>Contact Us</strong></p>
						<p>
						If you have any questions or requests in connection with these U.S. Privacy Disclosures or other privacy-related matters, please send an email to <a href={sanitizeUrl('mailto:privacy@robertparker.com')}>privacy@robertparker.com</a>.
						</p>
					</div>
				</div>
			</div>
		</div >
	)}

export default PrivacyPolicy