import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

export const SidebarMenuHeader = styled.div`
	margin-bottom: 20px !important;
	font-family: Libre Franklin;
	font-size: 22px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: var(--black);

`

export const MenuOption = styled.button<{ selected: boolean }>`
	height: 40px;
	margin: 0px 14px 0px 0px;
	background-color: #fff;
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	margin-bottom: 10px;

	&:hover {
		color: #a08436;
	}

	border: none;
	border-left: ${(props) => (props.selected ? '2px solid #333' : 'none')};
`

export const SelectOption = styled.span<{ selected: boolean }>`
	height: 40px;
	margin: 0px 14px 0px 0px;
	background-color: #fff;
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	margin-bottom: 10px;
	cursor: pointer;
	padding: 0 0.7rem;
	padding-bottom: 0.7rem;

	&:hover {
		color: #a08436;
	}
	border: none;
	border-bottom: ${(props) => (props.selected ? '4px solid #a08436' : 'none')};
`

export const SideTabMenu = styled.div`
	min-height: 800px;
`

/* Selection Menu Start */

export const MenuSelectionContainer = styled.div`
	display: none;
	& span {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 0.5rem;
		display: inline-block;
	}

	@media (max-width: 920px) {
		display: block;
	}
`

export const MenuSelection = styled.select`
	outline: none;
	& .option-group {
		font-weight: 300;
	}
`

/* Selection Menu End */

/* Modal Start */
export const ModalContainer = styled.div`
	& .modal{
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		transition: opacity 0.3s;
		z-index: 111;

		& .modal-content {
			background-color: white;
			border-radius: 8px;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
			animation: fade-in 0.5s;
			z-index: 112;
			bottom: 20%;
			max-width: 400px;
			width: 400%;

			& .header {
				padding: 15px 30px;
				display: flex;
				align-items: center;
				width: 100%;
				justify-content: space-between;
				border-bottom: 1px solid #ccc;

				& .modal-close {
					cursor: pointer;
					font-size: 1.2rem;
				}

				& .modal-tab {
					display: flex;
					align-items: center;
					gap: 0.5rem;
					font-size: 1.15rem;

					& a {
						cursor: pointer;
						color: rgba(0, 0, 0, 0.8);
					}
				}
			}

			& .tab-content {
				padding: 30px;
			}
	
		}

		& .modal-error {
			background-color: #e01a00;
			text-align: center;
			color: #fff;
			margin-bottom: -1rem;
			margin-top: 4em;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			position: absolute;
			width: 398px;
			animation: fade-in 0.5s;

			& p {
				padding: 12px;
				font-size: 12px;
				margin: 0;
			}
		}
	}

	@keyframes fade-in {
		from {
			opacity: 0;
			transform: translateY(-15%)
		}
		to {
			opacity: 1;
			transform: translateY(0)
		}
	}
`
/* Modal End */ 


const excludePathName = ['/free-publications/wine-journal', '/free-publications/wine-discoveries', '/free-publications/sustainability-and-wine', '/sustainability-and-wine/green-emblem-list', '/free-publications/green-emblem', '/free-publications/certification', '/events/webminars', '/about/editorial-team', '/about/reviewers', '/about/the-rating-system' , '/about/the-wine-advocate', '/resources/about-wines', '/trade-partners/preferred-retailers', '/trade-partners/preferred-wine-schools','/trade-partners/trade-directory', '/trade-directory/directory-profile', '/article/comments', '/article/categories']

export const useBasePath = (): string => {
	const searchPage = /\/(search\/wine|search\/article)/
	const { pathname } = useLocation()
	const ifSearch = searchPage.test(pathname)
	
	if(ifSearch) {
		const parts = pathname.split('?')
		return parts[0]
	}
	const secondSlashIndex = pathname.indexOf('/', pathname.indexOf('/') + 1)

	if(excludePathName.includes(pathname) || secondSlashIndex < 0){
		return pathname
	}
	
	return pathname.slice(0, secondSlashIndex)
}

/* Selection Menu End */
