import moment from 'moment'

export const GetPercentageOfDiscount = (
	discountValue: number,
	amount: number
): string => {
	return ((discountValue / amount) * 100).toFixed(0)
}

export const IsCodeActive = (start: string, end: string): boolean => {
	const today = moment(new Date())

	const startDate = moment(start)
	const endDate = moment(end)

	if (start && end) {
		return today.isBetween(startDate, endDate)
	} else if (start && !end) {
		return today.isAfter(startDate)
	} else if (!start && end) {
		return today.isBefore(endDate)
	}

	return true
}

export const GetPromoType = (
	code: any
): 'REDEMPTION' | 'DISCOUNT' | 'SUBSCRIPTION' | null => {
	// Check if it requires purchase
	if (code.requirePurchase) {
		// Discount or extension
		if (code.assignComplimentary && code.complimentarySub) {
			return 'SUBSCRIPTION' // subscription extension
		} else if (code.assignComplimentary && !code.complimentarySub) {
			return 'REDEMPTION'
		} else if (code.discountId) {
			return 'DISCOUNT' // discounted price
		}
	} else {
		// Redeption
		return 'REDEMPTION'
	}

	return null
}
