/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import DOMPurify from 'dompurify'

// i18n
import { TranslationsUtil } from '../../../../utils'
import englishTranslation from './translations/en.json'

// Styles
import { AuthorSummary, BioContainer } from './styles'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
import { addTargetBlank } from '../../../../helper/constants'

const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

function generateAuthorBox(author: any) {

	const translate = translationUtil.getTranslator()

	const cleanBio = DOMPurify.sanitize(addTargetBlank(author.bio), {ADD_ATTR: ['target']})

	return (
		<AuthorSummary className="author-box single-entry-section">
			{author.profile_image ? (
				<div className="author-box__image">
					<div className="author-avatar">
						<ImageComponent imageUrl={author.profile_image} />
					</div>
				</div>
			) : (<></>)}

			<div className="author-box__text">
				<div className="author-name meta-font">
					<Link to={`/author/${author.id}`} title={`${translate('Post By')} ${author.name}`} rel="author">{author.name}</Link>
				</div>

				<BioContainer>
					<div className="author-bio content" dangerouslySetInnerHTML={{ __html: cleanBio }} ></div>
					<div className='profile-link'>
						<Link to={ `/author/${ author.id }`}>Read more</Link>
					</div>
				</BioContainer>

				<div className="author-info">
					<div className="row row--space-between row--flex row--vertical-center grid-gutter-20">
						{/* <div className="author-socials col-12 col-sm-6">
							<ul className="list-unstyled list-horizontal list-space-sm">
								<li>
									<a href="#"><i className="mdicon mdicon-mail_outline"></i>
										<span className="sr-only">e-mail</span>
									</a>
								</li>
								<li>
									<a href="#"><i className="mdicon mdicon-twitter"></i>
										<span className="sr-only">Twitter</span>
									</a>
								</li>
								<li>
									<a href="#"><i className="mdicon mdicon-facebook"></i>
										<span className="sr-only">Facebook</span>
									</a>
								</li>
								<li>
									<a href="#"><i className="mdicon mdicon-google-plus"></i>
										<span className="sr-only">Google+</span>
									</a>
								</li>
							</ul>
						</div> */}
					</div>
				</div>
			</div>
		</AuthorSummary>
	)
}

export function ImageComponent({ imageUrl }: { imageUrl: string }) {
	const [isValidImage, setIsValidImage] = useState<boolean | null>(null)

	const checkURL = async (imageUrl: string) => {
		const url = imageUrl
		if (!url) {
			console.error('URL is not defined')
			return
		}
		try {
			const response = await fetch(url, {
				method: 'HEAD'
			})
	
			if (response.ok) {
				return imageUrl
			} else {
				return
			}
		} catch (error) {
			console.error('Error checking URL:', error)
		}
	}

	useEffect(() => {
		const fetchImageValidity = async () => {
			if (imageUrl) {
				const result = await checkURL(imageUrl)
				setIsValidImage(!!result)
			}
		}

		fetchImageValidity()
	}, [imageUrl])

	if (isValidImage === null) {
		return <div>Loading...</div>
	}

	return isValidImage ? (
		<CloudImageUtils 
			imgSrc={imageUrl} 
			alt='image' 
			ratio={0} 
			params='width=200&height=200&radius=999' 
		/>
	) : (
		<img alt='image' src='/img/icons/unknownUser.png' />
	)
}

function AuthorBoxMongo(props: { author: any }): ReactElement {

	const { author } = props

	return (
		author && author.name ? generateAuthorBox(author) : (<>There is no author</>)
	)
}

export default AuthorBoxMongo