import styled from 'styled-components'

const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;

`

export const SidebarSubscribebutton = styled.div`
	padding-bottom: 20px;
	${commonTextStyle}
`
export const SidebarLoginButton = styled.div`
	padding-bottom: 10px;
	color: #333;
	${commonTextStyle}
`
export const LoginSignUpContainer = styled.div`
	& a {
		cursor: pointer;
	}
`

export const SidebarAccount = styled.div`
	font-size: 14px;
	color: #fff;
	${commonTextStyle};
`
export const SidebarLoginSigUpAccount = styled.div`
	font-size: 14px;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 10px;
	color: #333;
	${commonTextStyle}
`

export const UserNameContainer = styled.div`
	width: 100%;
	background-color: #73333f;
`

export const SidebarSubscribeIcon = styled.div`
	font-size: 17px;
	color: #333;
	position: absolute;
	left: 30px;
	margin-top: -2px !important;
	padding-left: 4px !important;
`
export const SidebarSubscribeName = styled.div`
	padding-left: 30px;
	padding-top: 3px;
	color: #eee;
`
export const SidebarLoginIcon = styled.div`
	font-size: 17px;
	color: #333;
	position: absolute;
	left: 30px;
`
export const SidebarLoginName = styled.div`
	padding-left: 30px;
	padding-top: 3px;
	color: #333;
`
export const SidebarAccountIcon = styled.div`
	font-size: 25px;
	color: #fff;
	position: absolute;
`
export const SidebarAccountName = styled.div`
	text-transform: uppercase;
	padding-left: 30px;
	color: #fff;
	cursor: none;
	padding-top: 8px;
	font-size: 14px;
    font-weight: bold;
    font-family: Libre Franklin;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
`
export const SidebarAccountSub = styled.div`
	padding-left: 10px; 
	font-size: 14px;
	${commonTextStyle}
`
export const SidebarMenu = styled.div`
	padding-left: 20px;
	padding-right: 20px;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: bold;
	color: #333;
	${commonTextStyle}
	margin-top: 10px;
	margin-bottom: 10px;
`
export const SidebarMenuSub = styled.div`
	font-size: 14px;
	${commonTextStyle}
`
export const SidebarMenuSub1 = styled.div`
	font-size: 14px;
	${commonTextStyle}
`
export const SidebarSubscribe = styled.div`
	margin: 20px 20px 10px;
	font-size: 14px;
	font-weight: normal;
	text-align: center;
	${commonTextStyle}
`
export const SidebarEmailAddress = styled.div`
	width:100%;
	margin-bottom:10px;
`
export const SidebarSocials = styled.div`
	width: 40px;
	height: 40px;
`
export const UserNameMenu = styled.div`

	& .username-title {
		height: 50px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 10px;

		& img {
			filter: brightness(0) invert(1);
		}
	}

	& div.menu-title{ 
		padding-right: 20px;
		display: flex;
    	justify-content: space-between;
		align-items: center;
	}

	& ul.sub-menu {
		padding-top: 10px;
		background-color: #fafafa;
		padding-bottom: 10px;
		border-bottom: #cccccc 1px solid;
		border-top: #cccccc 1px solid;

		& a {
			cursor: pointer;
			margin-left: 30px;
			margin-bottom: 5px;
			color: #777777 !important;
		}
	}

	& a { 
		text-decoration: none;
		display: block;
		position: relative;
		padding: 4px 7px;
		background: transparent;
		color: rgba(0, 0, 0, 0.6);
	} 
`