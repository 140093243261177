/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Separator } from '../common'
import { isMobile } from 'react-device-detect'

// Data Sources
import LoadingOverlay from '../../../../../components/Loading-overlay'
import { CloudImageUtils } from '../../../../../utils/CloudImageUtils'
import { useGetUserListReviewersQuery } from '../../../../../app/services/Users'

const AuthorBox = styled.div`
	padding: 0px;
	background: none; 
	height: 120px; 
	width: 120px; 
	border: none;
`

const ProfileContainer = styled.div`
	display: flex;
	flex-direction: row;
	@media (max-width: 768px) {
    flex-direction: column;
  }
`

const PictureContainer = styled.div`
	width: 120px;
  height: 120px;
  margin: 3px 20px 20px 0;
  border: solid 4px var(--white-three);
	@media (max-width: 768px) {
		margin-top: 30px;
	}
`

const Name = styled.div`
	width: 610px;
  height: 32px;
  margin: 0 0 10px 20px;
  font-family: Libre Franklin;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  color: #a08436;
`

const Label = styled.div`
	width: 610px;
  height: 21px;
  margin: 10px 0 5px 20px;
  font-family: Libre Franklin;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #777;
`

const Description = styled.div`
	height: 56px;
	margin: 5px 0 19px 20px;
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #777;
`

function AboutOurReviewers(): ReactElement {

	const { data: reviewerListData, isLoading } = useGetUserListReviewersQuery() 

	return (
		<div>
			{isLoading && <LoadingOverlay/>}
			<div className="single-entry" style={{ marginTop: 5 }}>
				<div className="mnmd-block mnmd-block--fullwidth mnmd-block--contiguous page-heading page-heading--has-background" style={{ marginBottom: '10px' }}>
					<div className="container">
						{isMobile ? <h2 className="page-heading__title" style={{ paddingTop: '20px' }}>Our Reviewer Team</h2> : <h2 className="page-heading__title">Our Reviewer Team</h2>}
					</div>
				</div>

				<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
					<div className="container">
						<br />
						<br />
						{reviewerListData?.data?.length !== 0 ? reviewerListData?.data?.map((reviewer: any, index: number) => (
							<div key={`${JSON.stringify(reviewer)} - ${index}`}>
								<ProfileContainer >
									<AuthorBox className='author-box' >
										{reviewer.profile?.profile_image ? (
											<PictureContainer className="author-box__image">
												<div className='author-avatar'>
													<Link to={`/author/${reviewer._id}`} >
														<CloudImageUtils imgSrc={reviewer?.profile?.profile_image.url} alt='image' ratio={0} params='width=200&height=200&radius=999' />
													</Link>
												</div>
											</PictureContainer>
										) : null}
									</AuthorBox>
									<div>
										<Link to={`/author/${reviewer._id}`} ><Name>{reviewer?.profile?.name?.first_name} {reviewer?.profile?.name?.last_name}</Name></Link>
										{reviewer?.profile?.reviewer_info?.assigned_regions !== '' &&
										<span>
											<Label>Assigned Regions</Label>
											<Description>{reviewer?.profile?.reviewer_info?.assigned_regions}</Description>
										</span>
										}
									</div>
								</ProfileContainer>
								<Separator></Separator>
							</div>)) : (<LoadingOverlay />)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default AboutOurReviewers