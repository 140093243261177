/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { MutableRefObject, ReactElement, ReactNode } from 'react'
import { useAppSelector } from '../../../../../../app/hooks'
import { Users as UsersFromStore} from '../../../../../../app/slices/Users'

type Props = {
	refs: MutableRefObject<HTMLDivElement | null>;
    recieptData?: any
};

const IfNOn = ({data, display}:{data:any,display:ReactNode}):ReactElement => {
	return <>{
		data ? <h6>{display}</h6> : <h6>N/A</h6>
	}</>
}


function PrintReciept({refs, recieptData}: Props): ReactElement {
	
	const { userData } = useAppSelector(UsersFromStore)
    
	return <div ref={refs} className="reciept-layout">
		{
			recieptData && <div>
				<div>
					<img className="reciept-img-header" src="img/logo-mark-color.png" alt="logo" />
				</div>
				<div className='reciept-lh'>
					<h6 className='reciept-owner'>The Wine Advocate, Inc</h6>
					<h6><b>Tel:</b>  1-410-329-6477</h6>
					<h6><b>Email:</b>  support@robertparker.com</h6>
				</div>

				<h5 className='reciept-id'>Payment ID:  {recieptData?._id}</h5>
				<div>

					<h5>Customer details</h5>

					<hr />
					<div className='row'>
						<div className='col-3 content-subCategory reciept-lh'>
							<h6>Customer ID</h6>
							<h6>Customer name</h6>
							<h6>Email</h6>
						</div>
						<div className='col-9 reciept-lh'>
							<IfNOn data={userData?._id} display={userData?._id} />
							<IfNOn data={userData?.profile?.name} display={<>{userData?.profile?.name?.title} {userData?.profile?.name?.first_name} {userData?.profile?.name?.last_name}</>} />
							<IfNOn data={userData?.username} display={userData?.username} />
						</div>
					</div>

					<h5 className='reciept-subTitle'>Subscription details</h5>
					<hr />
					<div className='row'>
						<div className='col-3 content-subCategory reciept-lh'>
							<h6>Subscription ID</h6>
							<h6>Plan type</h6>
							<h6>Price</h6>
							{recieptData?.promo_code ? <h6>Promo Code</h6> : <h6></h6>}
							<h6>Start date</h6>
							<h6>End date</h6>
						</div>
						<div className='col-9 reciept-lh'>
							<IfNOn data={recieptData?.subscription?._id} display={recieptData?.subscription?._id} />
							<IfNOn data={userData?.subscription?.planId} display={userData?.subscription?.planId} />
							<IfNOn data={recieptData} display={<>{recieptData?.currency} {recieptData?.amount}</>} />
							{recieptData?.promo_code && <h6>{recieptData?.promo_code}</h6>}
							<IfNOn data={userData?.subscription?.start} display={<>{new Date(userData?.subscription?.start).toUTCString()}</>} />
							<IfNOn data={userData?.subscription?.end} display={<>{new Date(userData?.subscription?.end).toUTCString()}</>} />
						</div>
					</div>
                
					<h5 className='reciept-subTitle'>Payment details</h5>
					<hr />
					<div className='row'>
						<div className='col-3 content-subCategory reciept-lh'>
							<h6>Payment ID</h6>
							<h6>Payment method</h6>
							<h6>Transaction ID</h6>
							<h6>Transaction date</h6>
							<h6>Card type</h6>
							<h6>Cardholder name</h6>
							<h6>Card number</h6>
							<h6>Status</h6>
						</div>
						<div className='col-9 reciept-lh'>
							<IfNOn data={recieptData?._id} display={recieptData?._id} />
							<h6>Credit Card</h6>
							<IfNOn data={recieptData?.transaction_id} display={recieptData?.transaction_id} />
							<IfNOn data={recieptData?.transaction_date} display={new Date(recieptData?.transaction_date).toUTCString()} />
							<IfNOn data={recieptData?.credit_card?.type} display={recieptData?.credit_card?.type} />
							<IfNOn data={recieptData?.credit_card?.name} display={recieptData?.credit_card?.name} />
							<IfNOn data={recieptData?.credit_card?.number} display={recieptData?.credit_card?.number} />
							<IfNOn data={recieptData?.transaction_status} display={recieptData?.transaction_status} />
						</div>
					</div>
				</div>

				<hr />
				<div className="text-center">
					<h6 className='reciept-owner'>Robert Parker Wine Advocate</h6>
					<h6>6th West 18th Street, 5th Floor, New York, NY 10011, United States</h6>
				</div>
			</div>
		}
		
	</div>
}

export default PrintReciept
