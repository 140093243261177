import React, { ReactElement, ReactNode } from 'react'
import { isMobile } from 'react-device-detect'
import { Controller, Control, FieldValues, RegisterOptions } from 'react-hook-form'

import { CheckboxContainer, CheckboxMainContainer, ErrorMessage, HiddenCheckbox, Icon, StyledCheckbox } from '../styles'

type CheckboxProps =  {
	name: string;
	children: ReactNode;
	control: Control<FieldValues>;
	rules?: RegisterOptions;
	onChangeValueChecker?: (value: boolean, name: string) => void 
}

function Checkbox({ name, children, control, rules, onChangeValueChecker }: CheckboxProps): ReactElement {

	const changeHandler = (value: boolean, name: string) => {
		if(onChangeValueChecker)
			onChangeValueChecker(value, name)
	}

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={false}
			rules={rules}
			render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
				<CheckboxMainContainer>
					<CheckboxContainer isMobile={isMobile}>
						<HiddenCheckbox 
							onChange={onChange}
							checked={value}
							ref={ref}
						/>
						<StyledCheckbox checked={value} onClick={() => {
							onChange(!value)
							changeHandler(value, name)
						}}>
							{value && (
								<Icon viewBox="0 0 24 24">
									<polyline points="20 6 9 17 4 12" />
								</Icon>
							)}
						</StyledCheckbox>
						{children}
					</CheckboxContainer>
					{error && <ErrorMessage>{error?.message as string}</ErrorMessage>}
				</CheckboxMainContainer>
			)}
		/>
	)
}

export default Checkbox