import styled from 'styled-components'
import { RPColors } from '../../../../styles/CSSConstant'
export const UserName = styled.div``

const commonFontAttributes = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.7;
	letter-spacing: normal;
`

export const CompanyParentContainer = styled.div`
	margin: 24px 15px;
	display: flex;
	align-items: center;
	flex-direction: column;

	@media (min-width: 576px) and (max-width: 991px) {
		margin: 24px 0px;
	}
`

export const NotificationPrompt = styled.div`
	display: flex;
	justify-content: center;
	
	.success {
		padding: 13.5px 20px;
		border-radius: 3px;
		border: solid 3px #008185;
		color: #008185;
		width: 650px;
		font-weight: 600;
		margin: 15px 0 -8px 0;
	}

	@media (max-width: 575px) {
		padding: 0px 15px;
	}

	@media (max-width: 750px) {
		padding: 0px 15px;
	}

	@media (max-width: 991px) {
		width: 100%;
		margin-top: 24px;

		.success {
			width: 100%;
			padding: 10px 17px;
		}
	}
`

export const MobileTabMenu = styled.div`
	#menu {
		padding: 18px;
	}

	@media(max-width: 575px) {
		margin: 0px 14px; 
	}
`

export const PageTitle = styled.div<{ isMobile: boolean }>`
	font-size: 24px;
	font-weight: 600;
	margin-top: ${({ isMobile }) => (isMobile ? '32px' : '0px')};
	margin-bottom: ${({ isMobile }) => (isMobile ? '35px' : '0px')};
	${commonFontAttributes}
`

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
`

export const TabbedMenuContainer = styled.div`
	.group {
		margin-top: 17px;
	}
`

export const TabbedMenu = styled.div`
	display: flex;
	flex-direction: row-reverse;
	gap: 23.6px;
`

export const TabbedMenuItem = styled.div<{ isActive: boolean }>`
	padding-bottom: 11px;
	display: flex;
	height: 40px;
	cursor: pointer;

	& div.bar {
		width: 2px;
		height: 40px;
		margin: 0 10px 0 0;
		background-color: ${(props) => (props.isActive ? '#333' : '#fff')};
	}

	& div.menu {
		font-size: 14px;
		white-space: nowrap;
		border-bottom: ${(props) => (props.isActive ? '2px solid #a08436' : 'none')};

		font-weight: 500;
		font-family: Libre Franklin;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;

		&:hover {
			color: #a08436;
		}
	}
`

export const ButtonUpdate = styled.div<{ size: string }>`
display: flex;
justify-content: center;
align-items: center;
width: 161px;
height: 49px;
margin: 27px auto 0px auto; /* Center the button itself horizontally if needed */
padding: 0px !important; /* Adjust padding if necessary */
border-radius: 4px;
background-color: #73333f;
font-size: ${(props) => props.size};
color: #fff;
line-height: 1.2;
cursor: pointer;
`

export const Container = styled.div`
	display: flex;
`
export const GroupContainer = styled.div`
	border-radius: 10px;
	width: 701px;
	font-family: Libre Franklin;
	font-size: 24px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.17;
	letter-spacing: normal;
	text-align: center;
`

export const ImageContainer = styled.div`
	margin: 32px 0 8px 0;	
	height: 120px;
	width: 120px;
	.image {
		height: 100%;
		width: 100%;
		box-sizing: border-box;
	}

	.image-alternative {
		border: 1px solid lightgrey;
		height: 100%;
		width: 100%;
		border-radius: 4px;
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		color: grey;
	}
`

export const TradeContainer = styled.div`
	padding: 32px;

	.modal-dialog {
		max-width: 564px;
	}

	.modal-header {
		border: none;
		padding: 0;
	}

	.modal-content {
		padding: 32px;
		border-radius: 8px;
		width: 564px;
	}

	.modal-body {
		padding: 0;
		margin-top: 8px;

		div {
			font-size: 18px;
			margin-top: 1px;
		}
	}

	.modal-footer {
		border: none;
		padding: 0;
		justify-content: center;
		margin-top: 40px;
		gap: 16px;
	}

	.modal-title {
		font-size: 20px;
		font-weight: 600;
		margin-top: 16px;
		font-family: Libre Franklin;
	}

	.button-cancel {
		margin: 0 16px 0 0;
		padding: 16px 29.3px 16px 30.8px;
		border-radius: 4px !important;
		border: solid 1px #73333f;
		background-color: transparent;
		margin: 0;
	}

	.button-confirm {
	  	margin: 0 0 0 16px;
		padding: 16px 38px;
		border-radius: 4px !important;
		border: none;
		background-color: #73333f;
		color: white;
		margin: 0;
	}

	@media (max-width: 991px) {
		.modal-dialog {
			max-width: 565px;
		}
	}

	@media (max-width: 563px) {
		.modal-content {
			width: 100%;
			height: 100%;
		}

		.modal-dialog {
			margin: 0;
			height: -webkit-fill-available;
		}
	}

`

export const DetailContainer = styled.div<{ size: string }>`
		width: auto;
		display: flex;

	& div.parent-header {
		font-size:  ${(props) => props.size};
		font-weight: bold;
		color: #333;
		font-family: Libre Franklin;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.21;
		letter-spacing: normal;
		// margin-bottom: 8px;
	}

	& div.header {
		font-size:  ${(props) => props.size};
		font-weight: bold;
		color: #333;
		margin-top: 16px;
		font-family: Libre Franklin;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		display: inline-block;
		white-space: nowrap;
	}

	& div.header-type {
		font-size: ${(props) => props.size};
		font-weight: bold;
		color: #333;
		margin-top: 16px;
		font-family: 'Libre Franklin', sans-serif;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		display: inline-block;
		white-space: nowrap;
	}

	& div.required-header {
		margin: 0 0 12px;
		font-size:  ${(props) => props.size};
		font-weight: bold;
		color: #333;
		&:after {
			content:" *";
			color: red;
		}
		${commonFontAttributes}
	}

	& div.value {
		font-size:  16px;
		font-weight: normal;
		color: #333;
		max-width: 623px;
		text-align: justify;
		margin-top: 16px;
		font-family: Libre Franklin;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		text-align: left;
		word-break: break-word;
	}

	& p {
		margin-bottom: 5px;
		text-align: left;
	}

	& div.next-line-value {
		font-size:  16px;
		font-weight: normal;
		color: #333;
		max-width: 623px;
		text-align: justify;
		font-family: Libre Franklin;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
	}

	.contact-info {
		margin-bottom: 16px;
	}

	@media (max-width: 376px) {
		.parent-header {
			font-size: 23px !important;
		}
	}
`

export const Separator = styled.div`
	height: 1px;
	margin: 32px 0 32px 0;
	background-color: #a18436;
	width: 100%;

	@media(max-width: 768px) {
		margin-top: 7px;
	}
`

export const ViewDetailsSeparator = styled.div`
	height: 1px;
	margin: 24px 0;
	background-color: #a18436;
	width: 100%;
`

export const StatusSeparator = styled.div`
	height: 1px;
	margin: 24px 0 8px 0;
	background-color: #a18436;
	width: 100%;
`

export const UpdateModalSeparator = styled.div`
	height: 1px;
	margin: 0 0 30px -40px;
	background-color: lightgrey;
	width: 100vw;

	@media (max-width: 560px) {
		margin: 0 0 30px -23px;
	}
`

export const ContactInfoSeparator = styled.div`
	height: 1px;
	background-color: lightgrey;
	width: 100vw;
	margin-left: -40px;
	margin-top: 140px;
	margin-bottom: 23px;

	@media (max-width: 560px) {
		margin-left: -25px;
	}
`

export const FormProfilePicContainer = styled.div<{isCompany?: boolean}>`
	display: flex;
	justify-content: left;
	align-items: center;
	margin-bottom: 25px;
	
	& div.image-container {
		width:  120px;
		height: 120px;
		padding: 60px 60px 85px 85px;
		background-color: #9a9a9b;
		cursor: pointer;
		border-radius: ${(props) => (props.isCompany ? '50%' : 0)}
	}

	& div.cloudimage-container {
		cursor: pointer;
	}

	& div.cloudimage-image {
		height: 140px !important;
	}

	& div.text-value {
		font-size: 10px;
		font-weight: lighter;
		display: flex;
		flex-wrap: wrap;
		align-items: left;
	}

	& img {
		width: 160px !important;
		height: 145px !important;
	}


	& div.plus-icon {
		font-size: 40px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: white;
		margin-left: -24px;
		margin-top: -5px;
		}

		& div.upload-button {
			width: 136px;
			height: 33px;
			margin: 0 0 70px 20px;
			padding: 6px 0;
			text-align: center;
			border-radius: 8px;
			font-weight: bold;
			${commonFontAttributes}

			& input {
				display: none;
			}
		}
	.jpGfD {
		height: 120px;
	}
`

export const FormFieldContianer = styled.div`
	margin-bottom: 20px;
	& div.form-label {
		font-size: 14px;
		font-weight: bold;
		color: #333;
		margin-bottom: 5px;
	}

	& div.form-label-required {
		font-size: 14px;
		font-weight: bold;
		color: #333;
		margin-bottom: 5px;

		&:after {
			content:" *";
			color: red;
		}
	}

	& div.form-value {
		margin-bottom: 5px;
		max-width: 468px;
		font-weight: normal;
		line-height: 1.2;
		color: #333;
		border-color: #333;
		${commonFontAttributes}
	}
`

export const UploadButton = styled.div`
	height: 33px;
	margin: 15px 0 10px 0;
	padding: 4px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: white;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
	text-align: center;
	${commonFontAttributes}
	&:hover {
		background-color: #eeeeee;
	}
`

export const ValidationField = styled.div`
	height: 20px;
	font-size: 10px;
	text-align: left;
	color: red;
	font-weight: lighter;
`

export const SaveButton = styled.div`
	height: 33px;
	cursor: pointer;
	margin: 0 0 10px;
	padding: 6px 14.5px;
	border-radius: 8px;
	background-color: #73333f;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	color: white;
	
	&:hover {
		background-color: #8E4250;
	}
`

export const SelectContainer = styled.div`
	display: flex;
	flex-direction: row;
	& a {
		margin-top: 8px;
	}

	& select {
		width: 468px;
	}

	& p {
		color: #a08436;
		text-decoration: none;
		margin-top: 8px;
		
	}
`

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
`

export const ManageGroupContainers = styled.div<{ size: string }>`
	width: auto;
	display: flex;
	font-family: Libre Franklin;
	color: #333;
	${commonFontAttributes}

	& div.parent-header {
		font-size: ${(props) => props.size};
		font-weight: bold;
		line-height: 1.21;
	}

	& div.header {
		font-size: ${(props) => props.size};
		font-weight: bold;
		margin-top: 16px;
		white-space: nowrap;
	}

	& div.value {
		font-size: ${(props) => props.size};
		font-weight: normal;
		margin-top: 16px;
	}
	

	& input {
		width: 16px;
		height: 16px;
		margin: 3px 5px 0 0;
		border-radius: 8px;
	}

	& .noticeAddOns {
		font-size: 20px;
		font-style: italic;
	}

	& .manage-group-buttons {
		display: flex;
		align-items: center;
		margin-top: 32px;
		width: 100%;
		justify-content: space-between;
	}

	@media (max-width: 320px) {
		& div.parent-header {
		font-size: 22px;
		}

		& div.header {
			font-size: 13px;
		}

		& div.value {
			font-size: 13px;
		}
	}

	@media (max-width: 472px) {
		.manage-group-buttons {
			display: flex;
			flex-direction: column;
		}
	}
`

export const UserListContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.user-list {
		margin-top: 32px;
	}

	@media (max-width: 575px) {
		.user-list{
			padding: 0px 14px;
			margin-top: 12px;
		} 
	}

	@media (max-width: 992px) {
		.user-list{
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: center;
		} 
	}
`

export const GroupHeaderContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media(max-width: 575px) {
		padding: 0px 14px;
	}

	@media(max-width: 991px) {
		align-items: center;
		width: 100%;
	}
`

export const GroupContentContainer = styled.div< {isManager: boolean} >`
	display: flex;
	justify-content: center;
	alighn-items: center;

		.content-container {
			border: 1px solid #a08436;
			border-radius: 10px;
			margin-top: 27px;
			padding: 32px;
			width: 701px;
			// height: ${props => props.isManager ? '310px' : '225px'};

		@media (max-width: 425px) { 
			height: 100%;
			margin: 24px 14px; 
		}

		@media (min-width: 426px) and (max-width: 575px) {
			width: 100%;
			margin: 24px 14px; 
			height: auto;
		}

		@media (min-width: 576px) and (max-width: 768px) {
			width: 100%;
			margin: 24px 0px; 
			height: auto;
		}
	}
`

export const CompanyInfoContainer = styled.div<{isMobile: boolean}>`
	border: 1px solid #a08436;
	width: ${({ isMobile }) => (isMobile ? '100%' : '701px')};
	border-radius: 12px;
	padding: 32px;
	// min-height: 555px; //commented to for new invited user view
`

export const TradeDirectory = styled.div`
	width: 701px;

	.header {
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #333;
	}

	select {
		margin: 16px 0 24px 0;
		padding: 19px 16px 18px;
		border-radius: 8px;
		border: solid 1px #a18436;
		width: 100%;
	}

	@media (max-width: 991px) {
    	width: 100%;
	}
`

export const ContactInfoContainer = styled.div<{isMobile: boolean}>`
	border: 1px solid #a08436;
	width: ${({ isMobile }) => (isMobile ? '100%' : '701px')};
	// height: 528px;
	border-radius: 12px;
	padding: 32px;
	margin-top: 24px;

	@media (max-width: 991px) {
		margin-top: 0px;
	}
`

export const PlanContainerButton = styled.button`
	height: 49px;
	width: 181px;
	background-color: #73333f;
	color: #fff;
	cursor: pointer;
	font-size: 14px;
	font-weight: normal;
	border: none;
	float: right;
	border-radius: 4px !important;
	margin-left: 16px;

	&:disabled{
		background-color: #CCCCCC;
		color: #fff;
		pointer-events: none;
	}

	@media (max-width: 472px) {
		width: 100%;
		margin-top: 20px;
		margin-left: 0px;
	}

	@media (max-width: 768px) {
		margin-bottom: 0px;
}
`

export const BuyAddOnsButton = styled.button<{ isDisabled: boolean }>`
	height: 49px;
	// width: 130px;
	background-color: ${({ isDisabled }) => (isDisabled ? '#ccc' : '#73333f')};
	color: #fff;
	cursor: pointer;
	font-size: 14px;
	font-weight: normal;
	border: none;
	float: right;
	border-radius: 4px !important;
	padding: 0 15px;

	@media (max-width: 472px) {
		width: 100%;
	}
`


export const RemoveSelectedButton = styled.button`
	width: fit-content;
	padding: 6px 11.5px;
	border-radius: 8px;
	color: #333333;
	font-weight: bold;
	font-size: 14px;
	float: right;
	border: solid 1px #ccc;
	background-color: #ffffff;

	&:disabled {
		color: #fff;
		font-weight: normal;
		background-color: #cccccc;
	}
`

export const ButtonActionContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 10px;
`

export const ResendInviteButton = styled.button`
	height: 33px;
	width: auto;
	padding: 0px;
	border-radius: 3px;
	color: #333333;
	font-weight: bold;
	font-size: 14px;
	border: solid 1px #fff;
	background-color: #ffffff;
	font-weight: bold;
	text-decoration: underline;

	@media (max-width: 320px) {
		font-size: 12px;
	}

	@media (min-width: 321px) and (max-width: 355px) {
		font-size: 12px;
	}

`

export const RemoveButton = styled.div`
	height: 33px;
	width: fit-content;
	border-radius: 3px;
	color: BLACK;
	cursor: pointer;
	font-size: 14px;
	font-weight: bold;
	text-decoration: underline;
	line-height: 2.4;

	@media (max-width: 320px) {
		font-size: 12px;
		line-height: 2.6
	}

	@media (min-width: 321px) and (max-width: 355px) {
		font-size: 12px;
		line-height: 2.6
	}

	@media (min-width: 356px) and (max-width: 425px) {
		line-height: 2.3
	}
`

export const SelectRemoveActionContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 24px ;

	& .selectCheckAll {
		display: flex;
		justify-items: center;
		margin-left: 5px;

		& label {
			display: flex;
			align-items: end;
			margin-left: 5px;
		}
	}
`

export const RemoveAllContainer = styled.div`
	width: 100%;
`

export const UserContainer = styled.div`
	margin-bottom: 15px;
	border: 1px solid #a08436;
	border-radius: 10px;
	padding: 24px 32px 20px 32px;
	width: 701px;
	font-size: 16px;
	font-weight: normal;
	color: #333;

	${commonFontAttributes}
	${UserName} {
		font-weight: bold;
	}
	& i {
		display:flex; 
		justify-content: flex-end;
		margin-top: -20px;
	}

	& input {
		width: 16px;
		height: 16px;
		margin: 3px 5px 0 0;
		border-radius: 8px;
	}

	& .isDisabled {
		color: #cccccc;
	}

	& .name-status {
		display: flex;
		justify-content: space-between;
	}

	& .status {
		font-style: italic;
	}

	& .username {
		margin-bottom: 8px;
		margin-top: 5px;
		font-size: 14px;
		word-break: break-word;
	}

	@media (max-width: 575px) {
		.name-status {
			display: flex;
			flex-direction: column;
		}

		& .username {
			margin-top: 4px;
		}

		& .status {
			margin-top: 4px;
		}
	}

	@media (max-width: 991px) {
		width: 100%;
	}
`

export const Paging = styled.div`
	width: 34px;
	height: 34px;
	margin: 40px 220px 80px 390px;
	padding: 7px 12.5px 6px;
	background-color: #A08436;
	color: white;
`

export const SectionContainer = styled.div`
	margin-top: 20px;
`

export const Title = styled.div`
	display: flex;
	justify-content: center;
	font-family: Libre Franklin;
	font-size: 24px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.17;
	letter-spacing: normal;
	text-align: center;
	margin-top: 32px;

	@media (max-width: 991px) {
		margin-top: 32px;
	}
`

export const FormNotifications = styled.div`
	& div.form-error {
		width: auto;
		height: auto;
		margin-bottom: 5px;
		padding: 13.5px 20px;
		border-radius: 8px;
		border: solid 2px #c50000;
		background-color: var(--white-three);
	}

	& div.form-success {
		width: auto;
		height: auto;
		margin-bottom: 5px;
		padding: 13.5px 20px;
		border-radius: 8px;
		border: solid 3px #73333f;
		background-color: var(--white-three);
	}
`

export const ErrorNotification = styled.div`
	width: 764px;
	height: 21px;
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #c50000;
`

export const SuccessNotification = styled.div`
	width: auto;
	height: 21px;
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #73333f;
	justify-content: center;
`

export const ErrorMessage = styled.div`
	width: 451px;
	height: 18px;
	margin: 0 0 0 5px;
	font-family: Libre Franklin;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #c50000;
`

export const GroupParentContainer = styled.div`
	display: flex;
	justify-content: center;

	// .target-container {
	// 	padding: 0px 14px;
	// }

	@media (max-width: 630px) {
		.target-container {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
`

export const JoinGroupModalContainer = styled.div`
	.header {
		margin: 0 0 16px;
		font-family: Libre Franklin;
		font-size: 20px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
	}

	.value {
		margin: 16px 0 32px;
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter	-spacing: normal;
	}
`

export const FormGroup =styled.div`
	margin: auto;
	width: 564px;
	display: flex;
	flex-direction: column;
	gap: 0.6rem;
	border: 1px solid maroon;
	padding: 32px 32px 32px 32px;
	border-radius: 12px;
	border: solid 1px #a18436;
	margin-top: 32px;

	& .form-title{
		font-weight: bold;
		font-size: 24px;
		line-height: 1.21;
		margin-bottom: 32px;
	}

	& div {
		display: flex;
		flex-direction: column;
		gap: 0.2rem;

		& .password-container{
			position: relative;

			& svg{
				position: absolute;
				right: 0px;
				margin-right: 1rem;
				top: 12px;
			}
		}
	}

	.first-row {
		display: flex;
		flex-direction: row;
		gap: 8px;
	}

	.second-row {
		margin-bottom: -10px;
	}

	@media(max-width: 992px) {
		width: 100%;

		.first-row {
			display: flex;
			flex-direction: column;
		}

		.first-row #lastName{
			margin-top: -5px;
		}

		.first-row p {
			margin-top: 0px;
		}

		.second-row {
			margin-bottom: 0px;
		}
	}
`

export const FormGroupNewUser =styled.div`
	margin: auto;
	width: 564px;
	display: flex;
	flex-direction: column;
	gap: 0.6rem;
	border: 1px solid maroon;
	padding: 32px 32px 32px 32px;
	border-radius: 12px;
	border: solid 1px #a18436;
	margin-top: 22px;

	& .form-title{
		font-weight: bold;
		font-size: 24px;
		line-height: 1.21;
		margin-bottom: 32px;
	}

	& div {
		display: flex;
		flex-direction: column;
		gap: 0.2rem;

		& .password-container{
			position: relative;

			& svg{
				position: absolute;
				right: 0px;
				margin-right: 1rem;
				top: 12px;
			}
		}
	}

	.first-row {
		display: flex;
		flex-direction: row;
		gap: 8px;
	}

	.second-row {
		margin-bottom: -10px;
	}

	@media(max-width: 992px) {
		width: 564px;

		.first-row {
			display: flex;
			flex-direction: column;
		}

		.first-row #lastName{
			margin-top: -5px;
		}

		.first-row p {
			margin-top: 0px;
		}

		.second-row {
			margin-bottom: 0px;
		}
	}

	@media (max-width: 640px) {
		width: 85%;
	}
`

export const FormGroupAddUser =styled.div`
	margin: auto;
	width: 564px;
	display: flex;
	flex-direction: column;
	gap: 0.6rem;
	border: 1px solid maroon;
	padding: 32px 32px 32px 32px;
	border-radius: 12px;
	border: solid 1px #a18436;
	margin-top: 32px;

	& .form-title{
		font-weight: bold;
		font-size: 24px;
		line-height: 1.21;
		margin-bottom: 32px;
	}

	& div {
		display: flex;
		flex-direction: column;
		gap: 0.2rem;

		& .password-container{
			position: relative;

			& svg{
				position: absolute;
				right: 0px;
				margin-right: 1rem;
				top: 12px;
			}
		}
	}

	.first-row {
		display: flex;
		flex-direction: row;
		gap: 8px;
	}

	.second-row {
		margin-bottom: -10px;
	}

	@media(max-width: 992px) {
		.first-row {
			display: flex;
			flex-direction: column;
		}

		.first-row #lastName{
			margin-top: -5px;
		}

		.first-row p {
			margin-top: 0px;
		}

		.second-row {
			margin-bottom: 0px;
		}
	}

	@media (max-width: 630px) {
		width: 85%;
	}
`

export const FormGroupExistingUser =styled.div`
	margin: auto;
	width: 564px;
	display: flex;
	flex-direction: column;
	gap: 0.6rem;
	border: 1px solid maroon;
	padding: 36px 32px 32px 32px;
	border-radius: 12px;
	border: solid 1px #a18436;
	margin-top: 32px;

	.header {
		margin: 0 0 16px;
		font-family: Libre Franklin;
		font-size: 20px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
	}

	.value {
		margin: 16px 0 32px;
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter	-spacing: normal;
	}

	.activation-buttons-login {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	@media (max-width: 630px) {
		width: 100%;
	}

	@media (max-width: 457px) {
		.activation-buttons-login {
			display: flex;
			flex-direction: column;
		}
	}
`

export const ValidatedFieldSelect = styled.select`
	border-radius: 8px;
	padding: 6px 6px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	height: 64px;
	border: 1px solid #a18436;
	&:focus {
		border-color: #ccc;
		transition: border-color 0.3s ease-in-out;
		outline: 0;
	}
	&.error {
		border-color: #c50000;
	}
`

export const ValidatedField = styled.input`
	border-radius: 8px !important;
	border: solid 1px #a18436 !important;
	border: 1px solid black;
	border-radius: 8px;
	padding: 18px 16px 21px !important;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	margin-bottom: 16px;
	&:focus {
		border-color: red;
		transition: border-color 0.3s ease-in-out;
		outline: 0;
	}
	&.error {
		border-color: #c50000;
	}
	::placeholder {
	color: black;
}
`

export const FieldErrorMessage = styled.div`
	display: flex;
	flex-direction: row;
	color: #c50000;

	& .error-message {
		display: flex;
		flex-direction: row;
		gap: 0.5rem;

		& img {
			width: 15px;
		}
	}
`

export const ErrorContainer = styled.div<{ hasError?: boolean }>`
	margin-bottom: ${props => props.hasError ? '-16px' : '0px'};
`
export const CompanySeparator = styled.hr`
	width: 100%;
	margin: 2rem auto;
	background-color: #ccc;
`

export const ActionsContainer = styled.div`
	width: 100%;

	& .activation-buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
	}

	& .agreement {
		width: 100%;
	}

	& .checkbox-field {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
	}

	& .checkbox-user {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: row;
		margin-top: 10px;
	}

	&.authenticated-actions {
		width: 83%;
	}

	& .checkbox-user input[type="checkbox"] {
		margin-top: 6px;
	}

	@media (max-width: 1200px) {
		&.authenticated-actions {
			width: 80%;
		}
	}

	@media (max-width: 1000px) {
		&.authenticated-actions {
			width: 89%;
		}
	}

	@media (max-width: 768px) {
		&.authenticated-actions {
			width: 92.5%;
		}
	}
	

	@media(max-width: 550px) {
		width: 100%;

		& .activation-buttons {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;

			// & a {
			// 	width: 100%;

			// 	& button {
			// 		width: 100%;
			// 	}
			// }

			// & button {
			// 	width: 100%;
			// 	padding: 6px 0;
			// }
		}

		&.authenticated-actions {
			width: 90%;
		}
	}

	& .bold-link {
		font-weight: bold;
	}

	& .italic-link {
		font-style: italic;
	}

	@media (max-width: 374px) {
		& .activation-buttons {
			gap: 10px;
		}
	}
`

export const BackButton = styled.button`
	height: auto;
	padding: 13px 30px;
	border-radius: 4px !important;
	border: solid 1px #73333f;
	background-color: #ffffff;
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-weight: 600;

	& img {
		padding-top: 0.3rem;
		transform: scaleX(-1);
	}
	
	&.mobile {
		padding-right: 12px !important;
	}
`

export const BackButtonGroup = styled.button`
	height: auto;
	padding: 13px 30px;
	border-radius: 4px !important;
	border: none;
	background-color: #ffffff;
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-weight: 500;

	& img {
		padding-top: 0.3rem;
		transform: scaleX(-1);
	}
	
	&.mobile {
		padding-right: 12px !important;
	}

	@media (max-width: 369px) {
		padding: 13px 15px;
	}
`

export const ProceedButton = styled.button`
	padding: 16px 48px;
	border-radius: 4px !important;
	border: none;
	background-color: #73333f;
	color: white;

	&:hover {
		background-color: #8E4250;
	}
	
	@media (max-width: 519px) {
		padding: 16px 35px;
	}
`

export const LaterButton = styled.div`
	padding: 15px 40px;
	border: none;
	color: black;
	text-decoration: underline;
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: normal;
	text-align: center;

	@media (max-width: 519px) {
		padding: 16px 25px;
	}
`

export const ProceedButtonGroup = styled.button`
	height: 41px;
	padding: 10px 25px;
	border-radius: 4px !important;
	background-color: #73333f;
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	color: #fff;
	border: none;
	min-width: 140px;

	&:hover {
		background-color: #8E4250;
	}

	@media (max-width: 374px) {
		min-width: 120px;
	}

	@media (max-width: 369px) {
		padding: 0px;
	}
`

export const SuccessPageContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.header {
		font-size: 24px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.17;
		letter-spacing: normal;
		font-family: Libre Franklin;
		margin-top: 8px;
	}

	.sub-header {
		font-family: Libre Franklin;
		font-size: 20px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
	}

	.value {
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		margin-top: 16px;
	}

	.container {
		width: 564px;
		padding: 36px 32px 32px;
		border-radius: 12px;
		border: solid 1px #a18436;
		margin-top: 24px;
	}

	.home-button {
		margin-top: 32px;
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: center;
		text-decoration: underline;
		cursor: pointer;
		min-width: 150px;
	}

	@media (max-width: 991px) {
		.container {
			width: 100%;
		}
	}

	@media (max-width: 767px) {
		.container {
			height: auto;
		}
	}

	@media (max-width: 575px) {
		.container {
			border-radius: 0;
		}
	}
`

export const RemoveModal = styled.div`
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
	z-index: 99;
	transition: opacity 0.3s ease-in-out;

	.content-header {
		margin: 5px 40px 30px 0;
		font-family: Libre Franklin;
		font-size: 24px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.21;
		letter-spacing: normal;
	}

	&.open {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		padding: 8rem 1rem;
	}

	&.closing {
		opacity: 0;
	}

	& .modal-container {
		background-color: #fff;
		border-radius: 12px;
		position: relative;
		max-width: 564px;
		overflow-y: auto;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
		animation: fadeIn 0.3s ease-in-out;
		width: 564px;
		// height: 302px;

		& .modal-title {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			padding: 15px 37px 0px 30px;

		& .close-btn {
			cursor: pointer;
			font-size: 3.5rem;
			font-weight: 400;
			color: black;
			height: 45px;
		}

		}

		& .modal-body {
			padding: 15px 37px 5px 37px;
			font-size: 16px;
			line-height: 1.5;
		}

		& .modal-footer {
			display: flex;
			align-items: center;
			justify-content: center;
			column-gap: 1rem;
			border: none;
			margin-bottom: 24px;
		}

		@media (min-width: 600px) and (max-width: 900px) {
			// border: 2px solid green;
		}

		@media (min-width: 1000px) and (max-width: 1200px) {
			// border: 2px solid yellow;
		}
	}

	@keyframes fadeIn {
		from { opacity: 0; }
		to { opacity: 1; }
	}

	@media (max-width: 508px) {
		.modal-container {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&.open {
			padding: 0px;
		}

		.modal-footer {
			padding-top: 23px;
			margin-bottom: 10px;
			border-top: 1px solid lightgrey !important;
		}
	}
	
`

export const TitlePage = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	justify-content: center;

	& .main-title {
		font-size: 24px;
		font-weight: bold;
		margin: 0;
	}

	& .sub-title{
		font-size: 16px;
	}

	@media (max-width: 992px) {
		margin: 0 3rem;
	}

	@media (max-width: 768px) {
		margin: 3rem 2rem 0 2rem;
	}
`

export const JoinGroupNewUseForm = styled.div`

`

export const TitlePageNewUser = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;

	& .main-title {
		font-size: 32px;
		font-weight: bold;
		margin: 0;
	}

	& .sub-title{
		font-size: 16px;
	}

	@media (max-width: 992px) {
		margin: 0 3rem;
	}

	@media (max-width: 768px) {
		margin: 3rem 2rem 0 2rem;
	}
`

export const ModalContainer = styled.div<{ isOpen: boolean, isCompany?: boolean }>`
display: ${(props) => (props.isOpen ? 'flex' : 'none')};
position: fixed;
z-index: 1000; /* Ensure the modal is on top of all other content */
left: 0;
top: 0;
width: 100%;
height: 100%;
overflow: auto;
background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
justify-content: center; /* Center the content horizontally */
align-items: center; /* Center the content vertically */

	@media (max-width: 991px) {
		align-items: stretch;
		webkit-align-items: stretch;
	}

	@media (min-width: 1000px) and (max-width: 1600px) {
	-webkit-align-items: stretch ;
	align-items: stretch ;
	}

	@media (min-width: 1600px) and (max-width: 1800px) {
	-webkit-align-items: ${(props) => (props.isCompany ? 'stretch' : 'center')} ;
	align-items: ${(props) => (props.isCompany ? 'stretch' : 'center')}  ;
	}
`

export const ModalContent = styled.div <{ isCompany?: boolean, isMobile?: boolean }>`
width: 564px;
height: auto ;
padding: 40px 40px 32px;
border-radius: 12px;
border: solid 1px #a18436;
background-color: #fff;
overflow: auto;


.field-container {
	height: 88px;
}

.two-column {
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.header {
	width: auto;
	height: 29px;
	margin: 16px 13px 32px 0;
	font-size: 24px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.21;
	letter-spacing: normal;
}

.sub-header {
	width: 248px;
	height: 29px;
	margin: 25px 13px 30px 0;
	font-size: 20px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.21;
	letter-spacing: normal;
	color: var(--purple-brown);
}

.custom-button {
	display: flex;
	justify-content: center;
	margin-top: ${(props) => (props.isCompany ? '0px' : (props.isMobile ? '0px' : '145px'))};
}

.custom-margin-top {
	margin-top: 145px;
}

.cancel-button {
	width: 143px;
	height: 49px;
	padding: 16px 42.5px;
	border-radius: 4px;
	background-color: #fff;
	border: 1px solid #73333f;
	font-weight: 600;
	line-height: 1.2;
	cursor: pointer;
}

.update-button {
	width: 143px;
	height: 49px;
	margin: 0 0 0 16px;
	padding: 16px 42.5px;
	border-radius: 4px !important;
	background-color: #73333f;
	color: #fff;
	border: none;
	line-height: 1.2;
}

.close-container {
	font-size: 35px;
	display: flex;
	justify-content: flex-end;
}

.close-icon {
	height: 24px;
	width: 24px;
	filter: invert(100%) grayscale(100%) brightness(0%) contrast(100%);
	cursor: pointer;
}

& input {
	height: 57px;
	font-size: 14px;
	padding: 18px 16px 21px;
	border-radius: 8px;
	border: solid 1px #a18436;
}

& select {
	height: 57px;
	font-size: 14px;
	padding: 18px 16px 21px;
	border-radius: 8px;
	border: solid 1px #a18436;

	&::placeholder {
	color: black;
	}
}

& textarea {
	height: 200px;
	font-size: 14px;
	padding: 18px 16px;
	border-radius: 8px;
	border: solid 1px #a18436;
	resize: none; /* Prevent resizing */
	&::placeholder {
	color: black;
	}
}
	.type-container {
		margin-top: 16px;
	}
	.type-container .select__input-container {
	margin: 0px
	}
	.type-container .select__value-container {
	padding: 0px 8px;
	}

	.multi-select-container .custom-selector__control {
			padding: 0.3rem 0;
	}

	.custom-selector__value-container {
	min-height: 50px !important;
	
		& input {
			height: auto !important;
		}
	}

	@media (max-width: 350px) {
		.cancel-button {
			font-size: 13px !important;
		}

		.update-button {
			font-size: 13px;
		}
	}

	@media (max-width: 560px) {
		padding: 40px 24px 24px 24px;
		border-radius: inherit;

		.close-container {
			margin-right: 0px;
		}

		.custom-buttom {
			border-top: 1px solid lightgrey;
		}

		.cancel-button {
			padding: 16px 25px;
			width: auto;
		}

		.update-button {
			padding: 16px 25px;
			width: auto;
		}
	}

`
export const FieldContainer = styled.div`
	display: flex;
	gap: 20px;
`

export const TabParentContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`

export const DropdownContainer = styled.div<{isError?: boolean}>`
	position: relative;
	width: 100%;

	& select {
		padding: 1.2rem 0.5rem;
		border-radius: 8px;
		border: ${({ isError }) => (isError ? `solid 2px ${RPColors.borderColorError}` : `solid 1px ${RPColors.borderColor}`)};

		::placeholder {
			color: black;
		}
	}

	& .multi-select {
		border: 1px solid ${RPColors.borderColor};
		border-radius: 8px;
	}

	& .css-13cymwt-control, .css-t3ipsp-control {
		border: transparent;
		padding: 0.5rem 0;
		border-radius: 8px;

		& .css-1dyz3mf {
			gap: 0.5rem;
		}
	}
`
export const CompanyUpdateContainer = styled.div<{isCompany?: boolean}>`
	display: flex;
    flex-direction: column;
    align-items: center;

	& .header {
		font-family: Libre Franklin;
		font-size: 24px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.17;
		letter-spacing: normal;
		text-align: center;
	}

	& .breadcrumbs {
		font-family: Libre Franklin;
		font-size: 12px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-decoration: underline;
		position: relative;
		top: 20px;
		left: -120px;
		cursor: pointer;
	}

	& .sub-header {
		font-family: Libre Franklin;
		font-size: 24px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.17;
		letter-spacing: normal;
		margin: 0 0 32px 0;
	}

	& .company-info-container {
		padding: 40px;
		border-radius: 12px;
		border: solid 1px #a18436;
		margin: 32px 0 0 0;
		width: 701px;
	}

	& .payment-receipt-info {
		font-family: Libre Franklin;
		font-size: 20px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.45;
		letter-spacing: normal;
		margin: 0 0 31px 0;
	}

	& .field-header {
		font-family: Libre Franklin;
		font-size: 20px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		margin: 25px 0 32px 0;
	}

	& .two-column {
		display: flex;
		gap: 21px;
	}

	& .custom-button {
		display: flex;
		align-items: center;
    	justify-content: center;
		gap: 16px;
		margin-top: ${(props) => (props.isCompany ? '15px' : '30px')};
	}

	& .cancel-button {
		width: 120px;
		height: 49px;
		padding: 13px 30px;
		border-radius: 4px;
		border: solid 1px #73333f;
		cursor: pointer;
	}

	& .update-button {
		width: 143px;
		height: 49px;
		border-radius: 4px !important;
		border: none;
		background-color: #73333f;
		color: white;
		cursor: pointer;
	}

	@media (max-width: 991px) {
		& .header {
			margin-top: 32px;
		}

		& .breadcrumbs {
			display: none;
		}
	}

	@media (max-width: 750px) {
		& .company-info-container {
			width: 100%;
			border-radius: 0px;
		}
	}

	@media (max-width: 630px) {
		& .two-column {
			display: flex;
			flex-direction: column;
			gap: 0px;
		}
	}
`

