/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import styled from 'styled-components'
// import { useDispatch } from 'react-redux'
// import { SetKeywords } from '../../../../../app/slices/SearchFilters'

const KeywordFilterContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: var(--white-three);
	margin-bottom: 1rem;
	padding: 15px 0;


	& i {
		font-size: 20px;
		padding-left: 10px;
	}

	& input {
		font-size: 17px;
		width: 554px;
		border:none;

		&:focus{
			outline: none;
			border: none;
		}
	}

	@media (max-width: 1000px){
		
		width: 100% !important;
		& input {
			width: 100%;
		}
	}
`

function KeywordFilter(props: { setKeywords: any, keywords: string, onEnter:(event: React.KeyboardEvent<HTMLInputElement>) => void }): ReactElement {

	// const dispatch = useDispatch()
	// const [keywords, setKeywords] = useState('')

	const updateKeywords = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.setKeywords(event.target.value)
		// dispatch(SetKeywords(event.target.value))
	}

	// useEffect(() => {
	// 	dispatch(SetKeywords(keywords))
	// }, [dispatch, keywords])

	return (
		<KeywordFilterContainer>
			<i className="mdicon mdicon-search mdicon--last"></i>
			<input onKeyUp={props.onEnter} onChange={updateKeywords} type="text" value={props.keywords}/>
		</KeywordFilterContainer>
	)
}

export default KeywordFilter