/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react'

// Page Contents
import ManageGroup from './manage-group/ManageGroup'
import ViewCompanyDetails from './ViewCompanyDetails'
import { useAppSelector } from '../../../../app/hooks'
import { Users as UsersFromStore } from '../../../../app/slices/Users'
import { isMobile } from 'react-device-detect'
import { dataCySelector } from '../../../../app/services/constant'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

type LocationState = {
    tabIndex?: number
}

import { PageTitle, TabbedMenuContainer, TabbedMenu, TabbedMenuItem, TabParentContainer, MobileTabMenu } from './styles'

const menuOptions = [
	{
		index: 0,
		label: 'Manage Group',
		component: <ManageGroup />
	},
	{
		index: 1,
		label: 'Company Details',
		component: <ViewCompanyDetails />
	},
]

function MyCompany(): ReactElement {

	const location = useLocation<LocationState>()
	const tabIndex = location.state?.tabIndex || 0
	const history = useHistory()
	const isAuthorizedUser = ['project:customer-commercial-manager', 'project:customer-commercial']

	const { userData } = useAppSelector(UsersFromStore)
	const [isDisabled, setIsDisabled] = useState(false)

	const [activeMenu, setActiveMenu] = useState(tabIndex ?? 0)
	const changeMenu = (index: number) => {
		setActiveMenu(index)
		history.replace('/my-company', {})
	}

	useEffect(() => {
		if (userData && !userData?.orbit_roles?.some((role: string) => isAuthorizedUser.includes(role))) {
			history.push('/')
		}
	}, [userData])

	useEffect(() => {
		setIsDisabled(true)
		if (userData) {
			setIsDisabled(false)
		}
	}, [userData])

	return (
		<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
			<div className="d-flex container" style={{ justifyContent: 'center' }}>
				<PageTitle isMobile={isMobile}>My Company</PageTitle>
			</div>
			<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
				<div className="container">
					<div>
						{isMobile ?
							<MobileTabMenu>
								<select id='menu' name='menu-selection' className='component-design' onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
									const selectedValue = parseInt(event.target.value, 10)
									setActiveMenu(selectedValue)
								}}
								{...dataCySelector('companymenu-select-field')}>
									{menuOptions.map((option, key: number) => (<option key={key} value={key}>{option.label}</option>))}
								</select>
							</MobileTabMenu>
							:
							<TabParentContainer>
								<TabbedMenuContainer className="mnmd-sub-col--left sidebar js-sticky-sidebar">
									<div className={'group'}>
										<TabbedMenu>
											{menuOptions.map((option, key: number) => (
												<TabbedMenuItem
													style={{
														pointerEvents: isDisabled && option.label === 'Edit Company Details' ? 'none' : 'auto',
														color: isDisabled && option.label === 'Company Details' ? 'grey' : '',
													}}
													key={key}
													isActive={activeMenu == key ? true : false}
													onClick={() => changeMenu(key)} {...dataCySelector(`companymenu-${key}`)}>
													{/* <div className={'bar'}></div> */}
													<div className={'menu'}>{option.label}</div>
												</TabbedMenuItem>
											))}
										</TabbedMenu>
									</div>

								</TabbedMenuContainer>
							</TabParentContainer>
						}
						<div className="" role="main">
							<article className="mnmd-block post post--single post-10 type-post status-publish format-standard has-post-thumbnail hentry category-abroad tag-landscape cat-5" itemScope itemType="https://schema.org/Article">
								{menuOptions[activeMenu].component}
							</article>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MyCompany