const businessTypeOptions = [
	{ value: 'Agent', label: 'Agent', },
	{ value: 'Auction House', label: 'Auction House' },
	{ value: 'Broker', label: 'Broker', },
	{ value: 'Distributor', label: 'Distributor', },
	{ value: 'Educator', label: 'Educator', },
	{ value: 'F&B', label: 'F&B' },
	{ value: 'Hotel', label: 'Hotel', },
	{ value: 'Hotel-Restaurant', label: 'Hotel-Restaurant' },
	{ value: 'Importer', label: 'Importer', },
	{ value: 'Negociant', label: 'Negociant', },
	{ value: 'Producer', label: 'Producer', },
	{ value: 'Restaurant', label: 'Restaurant', },
	{ value: 'Retailer', label: 'Retailer', },
	{ value: 'Vineyard', label: 'Vineyard', },
	{ value: 'Wholesaler', label: 'Wholesaler', },
	{ value: 'Wine School', label: 'Wine School' },
	{ value: 'Others', label: 'Others', }
]

export default businessTypeOptions