import React from 'react'

const SubscribeModal: React.FC = () => (
	<div className="modal fade subscribe-modal" id="subscribe-modal" tabIndex={-1} role="dialog" aria-labelledby="subscribe-modal-label">
		<div className="modal-dialog" role="document">
			<div className="modal-content">
				<div className="modal-header">
					<button type="button" className="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&#10005;</span>
					</button>
					<h4 className="modal-title" id="subscribe-modal-label">Subscribe</h4>
				</div>
				<div className="modal-body">
					<div className="subscribe-form subscribe-form--horizontal text-center max-width-sm">
						<p className="typescale-1">
							{'Join our 868,537 subscribers and get access to the latest tools, freebies, product announcements and much more!'}
						</p>
						<div className="subscribe-form__fields">
							<p>
								<input type="email" name="EMAIL" placeholder="Your email address" required />
								<input type="submit" value="Subscribe" className="btn btn-primary" />
							</p>
						</div>
						<small>{'Don\'t worry, we don\'t spam'}</small>
					</div>
				</div>
			</div>
		</div>
	</div>	
)

export default SubscribeModal