import React from 'react'
import { validCards } from '../../gift-subscription/gift-sub/components/constants'
import { isMobile } from 'react-device-detect'

const CreditCards = (): JSX.Element => {
	return (
		<div className={isMobile ? 'cc-cards-subscription-mobile' : 'cc-cards-subscription'}>
			{validCards.map((card, index) => {
				if (card.name === 'new') return <div key={index} />
				return <img key={index} alt={card.name} src={card.imgUrl} />
			})}
		</div>
	)
}

export default CreditCards