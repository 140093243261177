import styled from 'styled-components'
export const LetterLabel = styled.div``

const commonFontAttributes = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
`

export const PageTitle = styled.div`
	width: 350px;
	margin: 0 0 40px 0;
	font-size: 40px;
	font-weight: bold;
	color: #333;
	${commonFontAttributes}
`

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
`

export const MainContainer = styled.div`
	margin-left: auto;
	margin-right: auto;
	padding: 0 2rem;
	width: 1170px;

	@media (max-width: 1200px) {
		& {
			width: 970px;
		}
	}

	@media (max-width: 1000px) {
		& {
			width: 750px;
		}
	}

	@media (max-width: 768px) {
		& {
			width: 100%;
		}
	}
`

export const Container = styled.div`
	display: flex;

	& ul {
		display: block;
		list-style-type: disc;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 40px;
		width: 300px;
		padding: 0;
	}

	& li {
		float: left;
		list-style-type: none;
		margin-right: 8px;
		padding: 5px;
		font-weight: normal;
		color: #A08436;
		line-height: 1.2;
		font-size: 16px;
	}
	
	& li:hover {
		float: left;
		list-style-type: none;
		margin-right: 8px;
		padding: 5px;
		font-weight: bold;
		color: #A08436;
		text-decoration: underline;
		line-height: 1.2;
		font-size: 16px;
		cursor: pointer;
	} 

	& b {
		float: left;
		list-style-type: none;
		margin-right: 8px;
		padding: 5px;
		font-weight: normal;
		color: #333;
		line-height: 1.2;
		font-size: 16px;
		text-decoration: none;
		cursor: default;
	}

	& div.sticky-sidebar {
		position: fixed;
		top: 0;
		width: 100%;
		padding-top: 70px;
	}

	& div.padding-left {
		padding-left: 310px;
	}

	& hr {
		display: none;
	}

	@media (max-width: 1000px) {
		& {
			flex-direction: column;
		}
		& ul {
			width: 100%;
		}

		& hr {
			display: block;
		}

		& div.sticky-sidebar {
			width: 90%;
			background-color: white;
			padding-bottom: 1rem;
			padding-top: 3rem;
		}

		& div.padding-left {
			padding-left: 0px;
		}
	}

	@media (max-width: 350px) {
		& div.sticky-sidebar {
			width: 83%;
		}
	}
`

export const DetailContainer = styled.div<{ size: string }>`
	width: 100%;

	& div.header {
		margin: 0 0 20px;
		font-size:  ${(props) => props.size};
		font-weight: bold;
		color: #333;
		${commonFontAttributes}
	}

	& p {
		margin-bottom: 5px;
	}
`

export const Separator = styled.div`
	height: 1px;
	margin: 40px 0 35px 0;
	background-color: #ddd;
	width: 804px;
`

export const FormProfilePicContainer = styled.div<{ size: string }>`
	display: flex;
	justify-content: left;
	align-items: center;

	& div.image-container {
		width:  ${(props) => props.size};
		height: 200px;
		margin: 0 20px 40px 0;
		padding: 85px 88px 85px 89px;
		background-color: #9a9a9b;
	}

	& div.text-value {
		font-size: 10px;
	}

	& div.plus-icon {
		font-size: 40px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: white;
		}
`

export const FormFieldContianer = styled.div`
	margin-bottom: 20px;
	& div.form-label {
		font-size: 14px;
		font-weight: bold;
		color: #333;
		margin-bottom: 5px;
	}

	& div.form-label-required {
		font-size: 14px;
		font-weight: bold;
		color: #333;
		margin-bottom: 5px;

		&:after {
			content:" *";
			color: red;
		  }
	}

	& div.form-value {
		margin-bottom: 5px;
		max-width: 468px;
		font-weight: normal;
		line-height: 1.2;
		color: #333;
		${commonFontAttributes}
	}
`

export const UploadButton = styled.div`
	height: 33px;
	margin: 0 0 10px;
	padding: 6px 14.5px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: white;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
	text-align: center;
	${commonFontAttributes}
`

export const SaveButton = styled.div`
	height: 33px;
	cursor: pointer;
	margin: 0 0 10px;
	padding: 6px 14.5px;
	border-radius: 8px;
	background-color: #e01a00;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	color: white;
	
`

export const SelectContainer = styled.div`
	display: flex;
	flex-direction: row;
	& a {
		margin-top: 8px;
	}

	& select {
		width: 468px;
	}
`

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
`

export const ManageGroupContainers = styled.div<{ size: string }>`
	width: auto;
	& div.header {
		font-size: ${(props) => props.size};
		font-weight: bold;
	}

	& div.value {
		font-size: ${(props) => props.size};
		font-weight: normal;
		margin-bottom: 20px;
	}
	font-family: Libre Franklin;
	margin-top: 10px;
	color: #333;
	${commonFontAttributes}

	& input {
		width: 16px;
		height: 16px;
		margin: 3px 5px 0 0;
		border-radius: 8px;
	}
	
`

export const PlanContainerButton = styled.button`{
	height: 33px;
	padding: 6px 11.5px;
	border-radius: 8px;
	background-color: #e01a00;
	color: #fff;
	cursor: pointer;
	font-size: 14px;
	border: none;
	float: right
} `

export const RemoveSelectedButton = styled.button`{
	width: 140px;
	height: 33px;
	padding: 6px 11.5px;
	border-radius: 0px;
	background-color: gray;
	color: #fff;
	font-size: 14px;
	border: none;
	float: right;
} ;`

export const RemoveButton = styled.div`{
	height: 33px;
	width: 80px;
	padding: 6px 15px;
	border-radius: 8px;
	background-color: #e01a00;
	color: #fff;
	cursor: pointer;
	margin-left: 22px;
} ;`

export const DataContent = styled.div`
	width: 100%;
	font-size: 16px;
	font-weight: normal;
	color: #333;

	${commonFontAttributes}
	${LetterLabel} {
		font-weight: bold;
		padding-bottom: 10px;
	}
	& p {
		width: 100%;
		font-family: Libre Franklin;
		font-size: 14px;
		color: #777;
		padding-bottom: 20px;
	}
`

export const Paging = styled.div`
	width: 34px;
	height: 34px;
	margin: 40px 220px 80px 390px;
	padding: 7px 12.5px 6px;
	background-color: #e01a00;
`

export const SearchBar = styled.div`
	display: flex;
	justify-content: start;
	width: 310px;
	height: 33px;
	border-radius: 8px;
	border: 1px solid #a08436;
	background-color: var(--white-three);

	& div {
		margin: auto;
		padding: 5px 5px 5px 10px;
	}

	& input {
		border: none;
		-moz-box-shadow: none;
		-goog-ms-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		background-color:transparent
	}

	& input:focus {
		outline: none;
		border: none;
		-moz-box-shadow: none;
		-goog-ms-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	@media (max-width: 1000px) {
		&{
			width: 100%;
		}
	}
`

export const PageSubTittle = styled.div`
	width: 262px;
	height: 24px;
	margin: 0 26px 20px 0;
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
	padding: 30px 0 15px 0;
`

export const VerticalSeperator = styled.div`
	width: 1px;
	height: auto;
	margin: 0 20px 0 40px;
	background-color: #e5e5e5;

	@media (max-width: 1000px) {
        display: none;
    }
`

export const DisplayView = styled.div`
	display: flex;
`