/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react'
import LoginForm from '../../../modals/login/LoginForm'
import FreeTrialForm from '../../../modals/login/FreeTrialForm'
import ForgotPasswordForm from '../../../modals/login/ForgotPasswordForm'

import { LoginContainer } from './styles'

type Props = {
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

function LoginForms({setIsOpen}: Props): ReactElement {

	const [errors, setErrors] = useState<any>(null)
	const [isFreeTrialShow, setIsFreeTrialShow] = useState(false)
	const [isLoginShow, setIsLoginShow] = useState(true)
	const [isForgotPasswordShow, setIsForgotPasswordShow] = useState(true)
	
	const toShow = (type: string) => {
		switch (type) {
		case 'trial':
			setIsFreeTrialShow(true)
			setIsLoginShow(false)
			setIsForgotPasswordShow(true)
			break
		case 'forgot':
			setIsFreeTrialShow(false)
			setIsForgotPasswordShow(false)
			break
		default:
			setIsFreeTrialShow(false)
			setIsLoginShow(true)
			setIsForgotPasswordShow(true)
			break
		}
	}

	useEffect(() => {
		if(!isForgotPasswordShow){
			setIsLoginShow(false)
		}
	},[isForgotPasswordShow])
	
	const handleClose = () => {
		setIsOpen(false)
	}

	return <LoginContainer>
		<div className='login-info-container'>
			<div className='header'>
				<div className='login-tab'>
					<a onClick={() => toShow('login')} id='loginAnchor'>LOG IN</a>
					<a onClick={() => toShow('trial')} id='trialAnchor'>SIGN UP</a>
				</div>
				<span className='modal-close' onClick={()=> setIsOpen(false)}><img src="img/icons/close.png" /></span>
			</div>
			<div className="tab-content" style={{ width: 380 }}>
				<LoginForm isShown={isLoginShow} setIsShown={setIsForgotPasswordShow} closeWindow={handleClose} handleError={(err: any) => setErrors(err)}/>
				<FreeTrialForm closeWindow={handleClose} isShown={isFreeTrialShow}/>
				<ForgotPasswordForm closeWindow={handleClose} isShown={isForgotPasswordShow}/>
			</div>
		</div>
		{!errors ? '' : errors !== 'User not found' || errors !== 'Invalid Password' ?
			<div className='modal-error'>
				<span>{errors}</span>
			</div> : ''}
	</LoginContainer>
}

export default LoginForms
