import styled from 'styled-components'
export {
	Separator,
	Description,
	Name,
	AuthorDetailContainer,
	PictureContainer,
	PageTitle,
	ArticleByCons,
	ProfileContainer,
	DefaultPicture,
}

const PageTitle = styled.div`
	width: 1170px;
	height: 60px;
	margin: 20px 134px 40px 0px;
	font-family: Libre Franklin;
	font-size: 40px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
`
const AuthorDetailContainer = styled.div`
	padding: 0px;
	background: none; 
	height: 120px; 
	width: 120px; 
	border: none;
`
const ProfileContainer = styled.div`
	display: flex;
	flex-direction: row;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`
const PictureContainer = styled.div`
	width: 120px;
	height: 120px;
	margin: 0 20px 1px 0;
	border: solid 4px var(--white-three);
`
const DefaultPicture = styled.div`
	width: 120px;
	height: 120px;
	margin: 0 20px 1px 0;
	border: solid 4px var(--white-three);
	border-style: dotted;
`

const Name = styled.div`
	width: 1030px;
	height: 24px;
	margin: 0 0 10px 20px;
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
`

const Description = styled.div`
	margin: 10px 0 31px 20px;
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #777;
`
const Separator = styled.div`
	width: 1170px;
	height: 1px;
	margin: 19px 0 35px;
	background-color: #ccc;
`
const ArticleByCons = styled.div`
	width: 1030px;
	height: 21px;
	margin: 15px 0 19px 20px;
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #e01a00;
`
