
import React, { ReactElement } from 'react'
import { SUSTAINABILITY_PAGES } from '../../../../../configs'

import { useHistory } from 'react-router-dom'

// Styles
import { ContentSection, ControlButtons, LogoContainer, BlueButton, ParagraphContainer, SectionHeader, CertificationBannerTitle, CertificationContainer } from './styles'
import { useDispatch } from 'react-redux'
import { SearchFilters as SearchFiltersFromStore, SetFacetsFilters } from '../../../../../app/slices/SearchFilters'
import { useAppSelector } from '../../../../../app/hooks'
import { imageConvertion } from '../../../../../utils/CloudImageUtils'

function CertificationPage(): ReactElement {
	const history = useHistory()
	const dispatch = useDispatch()

	const {ActiveFilters: activeFilters} = useAppSelector(SearchFiltersFromStore)

	const searchHandler = () => {
		dispatch(SetFacetsFilters({
			activeFilters: {
				...activeFilters,
				certified: ['certified:organic', 'certified:biodynamic']
			}
		}))
		history.push('/search/wine')
	}

	return(
		<>
			<div className="mnmd-block mnmd-block--fullwidth featured-with-overlap-posts mnmd-block--contiguous">
				<article className="main-post post cat-1">
					<div className="main-post__inner">
						<div className="background-img" style={{ backgroundImage: `url('${ imageConvertion(SUSTAINABILITY_PAGES.GREEN_EMBLEM_BACKGROUND)}')` }}></div>
						<CertificationBannerTitle className="post__text inverse-text">
							<div className="container">
								<div className="post__text-inner text-center">
									<h3 className="post__title typescale-5">Certified Sustainability</h3>
								</div>
							</div>
						</CertificationBannerTitle>
					</div>
				</article>
			</div>
			<div className="single-entry container">
				<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
					<CertificationContainer className="container">
						<div className="row">
							<LogoContainer>
								<img src={ SUSTAINABILITY_PAGES.CERTIFIED_ICON } height={ 230 } width={ 230 } />
							</LogoContainer>
							<ParagraphContainer fontSize={ '24px' } marginBottom={ '50px' } >
								Displaying this symbol next to a wine means the wine has been certified as <em>organic</em> or <em>biodynamic</em> by one of the internationally recognized third-party organizations.
							</ParagraphContainer>
						</div>
						
						<div className="row certificate-content" >
							<ContentSection className='right'>
								<div className={'content' } >
									<SectionHeader>
										WHAT IS BIODYNAMIC?
									</SectionHeader>
									<span>Definition</span>
									<p>First established by Rudolf Steiner in 1924, biodynamics is a set of farming practices that follow more philosophically based guidelines than those of conventional farming. It essentially uses a foundation of organic farming methods, such as the exclusion of artificial chemicals, and builds upon these. For example, specially crafted composts and herbal infusions are applied with rigor to vineyards. The movements and phases of celestial bodies are commonly used to determine the timing of key winemaking practices. Proponents swear by the methods not only as a means of producing superior wine, a moot point, but for sustainable farming, a more provable rationale.</p>
									<span>Certification</span>
									<p>Demeter is perhaps the largest and best known of the international certifying organizations, and they certify a broad range of agriculture products. Biodyvin is a France-based, lesser-known organization that specializes purely in wine production.</p>
								</div>
								<div className={ 'illustration' }>
									<img src={ imageConvertion(SUSTAINABILITY_PAGES.BIODYNAMIC_ILLUSTRATION) } />
								</div>
							</ContentSection>
							<ContentSection className='section'>
								<div className={ 'illustration' }>
									<img src={ imageConvertion(SUSTAINABILITY_PAGES.ORGANIC_ILLUSTRATION) } />
								</div>
								<div className={'content' } >
									<SectionHeader>
										WHAT IS ORGANIC
									</SectionHeader>
									<span>Wines from “organically grown grapes”</span>
									<p>Generally speaking, organic grape growing precludes the use of artificial chemicals such as chemical fertilizers, herbicides and synthetic pesticides in the vineyard. Most countries allow naturally occurring compounds to be applied within stricter limits than conventional agriculture, such as the limited use of sulfur and copper spraying in vineyards for the production of such wines.</p>

									<span>Organic wine</span>
									<p>Winemaking processes as well as viticulture are usually included. In most countries, organic winemaking bans the use of chemical additives, strictly controlling or banning the use of even the most ubiquitously used antioxidant/antimicrobial additive: sulfur dioxide (sulfites). Because it can be very difficult to produce a stable wine of quality without sulfites, organic wines are not very common.</p>

									<span>Certification</span>
									<p>Organic viticulture involves a regulated set of farming principles, with the degree of strictness versus conventional farming depending on the countries in which the grapes are produced and where the wine is sold.</p>

									<p>Throughout the world where the use of the word “organic” is legally controlled and protected, regulations can vary. In order to declare grapes as organically grown, the producer will need to seek certification by a recognized third-party auditing institution within the country of production. Usually, a vineyard will need to go through a three-year conversion period of documented and randomly tested practices that adhere to the national legal guidelines before a certification of “organically grown” can be granted.</p>

									<p>Well-known organic certifying organizations include Ecocert, USDA Organic, BioGro NZ, Australian Certified Organic and EU Organic.</p>
								</div>
							</ContentSection>
						</div>
						<div className="row">
							<ControlButtons>
								<BlueButton onClick={searchHandler}>SEARCH FOR CERTIFIED WINES</BlueButton>
							</ControlButtons>
						</div>
					</CertificationContainer>
				</div>
			</div>
		</>
	)
}

export default CertificationPage