/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, ReactElement, SetStateAction, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { WineContainer } from './styles'
import { useReactToPrint } from 'react-to-print'
import PrintNotes from '../../views/content/pages/search-results/AlternateWineResults/PrintNotes'
import { useDispatch, useSelector } from 'react-redux'
import {ClearSelectedWines, RemoveSelectedWineById, SearchResults as SelectedWines} from '../../app/slices/SearchResults'
import moment from 'moment'
import {Users as UsersFromStore} from '../../app/slices/Users'
import { useAppSelector } from '../../app/hooks'
import CommonModalContainer from '../../views/modals/ModalContainer'
import { isMobile } from 'react-device-detect'

type WineSelectedType = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
	setCheckValue: Dispatch<SetStateAction<boolean>>;
}
function WineListModal({setShowModal, setCheckValue}: WineSelectedType): ReactElement {
	const selectedWinesData = useSelector(SelectedWines)
	const { userData } = useAppSelector(UsersFromStore)
	const [selectedWines, setSelectedWines] = useState<any[]>([])
	const [printType, setPrintType] = useState<string>('1x1')
	const [isSelectedAll, setIsSelectedAll] = useState<boolean>(true)
	const componentRef = useRef<null | HTMLDivElement>(null)
	const total_results = selectedWines?.length
	const dateToday = new Date()
	const [isPrinting, setIsPrinting] = useState(false)
	const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

	const dispatch = useDispatch()

	useEffect(() => {
		if(selectedWinesData){
			setSelectedWines(selectedWinesData.Wines.selectedWines)

			if (selectedWinesData.Wines.selectedWines.length === 0) {
				setShowModal(false)
			}
		}
	},[selectedWinesData])


	const handleClose = () => {
		setShowModal(false)
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: 'Robert Parker - ' + (printType === '1x1' ? 'Print Note' : printType) + '_' + moment(dateToday).format('YYYYMMDD')
	})

	const deleteItemById = (wines: string[], wineId: string) => {
		const indexToDelete = wines.findIndex((item: any) => item.id === wineId)

		dispatch(RemoveSelectedWineById(wineId))

		if (indexToDelete !== -1) {
			const updatedWines = [...wines]
			updatedWines.splice(indexToDelete, 1)
			setSelectedWines(updatedWines)
			return updatedWines
		}
		
		return wines
	}

	const wineListHandlePrint = (type: string) => {
		setPrintType(type)

		if (type === 'shopping-list'){
			setPrintType('Shopping List')
		} else {
			setPrintType(type)
		}
		setIsPrinting(true)	
	}

	useEffect(() => {
		if (isPrinting) {
			handlePrint()
			setIsPrinting(false)
		}
	}, [isPrinting])

	const toggleCheckedItem = (itemId: string) => {
		const updatedSelectedWines = selectedWines.map((wine: any) => {
			if (wine.id === itemId) {
				return { ...wine, checked: !wine.checked }
			}
			return wine
		})
		setSelectedWines(updatedSelectedWines)
	}

	const deselectAll = () => {
		const updatedSelectedWines = selectedWines.map((wine: any) => ({
			...wine,
			checked: false,
		}))
		setSelectedWines(updatedSelectedWines)
		setIsSelectedAll(false)
	}

	const selectAll = () => {
		const updatedSelectedWines = selectedWines.map((wine: any) => ({
			...wine,
			checked: true,
		}))
		setSelectedWines(updatedSelectedWines)
		setIsSelectedAll(true)
	}

	const getUncheckedItemCount = () => {
		return selectedWines.filter((wine: any) => wine.checked)?.length
	}

	const renderColorClass = (color_class: string) => {
		switch (color_class) {
		case 'White':
			return 'img/icons/white.svg'
		case 'Red':
			return 'img/icons/red.svg'
		case 'Rosé':
			return 'img/icons/rose.svg'
		default:
			return 'img/icons/white.svg'
		}
	}

	const renderCertifiedIcon = (isCertified: any[]) => {
		const certificationsString = 
			isCertified
				.reduce((acc: string | number, current: string, index: number) => {
					return acc + (index > 0 ? ', ' : '') + current
				}, '')

		switch (certificationsString) {
		case 'Biodynamic':
			return <img style={{ height: 19 }} src='img/icons/elements-icons-certified-2x.png' />
		case 'Organic':
			return <img style={{ height: 19 }} src='img/icons/green-emblem.png' />
		case 'Biodynamic, Organic': 
			return (
				<>
					<img style={{ height: 19 }} src='img/icons/elements-icons-certified-2x.png' />
					<img style={{ height: 19, marginLeft: 20 }} src='img/icons/green-emblem.png' />
				</>
			)
		}
	}

	useEffect(() => {
		if(selectedWines.filter((wine: any) => wine.checked)?.length === 0){
			setIsSelectedAll(false)
		} else {
			setIsSelectedAll(true)
		}
	},[selectedWines])

	const clearWines = () => {
		dispatch(ClearSelectedWines())
		setCheckValue(false)
	}

	const ConfirmationModal = ():ReactElement => {
		return <div className="modal-content" style={{ width: isMobile ?  'auto' : '35rem' }}>
			<div className="modal-header d-flex">
				<div className='d-flex'>
					<p className='mb-0 fs-5'>REMOVE ALL FROM CURRENT LIST</p>
				</div>
				<button type="button" className="close" onClick={() => setIsConfirmationOpen(false)}><span style={{ color: '#cccccc' }} aria-hidden="true">&#10005;</span></button>
			</div>
			<div className="modal-body">
				<div>
					<p style={{ paddingLeft: '1rem', minWidth: '22rem' }}>Are you sure you want to clear your current wine selections?</p>
				</div>
			</div>
			<div className='modal-footer'>
				<div className='d-flex flex-row' style={{ gap: '1rem' }}>
					<button onClick={() => setIsConfirmationOpen(false)} className='btn '>BACK</button>
					<button onClick={clearWines} className='btn btn-primary'>PROCEED</button>
				</div>
			</div>
		</div>
		
	}

	return (
		<WineContainer>
			<div className='backdrop-transition'>
				<div>
					<div className="modal-dialog modal-dialog-winelist" role="document">
						<div className="modal-content modal-content-winelist">
							<div className="modal-header d-flex">
								<div className='d-flex'>
									<p className='mb-0'>{`${getUncheckedItemCount()} of ${total_results} results`}</p>
								</div>
								<button type="button" className="close" onClick={handleClose}><span style={{ color: '#cccccc' }} aria-hidden="true">&#10005;</span></button>
							</div>
							<div className="modal-body xc">
								<div className="subscribe-form subscribe-form--horizontal text-left max-width-sm">
									<div className='d-flex flex-row ml-4' style={{ gap: '1rem' }}>
										<div className='mb-2'><button disabled={!isSelectedAll} onClick={() => { wineListHandlePrint('1x1') }} className='btn winelist-name'>PRINT SELECTED</button></div>
										{(userData?.subscription?.planId === 'commercial-1-year' || userData?.orbit_roles.includes('project:customer-commercial')) && 
										<>
											<div className='mb-2'><button disabled={!isSelectedAll} onClick={() => { wineListHandlePrint('2x2') }} className='btn winelist-name'>2 x 2 FORMAT</button></div>
											<div className='mb-2'><button disabled={!isSelectedAll} onClick={() => { wineListHandlePrint('3x1') }} className='btn winelist-name'>3 x 1 FORMAT</button></div>
											<div className='mb-2'><button disabled={!isSelectedAll} onClick={() => { wineListHandlePrint('shopping-list') }} className='btn winelist-name'>SHOPPING LIST</button></div>
										</>}
										<div className='d-flex align-items-center justify-content-between container  px-0'>
											<div className='d-flex align-items-center'>
												<input type='checkbox' checked={isSelectedAll} onChange={() => isSelectedAll ? deselectAll() : selectAll()} />
												<p className='mb-0 ml-2 winelist-name'>{`${isSelectedAll ? 'Deselect all' : 'Select all'} (${getUncheckedItemCount()})`}</p>
											</div>
											<button onClick={() => setIsConfirmationOpen(true)} className='btn btn-primary'>REMOVE ALL</button>
										</div>
									</div>
									{selectedWines?.map((selectedWine: any, index: number) => {
										const { wine_details: itemData, id: wineId } = selectedWine
										const authorId = itemData?.latest_note?.reviewer._id
										const content = itemData?.latest_note?.content
										const {
											display_name: wineName,
											rating_display: rating,
											producer,
											drink_date_low: year_low,
											drink_date_high: year_high,
											region,
											reviewers,
											varieties,
											slug: wineSlug,
											certified: isCertified,
											color_class: color_class,
										}  = itemData || {}
										return (
											<div key={index} className='winelist-wine-info-container mt-3'>
												<div className='df-ac-20px'>
													<div className='d-flex'>
														<input type='checkbox' checked={selectedWine?.checked} id={selectedWine.id} onChange={(e) => toggleCheckedItem(e.target.id)} />
														<p className='winelist-name mb-0 ml-2 truncate-name'>{`${wineName}`}</p>
													</div>
													<div>
														{isCertified !== null && renderCertifiedIcon(isCertified)}
														<img src={renderColorClass(color_class)} style={{marginLeft: isCertified?.length === 1 ? 10 : 20}} />
														<Link to={`${'/wines'}/${wineId}/${wineSlug}`} style={{marginLeft: 10}} className='mb-0 winelist-viewdetails'>View details</Link>
													</div>
												</div>
												<hr className='mp' />
												<div className='p-20px'>
													<div className='d-flex justify-content-between'>
														<div>
															<p className='winelist-name'>Rating:</p><p>{rating !== null ? rating : 'N/A'}</p>
															<p className='winelist-name'>Producer:</p><p>{producer?.length ? producer : 'N/A'}</p>
														</div>
														<div>
															<p className='winelist-name'>Drink Date:</p>
															<p>{(year_low || year_high) !== null ? `${year_low} - ${year_high}` : 'N/A - N/A'}</p>
															<p className='winelist-name'>Wine Region:</p>
															<div className='d-flex'>
																<p>{region?.length > 0 ? region : 'N/A'}</p>
															</div>
														</div>
														<div>
															<p className='winelist-name'>Reviewed by:</p>
															<div className='d-flex'>
																<a href={`/author/${authorId}`} className='winelist-author'>{reviewers?.length > 0 ? reviewers?.join(', ') : 'N/A'}</a>
															</div>
															<p className='winelist-name'>Variety:</p>
															<div className='d-flex'>
																<p>{varieties?.length > 0 ? varieties?.join(', ') : 'N/A'}</p>
															</div>
														</div>
														<div />
													</div>
													<hr className='mp' />
													<p className='my-3'>{content}</p>
													<button onClick={() => deleteItemById(selectedWines, wineId)} className='select-button winelist-name remove-from-list'>REMOVE FROM LIST</button>
												</div>
											</div>
										)
									})
									}
									<div className='print-hide'>
										<PrintNotes type={printType} wineData={selectedWines} refs={componentRef} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<CommonModalContainer isOpen={isConfirmationOpen} onClose={()=> setIsConfirmationOpen(false)}>
				<ConfirmationModal />
			</CommonModalContainer>
		</WineContainer>
	)
}

export default WineListModal

WineListModal.defaultProps = {
	title: 'Wine List',
}