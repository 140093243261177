import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// i18n
import englishTranslation from './translations/en.json'
import { TranslationsUtil } from '../../utils'
const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

// Services
import { useGetArticleTagsQuery } from '../../app/services'
import { slugify, truncate } from '../../utils/ArticlesUtil'
import { SetActiveFilters } from '../../app/slices/SearchFilters'
import { CloudImageUtils } from '../../utils/CloudImageUtils'

function TaggedPreviewV2(props: { article: Articlev2 }): React.ReactElement {
	const { article } = props
	const translate = translationUtil.getTranslator()

	const dispatch = useDispatch()

	const [articleTags, setArticleTags] = useState([''])
	const {data: articleTagsQueryResult, isError} = useGetArticleTagsQuery(`article_id=${article._id}`, { skip: article._id ? false : true })

	useEffect(() => {
		if (articleTagsQueryResult) {
			if(articleTagsQueryResult.success){
				setArticleTags(articleTagsQueryResult.data.data)
			}
		}

		if(isError){
			console.log('Something went wrong. Please try again.')
		}
	}, [articleTagsQueryResult, isError])

	return (
		<article className="post post--vertical cat-1">
			<div className="post__thumb">
				<Link to={`/articles/${article._id}/${slugify(article.title)}`}>
					<CloudImageUtils alt='image' params='func=cropFit' ratio={3.5} imgSrc={article.banner?.url} />
				</Link>
				<Link to={`/search/wine?keyword=${articleTags[0]}`} className="post__cat post__cat--bg cat-theme-bg overlay-item--top-left">{articleTags[0]}</Link>
			</div>
			<div className="post__text">
				<h3 className="post__title typescale-2">
					<Link to={`/articles/${article._id}/${slugify(article.title)}`}>{truncate(article.title, 100)}</Link>
				</h3>
				<div className="post__excerpt">
					{article.excerpt}
				</div>
				<div className="post__meta">
					<span className="entry-author">{translate('By')}&nbsp;
						<Link to={`/author/${article?.author?.id}`} className="entry-author__name">{article?.author?.name}</Link>
					</span>
					<span className='time published'><i className="mdicon mdicon-schedule"></i> {moment(article.published_date).format('MMM DD, YYYY')}</span>
					<Link onClick={()=> dispatch(SetActiveFilters({}))} to={`/search/wine?article_id=${article._id}`} title={ `${translate(article.title)}` }><img src='/img/icons/black.png' />&nbsp;{ article.tasting_note_count } Tasting Notes</Link>
				</div>
			</div>
		</article>
	)
}


export default TaggedPreviewV2