import styled from 'styled-components'
import { RPColors } from '../../../../styles/CSSConstant'

const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
`

export const GreenEmblemIndicator = styled.div`
	display: flex;
	justify-content: space-between;
	& div.logo {
		margin-right: 10px;
	}

	& div.label {
		height: 40px;
		width: 260px;
		background-color: #87D37C;
		color: #fff;
		padding: 15px 0px;
		font-size: 10px;
		text-align: center;
		font-weight: bold;
		${commonTextStyle}
	}
`
export const GreenEmblemIndicatorMobile = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	& div.logo {
		margin-right: 10px;
	}

	& div.label {
		height: 40px;
		width: 100%;
		background-color: #87D37C;
		color: #fff;
		padding: 15px 0px;
		font-size: 10px;
		text-align: center;
		font-weight: bold;
		${commonTextStyle}
	}
`

export const MaturityAndDrinkDates = styled.div`
	margin: 20px 0px 0px 0px;

	& em {
		font-weight: bold;
	}

	& p {
		margin-top: 5px;
	}
`

export const ProducerName = styled.div`
	font-family: Libre Franklin;
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	margin-top -10px;

	& > a {
		color: #333;
	}
`

export const RatingContainer = styled.div`
	width: auto;
	height: 55px;
	display: flex;
	allign-items: center;
	justify-content: center;

	& div {
		width: 21px;
		height: 21px;
		margin: 4px;
	}

	& span.top {
		font-size: 24px !important;
	}
	
	& span {
		${commonTextStyle}
		font-weight: bold;
	}

	& span.top {
		font-weight: normal;
	}

	& span.rating {
		font-size: 24px !important;
		font-weight: bold;
	}

	& span.rate {
		font-size: 24px !important;
		font-weight: 400;
	}
`

export const RatingContainerMobile = styled.div`
	width: 100%;
	text-align: start;
	height: 55px;
	padding: 9px 0px;
	font-size: 24px;
	display: flex;

	& div {
		width: 21px;
		height: 21px;
		margin: 4px;
	}

	& span {
		${commonTextStyle}
		font-weight: bold;
		text-align: center;
	}

	& span.top {
		font-weight: normal;
	}

	& span.rating {
		font-weight: normal;
	}

`

export const WineAttributes = styled.div`
	margin-top: -20px;

	& div {
		& div.attribute-header {
			margin-top: 20px;
			font-family: Libre Franklin;
			font-size: 14px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			color: #333;
			margin-bottom: 10px;
		}
	}

	& div.certified {
		display: flex !important;

		& img {
			height: 20px;
			width: 24px;
			padding-right: 5px;
		}
	}
	
	& div.importer-contianer {
		margin-bottom: 10px;
	}
`

export const NoWinesFound = styled.div<{ isMobile: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(props) => (props.isMobile ? '#311820' : '')};
	font-size: ${(props) => (props.isMobile && '22px')};
	height: 100px;
	font-weight: 500;
`

export const ClearFiltersButton = styled.div<{ isMobile: boolean, onClick: any}>`
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(props) => (props.isMobile && RPColors.borderColor)};
	font-size: ${(props) => (props.isMobile && '20px')};
	font-style: italic;
	text-decoration: underline;
`

export const CustomButton = styled.button<{ isMobile: boolean}>`
	height: 28px;
	width: ${(props) => (props.isMobile ? '100%' : '')};
	margin: ${(props) => (props.isMobile ? '' : '20px 10px 20px 0')};
	padding:  ${(props) => (props.isMobile ? '' : '3.5px 14px')};
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: var(--white-three);
	font-weight: bold;
}

	&:hover {
		background-color: #eeeeee !important;
	}
`

export const WineImporterInfoComponent = styled.div`
	margin-top:1rem;
	
	& p, a{
		margin: 0.2rem 0;
		font-size: 14px;
	}
`