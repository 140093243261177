import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import {
	Label, LabelContainer, TextStyle1,
	TextStyle3, TextStyle4, Title
} from './common'

function CommercialExample(): ReactElement {

	return (
		<div role="main">
			<Title>USAGE GUIDELINES</Title>
			<LabelContainer>
				These Usage Guidelines set out how the Content and our trademarks may be used on your websites, applications,
				physical shops and/or other marketing material subject to the term set out above. The Content may only be used by you in the following forms:
			</LabelContainer>

			<LabelContainer>
				<Label><TextStyle1>A. PERMITTED LOGOS</TextStyle1></Label>
				<Label>1. You may use:</Label>
				<Label>(a) Our latest score logo; or</Label>
				<Label>(b) brand logo design in connection with the scores posting</Label>
				<Label>on your Platform. You may download images of these <Link to={' '}><TextStyle3>here</TextStyle3></Link>. These visuals are not to be altered for the purpose of print and digital reproduction.</Label>
			</LabelContainer>

			<LabelContainer>
				<Label><TextStyle1>B. REFERENCE TO ROBERT PARKER WINE ADVOCATE</TextStyle1></Label>
				<Label>1. If you intend to make a specific reference to our editorial content, using our articles, wine reviews, scores and tasting notes,
					or a curated selection of wines that is presented in association with our brand name or visuals (including our logos),
					you shall contact us at commercial@robertparker.com to request our written prior approval.</Label>

				<Label>2. If you provide a specific or curated selection of wines using our scores (such as a Wine Advocate score filter),
					which is presented with reference to our brand name but not with our logo or visuals,
					you shall take all necessary steps to ensure that such a selection is not promoted or presented as a collaboration with us or as a curation produced by us.</Label>
			</LabelContainer>

			<LabelContainer>
				<Label><TextStyle1>C. QUOTING SCORES AND TASTING NOTES</TextStyle1></Label>
				<Label>2. Any reference to the scores and tasting notes shall comply with either of the following options</Label>
			</LabelContainer>

			<Label><TextStyle1>Full excerpt</TextStyle1></Label>
			<Label><TextStyle4>Vintage Name of Wine</TextStyle4></Label>
			<Label><TextStyle4>[Robert Parker Wine Advocate] OR [RPWA] OR [WA] OR [RP] OR [Robert Parker] OR [Wine Advocate] – [RPWA score],
				the complete tasting note in quotes, followed by the review publish date and/or issue [e.g. &quot;Published May 21, 2020,
				on RobertParker.com.&quot; or &quot;Published in May 2020 Week 3 issue on RobertParker.com.&quot;
			</TextStyle4>
			</Label>

			<br />
			<Label>Example:</Label>
			<Label><TextStyle3><TextStyle4>2006 Penfolds Grange:</TextStyle4></TextStyle3></Label>
			<Label><TextStyle4>Robert Parker Wine Advocate – 98pts</TextStyle4></Label>
			<Label>
				&quot;Made from fruit coming predominantly from the Barossa Valley this year (97%) and containing 2% Cabernet Sauvignon,
				the 2006 Grange has been added to my list of favorite recent vintages. Deep garnet-purple colored, it’s still a little youthfully mute,
				offering notes of warm cherries, black currants, anise, coffee and toast with underlying hints of soy, yeast extract, black olives and Indian spices.
				Tight-knit and solidly structured on the medium to full bodied palate, the concentrated fruit is densely coiled around the firm grainy tannins and very crisp acidity at this stage,
				but promises something very special in the years to come. It finishes very long, complex and layered with the cedar poking through the fruit purity.
				Patience is required for this vintage; it should begin opening out around 2016 and drink to 2030+. – Feb 2012&quot; (Issue 199).
			</Label>
			<br />
			<LabelContainer>
				<Label><TextStyle1>In-store presentatio</TextStyle1></Label>
				<Label>Vintage Name of Wine</Label>
				<Label>[LOGO] [RP] OR [WA] OR [RPWA]-Score</Label>
				<Label>(b) brand logo design in connection with the scores posting</Label>
				<Label>Brief but not distorted portion of the Tasting Note in quotes and indicating that more information is available, the publish date of the review and/or issue</Label>
			</LabelContainer>

			<Label><u>Example:</u></Label>
			<Label><TextStyle3><TextStyle4>2006 Penfolds Grange:</TextStyle4></TextStyle3></Label>
			<Label>[LOGO] RP-98 &quot;... Dark cherries, smoke, licorice and earthiness are some of the nuances that emerge from 2006 Campoleone,
				a blend of Sangiovese and Merlot that spent 12 months in French oak...&quot; (23/12/2011).</Label>

			<LabelContainer>
				<Label><TextStyle1>Score-Only Post</TextStyle1></Label>
				<Label>[LOGO] [RP] OR [WA] OR [RPWA]-Score</Label>
			</LabelContainer>

			<Label><u>Example: RP-98</u></Label>
			<Label>When using this format, you shall ensure that the score indicated is the score for the same vintage.
				If the score for the specific vintage of the wine being sold is not available,
				your quotation shall indicate the vintage for which the score was given.</Label>

			<br />
			<Label><u>Example:</u></Label>
			<Label>[Under 2007 vintage] RP- 98 (2006 vintage)</Label>
			<Label>3. The name of the author of a review may be mentioned provided it is quoted after the indication of
				[Robert Parker Wine Advocate] OR [RPWA] OR [WA] OR [RP] OR [Robert Parker] OR [Wine Advocate] and not on its own.
			</Label>

			<br />
			<Label><u>Example:</u></Label>
			<Label>[LOGO] RP-98 &quot;... Dark cherries, smoke, licorice and earthiness are some of the nuances that emerge from 2006 Campoleone,
				a blend of Sangiovese and Merlot that spent 12 months in French oak...&quot; Wine Advocate (23/12/2011) [NAME OF REVIEWER].
			</Label>

			<LabelContainer>
				<Label><TextStyle1>D. IN-STORE DISPLAYS</TextStyle1></Label>
				<Label>4. You shall only use the in-store display aids provided by us for purchase on this <TextStyle3>link</TextStyle3>.
					This is the only authorized form of RP scores stickers allowed unless otherwise agreed between parties in writing.
					Any reproduction or purchase from any other source of similar or different sticker formats representing our scores constitutes a direct infringement to our copyrights.</Label>
			</LabelContainer>

			<LabelContainer>
				<Label><TextStyle1>E. UPDATES</TextStyle1></Label>
				<Label>5. We reserve the right to update these Usage Guidelines from time to time.
					In the event of an update to the Usage Guidelines, we will notify you by placing a notice on the Site.
					All updates to the Guidelines are effective immediately and you have 30 days to comply with the updated Usage Guidelines wherever you are using the Content.
					Failure to comply with any updates to the Usage Guidelines within the stipulated time constitutes a breach of this Agreement.</Label>
			</LabelContainer>
		</div>
	)
}

export default CommercialExample