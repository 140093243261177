/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, ReactElement } from 'react'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { ContactInfoSeparator, FormProfilePicContainer, ModalContainer, ModalContent, UpdateModalSeparator } from '../styles'
import { useEditUserCompanyDetailsMutation, useGetCompanyDetailsQuery, useGetTradeDirectoryLookupCountriesQuery } from '../../../../../app/services'
import { useAppSelector } from '../../../../../app/hooks'
import { Users as UsersFromStore } from '../../../../../app/slices/Users'
import ErrorModal from '../../../../../components/error-modal'
import Input from '../../../../../components/form-components/input/Input'
import Dropdown from '../../../../../components/form-components/dropdown/Dropdown'
import MultiSelect from '../../../../../components/form-components/dropdown/MultiSelectDropdown'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import CompanyLogo from '../CompanyLogo'
import { reverseObfuscateConstant } from '../../../../../utils/Constant'
import { RP_ENV } from '../../../../../configs'
import { Authentication as AuthFromStore } from '../../../../../app/slices/Authentication'
import { isMobile } from 'react-device-detect'

interface SaveModalProps {
	isOpen: boolean;
	onClose: (isSuccess: boolean, res: boolean, modal: string) => void;
	modal: string;
}

type CountryType = {
	name: string
	code: string
	_id: string
}

const SaveModal = ({ isOpen, onClose, modal }: SaveModalProps):ReactElement => {
	const { userData } = useAppSelector(UsersFromStore)
	const authentication = useAppSelector(AuthFromStore)

	const { data: CompanyDetails, refetch: CompanyDetailsRefetch } = useGetCompanyDetailsQuery(userData?._id, { skip: !userData })

	// const { data: CompanyDetails, refetch: CompanyDetailsRefetch, isLoading: CompanyDetailsLoading, isError: CompanyDetailsIsError } = useGetUserCompanyByManagerQuery(userData?._id, { skip: !userData._id })
	const { data: tradeDirectoryCountries, isError: tradeDirectoryCountriesIsError } = useGetTradeDirectoryLookupCountriesQuery('')
	const [lookupCountries, setLookupCountries] = useState<any>()
	const [companyData] = useState()
	const [errorState, setErrorState] = useState<any>()
	const [isLoading, setIsLoading] = useState(false)
	const [logoImage, setLogoImage] = useState<any>()
	const [newLogo, setNewLogo] = useState<any>()
	const [isLogoChange, setIsLogoChange] = useState(false)
	const [isListed, setIsListed] = useState<boolean>()


	const { control, handleSubmit, setValue } = useForm<FieldValues>({
		defaultValues: companyData,
	})

	const [editUserCompanyDetailsMutation] = useEditUserCompanyDetailsMutation()

	useEffect(() => {
		if (tradeDirectoryCountries) {
			if (tradeDirectoryCountries.success) {
				const countryList = tradeDirectoryCountries?.data?.map((country: { _id: string; code: string; name: string; }) => country.name)
				setLookupCountries(countryList)
			} else {
				setErrorState((state: any) => ({ ...state, isShow: true, message: 'Something went wrong!' }))
			}
		}
		if (tradeDirectoryCountriesIsError) {
			setErrorState((state: any) => ({ ...state, isShow: true, message: 'Something went wrong!' }))
		}
	}, [tradeDirectoryCountries, tradeDirectoryCountriesIsError])

	const updateFormValues = (data: any) => {
		setValue('_id', data[0]?.user_owned_companies[0]?._id)
		setValue('name', data[0]?.user_owned_companies[0]?.name)
		setValue('contact_person', data[0]?.user_owned_companies[0]?.contact_person)
		setValue('email', data[0]?.user_owned_companies[0]?.email)
		setValue('website', data[0]?.user_owned_companies[0]?.website_url)
		setValue('about', data[0]?.user_owned_companies[0]?.about)
		setValue('phone', data[0]?.user_owned_companies[0]?.phone)
		setValue('street', data[0]?.user_owned_companies[0]?.address?.street)
		setValue('street_optional', data[0]?.user_owned_companies[0]?.address?.street_optional)
		setValue('city', data[0]?.user_owned_companies[0]?.address?.city)
		setValue('state', data[0]?.user_owned_companies[0]?.address?.state)
		setValue('zip', data[0]?.user_owned_companies[0]?.address?.zip)
		setValue('country', data[0]?.user_owned_companies[0]?.address?.country?.name)
		setValue('type', data[0]?.user_owned_companies[0]?.type?.map((t: string) => ({ value: t, label: t })))
	}

	useEffect(() => {
		if (CompanyDetails && CompanyDetails.success) {
			const data = CompanyDetails.data
			updateFormValues(data)
			const logoUrl = data?.[0]?.user_owned_companies?.[0]?.logo?.url || ''
			setLogoImage(logoUrl)

			const trade_is_list = data?.[0].user_owned_companies[0]?.is_listed
			const isListed = trade_is_list === false ? false : true
			setIsListed(isListed)
		}
	}, [CompanyDetails])

	useEffect(() => {
		CompanyDetailsRefetch()
	}, [])

	const businessTypeOptions = [
		{ value: 'Agent', label: 'Agent', },
		{ value: 'Auction House', label: 'Auction House' },
		{ value: 'Broker', label: 'Broker', },
		{ value: 'Distributor', label: 'Distributor', },
		{ value: 'Educator', label: 'Educator', },
		{ value: 'F&B', label: 'F&B' },
		{ value: 'Hotel', label: 'Hotel', },
		{ value: 'Hotel-Restaurant', label: 'Hotel-Restaurant' },
		{ value: 'Importer', label: 'Importer', },
		{ value: 'Negociant', label: 'Negociant', },
		{ value: 'Producer', label: 'Producer', },
		{ value: 'Restaurant', label: 'Restaurant', },
		{ value: 'Retailer', label: 'Retailer', },
		{ value: 'Vineyard', label: 'Vineyard', },
		{ value: 'Wholesaler', label: 'Wholesaler', },
		{ value: 'Wine School', label: 'Wine School' },
		{ value: 'Others', label: 'Others', }
	]

	const closeModal = () => {
		onClose(false,false,  modal)
	}

	const onSubmit: SubmitHandler<FieldValues> = async (data) => {
		setIsLoading(true)
		const countryData = tradeDirectoryCountries?.data?.find(
			(country: CountryType) => country.name === data.country
		)
		const typeValues = data?.type?.map((t: { value: string }) => t.value)

		const payload = {
			companyId: data?._id || '',
			name: data?.name || '',
			type: typeValues || '',
			street: data?.street || '',
			street_optional: data?.street_optional || '',
			state: data?.state || '',
			city: data?.city || '',
			zip: data?.zip || '',
			country: countryData || '',
			contact_person: data?.contact_person || '',
			email: data?.email || '',
			website: data?.website || '',
			phone: data?.phone || '',
			about: data?.about || '',
			is_listed: isListed
		}

		const imageData = new FormData()
		imageData.append('image', newLogo)

		try {
			let logo = {
				_id: '',
				url: ''
			}
			if (isLogoChange) {
				const imageResponse = await uploadImage(imageData)
				if (imageResponse && imageResponse?.data.success) {
					logo = {
						...imageResponse.data.data
					}
				}
			}
			await updateCompanyDetails(payload, logo)
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoading(false)
		}
	}

	const updateCompanyDetails = async (formData: any, imageId: { _id: string; url: string }) => {
		try {
			if (imageId._id) {
				formData = {
					...formData,
					logo: imageId
				}
			}

			const editCompanyDetails = await editUserCompanyDetailsMutation({ params: formData, userID: userData._id }).unwrap()
			if (editCompanyDetails?.success) {
				onClose(true, true, modal)
			} else {
				setErrorState((state: any) => ({ ...state, isShow: true, message: 'Error: Changes not saved. Please try again.' }))
			}
		} catch (error) {
			console.log(error)
		}
	}

	const uploadImage = async (imageData: any) => {
		try {
			const urlLogoUpload = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/user-company/upload?customerId=${userData._id}`
			const response = await fetch(urlLogoUpload, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + authentication.User.accessToken,
					'x-api-key': RP_ENV.API_KEY,
				},
				body: imageData
			})

			const uploadedLogo = await response.json()

			if (uploadedLogo) {
				if (!uploadedLogo?.success) {
					return {
						message: uploadedLogo?.message
					}
				}

				return {
					data: uploadedLogo
				}
			}

		} catch (error) {
			console.log(error)
		} finally {
			setIsLoading(false)
		}
	}

	const handleImageChange = (newImage: string) => {
		setLogoImage(newImage)
	}

	const getNewCompanyLogo = async (file: File): Promise<string> => {
		try {
			const reader = new FileReader()
			return new Promise(() => {
				reader.onload = () => {
					setNewLogo(file)
				}
				reader.readAsDataURL(file)
			})
		} catch (error) {
			console.error(error)
			return ''
		}
	}

	const closeModalError = () => {
		setErrorState((state: any) => ({ ...state, isShow: false, message: '' }))
	}

	return (
		<ModalContainer isOpen={isOpen} isCompany={modal === 'company'}>
			{isLoading && <LoadingOverlay />}
			<ModalContent isCompany={modal === 'company'} isMobile={isMobile}>
				<div className="close-container" onClick={closeModal}>
					<img className='close-icon' src="/img/icons/close@2x.png" alt="Close" />
				</div>
				{modal === 'company' ? (
					<>
						<div className='header'>Company Information</div>
						<form className='company-info-container' onSubmit={handleSubmit(onSubmit)}>
							<FormProfilePicContainer>
								<CompanyLogo image={logoImage} onImageChange={handleImageChange} logoView={logoImage} getNewCompanyLogo={getNewCompanyLogo} setIsLogoChanged={setIsLogoChange} />
							</FormProfilePicContainer>
							<div className='field-container'>
								<Input
									control={control}
									name='name'
									type='text'
									placeholder='Company Name'
								/>
							</div>

							<div className='multi-select-container'>
								<MultiSelect
									control={control}
									name='type'
									placeholder='Business Type'
									options={businessTypeOptions}
								/>
							</div>

							<div className='sub-header'>Business Address</div>
							<div className='field-container'>
								<Input
									control={control}
									name='street'
									type='text'
									placeholder='Street Address'
								/>
							</div>
							<div className='field-container'>
								<Input
									control={control}
									name='street_optional'
									type='text'
									placeholder='Extended Address (optional)'
								/>
							</div>

							{!isMobile ?
								<div className='field-container'>
									<div className='two-column'>
										<Input
											control={control}
											name='city'
											type='text'
											placeholder='City'
										/>
										<Input
											control={control}
											name='state'
											type='text'
											placeholder='State/Province (optional)'
										/>
									</div>
								</div>
								:
								<>
									<div className='field-container'>
										<Input
											control={control}
											name='city'
											type='text'
											placeholder='City'
										/>
									</div>
									<div className='field-container'>
										<Input
											control={control}
											name='state'
											type='text'
											placeholder='State/Province (optional)'
										/>
									</div>
								</>
							}

							{!isMobile ?
								<div className='field-container'>
									<div className='two-column'>
										<Input
											control={control}
											name='zip'
											type='text'
											placeholder='Zip/Postal Code'
										/>
										<Dropdown
											disabled={false}
											control={control}
											options={lookupCountries || []}
											placeholder='Country'
											name='country'
										/>
									</div>
								</div>
								:
								<>
									<div className='field-container'>
										<Input
											control={control}
											name='zip'
											type='text'
											placeholder='Zip/Postal Code'
										/>
									</div>
									<div className='field-container'>
										<Dropdown
											disabled={false}
											control={control}
											options={lookupCountries || []}
											placeholder='Country'
											name='country'
										/>
									</div>
								</>
							}
							{isMobile && <UpdateModalSeparator />}
							{/* </div> */}
							<div className='custom-button'>
								<div className='cancel-button' onClick={closeModal}>CANCEL</div>
								<button className='update-button' type='submit'>UPDATE</button>
							</div>

						</form>
					</>
				) : (
					<>
						<div className='header'>Contact Information</div>
						<form className='company-info-container' onSubmit={handleSubmit(onSubmit)}>
							<div className='field-container'>
								<Input
									control={control}
									name='contact_person'
									type='text'
									placeholder='Contact Person Name'
								/>
							</div>

							<div className='field-container'>
								<Input
									control={control}
									name='email'
									type='text'
									placeholder='Business Email'
								/>
							</div>

							<div className='field-container'>
								<Input
									control={control}
									name='website'
									type='text'
									placeholder='website'
								/>
							</div>

							<div className='field-container'>
								<Input
									control={control}
									name='phone'
									type='text'
									placeholder='Phone'
								/>
							</div>

							<div className='field-container'>
								<Input
									control={control}
									name='about'
									type='textarea'
									placeholder='About the company'
								/>
							</div>

							{isMobile && <ContactInfoSeparator />}

							<div className='custom-button'>
								<div className='cancel-button' onClick={closeModal}>CANCEL</div>
								<button className='update-button' type='submit'>UPDATE</button>
							</div>
						</form>
					</>
				)}

			</ModalContent>

			{errorState?.isShow &&
				<ErrorModal messageBody={errorState?.message} isOpen={errorState?.isShow} onClose={closeModalError} />
			}
		</ModalContainer >
	)
}

export default SaveModal
