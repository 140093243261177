/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { createSlice } from '@reduxjs/toolkit'
import { APP_CONFIG } from '../../configs'
import { RootState } from '../store'

const initialState: SearchFiltersStoreData = {
	ActiveFilters: {},
	AdvanceFilter: {},
	CollectedFilters: {},
	GeneratedQuery: '',
	Keywords: '',
	FacetFilters: [],
	Offset_Limit: APP_CONFIG.SEARCH.OFFSET_LIMIT,
	Sort: 'latest_review',
	SearchType: 'default'
}

function isEmptyObject(value: any): boolean {
	if (typeof value === 'object' && !Array.isArray(value)) {
		return Object.keys(value).length === 0
	}
	return false
}

const SearchFiltersSlice = createSlice({
	name: 'SearchFiltersSlice',
	initialState,
	reducers: {
		SetSort: (state, action) => ({
			...state,
			Sort: action.payload,
		}),
		SetKeywords: (state, action) => ({
			...state,
			Keywords: action.payload,
			Offset_Limit: APP_CONFIG.SEARCH.OFFSET_LIMIT,
		}),
		RemoveAdvancedSearchParameters: (state, action) => ({
			...state,
			CollectedFilters: {
				...state.CollectedFilters,
				[action.payload.filter]: [
					...state.CollectedFilters[action.payload.filter].filter(
						(item: QueryFilters) => item !== action.payload.value
					),
				],
			},
		}),
		SetActiveFilters: (state, action) => {
			if(isEmptyObject(action.payload)) {
				return {
					...state,
					ActiveFilters: {},
					AdvanceFilter: {}
				}
			} else {
				return {
					...state,
					ActiveFilters: action.payload,
				}
			}
		},
		SetActiveAdvanceFilters: (state, action) => ({
			...state,
			AdvanceFilter: { ...state.AdvanceFilter, ...action.payload.advanceFilter },
		}),
		ClearActiveAdvanceFilters: (state) => ({
			...state,
			ActiveFilters: { },
			AdvanceFilter: { },
		}),
		ClearKeyWords: (state) => ({
			...state,
			Keywords: '',
		}),
		ClearRangeFilters: (state) => {
			delete state.AdvanceFilter.rating_computed
			delete state.AdvanceFilter.price_low
			return void(
				state
			)
		},
		ClearPublicationDateFilters: (state) => {
			delete state.AdvanceFilter.date_last_tasted
			return void(
				state
			)
		},
		SetAdvancedSearchParameters: (state, action) => ({
			...state,
			CollectedFilters: {
				...state.CollectedFilters,
				[action.payload.filter]: state.CollectedFilters[action.payload.filter]
					? [
						...state.CollectedFilters[action.payload.filter],
						action.payload.value,
					]
					: [action.payload.value],
			},
		}),
		SetCollectedFiltersAsActive: (state) => ({
			...state,
			ActiveFilters: state.CollectedFilters,
		}),
		SetRatingRanges: (state, action) => ({
			...state,
			CollectedFilters: {
				...state.CollectedFilters,
				RatingRange: {
					...state.CollectedFilters.RatingRange,
					[action.payload.key]: action.payload.value,
				},
			},
		}),
		SetGeneratedQuery: (state, action) => ({
			...state,
			GeneratedQuery: action.payload.generated_query,
		}),
		SetFacetsFilters: (state, action) => ({
			...state,
			ActiveFilters: action.payload.activeFilters,
			FacetFilters: action.payload.facetFilter,
			Offset_Limit: APP_CONFIG.SEARCH.OFFSET_LIMIT,
		}),
		SetPagination: (state, action) => ({
			...state,
			Offset_Limit: action.payload,
		}),
		SetSearchDisplayType: (state, action) => ({
			...state,
			SearchType: action.payload
		})
	},
})

export const {
	RemoveAdvancedSearchParameters,
	SetGeneratedQuery,
	SetKeywords,
	SetActiveFilters,
	SetActiveAdvanceFilters,
	ClearActiveAdvanceFilters,
	ClearRangeFilters,
	ClearPublicationDateFilters,
	ClearKeyWords,
	SetAdvancedSearchParameters,
	SetCollectedFiltersAsActive,
	SetRatingRanges,
	SetFacetsFilters,
	SetPagination,
	SetSort,
	SetSearchDisplayType
} = SearchFiltersSlice.actions

export default SearchFiltersSlice.reducer

export const SearchFilters = (state: RootState): SearchFiltersStoreData =>
	state.SearchFilters
