/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import Article from '../structure/classes/Article'
import { TaggedPreview } from '../components/article-previews'
import { APP_CONFIG } from '../configs'
import * as _ from 'lodash'
import TaggedPreviewV2 from '../components/article-previews/TaggedPreviewv2'

// Optimising properties 
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function AttatchCustomSortOrder(categories: any) {

	return categories.map((category: wineJournalCategory) => ({
		id: category.id,
		name: category.name,
		count: category.count ? category.count : 0,
		contents: category.contents ? category.contents : [],
		sort_order: APP_CONFIG.WINE_JOURNAL_CATEGORIES_ORDERING.indexOf(category.name)
	}))
}

const getAuthorProp = (item: any) => ({
	id: item?.author?.id ? item.author.id : item?.legacy?.author_id ? item?.legacy?.author_id : null,
	name: item?.author?.name ? item.author.name : item?.legacy?.author ? item?.legacy?.author : null
})

const getAssignmentProp = (item: any) => ({
	id: item?.assignment ? item.assignment._id ? item.assignment._id : item.assignment.id : null,
	label: item?.assignment ? item.assignment.label : null,
})

const getCategoryProp = (item: any) => ({
	id: item?.category ? item.category.id : null,
	name: item?.category ? item.category.name : null,
	parent: item?.category ? item.category.parent : null,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GetGroupedArticles = (articles: ArticleJSON[], groupKey: string): any => _.mapValues(_.groupBy(articles, groupKey))

export const GetGroupedArticlesSummary = (collection: any) => Object.keys(collection).map((key: any) => ({
	id: collection[key][0]['category']['id'],
	name: collection[key][0]['category']['name'],
	count: collection[key].length
}))

const getImageProp = (item: any) => ({
	id: item?.image?.id ? item.image.id : '',
	url: item?.image?.url ? item.image.url : '',
})

const getBannerProp = (item: any) => ({
	url: item?.banner?.url ? item.banner?.url : '',
})

const getIssueProp = (item: any) => ({
	id: item?.issue ? item.issue._id ? item.issue._id : item.issue.id : null,
	published_date: item?.issue ? item.issue.published_date : null,
	title: item?.issue ? item.issue.title : null,
})

const getPublicationProp = (item: any) => ({
	id: item?.publication ? item.publication._id ? item.publication._id : item.publication.id : null,
	title: item?.publication?.title ? item.publication.title : null,
})

export function ConvertJSONArticleToClass(item: any): Article {

	const articleInJSON: ArticleJSON = {
		id: item.objectID ? item.objectID : item._id,
		author: getAuthorProp(item),
		assignment: getAssignmentProp(item),
		category: getCategoryProp(item),

		full_article: item?.content_html ? item.content_html : item.content ? item.content : '',

		excerpt: item?.excerpt,	

		image: getImageProp(item),

		banner: getBannerProp(item),

		// Sort orders
		is_highlighted_order: item?.is_highlighted_order,
		is_recommended_order: item?.is_recommended_order,


		issue: getIssueProp(item),

		published_date: item?.published_date && item?.published_date ? item.published_date : '',

		publication: getPublicationProp(item),

		slug: item?.slug,
		sort_order: item?.sort_order,
		tasting_note_count: item?.tasting_note_count,
		title: item?.title,
		url: item?.url,
	}

	return new Article(articleInJSON)
}

export function ConvertJSONArticlesCollectionToClass<ArticleJSON>(
	input: ArticleJSON[]
): Article[] {
	return input?.map((item: any) => ConvertJSONArticleToClass(item)) ?? []
}

export function FilterArticlesByIssueID<ArticleFromAPI>(
	articles: any[],
	issueID: string,
	isMatch: boolean
): ArticleFromAPI[] {
	return articles.filter((article: any) => {
		if (isMatch) {
			return article.issue.id == issueID
		}

		return article.issue.id != issueID
	})
}

/**
 * Generates a collection of article object for displaying the preview on the home page
 * @param articles 
 * @param latestIssueID 
 * @returns 
 */
export function GetArticlesForPreview(articles: ArticleJSON[], latestIssueID: string, articleCollection: ArticleJSON[], issuesCollection: IssueJSON[], numberOfArticlesToDisplay: number): Article[] {
	const articlesFromCurrentIssue = FilterArticlesByIssueID(articles, latestIssueID, true)

	if (articlesFromCurrentIssue) {
		const articleFillers = GetArticleDisplayFillers(articlesFromCurrentIssue.length, numberOfArticlesToDisplay, articleCollection, issuesCollection)
		return ConvertJSONArticlesCollectionToClass([...articleFillers, ...articlesFromCurrentIssue]).sort((articleOne, articleTwo) => articleOne.is_highlighted_order > articleTwo.is_highlighted_order ? 1 : -1)
	}

	return []
}

/**
 * Returns a colecction of articles that serves as fillers if the condition satisfies
 * @param numberOfDisplayableArticles 
 * @returns 
 */
function GetArticleDisplayFillers(numberOfDisplayableArticles: number, numberOfArticlesToDisplay: number, articleCollection: ArticleJSON[], issuesCollection: IssueJSON[]): ArticleJSON[] {

	if (numberOfDisplayableArticles < numberOfArticlesToDisplay && (issuesCollection && issuesCollection.length > 1) && (articleCollection && articleCollection.length > 1)) {
		const difference = (numberOfArticlesToDisplay - numberOfDisplayableArticles)

		let fillers: any[] = []
		for (let iterator = 1; iterator <= difference; iterator++) {
			if (iterator <= difference) {
				const previousIssue = issuesCollection[iterator]
				fillers = [...FilterArticlesByIssueID(articleCollection, previousIssue.id, true).slice(0, difference), ...fillers]
			}
		}

		return fillers.slice(0, difference)
	} // This means that there is enough or more articles that there is no need to add fillers

	return []
}

/**
 * 
 * 
 * @param articles A collection of article objects
 * @returns 
 */
export function GetGroupedTaggedArticles(articles: Article[] | undefined): ReactElement[] {
	const returnElement = []
	if (articles) {
		const maxOnList = articles.length > APP_CONFIG.ARTICLES.TWA_MAX_LIST ? APP_CONFIG.ARTICLES.TWA_MAX_LIST : articles.length

		for (let iterator = 2; iterator < maxOnList; iterator += 3) {
			try {
				returnElement.push((
					<div className="row row--space-between" key={articles[iterator + 1].id} >
						<div className='col-12 col-lg-4 col-md-6'>
							<TaggedPreview article={articles[iterator]} extention className='tagged-height' />
						</div>
						{((iterator + 1) < articles.length) ? (<div className='col-12 col-lg-4 col-md-6'>
							<TaggedPreview article={articles[iterator + 1]} extention className='tagged-height'/>
						</div>) : null}
						{((iterator + 2) < articles.length) ? (<div className='col-12 col-lg-4 col-md-6'>
							<TaggedPreview article={articles[iterator + 2]} extention className='tagged-height'/>
						</div>) : null}
					</div>
				))
			} catch (error) {
				console.log('Error in generating previewed articles ...', error)
			}

		}
	}

	return returnElement
}

/**
 * 
 * 
 * @param articles A collection of article objects
 * @returns 
 */
export function GetGroupedTaggedArticlesV2(articles: Articlev2[] | undefined): ReactElement[] {

	const returnElement = []
	if (articles) {
		const maxOnList = articles.length > APP_CONFIG.ARTICLES.TWA_MAX_LIST ? APP_CONFIG.ARTICLES.TWA_MAX_LIST : articles.length

		for (let iterator = 0; iterator < maxOnList; iterator += 2) {
			try {
				returnElement.push((
					<div className="row row--space-between" key={articles[iterator + 1]._id} >
						<div className='col-12 col-sm-6'>
							<TaggedPreviewV2 article={articles[iterator]} />
						</div>
						{((iterator + 1) < articles.length) ? (<div className='col-12 col-sm-6'>
							<TaggedPreviewV2 article={articles[iterator + 1]} />
						</div>) : null}
					</div>
				))
			} catch (error) {
				console.log('Error in generating previewed articles ...', error)
			}

		}
	}

	return returnElement
}

export function slugify(text: string): string {
	if(!text)
		return ''

	return text
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-')        // Replace spaces with -
		.replace(/[^\w-]+/g, '')    // Remove all non-word chars
		.replace(/--+/g, '-')      // Replace multiple - with single -
		.replace(/^-+/, '')          // Trim - from start of text
		.replace(/-+$/, '')         // Trim - from end of text
}

//truncate with customize maxLength
export const truncate = (str: string, maxLength: number) => {
	if (str.length > maxLength) {
		return str.slice(0, maxLength) + '...'
	} else {
		return str
	}
}