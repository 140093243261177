import styled from 'styled-components'

const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
`

export const WineContainer = styled.div`
	max-width: 500px;

& .error-container {
	& .header {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 10px;
		padding: 20px 30px;
		background-color: #8E4250;
		border-bottom: 1px solid #e5e5e5;
		color: white;

		& .error-tab {
			display: flex;
			gap: 8px;

			& a {
				color: black;
				text-decoration: none;
				font-size: 16px;
			}
		}

		& .modal-close {
			cursor: pointer;
			font-size: 15px;
		}
	}

	& .tab-content {
		padding: 30px;
		padding-top: 10px;
	}
}

.backdrop-transition {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
  }

.winelist-name {
	color: #000000;
	font-weight: bold;

}

.truncate-name{
	width: 430px;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

`
