export const contributors = [
	{
		id: 1,
		profile_image: '',
		name: 'ABBE BAKER',
		description: 'Abbe Baker is the digital editor for Wine Journal as well as the United States of the MICHELIN Guide digital platform. Always on the move, Abbe is either seeking out the next good eats or running long distances—both of which merit a fine glass of wine immediately following. Follow her adventures on Instagram at @abbelew.',
		articles: 'Articles by this Contributor'
	},
	{
		id: 2,
		profile_image: '',
		name: 'ERIN BROOKS',    
		description: 'Erin Brooks is a Certified Sommelier and wine journalist. Born and raised in North Carolina, she moved to New Mexico to attend the College of Santa Fe where she graduated with a B.A. in Creative Writing and Politics. During her time in New Mexico, she became involved in the service industry and fell in love with wine. She spent a decade in service, accruing experience as a restaurant manager, sommelier and wine director. In 2015 Erin relocated to the Napa Valley where she worked for some of the most revered Michelin-rated restaurants in the US, including Chef Thomas Keller’s Bouchon Bistro, La Toque and the three-Michelin star The Restaurant at Meadowood, before joining the team at Robert Parker Wine Advocate as a reviewer in 2017.Erin’s passion for wine has led her on a journey through two different wine certification programs, the Wine & Spirit Education Trust (WSET) and the Court of Master Sommeliers (CMS). She is currently enrolled in the WSET Diploma program and is also pursuing the Advanced Sommelier credential through the CMS. She is the recipient of the Walter Clore Scholarship, the André Tchelistcheff Advanced Course Scholarship and the Bonaccorsi Advanced Exam Scholarship. In 2018 she achieved the WSET Level 3 Award in Sake, continuing her exploration of the beverages of the world. To see what she’s been drinking, follow her on Instagram (@joiedevino)',
		articles: 'Articles by this Contributor'
	},
	{
		id: 3,
		profile_image: '',
		name: 'JOE CZERWINSKI',    
		description: 'Joe Czerwinski is the Managing Editor of Robert Parker Wine Advocate and the reviewer of wines from Australia, New Zealand and Southern France. He spent more than 18 years at Wine Enthusiast, moving through a succession of positions—senior editor, tasting director and managing editor—before joining the team here in 2017. He’s visited New Zealand regularly to research stories since 2000 and has judged the Air New Zealand Wine Awards and Marlborough Wine Show. Joe took part in the latest edition of Penfolds Rewards of Patience, tasting every vintage of Grange ever produced, and he followed that up with a complete vertical of Henschke’s Hill of Grace. He’s spoken at the Margaret River Gourmet Escape and Mornington Peninsula International Pinot Noir Celebration. Joe lives in the New York City suburbs with his wife and two children. His hobbies include ice hockey—he’s played since he was five years old—and fly fishing, a more recently acquired passion.',
		articles: 'Articles by this Contributor'
	},
	{
		id: 4,
		profile_image: '',
		name: 'R.H. DREXEL',    
		description: 'R.H. Drexel is the pen name for a wine business insider who has worked in nearly every sector of the wine industry for the last 30 years. In 2012, Drexel published the first issue of Loam Baby, her wine culture zine, which garnered favorable reviews from Los Angeles Times, Punch, Imbibe, Wine & Spirits, Food & Wine, among others, for its irreverent cartoons, in-depth articles and anachronistic approach to wine journalism. Drexel continues to publish Loam Baby, and is also freelance writer and regular contributor to Wine Journal.',
		articles: 'Articles by this Contributor'
	},
	{
		id: 5,
		profile_image: '',
		name: 'LUIS GUTIÉRREZ',    
		description: 'Luis Gutiérrez has been reviewing the wines from his homeland, Spain, as well as Chile and Argentina, for Robert Parker Wine Advocate since early 2013. Since late 2015, he has also taken charge of reviewing wines from the tiny Jura in France. He likes to walk the vineyards to understand the wines and enjoys the local culture and gastronomy during his trips. He also likes to snap the landscape and winemakers, write long articles and provide the context for what’s in the bottle. Luis comes from a technical background unrelated to wine and has turned his passion into his life.',
		articles: 'Articles by this Contributor'
	}
]