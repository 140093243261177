/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'

import { ConvertJSONWineCollectionToClass, GetShortlistedResults , Shuffle } from '../../../../utils'

import { Link } from 'react-router-dom'

import dummyWines from './dummy.json'

// Services
import Wine from '../../../../structure/classes/Wine'

//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { imageConvertion } from '../../../../utils/CloudImageUtils'
import { SetKeywords } from '../../../../app/slices/SearchFilters'
import { useDispatch } from 'react-redux'

const defaultBottleImages = {
	red: '/img/wines/generic-red@3x.png', 
	white: '/img/wines/generic-white@3x.png', 
	rose: '/img/wines/generic-rose@3x.png'
}

function generateCarousel<GenericWineJSON>(winesInJSON: GenericWineJSON[], dispatch: any) {

	const wines = ConvertJSONWineCollectionToClass(Shuffle(GetShortlistedResults(winesInJSON)))
	
	//Owl Carousel Settings
	const options = {
		margin: 4,
		loop: false,
		nav: true,
		navText: ['<i class="mdicon mdicon-navigate_before"></i>', '<i class="mdicon mdicon-navigate_next"></i>'],
		// smartSpeed: 1000,
		responsive: {
			0: {
				items: 1,
			},
			768: {
				items: 2,
			},
			1200: {
				items: 3,
			}
		},
		dots: true, 
		// dotsClass: 'owl-dots', 
		// dotClass: 'dots-visible-xs'
	}
	

	// TODO: remove this.. we don't do this much ifs 
	const getBottleImage = (wine: any) => {
		if(wine.image && wine.image.url) {
			return wine.image.url
		} else {
			if(wine.color_class.value === 'red' ) {
				return defaultBottleImages.red
			}

			if(wine.color_class.value === 'white') {
				return defaultBottleImages.white
			}

			if(wine.color_class.value === 'rose' ) {
				return defaultBottleImages.rose
			}
		}
	}	
	
	const addKeywordFacets = (keyword: string) => {
		dispatch(
			SetKeywords({
				key: keyword,
			})
		)
	}
	
	return(
		<OwlCarousel className="owl-theme" {...options} nav>
			{ wines.map((ShortlistedWine: Wine, lookout_wine) => 
				<div className="slide-content" key={ lookout_wine }>
					<article className="post--overlay post--overlay-sm has-score-badge-bottom cat-4">
						<div className="background-img background-img--darkened" style={{ backgroundImage: `url("${ imageConvertion(getBottleImage(ShortlistedWine)) }")` }}></div>
						<div className="post__text inverse-text text-center">
							<div className="post__text-wrap">
								<div className="post__text-inner">
									<h3 className="post__title typescale-2">{ ShortlistedWine.name }</h3>
								</div>
							</div>
						</div>
						<Link to={ `/search/wine?keyword=${ ShortlistedWine.name }` } className="link-overlay" onClick={() => addKeywordFacets(ShortlistedWine.name)}></Link>
					</article>
				</div>
			) }
		</OwlCarousel>
	)
}

function WinesToLookoutfor(): ReactElement { 
	const dispatch = useDispatch()
	return (
		<div className="mnmd-block mnmd-carousel">
			<div className="block-heading block-heading--lg">
				<h4 className="block-heading__title">
					<span className="first-word">Wines to </span> Lookout for</h4>
			</div>
			{ generateCarousel( dummyWines, dispatch ) }
		</div>
	)
}

export default  WinesToLookoutfor