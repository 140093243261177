import styled from 'styled-components'

const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;

`
export const PageTitle = styled.div`
	margin: 10px 0px 20px;
	font-size: 40px;
	font-weight: bold;
	color: #333;
	${commonTextStyle}
`
export const DefaultPicture = styled.div`
	width: 120px;
	height: 120px;
	margin: 0 20px 1px 0;
	border: solid 4px var(--white-three);
	border-style: dotted;
`
export const TradeDescription = styled.div`
	text-align: justify;
	font-size: 15px;
	font-weight: normal;
	color: #333;
	${commonTextStyle}
`
export const FilterContainer = styled.div`
	& div {
		& label {
			margin: 0 0 6px;
			font-size: 14px;
			font-weight: bold;
		}

		& select {
			/* width: 268px; */
		}

		& input {
			/* width: 268px; */
		}
	}
	margin-bottom: 30px;
`
export const Separator = styled.div`
	height: 1px;
	margin: 19px 0 35px;
	background-color: #ccc;
`
export const ImageHolder = styled.div`
	width: 168px;
	height: 168px;
	margin: 0 20px 1px 0;
	padding: 20px;
	border: solid 1px #ccc;
	background-color: var(--white-three);

	@media (max-width: 768px) {
		height: 100%;
		margin: 0;
	}

	& img {
		width: 100%;
		height: 100%;
		border: solid 4px var(--white-three);
	}

`
export const TradeContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 30px;
	height: 200px;
	gap: 1.1rem;

	& .listing-info {
		display: flex;
		flex-direction: column;
		gap: 0.4rem;

		& .listing-info-name {
			font-family: Libre Franklin;
			font-size: 21px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.52;
			letter-spacing: normal;
			color: #333;
			cursor: pointer;

			&:hover, &:active {
				text-decoration: none;
			}
		}
	}
	
	@media (max-width: 768px) {
		height: 100%;
		gap: 0.7rem;
	}
`
export const BusinessType = styled.div`
	font-size: 12px;
	font-weight: bold;
	color: #777;
	${commonTextStyle}
`

export const Country = styled.div`
	font-size: 12px;
	font-weight: normal;
	color: #777;
	${commonTextStyle}
`
export const Address = styled.div`
	font-size: 14px;
	font-weight: normal;
	color: #333;
	${commonTextStyle}
`
export const VisitSite = styled.div`
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.29;
	letter-spacing: normal;
	color: #a08436;
	${commonTextStyle}

	& a {
		cursor: pointer;
	}
`
export const Col = styled.div`
	padding-left: 0px;
	padding-right: 0px;
`

export const ClearButton = styled.button`
	height: 33px;
	padding: 6px 11.5px;
	margin-top: 5px;
	border-radius: 8px;
	background-color: #73333f;
	color: #fff;
	border: none;
	float: right;
	width: 100%;
	&:hover {
		background-color: #8E4250;
	}

	& span{
		font-size: 14px;
		font-weight: bold;
		text-align: center;
		color: var(--white-three);
		${commonTextStyle}
	}
`


export const NoRecords = styled.div`
	color: rgba(0,0,0,.4);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100px;
	background-color: #f8f8f8;
`