import React, { ReactElement, useState } from 'react'
import TagManager from 'react-gtm-module'

import { STATIC_MESSAGES } from '../../../../configs'

// Styles
import {
	GetSubscriptionButton, LoginLinkSpan,
} from './styles'
import { useBasePath } from '../../Common'
import CommonModalContainer from '../../../modals/ModalContainer'
import LoginForms from '../../Common/Login/LoginForms'
import { Link } from 'react-router-dom'
import { IsUserAuthenticated } from '../../../../utils'
import { isFreeUser } from '../../../../utils/AuthenticationUtil'
import {Users as UsersFromStore} from '../../../../app/slices/Users'
import { useAppSelector } from '../../../../app/hooks'

function SubscriptionInvite(props: { inviteMessage: string, mainMessage: string }): ReactElement {
	const { inviteMessage, mainMessage } = props
	const basePath = useBasePath()
	
	const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)

	const { userData } = useAppSelector(UsersFromStore)

	const triggerSubscribe = () => {
		TagManager.dataLayer({
			dataLayer: {
				'event': 'subscribe_btn',
				'page_origin': basePath
			}
		})
	}

	return (
		<div className="subscribe-form-wrap" style={{ marginTop: '2rem' }}>
			<div className="subscribe-form subscribe-form--has-background subscribe-form--horizontal text-center">
				<div className="subscribe-form__inner">
					<div className="row--space-between row--flex row--vertical-center">
						<div className="col-xs-12 col-md-12">
							{!IsUserAuthenticated() || !isFreeUser(userData)? 
								<>
									<h3>
										<b>{inviteMessage}</b>
									</h3>
						
									<p>
										{mainMessage }
									</p>
								</>
								: 
								<>
									<h3>
										<b>You do not have an active subscription</b>
									</h3>
									<p>
										Unlock premium articles and experience the finest quality wine journalism through our exclusive subscription service.
									</p>
								</>
								
							}
							
						</div>
						<div className="col-12 col-md-12">
							<div className="subscribe-form__fields">
								<Link onClick={triggerSubscribe} to='/subscriptions'>
									<GetSubscriptionButton>
										<span className="btn btn-primary">{STATIC_MESSAGES.SUBSCRIBE}</span>
									</GetSubscriptionButton>
								</Link>
								{!IsUserAuthenticated() ?
									<div style={{ paddingTop: '30px' }}>
										<LoginLinkSpan onClick={()=> setIsLoginModalOpen(true)}>Already a member? Login</LoginLinkSpan>
									</div>
									:
									<>
									</>
								}

							</div>

						</div>
					</div>
				</div>
			</div>
			<CommonModalContainer isOpen={isLoginModalOpen} onClose={()=> setIsLoginModalOpen(false)}>
				<LoginForms setIsOpen={setIsLoginModalOpen} />
			</CommonModalContainer>
		</div>
	)
}

export default SubscriptionInvite