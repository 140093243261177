import React, { ReactElement } from 'react'
import { useParams, useLocation } from 'react-router'
import Redeem from './redeem'
import NonExistPage from '../404-page/NonExistPage'
import SuccessActivation from '../../../../components/success-page/SuccessPage'

const bodyContent = [
	{
		header: 'Free subscription redeemed',
		bodyOne:'Your free subscription has been redeemed. The subscription will end on',
		bodyTwo: 'You now have access to all articles and tasting notes on the site.',
	},
	{
		header: 'Free subscription redeemed',
		bodyOne:'Your free subscription has been redeemed. Your current subscription has been extended to',
		bodyTwo: '',
	},
]

function RedemptionSub(): ReactElement {
	const {type} = useParams<{ type: string }>()
	const location = useLocation()

	if(type === 'redeem') return <Redeem />
	if(type === 'success') {
		if(location.search === '?type=new')
			return <SuccessActivation header={bodyContent[0].header} bodyOne={bodyContent[0].bodyOne} bodyTwo={bodyContent[0].bodyTwo} />
		
		if(location.search === '?type=exist')
			return <SuccessActivation header={bodyContent[1].header} bodyOne={bodyContent[1].bodyOne} bodyTwo={bodyContent[1].bodyTwo} />
	} 
	return <NonExistPage />
}

export default RedemptionSub
