/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { useReactToPrint } from 'react-to-print'
import PrintTastingNotes from './print-layout'
import './print-layout/style.css'

const PrintButtons = (props: any): ReactElement => {

	const componentRef = useRef<null | HTMLDivElement>(null)

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: 'Robert Parker - ' + props.wine.vintage + ' ' + (props.wine?.producer.display_name === props.wine?.name ? props.wine.name : props.wine.producer.display_name + ' ' + props.wine.name)
	})

	// const desktopButton = () => {
	// 	return (
	// 		<>
	// 			<input name="submit" type="button" id="submit" value={props.item?.btnValue} onClick={handlePrint} /> &nbsp;
	// 			<div className="print-hide">
	// 				<PrintTastingNotes
	// 					wine={props.wine}
	// 					tastingNote={props.tastingNote}
	// 					drinkDate={props.drinkDate}
	// 					layoutType={props.item?.type} refs={componentRef} />
	// 			</div>
	// 		</>
	// 	)
	// }

	// const mobileButton = () => {
	// 	return (
	// 		<>
	// 			<input className="mobile-print-button" name="submit" type="button" id="submit" value={props.item?.btnValue} onClick={handlePrint} /> &nbsp;
	// 			<div className="print-hide">
	// 				<PrintTastingNotes
	// 					wine={props.wine}
	// 					tastingNote={props.tastingNote}
	// 					drinkDate={props.drinkDate}
	// 					layoutType={props.item?.type} refs={componentRef} />
	// 			</div>
	// 		</>
	// 	)
	// }

	return (
		<span>
			{props.subscription?.subscription?.planId === 'commercial-1-year' || props.subscription?.orbit_roles.includes('project:customer-commercial') ?
				<>
					<input className={isMobile ? 'mobile-print-button' : 'desktop-print-button'} name="submit" type="button" id="submit" value={props.item?.btnValue} onClick={handlePrint} /> &nbsp;
				</>
				:
				props.item?.btnValue === 'Print Note' &&
				<>
					<input className={isMobile ? 'mobile-print-button' : 'desktop-print-button'} name="submit" type="button" id="submit" value={props.item?.btnValue} onClick={handlePrint} /> &nbsp;
				</>
			}
			<div className="print-hide">
				<PrintTastingNotes
					wine={props.wine}
					tastingNote={props.tastingNote}
					drinkDate={props.drinkDate}
					layoutType={props.item?.type} refs={componentRef} />
			</div>
		</span>
	)
}

export default PrintButtons