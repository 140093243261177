export const validCards = [
	{
		name: 'new',
		cardType: 'New',
		imgUrl: '/img/icons/new-card.svg'
	},
	{
		name: 'visa',
		cardType: 'Visa',
		imgUrl: '/img/icons/visa.png'
	},
	{
		name: 'mastercard',
		cardType: 'MasterCard',
		imgUrl: '/img/icons/mastercard.png'
	},
	{
		name: 'jcb',
		cardType: 'JCB',
		imgUrl: '/img/icons/jcb.png'
	},
	{
		name: 'amex',
		cardType: 'American Express',
		imgUrl: '/img/icons/amex-cc-icon.png'
	},
	{
		name: 'discover',
		cardType: 'Discover',
		imgUrl: '/img/icons/discover.png'
	},
]

export function generateMonthOptions(): string[] {
	const options = []
	for (let i = 1; i <= 12; i++) {
		const formattedMonth = i.toString().padStart(2, '0')
		options.push(formattedMonth)
	}
	return options
}

export function generateYearOptions(): string[] {
	const currentYear = new Date().getFullYear()
	const endYear = currentYear + 10

	const options = []
	for (let year = currentYear; year <= endYear; year++) {
		options.push(year.toString())
	}
	return options
}