export default class Author {
	id: string
	name: string
	profile_image: string

	constructor(id: string, name: string, image: string) {
		this.id = id
		this.name = name
		this.profile_image = image
	}
}
