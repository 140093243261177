/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RP_ENV } from '../configs'
import { reverseObfuscateConstant } from './Constant'

export const getCSURFToken = async () => {
	try {
		const urlLogoUpload = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/user/get-csurf-token`
		const response = await fetch(urlLogoUpload, {
			headers: {
				'x-api-key': RP_ENV.API_KEY,
			},
			credentials: 'include',
			mode: 'cors'
		})

		const csrfToken = await response.json()
		if(csrfToken){
			if(csrfToken.success){
				return csrfToken.data.token
			} else {
				console.log(csrfToken.message)
			}
		}
		
	} catch (error) {
		console.log(error)
	}
}