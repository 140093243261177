export default class Assignment {
	id = ''
	label = ''
	subLabel = ''
	constructor(id: string, label: string, subLabel?: string) {
		this.id = id
		this.label = label
		this.subLabel = subLabel ? subLabel : ''
	}
}
