/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'react'
import { APP_CONFIG } from '../configs'
import { UpdateUserAuthDetails } from '../app/slices/Authentication'
import { clearCookie, getCookie, setCookie, setExpireLogin } from './CookieUtils'

/**
 * Executes the process of deleting the local storage session variable
 */
export function ClearUserSession(dispatch: Dispatch<any>): void {
	// Clears out the values from the store
	// Removes the storage
	// localStorage.removeItem(APP_CONFIG.AUTHENTICATION.SESSION_LABEL)

	clearCookie()
	dispatch(
		UpdateUserAuthDetails({
			token: '',
			tokenExpiry: '',
			refreshToken: '',
			refreshTokenExpiresAt: '',
			clientId: '',
			userId: '',
			country: '',
		})
	)
}

export function IsAppAuthenticated(data: AuthenticationStoreData): boolean {
	return data.WebApp.accessToken ? true : false
}

export function isFreeUser(userData: any): boolean {
	if (userData && userData.subscription) {
		if(userData?.orbit_roles.includes('project:customer-free')){
			return true
		}
		if (userData.subscription.status === 'Active') {
			return false
		} else {
			if (userData.subscription.end) {
				const endDate = new Date(userData.subscription.end)
				const today = new Date()
				return endDate < today
			} else {
				return false
			}
		}
	} else {
		return true
	} 
}

export function IsUserAuthenticated(): boolean {
	if (!getCookie(APP_CONFIG.AUTHENTICATION.SESSION_LABEL)) {
		return false
	}

	const authFromSession = getCookie(APP_CONFIG.AUTHENTICATION.SESSION_LABEL)

	return authFromSession &&
		authFromSession.token &&
		authFromSession.type === 'user'
		? true
		: false
}

export function IsUserAuthenticationExpired(): boolean {

	if (!getCookie('LastLogin')) {
		return true
	}
	return false
}

export function SaveAuthenticationSession(
	type: 'user' | 'app',
	token: string,
	tokenExpiry: string,
	refreshToken = '',
	refreshTokenExpiry = '',
	clientId = '',
	userId = '',
	country = '',
): void {
	const storageData: AuthSession = {
		type,
		token,
		tokenExpiry,
		refreshToken,
		refreshTokenExpiry,
		clientId,
		userId,
		country,
	}

	const tokenDate = new Date(tokenExpiry)
	const year = tokenDate.getFullYear()
	const month = tokenDate.getMonth()
	const day = tokenDate.getDate()

	setCookie(APP_CONFIG.AUTHENTICATION.SESSION_LABEL, JSON.stringify(storageData), new Date(year, month + 1, day))
	setExpireLogin()
}
