/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import DOMPurify from 'dompurify'

import { PreviewContainer, ProfileContent, ProfileSummary, Separator } from './styles'
import moment from 'moment'
import LoadingOverlay from '../../../../components/Loading-overlay'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
import { addTargetBlank } from '../../../../helper/constants'
import { useGetArticleByAuthorQuery, useGetAuthorDetailsByIdQuery } from '../../../../app/services'

function AuthorPage(): ReactElement {

	const { authorID } = useParams<{ authorID: string }>()
	// const { triggerTrackingHandler } = useArticleTracking()

	const {data: previewArticle} = useGetArticleByAuthorQuery({authorId: authorID}, {skip: !authorID})
	const {data: authorData, isLoading} = useGetAuthorDetailsByIdQuery(authorID, {skip: !authorID})
	const [isProfileAccessible, setIsProfileAccessible] = useState(false)
	const getAuthorName = (author: any) => {
		if(!author) return ''
		return (author.first_name + ' ' + author.last_name) 
	}

	const cleanHtml = () => {
		if(authorData?.data?.profile?.reviewer_info?.bio) {
			return DOMPurify.sanitize(addTargetBlank(authorData?.data?.profile?.reviewer_info?.bio), {ADD_ATTR: ['target']})
		} else {
			return '<></>'
		}
	}

	const checkURL = async () => {
		const url = authorData?.data?.profile?.profile_image?.url
		if (!url) {
			console.error('URL is not defined')
			return
		}
		try {
			const response = await fetch(url, {
				method: 'HEAD'
			})
	
			if (response.ok) {
				setIsProfileAccessible(true)
			} else {
				setIsProfileAccessible(false)
			}
		} catch (error) {
			console.error('Error checking URL:', error)
		}
	}

	useEffect(() => {
		if(authorData && authorData.success){
			checkURL()
		}
	}, [authorData])

	return (
		<>
			{isLoading && <LoadingOverlay />}
			<div className='single-entry'>
				<div className='container'>
					<header className='single-header'>
						<h1 className="entry-title entry-title--lg">{getAuthorName(authorData?.data?.profile?.name)}</h1>
					</header>
				</div>
				<br />
				<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
					<div className='container'>
						<div className='row'>
							<ProfileSummary className="mnmd-sub-col mnmd-sub-col--left sidebar js-sticky-sidebar" role="complementary">
								<div className='profile-pic'>
									{isProfileAccessible ? 
										<CloudImageUtils imgSrc={authorData?.data?.profile?.profile_image?.url || ''} alt='' ratio={0} params='width=300&height=300&radius=999' />
										:
										<img alt='image' src='/img/icons/unknownUser.png' />
									}
								</div>
								<div className='base'>
									<span className='label'>Base: </span>
									<br />
									<span className='value'>{authorData?.data?.profile?.reviewer_info?.base || ''}</span>
								</div>
								<div className='assigned-regions'>
									<span className='label'>Assigned Regions:</span>
									<br />
									<span className='value'>{authorData?.data?.profile?.reviewer_info?.assigned_regions || ''}</span>
								</div>
								<Separator></Separator>
								<div className='counters'>
									<span>
										<Link to={`/search/article?reviewers=${getAuthorName(authorData?.data?.profile?.name)}`}>{authorData?.data?.article_count || 0} Articles</Link>
									</span>
									<br />
									<span>
										<Link to={`/search/wine?reviewers=${getAuthorName(authorData?.data?.profile?.name)}`}>{authorData?.data?.tasting_notes_count || 0} Tasting Notes</Link>
									</span>
								</div>
							</ProfileSummary>
							<ProfileContent className="mnmd-main-col has-left-sidebar" role="main">
								<div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: cleanHtml() }}></div>
								<Separator></Separator>
								<div>
									<br />
									<div>
										<div><h3>RECENT ARTICLES</h3></div>
										<PreviewContainer className='preview-container'>
											{
												previewArticle && previewArticle?.data.map((article: any, index: number) => <div key={index} style={{ width: '220px' }}>
													<div className='image'>
														<Link to={`/articles/${article?._id}`}>
															{article?.banner?.url ? <CloudImageUtils imgSrc={article?.banner?.url} alt='' ratio={3.3} params='func=cropFit'/> 
																: <img src='img/icons/default_article_banner_sm.jpg'  alt='image'/>}
															
														</Link>
													</div>
													<div className='title'>
														<Link to={`/articles/${article?._id}`}>{article?.title}</Link>
													</div>
													<div className='publish-date'>
														<img src={'img/icons/clock.png'} />
														<span>{moment(article?.published_date).format('MMM DD, YYYY').toString()}</span>
													</div>
												</div>)
											}
										</PreviewContainer>
									</div>
								</div>
							</ProfileContent>

						</div>
					</div>
				</div>
			</div>
		</>

	)
}

export default AuthorPage