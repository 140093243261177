/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { slugify } from '../../utils/ArticlesUtil'
import moment from 'moment'

// Styles
import {
	PrimaryButton,
} from './styles'
import { useDispatch } from 'react-redux'
import { SetActiveFilters } from '../../app/slices/SearchFilters'
import { useGetFeaturedWineAdvocateQuery } from '../../app/services'
import { CloudImageUtils } from '../../utils/CloudImageUtils'

function NavbarSummaries(): ReactElement {

	const { data: dataArticleLimit20, isLoading, isError } = useGetFeaturedWineAdvocateQuery()
	const [articlesData, setArticlesData] = useState<any[]>([])

	const dispatch = useDispatch()

	useEffect(() => {
		if (dataArticleLimit20) {
			if(dataArticleLimit20.success){
				const limitFourArticle = dataArticleLimit20.data.slice(0, 4)
				setArticlesData(limitFourArticle)
			}
		}

		if(isError){
			console.log('Something went wrong. Please try again.')
		}
	}, [dataArticleLimit20, isError])

	return (
		<div className="mnmd-mega-menu__inner">
			{isLoading ? (<>Loading...</>) : null}
			<ul className="posts-list list-unstyled">
				{articlesData?.map((article: any, article_key: number) => (
					<li className={(article_key == 0) ? 'big-post' : ''} key={`article_element_${article_key}`}>
						<article className="post post--vertical cat-6">
							<div className="post__thumb">
								<Link to={`/articles/${article.id}/${slugify(article.title)}`}>
									<CloudImageUtils imgSrc={article?.banner?.url} alt="image" ratio={0} params=''/>
								</Link>
							</div>
							<div className="post__text">
								<h3 className="post__title typescale-0">
									<Link to={`/articles/${article.id}/${slugify(article.title)}`}>{article.title}</Link>
								</h3>
								<div className="post__meta">
									<span className="entry-author">By&nbsp;
										<Link to={`/author/${article?.author?.id}`} className="entry-author__name">{article.author?.name}</Link>
									</span><br />
									<time className="time published" dateTime={article.published_date} title={moment(article.published_date).format('MMM DD, YYYY at hh:mm a').toString()} >
										<i className="mdicon mdicon-schedule"></i>{moment(article.published_date).format('MMM DD, YYYY').toString()}
									</time>
									<Link onClick={()=> dispatch(SetActiveFilters({}))} to={`/search/wine?article_id=${article.id}`} ><img src='/img/icons/black.png' />&nbsp;{article.tasting_note_count}</Link>
								</div>
							</div>
						</article>
					</li>
				))}
			</ul>
			<div style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'right' }}>
				<Link to='/issues/latest'>
					<PrimaryButton>
						<span className="btn btn-primary">View Latest Issues</span>
					</PrimaryButton>
				</Link>
			</div>
		</div >
	)
}

export default NavbarSummaries