import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import CommercialExample from './CommercialExample'
import {
	PageTitle,
	Title,
	SubscriptionsBlurb,
	TextStyle1,
	TextStyle3,
	TextStyle2,
	SectionDescription,
	SectionSubDescription,
	SectionContainer,
} from './common'

function CommercialSubscription(): ReactElement {

	return (
		<div role="main">
			<PageTitle>Commercial Subscription Agreement</PageTitle>
			<SubscriptionsBlurb>
				Last modified: <TextStyle1>February 2021</TextStyle1>
				<br /><br />
				This Commercial Subscription Agreement (the &quot;Agreement&quot;) is made by and between The Wine Advocate, Inc. (&quot;The Wine Advocate,&quot; &quot;we&quot; or &quot;us&quot;) and the Commercial Subscriber (as defined below).
				By signing up for a Commercial Subscription with RobertParker.com (&quot;Site&quot;), you are deemed to accept the terms of this Agreement, including the terms relating to use of the Site and its content, which refer to all visual and editorial content including wine reviews and scores, posted currently and in the past on the Site (&quot;Content&quot;).
				<br /><br />
				The Commercial Subscription is solely for entities or natural persons engaged in (i) the retail or wholesale sale of wine, (ii) the properly licensed importation or distribution of wine on either a retail or wholesale basis, (iii) the licensed commercial wine production,
				or (iv) the operation of a restaurant, wine bar or such other establishment serving wine with a wine list.
				<br /><br />
				<TextStyle2>If you do not qualify for a Commercial Subscription, then no right to the Content is granted to you under this Agreement.</TextStyle2>
				If you do not qualify as a Commercial Subscriber and are using your subscription in your personal capacity to read and consult the Content but not to repost any of the Content in print or digital supports or in supports of any form, please check the Personal Subscription Agreement.
				If you are in the business of providing marketing, education, digital or information services related to wine or the wine industry, or operating an online marketplace, whether specifically for wines or for various products including wine (where you list wines for sale on a consignment basis, and/or you do not retain stock of the wines sold in your sales channels), you do not qualify for a Commercial Subscription.
				If for any reason you do not qualify for a Commercial Subscription we request that you contact us at commercial@robertparker.com for alternative solutions for the use of our Content.
			</SubscriptionsBlurb>

			<SectionContainer>
				<Title>A. HIGHLIGHTS</Title>
				<SectionDescription>
					<ol>
						<li>
							The following highlights are intended only to convey the key points of the Agreement and are not a substitution for the full Agreement.
							The Commercial Subscriber is required to read all the terms stated in the Agreement and abide by them.
						</li>
						<li>
							The terms and conditions of this Agreement apply only to Commercial Subscribers. Commercial Subscribers are entities or natural persons who are engaged in any of the following:
							<SectionSubDescription>
								<li>
									retail or wholesale sale of wine
								</li>
								<li>
									importation or distribution of wine
								</li>
								<li>
									commercial wine production; and/or
								</li>
								<li>
									operating a restaurant, wine bar or such other establishment serving wine with a wine list.
								</li>
							</SectionSubDescription>
						</li>
						<li>
							As a Commercial Subscriber, you are permitted to use the Content (as defined below) on one (1) unique Platform and country website,
							solely in connection with the sale or distribution of wine in your ownership and/or otherwise in your inventory subject to the terms of this Agreement.
							You shall not use the Content on marketplaces or other platforms that are not in your direct control.
							You shall not use the Content on your social media posts or any other online media platform.
						</li>
						<li>
							You may not use the Content in relation to wines that are not currently in your ownership and inventory.
							If you have used the Content for wines in your inventory that have subsequently been removed from your inventory or otherwise become out of stock,
							you are required to remove the Content from your site within 10 (ten) working days of the wine being out of stock.
						</li>
						<li>
							You shall not post, reproduce partially or in full any article published in The Wine Advocate and place it in any printed or digital support.
						</li>
						<li>
							You may use Content (including tasting notes and/or scores) of <TextStyle1>up to two hundred (200) different wines and vintages</TextStyle1>  at any given time,
							whether online or in-store (i.e., different vintages of the same wines are considered as unique content postings).
							If you require capacity to use Content on more than 200 wines and vintages, please contact us at <TextStyle3>commercial@robertparker.com</TextStyle3>
						</li>
						<li>
							You are at all times responsible for ensuring that the display of Content whether online or in-store complies with the Usage Guidelines indicated below.
							Information on RP scores stickers is available <Link to={' '}> <TextStyle3>here</TextStyle3></Link>. They are the only authorized form of RP scores stickers allowed.
							Any reproduction or purchase from any other source constitutes a direct infringement to our copyrights.
						</li>
						<li>
							You shall not modify or make any derivative works from the Content and shall not copy, archive, publish, republish, distribute,
							transmit or disseminate the Content or any portion in any manner unless specifically permitted in this Agreement.
						</li>
						<li>
							The Commercial Subscription registered by a corporate entity is not transferable to other corporate entities even if they have a common ownership,
							shareholding or management. Specific group Commercial Subscription plans can be arranged by contacting us at <TextStyle3>commercial@robertparker.com</TextStyle3>
						</li>
						<li>
							The rights acquired through this Commercial Subscription are not transferrable to any third party.
							As such any score or tasting note posted by a wine supplier (winery or wine merchant) on any marketing support (website, social media, catalogue,…) visible to its clients or to the general public,
							cannot be reposted by any other party on their own marketing supports unless they are themselves Commercial Subscribers.
						</li>
						<li>
							As an example, a winery or wine merchant may reproduce the scores and notes of their wines in their marketing material if they are a Commercial Subscriber.
							However, any of their distributors, clients, or member of the general public, willing to share, or post these scores and notes on their own marketing supports,
							will need to be a Commercial Subscriber.
						</li>
					</ol>
				</SectionDescription>

				<Title>B. THE SERVICE</Title>
				<SectionDescription>
					<ol>
						<li>
							The Site provides access to wine tasting notes and other information about wine,
							including information that may have been previously published by Robert M. Parker, Jr. in the print edition of The Wine Advocate®.
							The Site may also include other features and services of interest to wine drinkers and collectors and to those in the wine trade,
							including access to The Wine Advocate Vintage Guide. The choice of such features and services is made at the sole discretion of The Wine Advocate and may change from time to time without notice.
							The wine tasting notes, reviews and scores available on the Site, as well as The Wine Advocate Vintage Guide, are collectively referred to herein as the &quot;Content.&quot;
						</li>
						<li>
							The database of information will be updated from time to time at the discretion of The Wine Advocate throughout the year.
						</li>
					</ol>
				</SectionDescription>

				<Title>C. YOUR SUBSCRIPTION</Title>
				<SectionDescription>
					<ol>
						<li>
							As a Commercial Subscriber, your use of the Site and this subscription is limited solely to (i) use by you and your employees
							accessing the Site via a personal computer or a mobile electronic device that provides access to the Internet (a &quot;Device&quot;) and (ii) use in connection with your business.
						</li>
						<li>
							Each Commercial Subscriber will be assigned 1 log-in upon registration. You may purchase additional log-ins if necessary from the Site.
							Each log-in should be only assigned to one employee/ representative. Each log-in is non-transferable.
						</li>
						<li>
							You must select a log-in name and password for each log-in at the time you subscribe (choice of log-in name is subject to availability),
							or at the time of purchase of each new log-in. Only one person may use each log-in name at a time. Sharing your log-in name and password with others (except as described below)
							is a violation of this Agreement and is grounds for immediate termination of your subscription, without a full or partial refund of your subscription fee.
						</li>
						<li>
							Each log-in you obtain provides access to the Site pursuant to this Agreement through a single log-in name and password on any one Device.
							You may not make concurrent or simultaneous use of a log-in on more than one Device at any one time. In other words,
							if you want to access the Site on more than one Device at the same time, you will need to obtain an additional subscription for each concurrent usage, which will require a separate Commercial Subscription Agreement
						</li>
					</ol>
				</SectionDescription>

				<Title>D. INTELLECTUAL PROPERTY AND USAGE LIMITATIONS</Title>
				<SectionDescription>
					<ol>
						<li>
							For the purposes of this Agreement, &quot;Intellectual Property Rights&quot; shall mean trademarks and service marks, business names and domain names,
							ights in get-up and trade dress, goodwill and the right to sue for passing off or unfair competition, copyright and related rights to the Content,
							moral rights, rights in designs, rights in computer software, database rights, rights to use, and protect the confidentiality of,
							Confidential Information (including know-how and trade secrets) and all other intellectual property rights,
							in each case whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or extensions of,
							and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection that subsist or will subsist now or in the future in any part of the world.
						</li>
						<li>
							The Wine Advocate, Inc. alone (and its licensors, where applicable) shall own all right, title and interest,
							including all related Intellectual Property Rights in and to the Site and the Content and any suggestions, ideas, enhancement requests, feedback,
							recommendations or other information provided by you or any other party relating to your subscription.
							Save as provided in this Agreement, this Agreement does not convey to you any rights of ownership in relation to the Intellectual Property Rights, trademarks and/or Content.
						</li>
						<li>
							You may use our Intellectual Property Rights, including any trademarks or Content, subject at all times to the terms set out in this Agreement and only in a manner compliant with the Usage Guidelines set out below.
						</li>
						<li>
							You shall not reproduce partially or in full any article published in The Wine Advocate and place it in any printed or digital support.
						</li>
						<li>
							You may use the Content, including scores and tasting notes, on a single website, mobile application, or physical store (each of which shall be referred to as a &quot;Platform&quot;)
							that is directly controlled and owned by you, provided, where practical, that you include a global link to the Site on your Platform. For the purposes of this Agreement,
							a &quot;global link&quot; means a reference to the URL of the Site. In both online and physical stores,
							our brand name must be mentioned next to each score or tasting note so as to clearly identify us as the source of the Content. Our brand may be referred to as The Wine Advocate,
							or Robert Parker Wine Advocate. If you use the acronym RP or WA, you must clearly indicate on the website or printed document that the acronym refers to &quot;The Wine Advocate&quot; or to &quot;Robert Parker Wine Advocate.&quot;
							You may mention the name of the wine critic who rated the wine, provided that you also mention jointly Robert Parker Wine Advocate, or &quot;RP&quot; or &quot;WA.&quot;
							Each Commercial Subscription entitles you to use the Content on one Platform only. Websites with a common core URL followed by variations in the end of the URL,
							such as variations per shop or per country (e.g: .us, .uk,…) are considered different platforms.
						</li>
						<li>
							You may use Content in relation to up to two hundred (200) unique wines and vintages at any given time, as long as such use is consistent with the other terms of use described in this Agreement.
							Content posted on the same wine for two different vintages counts as two (2) unique Content postings.
							If you require the capacity to use Content about more than 200 wines and vintages, you may contact us at commercial@robertparker.com for a commercial discussion.
							This Content cannot be aggregated or otherwise compiled with any other information.
							The Content, including tasting notes and scores, may be presented or provided to others in a searchable or database format created by you (&quot;Subscriber-Created Works&quot;),
							provided, however, that the use of such Content otherwise complies with the terms of this Agreement, including the Usage Guidelines.
							Without our written consent, you may not use the Content in blogs, websites, social media or other information related publications or
							formats that provide others with general information about wines, or a particular wine, and that is unrelated to your actual sale or distribution of a particular wine.
							Sale or distribution of a particular wine implies that you own stocks of this wine registered in your company’s general ledger of accounts as company’s inventory,
							and that this inventory is fully owned by you. You may not to use the Content on your commercial and marketing supports such as websites or catalogues,
							related to wines that you do not own and that you do not carry as your current stock.
						</li>
						<li>
							You may only use the Content for in-store displays in accordance with our Usage Guidelines and our official display aids.
							Please contact us at commercial@robertparker.com for more details.
						</li>
						<li>
							Except as otherwise permitted, your subscription is limited to your use and not transferable or sub-licensable to, or usable by, any other person, business or entity.
						</li>
						<li>
							In addition, access to the Site using computer programs, software or routines, or any other means, including but not limited to, web robots, web wanderers,
							crawlers or spiders, that are designed to systematically download the Content in any manner, is specifically and strictly prohibited.
						</li>
						<li>
							Should the subscriber reproduce, post or archive Content in its marketing materials or communication supports of any form,
							the Subscriber accepts that this activity does not constitute a publication of the Content and that it does not provide any rights to the Content copyright regardless of the copyright registration status in the subscriber’s jurisdiction.
							The Subscriber has the duty to erase and delete any Content from its marketing materials or communication supports as soon as the Content does not expressly relate to the presentation of wines promoted and sold as part of the active and
							fully owned inventory of the Subscriber. If you have used the Content for wines in your inventory that have subsequently been removed from your inventory or otherwise become out of stock, you are required to remove the Content from your
							site and marketing materials or communication supports of any form within 10 (ten) working days of the wine being out of stock.
						</li>
						<li>
							<TextStyle1>
								Any violation of this Agreement or use of the Content beyond what is specifically permitted in this Agreement is grounds for immediate suspension or termination
								of your subscription without notice and without a right of refund of your subscription fee, and may constitute an infringement of our intellectual property rights,
								for which we reserve the right to seek damages, legal fees and costs.
							</TextStyle1>
						</li>
					</ol>
				</SectionDescription>

				<Title>E. TERM OF YOUR SUBSCRIPTION</Title>
				<SectionDescription>
					<ol>
						<li>
							Unless otherwise previously terminated, subscriptions are for one year. Using the information you provided when you registered,
							we will attempt to notify you several weeks before your subscription expires so as to remind you of the renewal.
							Your subscription will then be automatically renewed unless you turn off the automatic renewal by visiting the online &quot;Manage Subscription&quot; page and change your account information.
							Commercial subscription fees are not refundable.
						</li>
					</ol>
				</SectionDescription>

				<Title>F. TERMINATION OF YOUR SUBSCRIPTION</Title>
				<SectionDescription>
					<ol>
						<li>
							If you breach any of the terms of this Agreement, we reserve the right to suspend or terminate your subscription and access to the Site with notice and without a right of refund of your subscription fee.
						</li>
					</ol>
				</SectionDescription>

				<Title>G. YOUR PERSONAL INFORMATION</Title>
				<SectionDescription>
					<ol>
						<li>
							By subscribing to the Site, you hereby consent to the collection and processing of corporate or personal data provided at registration for the purposes of managing your subscription. Our full privacy notice may be accessed here.
						</li>
						<li>
							We may occasionally allow other companies to provide information to our subscribers about their wine-related products or services.
							We do not provide any information about you to these companies other than your email address and will do so only if you agree to it at the time you register.
							You may withdraw your consent at any time.
						</li>
						<li>
							We shall endeavor to protect the security of your personal information. We have implemented procedures that are intended to protect your data from loss, misuse,
							unauthorized access or disclosure, alteration, or destruction. Your personal information is not shared by us without your permission.
							Your data is stored in password-controlled secure servers, and your information will be stored and processed in the United States and Singapore.
						</li>
						<li>
							You also have a significant role in protecting your information. No one can see or edit your subscription information without knowing your Username and Password, so do not share these with others.
						</li>
						<li>
							We shall not be responsible for any loss, destruction, or alteration of information or data stored under your Subscriber’s account,
							such data includes your wine inventory and related information that you may register under the &quot;Cellar&quot; function of the website.
						</li>
					</ol>
				</SectionDescription>

				<Title>H. USE OF COOKIES</Title>
				<SectionDescription>
					<ol>
						<li>
							When you sign onto our Site, you are assigned a personal identification number that is sent to your Device in the form of a small text file called a &quot;cookie.&quot;
							This cookie allows us to monitor your use of our Site.
							This is done only for the purpose of protecting us and protecting you should your Username and Password become available to someone other than you.
						</li>
						<li>
							If you choose to not have your browser accept cookies from the RobertParker.com Site, you will not be able to access the Site. Our full cookie policy may be accessed here.
						</li>
					</ol>
				</SectionDescription>

				<Title>I. LIMITATION OF LIABILITY</Title>
				<SectionDescription>
					<ol>
						<li>
							We will use commercially reasonable efforts to ensure the accuracy, responsiveness and availability of the Site to its subscribers. Under no circumstances,
							will we be liable for any direct or indirect damages with respect to the accuracy of the information,
							the availability of the Site, the responsiveness of the Site or your use of information provided to you by the Site.
						</li>
					</ol>
				</SectionDescription>

				<Title>J. COMPLETE AGREEMENT</Title>
				<SectionDescription>
					<ol>
						<li>
							This is the complete Agreement with respect to your use of the Site as a Commercial Subscriber.
							Nothing published or stated elsewhere should be viewed as a modification of this Agreement.
						</li>
					</ol>
				</SectionDescription>

				<Title>K. GOVERNING LAW AND JURISDICTION</Title>
				<SectionDescription>
					<ol>
						<li>
							This Agreement shall be construed, interpreted and determined in accordance with the laws of the Republic of Singapore.
							Any dispute arising out of or in connection with this Agreement, including any question regarding its existence, validity or termination,
							shall be referred to and finally resolved by arbitration in Singapore in accordance with the Arbitration Rules of the Singapore International Arbitration Centre (&quot;SIAC Rules&quot;)
							for the time being in force, which rules are deemed to be incorporated by reference in this clause.
							The Tribunal shall consist of 1 (one) arbitrator. The language of the arbitration shall be English.
						</li>
					</ol>
				</SectionDescription>

				<Title>L. INJUNCTIVE RELIEF</Title>
				<SectionDescription>
					<ol>
						<li>
							Your breach of this Agreement may cause irreparable damage that cannot be readily remedied by monetary damages in an action at law and may also constitute an infringement of trademark rights and/or copyright rights.
							As such, you agree to the entry of an immediate injunction, in addition to any other remedies available at law or in equity, to stop or prevent any irreparable harm,
							loss or dilution as result of a breach of this Agreement.
						</li>
					</ol>
				</SectionDescription>

				<Title>M. CHANGES TO THIS AGREEMENT</Title>
				<SectionDescription>
					<ol>
						<li>
							We may occasionally update the terms and conditions of this Agreement. When we do,
							we will also revise the &quot;last updated&quot; date at the top of the copy of the Agreement posted on the Site.
							For changes to this Agreement we consider material, RobertParker.com will notify you by placing a notice on the Site. Such changes will be effective when posted by us.
							Your continued use of the Site after the date upon which this Agreement is changed, and the changes are posted, will constitute your continued acceptance of this Agreement.
							Your only alternative, should you no longer wish to be bound by this Agreement, is termination of your use of the Site. No full or partial refunds will be offered in such an event.
						</li>
					</ol>
				</SectionDescription>
				<CommercialExample />
			</SectionContainer>
		</div>
	)
}

export default CommercialSubscription