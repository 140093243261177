import React, { ReactElement, useEffect, Fragment } from 'react'
import { useHistory  } from 'react-router-dom'

function ScrollToTop(props: { children: any }): ReactElement {

	const history = useHistory()

	useEffect(() => {
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0)
		})

		return () => {
			unlisten()
		}
	}, [])

	return <Fragment>{props.children}</Fragment>
}

export default ScrollToTop