import TranslationsUtil from './TranslationsUtil'

import { GetBaseQuery } from './APIUtil'
import * as Constant from './Constant'

import {
	ClearUserSession,
	IsAppAuthenticated,
	IsUserAuthenticated,
	IsUserAuthenticationExpired,
	SaveAuthenticationSession
} from './AuthenticationUtil'

import { ExtractOptionValues, GenerateQueryForFilters } from './FilterUtils'

import {
	AttatchCustomSortOrder, ConvertJSONArticlesCollectionToClass, ConvertJSONArticleToClass, FilterArticlesByIssueID, GetArticlesForPreview, GetGroupedArticles,
	GetGroupedArticlesSummary,
	GetGroupedTaggedArticles
} from './ArticlesUtil'

import { GetPreviousNextLinks } from './IssuesUtil'

import { ConvertJSONReviewerToClass } from './ReviewersUtil'

import { GetPercentageOfDiscount, GetPromoType, IsCodeActive } from './Payment'

import {
	CleanPriorSortingFilters,
	ExtractFilterFromURL, FilterCollection, GenerateFilterQueriesFromSelectedFilters,
	GenerateFullURLQueryForSearch,
	GetArticleIdFromQuery, GetFiltersFromQuery, InjectFilterIntoURL,
	IsFilteredFromArticle,
	IsOptionChecked,
	ToggleAdvancedSearchChoice,
	TriggerSearch,
	UpdateSearchFilters
} from './SearchUtil'

import {
	ConvertJSONTastingNotesCollectionToClass, ConvertJSONTastingNoteToClass
} from './TastingNotesUtil'

import {
	FilterCountriesFromScores,
	FilterCountryGroupFromScores,
	
	FilterVintageScoresFromGroupScores,
	GetRowListFromScoresAndCountry,
	SplitRatingSegments
} from './VintageChart'

import { APP_CONFIG } from '../configs'
import {
	ConvertJSONWineCollectionToClass, ConvertJSONWineToClass, GetShortlistedResults, GetWineNameDisplay
} from './WinesUtils'

export { Constant }

export function Shuffle<ArrayObject>(array: ArrayObject[]): ArrayObject[] {
	let currentIndex = array.length,
		temporaryValue,
		randomIndex
	while (0 !== currentIndex) {
		randomIndex = Math.floor(Math.random() * currentIndex)
		currentIndex -= 1
		temporaryValue = array[currentIndex]
		array[currentIndex] = array[randomIndex]
		array[randomIndex] = temporaryValue
	}

	return array
}

/**
 * Paginating generic collection
 *
 * @param collection
 * @param size
 * @param page
 * @returns
 */
export function Paginate<GenericCollection>(
	collection: GenericCollection[],
	size: number = APP_CONFIG.TASTING_NOTES.MAX_ITEM_PER_PAGE,
	page = 1
): GenericCollection[] {
	return collection.slice((page - 1) * size, page * size)
}

export {
	AttatchCustomSortOrder,
	CleanPriorSortingFilters,
	ConvertJSONArticleToClass,
	ConvertJSONArticlesCollectionToClass,
	ConvertJSONReviewerToClass,
	ConvertJSONTastingNotesCollectionToClass,
	ConvertJSONTastingNoteToClass,
	ConvertJSONWineToClass,
	ConvertJSONWineCollectionToClass,
	ClearUserSession,
	ExtractFilterFromURL,
	ExtractOptionValues,
	FilterCountriesFromScores,
	FilterCountryGroupFromScores,
	FilterVintageScoresFromGroupScores,
	GenerateQueryForFilters,
	GetArticleIdFromQuery,
	GetArticlesForPreview,
	GetBaseQuery,
	GetFiltersFromQuery,
	GetGroupedArticles,
	GetGroupedArticlesSummary,
	GetGroupedTaggedArticles,
	GetPreviousNextLinks,
	GetPercentageOfDiscount,
	GetPromoType,
	GetRowListFromScoresAndCountry,
	GetWineNameDisplay,
	GenerateFilterQueriesFromSelectedFilters,
	GenerateFullURLQueryForSearch,
	GetShortlistedResults,
	FilterCollection,
	InjectFilterIntoURL,
	IsCodeActive,
	IsFilteredFromArticle,
	IsOptionChecked,
	IsUserAuthenticationExpired,
	FilterArticlesByIssueID,
	IsAppAuthenticated,
	IsUserAuthenticated,
	SaveAuthenticationSession,
	SplitRatingSegments,
	ToggleAdvancedSearchChoice,
	TranslationsUtil,
	TriggerSearch,
	UpdateSearchFilters,
}

