import styled from 'styled-components'

export const UpgradePlanContainer = styled.div`
	padding: 15px;
    margin: auto;
    width: 50%;

	& .subHeader{
		display: flex;
		flex-direction: column;
		margin: 20px 0px 20px 0px;

	}
	
	& .backButton {
		width: 213px;
		height: 14px;
		font-size: 12px;
		font-family: Libre Franklin;
		font-weight: bold;
		font-style: underline;
		color: #73333f;
		cursor: pointer;
	}

	& .instruction {
		width: auto;
		height: 19px;
		font-family: Libre Franklin;
		font-size: 16px;
		color: #333;
	}


    & .changePlan {
        display: flex;
        align-items: center;
        justify-content: space-between;
		padding: 20px 0px 0px;

        & .reduce-title {
            font-weight: bold;
            font-size: 20px;
        }
    }

    & .upgradePlan-content {
		width: 100%;
        display: flex;
		align-items: flex-start;
		justify-content: space-between;

		& .main-selectContainer {
			width: 100%;
		}
    }

	& .selectContainer {
		width: auto;
		display: flex;
		justify-content: space-between;
		align-items: center;

		& .planSelect {
			width: 268px;
  			height: 33px;
		}

		& .qtyInput {
			& label {
				padding-right: 10px;
			}
		}

		& .qtySelect {
			width: 98px;
  			height: 33px;
		}
	}

	& .footerTitle {
		font-size: 16px;
	}

    @media (max-width: 992px) {
        width: 85%;
    }
	
`

export const CancelButton = styled.button`
	width: 104px;
  	height: auto;
  	padding: 6px 12px 6px 12px;
  	border-radius: 8px;
  	border: solid 1px #ccc;
  	background-color: #ffffff;
	display: flex;
	flex-direction: row;
	justify-content: center;
	text-decoration: none !important;


	& span {
		font-weight: bold;
	}

	& .mobile {
		padding-right: 12px !important;
	}

	&:hover {
		background-color: #efefef;
	}
`


export const UpgradePlanButton = styled.button`
	width: fit-content;
  	height: auto;
  	padding: 6px 26px;
  	border-radius: 8px;
  	background-color: #73333f;
	font-family: Libre Franklin;
  	font-size: 14px;
  	font-weight: bold;
  	font-stretch: normal;
  	font-style: normal;
  	line-height: 1.2;
  	letter-spacing: normal;
  	text-align: center;
	color: #fff;
	border: none;

	&:hover {
		background-color: #8E4250;
	}
`


export const TotalLabel = styled.div`
	display: flex;
	justify-content: end;
`



