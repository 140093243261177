/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { createSlice } from '@reduxjs/toolkit'
import { AlgoliaSearchAPI } from '../services'
import { RootState } from '../store'

const initialState: SearchResultsStoreData = {
	Wines: {
		total: 0,
		matches: [],
		filters: {},
		selectedWines: []
	},
	Articles: {
		total: 0,
		matches: [],
		filters: {},
	},
	display: 'wines',
}

const SearchResultSlice = createSlice({
	name: 'SearchResultSlice',
	initialState,
	reducers: {
		AddSelectedWines: (state, action) => {
			state.Wines.selectedWines.push(action.payload)
		},
		RemoveSelectedWineById: (state, action) => {
			state.Wines.selectedWines = state.Wines.selectedWines.filter(wine => wine.id !== action.payload)
		},
		ClearSelectedWines: (state) => ({
			...state,
			Wines: initialState.Wines,
		}),
		SetDisplayMode: (state, action) => ({
			...state,
			display: action.payload.display,
		}),
		ClearWineSearch: (state) => ({
			...state,
			Wines: {
				...state.Wines,
				total: 0,
				matches: [],
				filters: {}
			},
		}),
		ClearArticleSearch: (state) => ({
			...state,
			Articles: initialState.Articles,
		}),
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(
				AlgoliaSearchAPI.endpoints.getWinesFromAlgolia.matchPending,
				() => {
					// placeholder
				}
			)
			.addMatcher(
				AlgoliaSearchAPI.endpoints.getWinesFromAlgolia.matchFulfilled,
				(state, action) => ({
					...state,
					Wines: {
						...state.Wines,
						total: action.payload.nbPages,
						matches: action.payload.hits,
						filters: action.payload.facets,
					},
				})
			)
			.addMatcher(
				AlgoliaSearchAPI.endpoints.getWinesFromAlgolia.matchRejected,
				(state) => ({
					...state,
					Wines: {
						...state.Wines,
						total: 0,
						matches: [],
						filters: {},
					},
				})
			)
			.addMatcher(
				AlgoliaSearchAPI.endpoints.getArticlesFromAlgolia.matchPending,
				() => {
					// placeholder
				}
			)
			.addMatcher(
				AlgoliaSearchAPI.endpoints.getArticlesFromAlgolia.matchFulfilled,
				(state, action) => (
					{
						...state,
						Articles: {
							total: action.payload.nbPages,
							matches: action.payload.hits,
							filters: action.payload.facets,
						},
					}
				)
			)
			.addMatcher(
				AlgoliaSearchAPI.endpoints.getArticlesFromAlgolia.matchRejected,
				(state) => ({
					...state,
					Articles: {
						total: 0,
						matches: [],
						filters: {},
					},
				})
			)
	},
})

export const { ClearArticleSearch, ClearWineSearch, SetDisplayMode, AddSelectedWines, ClearSelectedWines, RemoveSelectedWineById} =
	SearchResultSlice.actions

export default SearchResultSlice.reducer

export const SearchResults = (state: RootState): SearchResultsStoreData =>
	state.SearchResults
