import { ArticleWriter } from '../interfaces/ArticleWriter'
import Author from './Author'

export default class Reviewer extends Author implements ArticleWriter {
	associated_regions: string
	base: string
	bio: string
	type: string
	social: string

	constructor(params: ReviewerJSON) {
		super(
			params.id,
			params.name,
			params.profile_image ? params.profile_image.url : ''
		)

		this.associated_regions = params.associated_regions
		this.base = params.base ? params.base : ''
		this.bio = params.bio ? params.bio : ''
		this.type = params.type
		this.social = params.social ? params.social : ''
	}

	getBio(): string {
		return this.bio
	}

	getId(): string {
		return this.id
	}

	getName(): string {
		return this.name
	}

	getProfilePicture(): string {
		return this.profile_image
	}

	getType(): string {
		return this.type
	}

	getSocial(): string {
		return this.social
	}
}
