import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import JoinGroupNewUser from './JoinGroupNewUser'
import JoinGroupExistingUser from './JoinGroupExistingUser'
import NonExistPage from '../../404-page/NonExistPage'

const JoinGroup = ():ReactElement => {
	const {type} = useParams<{type: string}>()

	if(type === 'existing-user'){
		return <JoinGroupExistingUser />
	}

	if(type === 'new-user'){
		return <JoinGroupNewUser />
	}
	return <NonExistPage />
}

export default JoinGroup
