/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit'
import { UsersAPIv2 } from '../services'
import { RootState } from '../store'

interface UsersInitialState {
	userData: any
}

const initialState: UsersInitialState = {
	userData: null,
}

const UsersSlice = createSlice({
	name: 'UsersSlice',
	initialState,
	reducers: {
		UpdateEndSubscription: (state, action) => ({
			...state,
			userData: {
				...state.userData,
				subscription: {
					...state.userData.subscription,
					end: action.payload.end
				}
			}
		})
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(
				UsersAPIv2.endpoints.getUserDetailsById.matchFulfilled,
				(state, action) => ({
					...state,
					userData: action.payload.data
				})
			)
			.addMatcher(
				UsersAPIv2.endpoints.getManagerDetailsById.matchFulfilled,
				(state, action) => ({
					...state,
					managerData: action.payload.data
				})
			)
	},
})

export const { UpdateEndSubscription } = UsersSlice.actions

export default UsersSlice.reducer

export const Users = (state: RootState): UsersInitialState => state.Users
