/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS, RP_ENV } from '../../configs'
import { GetBaseQuery } from '../../utils/'
import { MAX_RETRIES } from './constant'
import { reverseObfuscateConstant } from '../../utils/Constant'

export const IssuesAPIv2 = createApi({
	reducerPath: 'IssuesAPIv2',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2)),
	endpoints: (builder) => ({
		getIssues: builder.query<any, {limit: number, offset: number, keyword?: string}>({
			query: ({limit, offset, keyword}) => {
				if(!keyword) {
					return `${API_ENDPOINTS.get_issues}?offset=${offset}&limit=${limit}`
				} else {
					return `${API_ENDPOINTS.get_issues}?offset=${offset}&limit=${limit}&keyword=${keyword}`
				}
			} 
		}),
		getLatestIssue: builder.query({
			query: () => API_ENDPOINTS.get_latest_issue,
		}),
	}),
})


export const {
	useGetLatestIssueQuery,
	useGetIssuesQuery
} = IssuesAPIv2