import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
// import Breadcrumbs from '../../sections/breadcrumbs'
import { PageTitle, ProfileContainer, PictureContainer, DefaultPicture, Name, ArticleByCons, Description, Separator, AuthorDetailContainer } from './styles'

import { contributors } from './data'

function Contributors(): ReactElement {

	return (
		<div className="single-entry">
			<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
				<header className="container">
					{/* <Breadcrumbs /> */}
					<PageTitle>Our Contributors</PageTitle>
				</header>
				<div className="container">
					<br />
					{ contributors.map((data, index: number) => {
						return (
							<>
								<ProfileContainer key={ index } >
									<AuthorDetailContainer>
										{ data.profile_image !== '' ? (
											<PictureContainer>
												<div className='author-avatar'>
													<img src={ data.profile_image } className="avatar photo" />
												</div>
											</PictureContainer>
										) : <DefaultPicture>

										</DefaultPicture>
										}
									</AuthorDetailContainer>
									<div>
										<Link to={'#'} >
											<Name>{ data.name }</Name>
										</Link>
										<Description>
											{ data.description }
										</Description>
										<ArticleByCons>{ data.articles }</ArticleByCons>

									</div>
								</ProfileContainer>
								<Separator />
							</>
						)
					})
					}
				</div>
			</div>
		</div>
	)
}

export default Contributors