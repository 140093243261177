import styled from 'styled-components'

const commonTextStyles = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
`

export const PageTitle = styled.h3`
	font-size: 22px;
	font-weight: bold;
	color: #333;
	${commonTextStyles}
`
export const OrangeButton = styled.div`
	height: 33px;
	padding: 6px 15px;
	border-radius: 8px;
	background-color: #73333f;
	color: #fff;
	font-weight: bold;
	cursor: pointer;
	text-align: center;
`
export const SectionContainer = styled.div`
	& div {
		margin-bottom: 20px;
	}
`

export const FormFieldContianer = styled.div`
	& div.form-label {
		font-size: 16px;
		font-weight: bold;
		color: #333;
		margin-bottom: 5px;

		& span {
			color: red;
		}
	}

	& div.form-value {
		max-width: 386px;
	}

	& p {
		height: 48px;
		font-size: 16px;
		font-weight: normal;
		color: #333;
	}

	& label.unbold {
		font-weight: normal;
		pointer: cursor;
	}

	& div.sub-options {
		margin-bottom: 0px;

		& input {
			margin-right: 8px;
		}
	}
`


export const FormButtonsContainer = styled.div`
	display: flex;

	& div {
		margin-right: 10px;
	}
`
