import React, { ReactElement } from 'react'
import { RATING_RANGES } from '../../../../configs'

// Utilities
import { SplitRatingSegments } from '../../../../utils'

import { ChartRatingDisplay, RatingRanges } from './styles'

function RatingDisplay(props: { rating: string }): ReactElement {

	const { rating } = props

	const segments = SplitRatingSegments(rating)

	const getColoredRatingDisplay = () => {

		let color = ''
		let key = 0
		let displayRating = segments.rating

		if (displayRating.includes('-')) {
			const splitArray = displayRating.split('-')

			displayRating = splitArray[0]
		}

		RATING_RANGES.reverse().map((range: any, rating_range_key: number) => {
			if (segments.label !== 'NT') {
				if (Number(displayRating) >= range.low && Number(displayRating) <= range.high) {
					color = range.color
					key = rating_range_key
				}
			}
		})

		return (<ChartRatingDisplay key={key} color={color}>{`${rating}`}</ChartRatingDisplay>)
	}

	return (
		<>
			{segments.label === 'NT' ? (<ChartRatingDisplay color={'#333'} >{rating}</ChartRatingDisplay>) : getColoredRatingDisplay()}
		</>
	)
}

export default RatingDisplay