/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ProducersContainer } from './style'
import { useAppSelector } from '../../../../../app/hooks'
import {SearchFilters as SearchFiltersFromStore, SetFacetsFilters} from '../../../../../app/slices/SearchFilters'
import { Link } from 'react-router-dom'

type Props = {
    producersData: {count: number; name: string}[];
	onWineListView: (type: string) => void; 
}

function ProducersResults({producersData, onWineListView}: Props): ReactElement {
	const {ActiveFilters: activeFilters} = useAppSelector(SearchFiltersFromStore)
	const [producers, setproducers] = useState<any[]>([])
	
	const dispatch = useDispatch()

	useEffect(() => {
		setproducers(producersData)
	}, [producersData])
	

	const searchProducerHandler = (name: string) => {
		const producer = [`producer:${name.toLowerCase()}`]
		dispatch(SetFacetsFilters({
			activeFilters: {
				...activeFilters,
				producer
			}
		}))
		onWineListView('default')
	}
    
	return <ProducersContainer>
		{producers.map((producer:{ name: string; count: number }, index: number) => <Link to={`/search/wine?producer=${encodeURIComponent(producer?.name)}`} onClick={()=>searchProducerHandler(producer.name)} key={index} className='producer-row'>
			<p className='producer-name'>{producer.name}</p>
			<span className='producer-display'>
				<span>{producer.count} wines</span>
			</span>
		</Link>)}
	</ProducersContainer>
}

export default ProducersResults
