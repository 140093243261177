/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../../../../app/hooks'
import { APP_CONFIG } from '../../../../configs'

import { ActionButton, ButtonStickySearch, CloseButton, DivFilters, DivFiltersMobile, FilterButton, HardcodedOption, HeaderFilterMobile, HeaderSticky } from './common/styles'
import FilterOptions from './sections/FilterOptions'

// Data and slices
import { ClearActiveAdvanceFilters, ClearPublicationDateFilters, ClearRangeFilters, SearchFilters as SearchFiltersFromStore, SetActiveAdvanceFilters, SetFacetsFilters, SetRatingRanges } from '../../../../app/slices/SearchFilters'
import { IsChecked, generateActiveFilters } from '../../../../utils/SearchUtil'
import SectionSeparator from '../../sections/section-separator'
import { IsUserAuthenticated } from '../../../../utils'
import { ResetButton, TitleResetContainer } from '../advanced-search/styles'
import PublicationDateFilter from '../advanced-search/sections/PublicationDateFilter'
import { Authentication as AuthFromStore } from '../../../../app/slices/Authentication'
import RatingRangeFilter from '../advanced-search/sections/RatingRangeFilter'
import { Users as UsersFromStore } from '../../../../app/slices/Users'
import { isFreeUser } from '../../../../utils/AuthenticationUtil'
import queryString from 'query-string'

type FiltersType = {
	searchType: string;
	filters: SearchFilterObject;
	wineDisplay: string;
	onSwitchList: (type: string) => void;
	onToggleClear: () => void;
	dataRequest: any;
	dataLength: any;
	rangeData: any;
}

type PriceRatingRangeType = {
	price_low: number[],
	rating_computed: number[]
}

type PublicationDateType = {
	dateFrom: number,
	dateTo: number,
}

function Filters({searchType, filters, wineDisplay, onSwitchList, dataRequest, dataLength, onToggleClear, rangeData}: FiltersType): ReactElement {

	const [filtersBody, setFiltersBody] = useState({
		facetFilters: dataRequest.facetFilters,
		filters: dataRequest.filters,
		query: dataRequest.query
	})

	const [isSliderMoved, setIsSliderMoved] = useState({
		price_low: false,
		rating_computed: false,
	})
	const [priceRatingRangeData, setPriceRatingRangeData] = useState<PriceRatingRangeType>({
		price_low: [0, 110000],
		rating_computed: [50, 100]
	})
	const [range, setRange] = useState({
		price_low: {
			min: 0,
			max: 200000
		},
		rating_computed: {
			min: 50,
			max: 100
		}
	})

	const [initialRange, setInitialRange] = useState({
		rating_computed: {
			min: 50,
			max: 100
		}
	})

	const [publicationDateData, setPublicationDateData] = useState<PublicationDateType>({
		dateFrom: 0,
		dateTo: 0,
	})

	const { search } = useLocation()

	const queryStrings = queryString.parse(search) as any
	const authentication = useAppSelector(AuthFromStore)
	const isAuthenticated = IsUserAuthenticated()
	const { userData } = useAppSelector(UsersFromStore)
	const didMountRef = useRef(false)
	const dispatch = useDispatch()
	const activeFilterData = useAppSelector(SearchFiltersFromStore)

	// const dataRef = useRef<any>([activeFilterData.ActiveFilters, activeFilterData.Keywords, activeFilterData.AdvanceFilter?.article_id, activeFilterData.AdvanceFilter?.date_last_tasted])
	// const [runRatingRangeQuery, setRunRatingRangeQuery] = useState(false)
	const [runValueHandler, setRunValueHandler] = useState(true)
	const [isFilterToggle, setIsFilterToggle] = useState(false)
	const isInitialRangeSetRef = useRef(false)

	useEffect(() => {
		// const previousFilterData = dataRef.current
		// dataRef.current = [activeFilterData.ActiveFilters, activeFilterData.Keywords, activeFilterData.AdvanceFilter?.article_id, activeFilterData.AdvanceFilter?.date_last_tasted]
	
		// if (JSON.stringify(previousFilterData) === JSON.stringify([activeFilterData.ActiveFilters, activeFilterData.Keywords, activeFilterData.AdvanceFilter?.article_id, activeFilterData.AdvanceFilter?.date_last_tasted])) {
		// 	setRunRatingRangeQuery(false)
		// } else {
		// 	setRunRatingRangeQuery(true)
		// }
		
		if(runValueHandler){
			ratingValueHandler()
		}

		if(activeFilterData.AdvanceFilter?.rating_computed){
			if(activeFilterData.AdvanceFilter?.rating_computed[0] === -1){
				setPriceRatingRangeData(prevState => ({
					...prevState,
					rating_computed: [activeFilterData.AdvanceFilter?.rating_computed[0], activeFilterData.AdvanceFilter?.rating_computed[1]]
				}))
			} else {
				setPriceRatingRangeData(prevState => ({
					...prevState,
					rating_computed: [activeFilterData.AdvanceFilter?.rating_computed[0], activeFilterData.AdvanceFilter?.rating_computed[1]]
				}))
			}
		}		
	},[activeFilterData])

	useEffect(() => {
		if(queryStrings.date_last_tasted){
			const [fromTimestampStr, toTimestampStr] = queryStrings.date_last_tasted.split(' TO ')
			
			const fromTimestamp = parseInt(fromTimestampStr)
			const toTimestamp = parseInt(toTimestampStr)

			setPublicationDateData(prevState => ({
				...prevState,
				dateFrom: fromTimestamp, dateTo: toTimestamp,
			}))
		}
	}, [])

	const { ActiveFilters: activeFilters } = useAppSelector(SearchFiltersFromStore)
	const { AdvanceFilter: advanceFilter } = useAppSelector(SearchFiltersFromStore)

	useEffect(() => {
		if (advanceFilter.rating_computed) {
			const minRating = advanceFilter.rating_computed[0]
			const maxRating = advanceFilter.rating_computed[1]

			if (!isInitialRangeSetRef.current) {
				setInitialRange({
					rating_computed: {
						min: minRating,
						max: maxRating,
					},
				})
				isInitialRangeSetRef.current = true
			}
		}
	}, [advanceFilter])


	const ratingValueHandler = () => {
		if (activeFilterData.AdvanceFilter?.rating_computed){
			setPriceRatingRangeData(prevState => ({
				...prevState,
				rating_computed: [activeFilterData.AdvanceFilter.rating_computed[0], activeFilterData.AdvanceFilter.rating_computed[1]]
			}))
			setRange(prevState => ({
				...prevState,
				rating_computed: {
					min: activeFilterData.CollectedFilters.RatingRange?.rate_computed[0],
					max: activeFilterData.CollectedFilters.RatingRange?.rate_computed[1],
				},
			}))
		}
		setRunValueHandler(false)
	}

	const toggleRatedFilter = (event: React.ChangeEvent<HTMLInputElement>, reference: string) => {
		dispatch(SetFacetsFilters({
			activeFilters: generateActiveFilters(activeFilters, reference, event.target.id)
		}))
	}

	useEffect(() => {
		const filterValue = dataRequest.filters

		const getArticleID = /referring_articles:[\w\d]+/
		const match = filterValue.match(getArticleID)
		const referringArticles = match ? match[0] : ''

		setFiltersBody({
			facetFilters: dataRequest.facetFilters,
			filters: referringArticles,
			query: dataRequest.query
		})
	}, [dataRequest])

	useEffect(() => {
		// if(rangeData && runRatingRangeQuery && authentication.User.accessToken) {
		if(rangeData && authentication.User.accessToken) {
			if(!isFilterToggle) {
				let ratingsMin = 0
				const priceMin = rangeData?.price_low?.min,
					priceMax = rangeData?.price_low?.max,
					ratingsMax = rangeData?.rating_computed?.max
	
				if(rangeData?.rating_computed?.min > 0){
					ratingsMin = rangeData?.rating_computed?.min
				}
				
				if(!isSliderMoved.rating_computed && !isSliderMoved.price_low){
					setRange({
						price_low: {
							min: priceMin,
							max: priceMax,
						},
						rating_computed: {
							min: ratingsMin,
							max: ratingsMax,
						}
					})
					setPriceRatingRangeData({
						rating_computed: [ratingsMin, ratingsMax],
						price_low: [priceMin, priceMax]
					})
				} else {
					if(isSliderMoved.rating_computed) {
						setRange(prevState => ({
							...prevState,
							price_low: {
								min: priceMin,
								max: priceMax,
							},
						}))
						setPriceRatingRangeData(prevState => ({
							...prevState,
							price_low: [priceMin, priceMax]
						}))
					}
	
					if(isSliderMoved.price_low) {
						setRange(prevState => ({
							...prevState,
							rating_computed: {
								min: ratingsMin,
								max: ratingsMax,
							},
						}))
						setPriceRatingRangeData(prevState => ({
							...prevState,
							rating_computed: [ratingsMin, ratingsMax]
						}))
					}
				}
	
				setIsSliderMoved({
					price_low: false,
					rating_computed: false,
				})

				dispatchHandler(rangeData)
			}
		}

		// if(rangeData && !runRatingRangeQuery && authentication.User.accessToken){
		// 	setRange(prevState => ({
		// 		...prevState,
		// 		rating_computed: {
		// 			min: 50,
		// 			max: 100,
		// 		},
		// 	}))
		// }
	}, [rangeData])

	const dispatchHandler = (rangeData: any) => {
		dispatch(SetActiveAdvanceFilters({
			advanceFilter: { ['rating_computed']: [rangeData?.rating_computed?.min === -1 ? rangeData?.rating_computed?.min + 1 : rangeData?.rating_computed?.min, rangeData?.rating_computed?.max] }
		}))
		dispatch(SetRatingRanges({ key: 'rate_computed', value: [rangeData?.rating_computed?.min, rangeData?.rating_computed?.max] }))
		setIsFilterToggle(false)
	}

	useEffect(() => {
		if (didMountRef.current) {
			let filter = filtersBody.filters
			if (isSliderMoved.rating_computed || isSliderMoved.price_low) {
				// const timer = setTimeout(() => {
				dispatch(ClearRangeFilters())

				if (isSliderMoved.rating_computed) {
					filter = dispatchRangeFilter('rating_computed', filter)
				}

				if (isSliderMoved.price_low) {
					filter = dispatchRangeFilter('price_low', filter)
				}

				setFiltersBody(prevState => ({
					...prevState,
					filters: filter
				}))
				// }, 1500)

				return () => {
					// clearTimeout(timer)
					setIsSliderMoved(prevState => ({
						...prevState,
						rating_computed: false
					}))
				}

			}
		}
		didMountRef.current = true
	}, [priceRatingRangeData])

	const dispatchRangeFilter = (reference: 'rating_computed' | 'price_low', filter: string) => {
		
		dispatch(SetActiveAdvanceFilters({
			advanceFilter: { [reference]: [priceRatingRangeData[reference][0], priceRatingRangeData[reference][1]] }
		}))

		filter = updateRange(filter, reference, priceRatingRangeData[reference][0], priceRatingRangeData[reference][1])

		return filter
	}

	const updateRange = (inputString: string, fieldName: 'rating_computed' | 'price_low', newLowerBound: number, newUpperBound: number) => {
		
		if(!inputString){
			return `${fieldName}:${newLowerBound} TO ${newUpperBound}`
		}
		
		const regexPrice = new RegExp('price_low:(-?\\d+\\.?\\d*) TO (-?\\d+\\.?\\d*)', 'g')
		const regexRating = new RegExp('rating_computed:(-?\\d+\\.?\\d*) TO (-?\\d+\\.?\\d*)', 'g')

		if(fieldName === 'rating_computed' && regexPrice.test(inputString)) {
			return inputString.replace(regexPrice, `rating_computed:${newLowerBound} TO ${newUpperBound}`)
		}

		if(fieldName === 'price_low' && regexRating.test(inputString)) {
			return inputString.replace(regexRating, `price_low:${newLowerBound} TO ${newUpperBound}`)
		}

		const regex = new RegExp(`${fieldName}:(\\d+(?:\\.\\d+)?) TO (\\d+(?:\\.\\d+)?)`, 'g')
		return inputString.replace(regex, `${fieldName}:${newLowerBound} TO ${newUpperBound}`)
	}

	/*********canvas */
	const setInactive = () => {
		const offCanvas: HTMLElement | null = document.querySelector('#mnmd-filter')
		const backdrop: HTMLElement | null = document.querySelector('#backdrop-filter')
		if (offCanvas !== null && offCanvas.classList.contains('is-active')) {
			offCanvas.classList.remove('is-active', 'ps-container', 'ps-theme-default')
		}

		if (backdrop !== null && backdrop.classList.contains('mnmd-offcanvas-backdrop')) {
			backdrop.remove()
		}
	}
	/********* end canvas */

	const toggleFilter = () => {
		setIsFilterToggle(true)
		if(wineDisplay === 'producer') return onSwitchList('default')
	}

	const isRatingMoved = () => {
		if(!isSliderMoved.rating_computed){
			return setIsSliderMoved(prevState => ({
				...prevState,
				rating_computed: true
			}))
		}
	}

	const setRangeHandler = (data: number | number[], reference: string) => {
		if(reference === 'rating_computed' && typeof data !== 'number') {
			isRatingMoved()
			return setPriceRatingRangeData(prevState => ({
				...prevState,
				rating_computed: data
			}))
		}
	}

	const resetRangeHandler = (reference: string) => {
		if (reference === 'rating_computed') {
			if (activeFilterData.CollectedFilters.RatingRange) {

				// const ratingMin = activeFilterData.CollectedFilters.RatingRange?.rate_computed[0] === -1 ? activeFilterData.CollectedFilters.RatingRange?.rate_computed[0] + 1 : activeFilterData.CollectedFilters.RatingRange?.rate_computed[0]
				// const ratingMax = activeFilterData.CollectedFilters.RatingRange?.rate_computed[1]

				const ratingMin = initialRange.rating_computed.min
				const ratingMax = initialRange.rating_computed.max

				setRange(prevState => ({
					...prevState,
					rating_computed: {
						min: ratingMin,
						max: ratingMax,
					},
				}))

				dispatch(SetActiveAdvanceFilters({
					advanceFilter: { [reference]: [ratingMin, ratingMax] }
				}))
				return setPriceRatingRangeData(prevState => ({
					...prevState,
					rating_computed: [ratingMin, ratingMax]
				}))
			} else {
				const ratingMin = range.rating_computed.min === undefined ? 0 : range.rating_computed.min === -1 ? range.rating_computed.min + 1 : range.rating_computed.min
				const ratingMax = range.rating_computed.max === undefined ? 100 : range.rating_computed.max

				setRange(prevState => ({
					...prevState,
					rating_computed: {
						min: ratingMin,
						max: ratingMax,
					},
				}))
				
				dispatch(SetActiveAdvanceFilters({
					advanceFilter: { [reference]: [ratingMin, ratingMax] }
				}))

				return setPriceRatingRangeData(prevState => ({
					...prevState,
					rating_computed: [ratingMin, ratingMax]
				}))
			}
			
		}
	}

	const publicationDateApplyHandler = (data: any) => {
		const fromDate = data.fromDate
		const toDate = data.toDate

		const fromUnixTimestamp = new Date(fromDate).getTime()
		const toUnixTimestamp = new Date(toDate).getTime()
		dispatch(SetActiveAdvanceFilters({
			advanceFilter: { ['date_last_tasted']: [fromUnixTimestamp, toUnixTimestamp] }
		}))
	}

	const resetPublicationHandler = () => {

		setPublicationDateData(prevState => ({
			...prevState,
			dateFrom: 0,
			dateTo: 0,
		}))

		dispatch(ClearPublicationDateFilters())
	}

	const resetSearchQueryHandler = () => {
		dispatch(ClearActiveAdvanceFilters())
	}

	const filterDesktop = (
		<DivFilters>
			<br />
			<br />
			<div style={{ display: 'flex', flexDirection: 'column', gap: '0.6rem' }}>
				<FilterButton type={'white'} isEnabled={true}>
					<a href={`/advanced-search/${searchType}`}>Advanced Search</a>
				</FilterButton>
				{searchType === APP_CONFIG.SEARCH.TYPES.WINE && <ActionButton onClick={() => onSwitchList(wineDisplay === 'producer' ? 'default' : 'producer')}>
					{wineDisplay === 'producer' ? 'LIST WINES' : 'LIST PRODUCERS'}  
				</ActionButton>}
				
			</div>
			{searchType === APP_CONFIG.SEARCH.TYPES.WINE ? (
				<>
					<div>
						<HardcodedOption>
							<input onChange={(e) => toggleRatedFilter(e, 'eco_distinction')} type="checkbox" checked={IsChecked('eco_distinction', 'eco_distinction:true', activeFilters)} id='eco_distinction:true' />
							<label>Results with Green Emblem only</label>
						</HardcodedOption>
					</div>
				</>
			) : null}
			
			<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
				{isAuthenticated && !isFreeUser(userData) && searchType === APP_CONFIG.SEARCH.TYPES.WINE && 
					<>
						{priceRatingRangeData.rating_computed && <div>
							<TitleResetContainer>
								<span style={{ fontWeight: 'bold' }}>RP RATINGS</span>
								<ResetButton onClick={() => resetRangeHandler('rating_computed')} >Reset</ResetButton>
							</TitleResetContainer>

							<RatingRangeFilter rangeData={priceRatingRangeData.rating_computed} onSettingRangeHandler={setRangeHandler}/>
							<SectionSeparator position={'horizontal'} ></SectionSeparator>
						</div>}
					</>
				}

				{filters ? <>
					{searchType === APP_CONFIG.SEARCH.TYPES.ARTICLE &&
							<div>
								<TitleResetContainer>
									<span style={{ fontWeight: 'bold' }}>PUBLICATION DATE</span>
									<ResetButton onClick={() => resetPublicationHandler()} >Reset</ResetButton>
								</TitleResetContainer>

								<PublicationDateFilter type={'normal-search'} clickApplyHandler={publicationDateApplyHandler} publicationDateData={publicationDateData}/>
								<SectionSeparator position={'horizontal'} ></SectionSeparator>
							</div>
					}
					
					{dataLength.totalRecords !== 0 ? 
						<>					
							<FilterOptions filters={filters} searchType={searchType} wineDisplay={wineDisplay} onToggleFilter={toggleFilter} onToggleClear={() => onToggleClear()} rangeData={rangeData}/>
						</>
						:
						<>
							<ActionButton style={{width: '100%' }} onClick={() => resetSearchQueryHandler()}>RESET FILTERS</ActionButton>
						</>
					}</> : <></>}
			</div>
		</DivFilters>
	)

	const filterMobile = (
		<div id="mnmd-filter" className="mnmd-offcanvas">
			<HeaderSticky>
				<HeaderFilterMobile>FILTERS: WINES</HeaderFilterMobile>
				<ButtonStickySearch>
					<FilterButton type={'white'} isEnabled={true}>
						<a href={`/advanced-search/${searchType}`}>Advanced Search</a>
					</FilterButton>
					{searchType === APP_CONFIG.SEARCH.TYPES.WINE && <ActionButton onClick={() => onSwitchList(wineDisplay === 'producer' ? 'default' : 'producer')}>
						{wineDisplay === 'producer' ? 'LIST WINES' : 'LIST PRODUCERS'}  
					</ActionButton>}
				</ButtonStickySearch>
				<div style={{ fontWeight: 'bold' }}>
					<CloseButton onClick={setInactive} aria-label="Close">
						<span aria-hidden="true">&#10005;</span>
					</CloseButton>
				</div>
			</HeaderSticky>
			<DivFiltersMobile>
				{searchType === APP_CONFIG.SEARCH.TYPES.WINE ? (
					<>
						<div>
							<HardcodedOption>
								<input onChange={(e) => toggleRatedFilter(e, 'eco_distinction')} type="checkbox" checked={IsChecked('eco_distinction', 'eco_distinction:true', activeFilters)} id='eco_distinction:true' />&nbsp;
								<label>Results with Green Emblem only</label>
							</HardcodedOption>
						</div>
					</>
				) : null}
				<SectionSeparator position={'horizontal'} ></SectionSeparator>
				
				<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
					{isAuthenticated && !isFreeUser(userData) && searchType === APP_CONFIG.SEARCH.TYPES.WINE && <>
						{priceRatingRangeData.rating_computed && <div>
							<TitleResetContainer>
								<span style={{ fontWeight: 'bold' }}>RP RATINGS</span>
								<ResetButton onClick={() => resetRangeHandler('rating_computed')} >Reset</ResetButton>
							</TitleResetContainer>

							<RatingRangeFilter rangeData={priceRatingRangeData.rating_computed} onSettingRangeHandler={setRangeHandler}/>
							<SectionSeparator position={'horizontal'} ></SectionSeparator>
						</div>}
					</>}
					{
						filters ? <>
							{searchType === APP_CONFIG.SEARCH.TYPES.ARTICLE &&
								<div>
									<TitleResetContainer>
										<span style={{ fontWeight: 'bold' }}>PUBLICATION DATE</span>
										<ResetButton onClick={() => resetPublicationHandler()} >Reset</ResetButton>
									</TitleResetContainer>

									<PublicationDateFilter type={'normal-search'} clickApplyHandler={publicationDateApplyHandler} publicationDateData={publicationDateData}/>
									<SectionSeparator position={'horizontal'} ></SectionSeparator>
								</div>	
							}
			
							{dataLength.totalRecords !== 0 ? 
								<>
									<FilterOptions filters={filters} searchType={searchType} wineDisplay={wineDisplay} onToggleFilter={toggleFilter} onToggleClear={() => onToggleClear()} rangeData={rangeData}/>
								</>
								:
								<>
									<ActionButton style={{width: '100%' }} onClick={() => resetSearchQueryHandler() }>RESET FILTERS</ActionButton>
								</>
							}
						</> : <></>
					}
				</div>
				
			</DivFiltersMobile>
		</div>
	)

	return (
		<>
			{filterMobile}
			{filterDesktop}
		</>
	)
}

export default Filters