/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Container, OptionItem } from '../styles'

interface Option {
	value: string;
	label: string;
}

interface ProfileSelectorProps {
	onSelect: (value: string) => void;
}

const options: Option[] = [
	{ value: 'Edit Profile', label: 'Edit Profile' },
	{ value: 'Update Password', label: 'Update Password' },
	{ value: 'Preferences', label: 'Preferences' },
]

const ProfileSelector = ({ onSelect }: ProfileSelectorProps): ReactElement => {
	const [selected, setSelected] = useState<string>('Edit Profile')
	const {type} = useParams<{type: string}>()

	useEffect(() => {
		if(type === 'preferences'){
			setSelected('Preferences')
			onSelect('Preferences')
		}
	}, [type])

	const handleSelect = (value: string) => {
		setSelected(value)
		onSelect(value)
	}

	return (
		<Container>
			{options.map((option) => (
				<OptionItem
					key={option.value}
					isSelected={selected === option.value}
					onClick={() => handleSelect(option.value)}
				>
					{option.label}
				</OptionItem>
			))}
		</Container>
	)
}

export default ProfileSelector