import React, { ReactElement, ReactNode } from 'react'
import { BackdropTransition, ModalContent, ModalContentWrapper, ModalOverlay, ModalTransition } from './styles'

interface ModalI {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
	closable?: boolean;
}

const ModalContainer = ({ isOpen, onClose, children, closable = true }: ModalI): ReactElement => {
	return (
		<BackdropTransition
			in={isOpen}
			timeout={300}
			classNames="backdrop"
			unmountOnExit
		>
			<ModalOverlay onClick={onClose}>
				<ModalContentWrapper>
					<ModalTransition
						in={isOpen}
						timeout={300}
						classNames="modal"
						unmountOnExit
					>
						<ModalContent onClick={(e) => e.stopPropagation()}>
							{closable && <span className='close-icon' onClick={onClose}>x</span>}
							{children}
						</ModalContent>
					</ModalTransition>
				</ModalContentWrapper>
			</ModalOverlay>
		</BackdropTransition>
	)
}

export default ModalContainer