/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

type IssueInitialState = {
    latestIssue: any
}

const initialState: IssueInitialState = {
	latestIssue: {}
}

const IssueSlice = createSlice({
	name: 'IssueSlice',
	initialState,
	reducers: {
		dispatchLatestIssue(state, action){
			return {
				...state,
				latestIssue : {
					...action.payload
				}
			}
		},
	},
})

export const {dispatchLatestIssue} = IssueSlice.actions
export default IssueSlice.reducer

export const Issues = (state: RootState): IssueInitialState => state.Issues