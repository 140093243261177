import React, { ReactElement } from 'react'
import { TradeContainer } from './styles'

interface TradeDirectoryModalProps {
	isOpen: boolean;
	onClose: () => void;
	onProceed: () => void;
	content: React.ReactNode; // Added prop for content
}

function TradeDirectoryModal({ isOpen, onClose, content, onProceed }: TradeDirectoryModalProps): ReactElement {
	return (
		<TradeContainer>
			<div className='backdrop-transition'>
				<div
					className={isOpen ? 'modal' : 'modal fade'}
					style={{ display: isOpen ? 'block' : 'none' }}
				>
					<div className='modal-dialog' role='document'>
						<div className='modal-content'>
							<div className='modal-header'>
								<button type='button' className='close' onClick={onClose}>
									<span style={{
										color: 'black',
										fontSize: '32px',
										lineHeight: '32px', 
										height: '20px', 
										width: '20px', 
										display: 'inline-flex', 
										alignItems: 'center',
										justifyContent: 'center',
									}} aria-hidden='true'>
										&#10005;
									</span>
								</button>
							</div>
							<div className='modal-title'>Trade Directory Listing</div>
							<div className='modal-body'>
								{content}
							</div>
							<div className='modal-footer'>
								<button
									type='button'
									className='button-cancel'
									onClick={onClose}
								>
									Cancel
								</button>
								<button
									type='button'
									className='button-confirm'
									onClick={onProceed}
								>
									Confirm
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</TradeContainer>
	)
}

export default TradeDirectoryModal
