/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { Container } from './styles'
import { useAppSelector } from '../../../../app/hooks'
import { Users as UsersFromStore} from '../../../../app/slices/Users'
import TagManager from 'react-gtm-module'
import LoadingOverlay from '../../../../components/Loading-overlay'
import { Link, useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { useGetCustomerPaymentMethodQuery } from '../../../../app/services'
import { IsUserAuthenticated } from '../../../../utils'

interface LinkButtonProps {
	href: string;
	children: ReactNode;
}

const regionList = [{
	key: 'bordeaux',
	name: 'Bordeaux',
	url: '/search/wine?country=France&region=Bordeaux'
}, {
	key: 'burgundy',
	name: 'Burgundy',
	url: '/search/wine?country=France&region=Burgundy'
}, {
	key: 'california',
	name: 'California',
	url: '/search/wine?country=USA&region=California'
}, {
	key: 'champagne',
	name: 'Champagne',
	url: '/search/wine?country=France&region=Champagne'
}, {
	key: 'italy',
	name: 'Italy',
	url: '/search/wine?country=Italy'
}]

function LinkButton({ href, children }: LinkButtonProps): ReactElement {
	return <Link to={href} >
		<span>{children}</span>
	</Link>
}

const planData = (planId: string) => {
	if(planId === 'commercial-1-year'){
		return {
			item_name: 'Annually commercial subscription',
			index: 2,             
			item_category: 'Commercial', 
			item_category2: 'Annually',
			price: 249
		}
	}

	if(planId === 'personal-1-year') {
		return {
			item_name: 'Annually personal subscription',
			index: 1,             
			item_category: 'Personal',  
			item_category2: 'Annually',
			price: 129 
		}
	}

	if(planId === 'personal-monthly') {
		return {
			item_name: 'Monthly personal subscription',
			index: 1,             
			item_category: 'Personal',  
			item_category2: 'Monthly',
			price: 15 
		}
	}

	return {
		item_name: 'Quaterly personal subscription',
		index: 0,             
		item_category: 'Personal',  
		item_category2: 'Quaterly',
		price: 37
	}
}

function SuccessSubscription(): ReactElement {
	const { userData } = useAppSelector(UsersFromStore)
	const [isLoading, setIsLoading] = useState(false)
	// const { data: subscriptionEnd } = useGetCustomerPaymentMethodQuery(userData?._id, { skip: userData?._id ? false : true })
	// const { subscriptionType } = useParams<{ subscriptionType: string }>()

	
	// const [endDate, setEndDate] = useState()


	// async function fetchSubsEnd() {
	// 	try {
	// 		const response = await subscriptionEnd
	// 		if (response) {
	// 			setEndDate(response.data[0].subscriptions[0].nextBillingDate)
	// 			setIsLoading(false)
	// 		}
	// 	} catch (error) {
	// 		console.error(error)
	// 	}
	// }

	const history = useHistory()

	useEffect(() => {
		if(!IsUserAuthenticated()){
			history.push('/login')
		}
	}, [])

	useEffect(() => {
		setIsLoading(true)
		// fetchSubsEnd()
		if(userData) {
			triggerTags()
			setIsLoading(false)
		}
	}, [userData])

	const triggerTags = () => {
		const {_id, planId} = userData.subscription

		const event = {
			event: 'purchase',
			ecommerce: {
				transaction_id: _id,
				currency: 'USD',
				value: planData(planId).price,
				items : [
					{
						...planData(planId)
					}
				]
			}
		}

		TagManager.dataLayer({
			dataLayer: {
				ecommerce: null
			}
		})

		TagManager.dataLayer({
			dataLayer: event
		})
	}
		
	return <Container>
		{isLoading && <LoadingOverlay />}
		<div className='info-container'>
			{/* {subscriptionType === 'trial' ? 
				<>
					<h1>Thank you for subscribing to Robert Parker Wine Advocate</h1>
					{isSuccess &&
						<>
							<p>
								{`Your trial subscription has been activated. Payment for your subscription will be charged on ${endDate ? moment(endDate).format('MMMM DD, YYYY') : ''}.`}
							</p>
						</>}
					<br />	
					<p>Thank you for subscribing to Robert Parker Wine Advocate. You now have access to all articles and tasing notes on the site.</p>
				</>
				:
				<>
					<h1>Thank you for subscribing to Robert Parker Wine Advocate</h1>
					<p>Your payment was successful</p>
					<p>Thank you for subscribing to Robert Parker Wine Advocate. You now have access to all articles and tasing notes on the site.</p>
				</>} */}
			<h1>Thank you for subscribing to Robert Parker Wine Advocate</h1>
			<p>Your payment was successful</p>
			<p>Thank you for subscribing to Robert Parker Wine Advocate. You now have access to all articles and tasing notes on the site.</p>
			<br />	
			<LinkButton href='/issues/latest'>Read the latest Wine Advocate articles</LinkButton>
			<LinkButton href='/my-subscription'>
				Manage your account
			</LinkButton>
			<br />
			<p>Or try browsing wines from notable wine regions:</p>
			<br />
			{regionList.map((region) =>
				<LinkButton href={region.url} key={region.key}>
					{region.name}
				</LinkButton>
			)}
		</div>
	</Container>
}

export default SuccessSubscription
