/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect } from 'react'
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form'
import { isMobile } from 'react-device-detect'

import Input from '../../../../../../components/form-components/input/Input'

import { GiftCardContainer, GiftCardFormBody } from '../../styles'

type Props = {
    control: Control<FieldValues>;
	setValue: UseFormSetValue<FieldValues>;
	userData: any
}

function SenderDetails({control, setValue, userData}: Props):ReactElement {

	const isLogged = userData?._id ? true : false
	const setSenderFields = (): void => {
		if(userData){
			setValue('senderFirstName', userData.profile.name.first_name)
			setValue('senderLastName', userData.profile.name.last_name)
			setValue('senderEmail', userData.emails[0].address)
			
		}
	}

	useEffect(() => {
		setSenderFields()
	}, [userData])
	

	return <GiftCardContainer isMobile={isMobile}>
		<GiftCardFormBody isMobile={isMobile}>
			<div className='head-body'>
				<h3>1. Sender details</h3>
			</div>
			<div className='form'>
				<div className='first-input'>
					<Input disabled={isLogged} control={control} name='senderFirstName' type='text' placeholder='*First Name' rules={{ required: 'Sender\'s first name is required' }} />
					<Input disabled={isLogged} control={control} name='senderLastName' type='text' placeholder='*Last Name' rules={{ required: 'Sender\'s last name is required' }} />
				</div>
				<div className='first-input'>
					<Input disabled={isLogged} control={control} name='senderEmail' type='email' placeholder='*Email Address' rules={{ required: 'Sender\'s email address is required' }} />
				</div>
			</div>
		</GiftCardFormBody>
	</GiftCardContainer>
}

export default SenderDetails
