import styled from 'styled-components'

export const PageTitle = styled.span`
	width: 100%;
	height: 60px;
	// margin: 40px 134px 20px 0px;
	// margin-top: 20px;
	font-family: Libre Franklin;
	font-size: 40px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
`
export const PageSubTitle = styled.span`
	width: 100%;
	height: 120px;
	margin: 0 0 20px;
	font-family: Libre Franklin;
	font-size: 40px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
`

export const Separator = styled.div`
	height: 1px;
	margin: 20px 0 40px 0px;
	background-color: #ccc;
`
