/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'

import { RATING_RANGES, WINE_MATURITIES } from '../../../../configs'

// Utilities
import { SplitRatingSegments } from '../../../../utils'

function DescriptionDisplay(props: { rating: string }): ReactElement {

	const { rating } = props

	const segments = SplitRatingSegments(rating)

	const getDescriptionDisplay = (): string => {
		const container: string[] = []

		const ratingDetail = RATING_RANGES.reverse().filter((range: any) => segments.rating && Number(segments.rating) >= range.low && Number(segments.rating) <= range.high)[0]
		if (ratingDetail) {
			container.push(ratingDetail.description)
		}

		const maturityDetail = WINE_MATURITIES.filter((maturity: any) => segments.label === maturity.label)[0]
		if (maturityDetail) {
			container.push(maturityDetail.description)
		}

		return container.join('. ')
	}

	return (
		<>
			{getDescriptionDisplay()}
		</>
	)
}

export default DescriptionDisplay