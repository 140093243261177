import styled from 'styled-components'

const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
`

const sectionHeader = `{
	font-family: Libre Franklin;
	font-size: 22px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
}`

export const AccordionContainer = styled.div`
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;


	& div.calendar_entries {
		padding-left: 20px;

		& div.entry_author {
			font-size: 21px;
			font-weight: bold;
			line-height: 1.52;
			color: #a08436;
			margin-bottom: 15px;
			${commonTextStyle}
		}

		& div.entry_label {
			font-size: 16px;
			font-weight: bold;
			color: #333;
			margin-bottom: 5px;

			${commonTextStyle}
		}

		& div.entry_sublabel {
			font-size: 16px;
			font-weight: normal;
			letter-spacing: normal;
			color: #333;
			margin-bottom: 20px;
		}
	}
`

export const AccordionController = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	& div.group-head {
		& div.group-label {
			font-size: 24px;
			font-weight: bold;
			color: #333;

			${commonTextStyle}
		}

		& div.group-sublabel {
			font-size: 14px;
			font-style: italic;
			color: #333;
		}
	}
`

export const PageHeader = styled.div`
	width: 100%;
	height: 80px !important;

	& div.title {
		& h2 {
			font-size: 40px;
			font-weight: bold;
			letter-spacing: normal;
			color: #333;
		}

		& span {
			font-family: Libre Franklin;
			font-size: 16px;
			font-style: normal;
			color: #777;
			${commonTextStyle}
		}
	}
`
