import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

const initialState: AuthenticationStoreData = {
	WebApp: {
		accessToken: '',
		accessTokenExpiresAt: '',
		client: {
			_id: '',
		},
	},
	User: {
		accessToken: '',
		accessTokenExpiresAt: '',
		refreshToken: '',
		refreshTokenExpiresAt: '',
		client: {
			_id: '',
		},
		user: {
			_id: '',
			country: '',
		},
	},
	Error: '',
}

const AuthenticationSlice = createSlice({
	name: 'AuthSlice',
	initialState,
	reducers: {
		logout: () => initialState,
		UpdateUserAuthDetails: (state, action) => ({
			...state,
			User: {
				...state.User,
				accessToken: action.payload.token,
				accessTokenExpiresAt: action.payload.tokenExpiry,
				refreshToken: action.payload.refreshToken,
				refreshTokenExpiresAt: action.payload.refreshTokenExpiry,
				client: {
					_id: action.payload.clientId,
				},
				user: {
					_id: action.payload.userId,
					country: action.payload.country
				},
			},
		}),
	},
})

export const { logout, UpdateUserAuthDetails } = AuthenticationSlice.actions
export default AuthenticationSlice.reducer

export const Authentication = (state: RootState): AuthenticationStoreData =>
	state.Authentication
