import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS, RP_ENV } from '../../configs'
import { GetBaseQuery } from '../../utils/'

import { sprintf } from 'sprintf-js'
import { MAX_RETRIES } from './constant'
import { reverseObfuscateConstant } from '../../utils/Constant'

const URL_V2 = reverseObfuscateConstant(RP_ENV.API_URL_V2)

export const VintageChartV2API = createApi({
	reducerPath: 'VintageChartV2API',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, URL_V2),
	endpoints: (builder) => ({
		getChartData: builder.query<APIReturn, void>({
			query: () => sprintf(API_ENDPOINTS.get_chart_data_v2),
		}),
	}),
})


export const { useGetChartDataQuery:useGetChartDataV2Query } = VintageChartV2API
