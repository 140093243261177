import React, { ReactElement, ReactNode } from 'react'
import './styles.css'

type DrawerType = {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    drawerTitle: string;
}

const Drawer = ({ isOpen, onClose, children, drawerTitle }: DrawerType): ReactElement => {
	return (
		<div className='drawer-bdrop'>
			{isOpen && <div className="drawer-backdrop" onClick={onClose}></div>}
			<div className={`drawer ${isOpen ? 'open' : ''}`}>
				<div className='drawer-header'>
					<span>{drawerTitle}</span>
					<img onClick={onClose} src='/img/icons/close.png' alt='' />
				</div>
				<div className='drawer-body'>
					{children}
				</div>
			</div>
		</div>
	)
}

export default Drawer
