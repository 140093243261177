/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react'

const defaultScreenMap = [
	{name: 'smallMobile', minWidth: 0},
	{ name: 'mobile', minWidth: 500 },
	{ name: 'tablet portrait', minWidth: 768 },
	{ name: 'desktop', minWidth: 992 }
]

const initScreen = (screenMap:{
    name: string;
    minWidth: number;
}[]) =>
	screenMap.reduce((acc, curr) => {
		if (matchMedia(`(min-width: ${curr.minWidth}px)`).matches) acc = curr.name
		return acc
	}, 'smallMobile')

function useScreen(screenMap = defaultScreenMap): string {
	const defaultScreen = screenMap[0].name

	const [screen, setScreen] = useState(initScreen(screenMap))

	useEffect(() => {
		const mediaWatcherList = screenMap.map(({ name, minWidth }, i) => {
			const format = matchMedia(`(min-width: ${minWidth}px)`)

			const formatWatcher = (format: any) => {
				if (format.matches) {
					setScreen(name)
				} else {
					setScreen(screenMap[i - 1] ? screenMap[i - 1].name : defaultScreen)
				}
			}
			format.addListener(formatWatcher)
			return { format, formatWatcher }
		})

		return () => {
			mediaWatcherList.forEach(({ format, formatWatcher }) =>
				format.removeListener(formatWatcher)
			)
		}
	}, [defaultScreen, screenMap])

	return screen
}

export default useScreen