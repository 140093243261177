/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { autocomplete } from '@algolia/autocomplete-js'
import React, { createElement, Fragment, useEffect, useRef } from 'react'
import { render } from 'react-dom'

export function Autocomplete(props: any): any {
	const containerRef = useRef<HTMLDivElement | null>(null)
	const autocompleteInstanceRef = useRef<any>(null)

	useEffect(() => {
		if (!containerRef.current) {
			return undefined
		}
		
		autocompleteInstanceRef.current = autocomplete({
			container: containerRef.current,
			renderer: { createElement, Fragment },
			render({ children }, root) {
				render(children, root)
			},
			autoFocus: false,
			...props,
		})

		return () => {
			if (autocompleteInstanceRef.current) {
				autocompleteInstanceRef.current.destroy()
				autocompleteInstanceRef.current = null
			}

		}
		// eslint-disable-next-line
	}, [props.type, props.initialState])

	return <div ref={containerRef} style={{ flex: 1 }} />
}