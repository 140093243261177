import React from 'react'

import { Link } from 'react-router-dom'

import styled from 'styled-components'
import { validateURL } from '../../../../utils/ValidateUrl'
import { sanitizeUrl } from '@braintree/sanitize-url'

const indendtedListItem = `
& > li {
	margin-left: 30px;
}
`

const IndentedList = styled.ul`
	& > li {
		margin-left: 30px;
	}

	${indendtedListItem}
`

const CookiePolicy: React.FC = () => (

	<div className='single-entry'>
		<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
			<div className="container">
				<div>
					<h2 id="title" style={{ textAlign: 'center' }}><strong>Robert Parker Wine Advocate Cookie Notice</strong></h2>
					<br/>
					<p><strong>Last modified:</strong> December 2023</p>
					<h2>Summary</h2>

					<p>
						Robert Parker The Wine Advocate is committed to protecting your personal information and being clear and transparent about what information we collect and how we use it. When you use our website or applications, provided by RPWA, information can be collected through cookies to distinguish you from other users of our Services, website, or applications.
					</p>
					<p>
						This Cookie Notice describes the purposes for which we and our partners use cookies and similar technologies and how you can manage your preferences regarding cookies.
					</p>
					<br />
					<h2>1. Who we are and how we can be contacted</h2>

					<p>
						Our full company name is WINE ADVOCATE PTE LTD <strong>{'("RPWA")'}</strong> and when you use our website and our services directly we act as a Data Controller.
					</p>
					<p>
						If you need to contact us, you have two options:
					</p>
					<IndentedList>
						<li>
							<p>
								email us at <a href={sanitizeUrl('mailto:privacy@robertparker.com')}>privacy@robertparker.com</a>
							</p>
						</li>
						<li>
							<p>
								send us a letter addressed to the Privacy Department, 78 Shenton Way #23-02 Singapore 079120
							</p>
						</li>
					</IndentedList>
					<br />
					<h2>2. About our Data Protection Officer</h2>

					<p>
						While everyone at RPWA is committed to protecting and respecting your privacy, we have a Data Protection Officer who deals with all aspect of personal data at RPWA. You can contact our Data Protection Officer by sending an email to <a href={sanitizeUrl('mailto:privacy@robertparker.com')}>privacy@robertparker.com</a>
					</p>
					<br />
					<h2>3. What is a cookie?</h2>

					<p>
						A cookie is a text file or a software element which records information relating to the navigation of your terminal on the Internet. It is under the control of your browser, and sometimes carries a unique and random number.
					</p>
					<p>
						When RPWA mentions {'"cookies"'}, these may be cookies or other similar technologies which are based on access to the device that you use (computer, tablet, smartphone) when visiting our websites. We and our third-party providers may use (i) cookies or small data files that are stored on an individual’s computer and (ii) other, related technologies, such as web beacons, pixels, embedded scripts, location-identifying technologies, and logging technologies (collectively, “cookies”) to automatically collect personal information. We may also use this information to distinguish you from other users of our Services. This helps us monitor and analyze how you use and interact with our Services. It also helps us and our partners to determine products and services that may be of interest to you.
					</p>
					<br />
					<h2>4. Other policies we encourage you to read</h2>

					<p>
						For further information on how we use, store, keep your personal data secure and about rights that may be available to you, we encourage you to read our <Link to={ '/privacy-notice' } >Privacy Notice</Link> located in the footer of our website and the Privacy policies of our partners available through our Cookie Management Solution.
					</p>
					<br />
					<h2>5. Why does RPWA use cookies?</h2>
					<p>
						RPWA is always seeking new ways to better its Internet website(s) and to offer you, the internet user, a better service and experience. Thus, RPWA and its partners uses different types of cookies as indicated hereafter.
					</p>
					<br />
					<h2>
						6. How does RPWA inform you of the cookies that it uses?
					</h2>

					<p>
						When you first visit the RPWA website or application, you will be presented with our Cookie Management Solution. The Cookie Management Solution will provide you certain information about our cookie practices, but also directs you to this Cookie Notice for more information about the cookies used. This Cookie Notice is also available through the “Cookie Notice” link in the footer of our website.
					</p>
					<br />
					<h2>
						7. Your choices regarding cookies
					</h2>
					<p>
						With the exception of strictly necessary cookies which are enabled by default (as described below), cookies on the RPWA website and application are turned off by default and will only be enabled if you accept our use of all or certain cookies through our Cookie Management Solution. 
					</p>

					<p>
						Once you have made a choice regarding our use of cookies, you may adjust your cookie preferences by managing your settings in our Cookie Management Solution. In addition, most browsers also allow you to adjust your browser settings to: (i) notify you when you receive a cookie, which lets you choose whether to accept it; (ii) disable existing cookies; or (iii) set your browser to automatically reject cookies. 
					</p>

					<p>
						You may also set your e-mail options to prevent the automatic downloading of images that may contain technologies that would allow us to know whether you viewed or engaged with our emails. Some websites have “do not track” features that allow you to tell a website not to track you. These features are not all uniform. We do not currently respond to those signals. 
					</p>

					<p>
						Blocking or deleting cookies may negatively impact your experience using the Services, as some features and functions on our Services may not work properly. Depending on your mobile device and operating system, you may not be able to delete or block all cookies or tracking technologies. In addition, if you want to turn off cookies across all your browsers and devices, you will need to do so on each browser on each device you actively use. 
					</p>

					<p>
						Third Party Partners and Google Analytics. We work with a variety of third-party partners to provide cookie-related services, including in relation to cookie-based analytics. For example, we use Google Analytics to recognize you and link the devices you use when you visit our Services on your browser or mobile device, log in to your account on our Services, or otherwise engage with us. We share a unique identifier, like a user ID or hashed email address, with Google to facilitate the service. Google Analytics allows us to better understand how our users interact with our Services. For information on how Google Analytics collects and processes data, as well as how you can control information sent to Google, review {'Google\'s'} website, “How Google uses data when you use our partners’ sites or apps” located at <a href={validateURL('https://www.google.com/policies/privacy/partners/') ? 'https://www.google.com/policies/privacy/partners/' : ''}>www.google.com/policies/privacy/partners/</a>. You can learn about Google Analytics’ currently available opt-outs, including the Google Analytics Browser Ad-On here: <a href={validateURL('https://tools.google.com/dlpage/gaoptout/') ? 'https://tools.google.com/dlpage/gaoptout/' : ''}>https://tools.google.com/dlpage/gaoptout/</a>. 
					</p>
					<br />
					<h2>
						8. What types of Cookies are used by RPWA?
					</h2>

					<p>
						A cookie can be classified in different ways.
					</p>
					<p>
						By lifespan, a cookie is either a:
					</p>
					<IndentedList>
						<li>
							<strong>Session cookie</strong> which is erased when the
				user closes the browser.
						</li>
						<li>
							<strong>Persistent cookie</strong> which remains on the {'user\'s'} computer/device for a pre-defined period according to the span that is attributed and the parameters of your internet browser. Retention periods vary from cookie to cookie. We determine the duration of cookies based on what is proportionate in relation to the intended outcome and then limit this to what is necessary to achieve the purpose.
						</li>
					</IndentedList>
					<p>
						As for the domain to which it belongs, they are either:
					</p>
					<IndentedList>
						<li>
							<strong>First-party cookies </strong>which are set by the web server of the visited page and share the same domain, in this case RPWA’s domain.
						</li>
						<li>
							<strong>Third-party cookies</strong> which are stored by a different domain to the visited {'page\'s'} domain. Third-party cookies are placed by a third party.
						</li>
					</IndentedList>
					<p>
						Besides these characteristics relating to their functioning, cookies can serve several purposes. Please note that the titles of cookie categories may vary from one actor to another. To a better understanding, RPWA defines cookies with regard to the categories below.
					</p>
					<IndentedList>
						<li>
							<h2>Strictly Necessary Cookies</h2>
							<p>
								Strictly necessary cookies are cookies that are indispensable because the functionality of the website is contingent on their existence. Their deactivation will cause important difficulties for using the site, or even the impossibility to use the services that it proposes. Many purposes are covered by these cookies. They permit, for example, to identify the terminals to dispatch the communication, to assign numbers to the data {'"packets"'} to dispatch them in the requested order, and to detect transmission errors or data loss, keep in memory the content of a basket during the session, customize the user interface (language, presentation of a service) or even authentication with a service.
							</p>
							<p>
								These cookies also record the choice expressed by users regarding their cookies preferences so as not to request them again for a defined duration.
							</p>
							<p>
								These cookies are based on RPWA’s legitimate interest. Because they are strictly necessary for your navigation, your prior consent is not required. These cookies are NOT managed by our Cookie Management Solution.
							</p>
						</li>
						<li>
							<h2>Functional cookies</h2>
							<p>
								Functional cookies are used to improve your browsing experience. They remember the choices you make (your username, save the last route options for example) and provide personalized services. Thanks to these cookies, you do not have to enter certain information each time you connect to our website or application. They can remember changes you have made to text size, font, or other parts of web pages that can be customized.
							</p>
							<p>
								You are free to accept or refuse them, but by blocking them, it is also possible that your previous service selections will not be kept in memory.
							</p>
							<p>
								These cookies are used based on your prior consent.
							</p>
						</li>
						<li>
							<h2>
								Analytics and audience measurement cookies
							</h2>
							<p>
								The audience measurement cookies help to recognize visitors of the website across their different visits. These cookies only stock an internet user’s ID (specific to each cookie) and is in no case used to collect nominative information belonging to the visitors. The data collected makes it possible to provide anonymous statistical data on visits (the number of visitors and pages visited, their frequency of return the duration of each visit, and the error messages encountered…). They help to enable a performance increase of the RPWA internet websites.
							</p>
							<p>
								The analytics cookies can be installed and managed by RPWA partners.
							</p>
							<p>
								You are free to accept or refuse them, but by blocking them, RPWA will have less information to improve the performance of its site.
							</p>
							<p>
								These cookies are used based on your prior consent.
							</p>
						</li>
						<li>
							<h2>Cookies generated by social network sharing buttons</h2>
							<p>
								RPWA uses so-called social plugins (hereinafter referred to as buttons) which link to social networks such as Facebook, LinkedIn and YouTube.
							</p>
							<p>
								When you visit our website, these buttons are disabled by default, which means they do not send any information to the social networks in question without action on your part. Before you can use these buttons, you must click to enable them. The buttons remain enabled until you disable them again or you delete your cookies. Once they have been enabled, the system connects directly to the server of the social network selected. The content of the button is then transferred directly from the social networks to your web browser, which incorporates them into the website you are visiting.
							</p>
							<p>
								As soon as you have enabled a button, the social network in question can collect data, irrespective of whether you use the button. If you are logged on to a social network, it can link your visit to this website to your user account. Visits to other RPWA websites will only be linked if you have also enabled the corresponding button on these sites.
							</p>
							<p>
								If you are a member of a social network and you do not want it to link the data collected from your visit to our website to the information provided when you joined the network, you must log out of the social network in question before enabling the buttons.
							</p>
							<p>
								Please note that we have no influence over the scope of data collected by the social networks through your buttons. Please refer to the data protection statement published by the social networks in question for more information about the scope and purpose of the data collection and how they process and use such data, as well as the rights and setting options to protect your privacy.
							</p>
							<p>
								These cookies are used based on your prior consent.
							</p>
						</li>
					</IndentedList>
					<h2>
						9. 	Changes to this Cookie Notice
					</h2>
					<p>
						We will update this Cookie Notice from time to time.  When we make changes to this Notice, we will change the “Last Updated” date at the top. If we make material changes, we may notify you by email to your registered email address, by prominent posting on our online services, or through other appropriate communication channels.  All changes shall be effective from the date of publication unless otherwise provided in the notification.
					</p>
				</div>
			</div>
		</div>
	</div>
)

export default CookiePolicy