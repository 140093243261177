import React, { ReactElement, useEffect, useState } from 'react'
import { RangeFilterContainer, RangeInputs, SliderCotainer } from '../styles'
import Slider from 'rc-slider'

type Props = {
	rangeData: number[]
	onSettingRangeHandler: (data: number | number[], reference: string) => void,
}

const RatingRangeFilter = ({onSettingRangeHandler, rangeData}: Props): ReactElement => {

	const [valueDataRange, setValueDataRange] = useState<number | number[]>()

	const onChangeSetRange =  (data: number | number[]) => {
		setValueDataRange(data)
	}

	useEffect(() => {
		if(rangeData){
			setValueDataRange(rangeData)
		}
	}, [rangeData])

	const onSetHandler = (data: number | number[]) => {
		onSettingRangeHandler(data, 'rating_computed')
	}

	return <RangeFilterContainer>
		<RangeInputs>
			<div className='range-field'>
				<label htmlFor='min'>Minimum</label>
				<div className="input-container">
					<i className='indicator'>RP {Array.isArray(valueDataRange) ? valueDataRange[0] : valueDataRange}</i>		
				</div>
			</div>
			<div className='divider'> - </div>
			<div className='range-field'>
				<label htmlFor='max'>Maximum</label>
				<div className="input-container">
					<i className='indicator'>RP {Array.isArray(valueDataRange) ? valueDataRange[1] : valueDataRange}</i>		
				</div>
			</div>
		</RangeInputs>
		<SliderCotainer>
			<Slider value={valueDataRange}
				defaultValue={[0, 0]}
				onChange={(data) => onChangeSetRange(data)}
				onChangeComplete={(data) => onSetHandler(data)}
				min={50} 
				max={100}
				handleStyle={[{ height: 19, width: 19}, { height: 19, width: 19}]} 
				trackStyle={[{ backgroundColor: '#73333f', height: 7, }]} 
				range 
				count={1} 
				allowCross={true} />
		</SliderCotainer>
		
	</RangeFilterContainer>
}

export default RatingRangeFilter
