/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { Component, ErrorInfo } from 'react'
import NonExistPage from '../content/pages/404-page/NonExistPage'

interface Props {
	children: React.ReactNode;
}

interface State {
	hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { hasError: false }
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('ErrorBoundary caught an error: ', error, errorInfo)
		this.setState({ hasError: true })
	}

	render() {
		if (this.state.hasError) {
			window.addEventListener('popstate', () => window.location.reload())
			return <NonExistPage />
		}
		window.removeEventListener('popstate', () => window.location.reload())
		return this.props.children
	}
}

export default ErrorBoundary