import React,  { ReactElement } from 'react'
import styled from 'styled-components'

const HorizontalSeparator = styled.div<{ topMargin?: string, bottomMargin?:string }>`
	height: 1px;
	margin: ${props => props.topMargin? props.topMargin :'11px'} 0 ${props => props.bottomMargin? props.bottomMargin: '11px'} 0px;
	background-color: #ccc;
`

const VerticalSeparator = styled.div<{ leftMargin?: string, rightMargin?:string }>`
	width: 1px;
	min-height: 300px;
	margin: 0px ${props => props.rightMargin? props.rightMargin: '0px'} 0px ${props => props.leftMargin? props.leftMargin: '0px'};
	background-color: #e5e5e5;

	@media only screen and (max-width: 1000px) {
		display: none;
	}
`

function SectionSeparator(props: { position: 'horizontal' | 'vertical', leftMargin?: string, rightMargin?: string, topMargin?: string, bottomMargin?: string, className?:string}): ReactElement {

	const { position, leftMargin, rightMargin, topMargin, bottomMargin, className } = props

	if(position === 'horizontal') {
		return (<HorizontalSeparator className={className} topMargin={ topMargin } bottomMargin={ bottomMargin }></HorizontalSeparator>)
	} else if (position === 'vertical') {
		return (<VerticalSeparator className={className} leftMargin={ leftMargin } rightMargin={ rightMargin } >&nbsp;</VerticalSeparator>)
	}	

	return (<></>)
}

export default SectionSeparator
