import styled from 'styled-components'
import { RPColors } from '../../../../styles/CSSConstant'

const profileCommonAttributes = `
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
`
const centerizeContents = `
	display: flex;
	justify-content: center;
`
const paddingHorizontal24 = `
	padding-left: 24px;
	padding-right: 24px;
`

export const ProfileMainContainer = styled.div<{ isMobile: boolean }>`
	align-items: center;
`
export const Title = styled.span<{isPreferences?: boolean}>`
	font-size: 22px;
	font-weight: 600;
	color: #333;
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 32px;
	${centerizeContents}
	${profileCommonAttributes}
`
export const ProfileActionSelect = styled.div`
	${centerizeContents}
`
export const ProfileForm = styled.div`
    padding: 0px 0px 32px 0px;
`
export const Hr = styled.div`
	width: 100%
    border: solid 1px ${RPColors.borderColor};
`
export const ResetButton = styled.span<{onClick: onClick}>`
	width: 100%
	border: solid 1px ${RPColors.borderColor};
	text-decoration: underline;
	cursor: pointer;
	color: ${RPColors.darkPrimary};
	font-size: 12px;
	font-weight: bold;
`
export const ActionContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	padding-bottom: 32px;
`
export const ProfileFormContainer = styled.div<{isMobile: boolean}>`
	width:  ${(props) => (props.isMobile ? '100%' : '700px')};
	border-style: solid;
	border-color: ${RPColors.borderColor};
	border-width: ${(props) => (props.isMobile ? '1px 0' : '1px')};
	padding-top: 32px;
	margin-top: 32px;
	align-self: center;
    border-radius: ${(props) => (props.isMobile ? 'none' : '8px')};
	${paddingHorizontal24}
`
export const CenterizedDropdown = styled.div`
	${paddingHorizontal24}
`
export const StatusModalContainer = styled.div<{status: boolean, isMobile: boolean}>`
	border: ${(props) => (props.status ? 'solid 2px #008185' : 'solid 2px #c50000')} ;
	width: ${(props) => (props.isMobile ? '100%' : '700px')} ;
	height: 60px;
	margin-top: 24px;
    justify-content: left;
	align-items: center;
	margin-left: auto;
    margin-right: auto;
	display: flex;
`
export const StatusSuccess = styled.span`
	color: #008185;
	font-size: 12px;
	font-weight: bold;
	padding-left: 10px;
	text-align: left;
`
export const StatusFailed = styled.span`
	color: #c50000;
	font-size: 12px;
	font-weight: bold;
	padding-left: 10px;
	text-align: left;
`
export const EmailPreferencesContainer = styled.div`
	color: #c50000;
	font-size: 12px;
	font-weight: bold;
	padding-left: 10px;
`
export const PreferencesTitle = styled.div<{ title: string; description: string }>`
	padding-top: 20px;
  .title {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
  }

  .description {
    color: #333333;
    font-size: 12px;
    display: block;
  }
`
export const Container = styled.div`
  display: flex;
  gap: 10px;
  padding: 20px;
  justify-content: center;
`

export const OptionItem = styled.div<{ isSelected: boolean }>`
  color: ${RPColors.darkPrimary};
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  cursor: pointer;
  border-bottom: 2px solid ${(props) => (props.isSelected ? `${RPColors.borderColor}` : 'transparent')};

  &:hover {
    background-color: #f0f0f0;
  }
`





const commonFontAttributes = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
`

export const PageTitle = styled.div`
	width: 450px;
	margin: 0 0 40px 0;
	font-size: 40px;
	font-weight: bold;
	color: #333;
	${commonFontAttributes}
`

export const FormButtonsContainer = styled.div`
	display: flex;

	& div {
		margin-right: 10px;
	}
`

export const FormFieldContianer = styled.div`
	// padding-left: 35px;

	& div.form-label {
		font-size: 16px;
		font-weight: bold;
		color: #333;
		margin-bottom: 5px;

		& span {
			color: red;
		}
	}

	& div.form-value {
		max-width: 386px;
		& input::after {
			display: none !important;
		}import { isMobile } from 'react-device-detect';

	}

	& div.form-value-required {
		// width: 468px;
		max-width: 386px;
		height: 36px;
		margin: 6px 0 10px;
		// padding: 6px 14.6px 6px 12px;
		border-radius: 8px;
		border: solid 1px #c50000;
		background-color: var(--white-three);
    }

	& p {
		height: 48px;
		font-size: 16px;
		font-weight: normal;
		color: #333;
	}

	& label.unbold {
		font-weight: normal;
		pointer: cursor;
	}

	& div.sub-options {
		margin-bottom: 0px;

		& input {
			margin-right: 8px;
		}
	}
`

export const FormProfilePicContainer = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	// padding-left: 35px;

	& div.image-container {
		width:  120px;
		height: 120px;
		margin: 0 20px 40px 0;
		padding: 60px 60px 85px 85px;
		background-color: #9a9a9b;
		border-radius: 50%;
	}

	& div.text-value {
		font-size: 10px;
	}
	
	& div.cloudimage-container {
		width:  145px;
		height: 145px;
		margin: 0 20px 20px 0;
	}

	& img {
		width:  145px;
		height: 145px;
		margin: 0 20px 40px 0;
		border-radius: 50%;
	}

	& div.plus-icon {
		font-size: 40px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: white;
		margin-left: -24px;
		margin-top: -10px;
		}

		& div.upload-button {
			width: 136px;
			height: 33px;
			padding: 6px px;
			text-align: center;
			border-radius: 8px;
			font-weight: bold;
			${commonFontAttributes}

			& input {
				display: none;
			}
		}
`

export const GrayButton = styled.div`
	padding: 6px 13px 6px 12px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: #fff;
	font-weight: bold;
	cursor: pointer;
	text-align: center;

	&:hover {
		background-color: #eee;
	}
`

export const OrangeButton = styled.div`
	height: 33px;
	padding: 6px 15px;
	border-radius: 8px;
	background-color: #73333f;
	color: #fff;
	font-weight: bold;
	cursor: pointer;
	text-align: center;

	&:hover {
		background-color: #8E4250;
	}
`

export const SectionContainer = styled.div`
	& div {
		margin-bottom: 20px;
	}
`

export const TabbedMneu = styled.div`
	display: flex;
	& div.bar {
	}
`

export const TabbedMenuContainer = styled.div``

export const TabbedMenu = styled.div``

export const TabbedMenuItem = styled.div<{ isActive: boolean }>`
	display: flex;
	height: 40px;
	cursor: pointer;

	& div.bar {
		width: 2px;
		height: 40px;
		margin: 0 10px 0 0;
		background-color: ${(props) => (props.isActive ? '#333' : '#fff')};
	}

	& div.menu {
		font-size: 16px;
		padding: 8px;

		font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
		${commonFontAttributes}

		&:hover {
			color: #a08436;
		}
	}
`

export const FormNotifications = styled.div`
	& div.form-error {
		width: 100%;
		height: 48px;
		padding: 13.5px 20px;
		border-radius: 8px;
		border: solid 2px #c50000;
		background-color: var(--white-three);
	}

	& div.form-success {
		width: 100%;
		height: 48px;
		padding: 13.5px 20px;
		border-radius: 8px;
		border: solid 3px #73333f;
		background-color: var(--white-three);
	}
`

export const ErrorNotification = styled.div`
	width: 764px;
  	height: 21px;
  	font-family: Libre Franklin;
  	font-size: 14px;
  	font-weight: bold;
  	font-stretch: normal;
  	font-style: normal;
  	line-height: 1.2;
  	letter-spacing: normal;
  	color: #c50000;
`

export const SuccessNotification = styled.div`
	width: auto;
	height: 21px;
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #73333f;
	margin: 0px;
`

export const ErrorMessage = styled.div`
	width: 451px;
	height: 18px;
	margin: 0 0 0 5px;
	font-family: Libre Franklin;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #c50000;
`

export const UpdateMessageContainer = styled.div<{ type?: string }>`
	padding: 1rem;
	margin: 0;
	border-radius: 8px;
	border: 3px solid ${(props) => props.type === 'success' ? '#32cd32' : 'red'};
	background-color: ${(props) => props.type === 'success' ? '#e2ffe6' : '#FF8A8A'};
	color: black;

`