import React, { ReactElement } from 'react'
import { isMobile } from 'react-device-detect'
import { Control, FieldValues, UseFormHandleSubmit } from 'react-hook-form'

import { generateMonthOptions, generateYearOptions, validCards } from './constants'

import { GiftCardFormBody } from '../../styles'
import Input from '../../../../../../components/form-components/input/Input'
import Dropdown from '../../../../../../components/form-components/dropdown/Dropdown'
import CheckoutButton from '../../../../../../components/form-components/buttons/CheckoutButton'

type Props = {
    control: Control<FieldValues>;
    handleSubmit: UseFormHandleSubmit<FieldValues>,
    onSubmit: (data: FieldValues) => void;
    onClose: () => void;
}

function AddNewCard({ control, handleSubmit, onSubmit, onClose }: Props): ReactElement {

	const months = generateMonthOptions()
	const years = generateYearOptions()

	return <GiftCardFormBody isMobile={isMobile}>
		<div className='head-body'>
			<h3>Add New Card</h3>
		</div>
		<div className='cc-cards'>
			{ validCards.map((card, index) => {
				if(card.name === 'new')
					return
				return <img key={index} alt={card.name} src={card.imgUrl} />
			})}
		</div>
		<form className='form' onSubmit={handleSubmit(onSubmit)}>
			<div className='first-input'>
				<Input control={control} name='cardHolderFirstName' type='text' placeholder='Cardholder First Name' rules={{ required: 'Cardholder first name is required' }} />
				<Input control={control} name='cardHolderLastName' type='text' placeholder='Cardholder Last Name' rules={{ required: 'Cardholder last name is required' }} />
			</div>
			<div className='first-input'>
				<Input control={control} name='cardNumber' type='text' placeholder='Card Number' rules={{ required: 'Card number is required' }} />
			</div>
			<div className='first-input'>
				<Dropdown control={control} rules={{required: 'Expiration month is required'}} options={months} placeholder='Exp. Month' name='expiryMonth' />
				<Dropdown control={control} rules={{required: 'Expiration year is required'}} options={years} placeholder='Exp. Year' name='expiryYear' />
			</div>
			<div className='first-input'>
				<Input control={control} name='cvv' type='text' placeholder='CVV' rules={{ required: 'CVV is required' }} />
				<Input control={control} name='zipCode' type='text' placeholder='ZIP / Postal Code' rules={{ required: 'Zip / Postal code is required' }} />
			</div>
			<div className='footer'>
				<p>All payments are secured and encrypted.</p>
			</div>
			<div className='action-input' style={{ flexDirection: 'row', width: '100%' }}>
				<CheckoutButton name='BACK' type='button' bgColor='white' textColor='black' onClick={onClose}  />
				<CheckoutButton name='ADD' type='submit' />
			</div>
		</form>
		
	</GiftCardFormBody>
}

export default AddNewCard
