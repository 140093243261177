import styled from 'styled-components'

export const Separator = styled.hr`
    width: 100%;
    margin: 2rem auto;
    background-color: #ccc;
`

export const FieldErrorMessage = styled.div`
    display: flex;
    flex-direction: row;
    color: #c50000;

    & .error-message {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        & img {
            width: 15px;
        }
    }
`

export const ActionButtons = styled.div<{$isAuthenticated?:boolean}>`
	display: flex;
	align-items: center;
	justify-content: space-between;

	${props => props.$isAuthenticated &&`
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 1.5rem;
		width: 100%;
	`}

	&.desktop {
		width: 50%;
		margin: auto;
	}
	
	&.mobile {
		margin: 20px;
	}

`

