import styled from 'styled-components'

export const ProducersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    

    & .producer-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        padding: 0rem 1rem;
        cursor: pointer;

        & .producer-name {
            font-weight: bold;
            color: rgba(0,0,0,.8);
        }

        & .producer-display {
            & p {
                color: #A08436;
                min-width: 65px;
            }
        }
    }

    & .producer-row:hover {
        text-decoration: none;
    }
`