/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isBrowser, isAndroid, isIOS } from 'react-device-detect'
import { detect } from 'detect-browser'

const browser = detect()

const getDeviceVersion = () => {
	const userAgent = navigator.userAgent
	if (isAndroid) {
		const match = userAgent.match(/Android\s([0-9.]+)/)
		if (match) {
			return match[1]
		}
	} else if (isIOS) {
		const match = userAgent.match(/OS\s(\d+)_(\d+)_?(\d+)?/)
		if (match) {
			return match[1]
		}
	}
}

export const deviceType = (): string => {
	if(isBrowser) {
		return 'Web | ' + browser?.name + ' ' + 'v.' + browser?.version
	}

	if(isAndroid) {
		return 'Android | ' + 'v.' + getDeviceVersion()
	}

	if(isIOS) {
		return 'iOS | ' + 'v.' + getDeviceVersion()
	}

	return 'Unknown Device'
}

export function encodeUrlParams(input: string[][]): { [key: string]: string } {
	const result: { [key: string]: string } = {}
  
	for (const subArray of input) {
		for (const item of subArray) {
			const [key, value] = item.split(':')
			if (key && value) {
				if (result[key]) {
					result[key] += `,${value}`
				} else {
					result[key] = value
				}
			}
		}
	}
  
	return result
}

export function decodeUrlParams(queryString: string): { [key: string]: string[] } {
	const params = new URLSearchParams(queryString)
	const output: string[][] = []
  
	params.forEach((value, key) => {
		if (key !== 'price_low' && key !== 'rating_computed' && key !== 'article_id' && key !== 'keyword' && key !== 'date_last_tasted') {
			const data = value.split(/,(?!\s)/)
			for (const d of data) {
				output.push([`${key}:${decodeURIComponent(d)}`])
			}
		}
	})  
	const resultObject: { [key: string]: string[] } = {}

	for (const innerArray of output) {
		for (const element of innerArray) {
			const [key] = element.split(':')
			if (!resultObject[key]) {
				resultObject[key] = []
			}
			resultObject[key].push(element)
		}
	}

	return resultObject

}

export function decodeUrlParamsFilter(queryString: string): { [key: string]: string[] } {
	const params = new URLSearchParams(queryString)
	const output: string[][] = []
  
	params.forEach((value, key) => {
		if(!value.includes('undefined') && !value.includes('undefined')){
			if (key === 'price_low' || key === 'rating_computed') {
				const data = value.split(/,(?!\s)/)
				for (const d of data) {
					output.push([`${key}:${decodeURIComponent(d)}`])
				}
			}
		}
	})  
	const resultObject: { [key: string]: string[] } = {}

	for (const innerArray of output) {
		for (const element of innerArray) {
			const [key] = element.split(':')
			if (!resultObject[key]) {
				resultObject[key] = []
			}
			resultObject[key].push(element)
		}
	}

	return resultObject
}

export function decodeUrlKeywordQuery(queryString: string): { [key: string]: string[] } {
	const params = new URLSearchParams(queryString)
	const output: { [key: string]: string[] } = {}
  
	params.forEach((value, key) => {
		if (key === 'keyword') {
			const data = value.split(/,(?!\s)/)
			if(data.length > 0){
				output[key] = data
			}
		}
	})
  
	return output
}
  

export const addTargetBlank = (content:string) => {
	const tempContainer = document.createElement('div')

	tempContainer.innerHTML = content

	const anchorTags = tempContainer.getElementsByTagName('a')

	for (let i = 0; i < anchorTags.length; i++) {
		anchorTags[i].setAttribute('target', '_blank')
	}

	// Return the modified HTML content
	return tempContainer.innerHTML
}

export const hasDayPassed = () => {
	const date = new Date().toLocaleDateString()

	if(localStorage.isTodayDate === date) {
		return false
	}

	localStorage.isTodayDate = date
	return true
}