import React, { ReactElement } from 'react'
import { isMobile } from 'react-device-detect'
import { GiftCardContainer, GiftCardFormBody } from '../../../gift-subscription/styles'
import { Control, FieldValues } from 'react-hook-form'
import Dropdown from '../../../../../../components/form-components/dropdown/Dropdown'

type Props = {
    control: Control<FieldValues>;
    headerText: string;
}

function AddOnCard({control, headerText}: Props): ReactElement {

	const addOnOptions = Array.from({length: 16}, (_, i) => i)

	return <GiftCardContainer isMobile={isMobile}>
		<GiftCardFormBody isMobile={isMobile}>
			<div className='head-body'>
				<h3>{headerText}</h3>
			</div>
			<div className='form'>
				<div className="addon-input">
					<div className='addon-text'>
						<span>Additional Seat</span>
						<span>You can select up to [X]</span>
					</div>
					<Dropdown control={control} options={addOnOptions} placeholder='Quantity(optional)' name='addon' isErrorField={false} />
				</div>
				<div className="addon-input">
					<div className='addon-text'>
						<span>Robert Parker Marketing Usage</span>
						<a href='/contact-us'>Contact us</a>
					</div>
				</div>
			</div>
		</GiftCardFormBody>
	</GiftCardContainer>
}

export default AddOnCard
