import React from 'react'

import UserLogin from './user-login'
import MobileHeader from './mobile-header'
import NavBar from './nav-bar'


const Header: React.FC = () => (
	<header className="site-header site-header--skin-5">
		{/* <TopBar /> */}
		<UserLogin />
		<MobileHeader /> 
		<NavBar />
	</header>
)

export default Header