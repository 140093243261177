import { createSlice } from '@reduxjs/toolkit'

interface PaymentState {
    token:string;
	tab:0
}

const initialState: PaymentState = {
	token: '',
	tab:0
}

const PaymentSlice = createSlice({
	name: 'PaymentSlice',
	initialState,
	reducers: {
		selectToken(state, action){
			return {
				...state,
				token:action.payload
			}
		},
		selectTab(state,action){
			return {
				...state,
				tab:action.payload
			}
		}
	},
	
})

export const {selectToken, selectTab} = PaymentSlice.actions
export default PaymentSlice.reducer