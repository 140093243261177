/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Article from '../../../../structure/classes/Article'

import { CounterLabel, ResultBoxContainer, ResultArticleExcerptContainer, ResultImageContainer, ResultImageContainerMobile, ResultArticleExcerptContainerMobile } from './common/styles'
import CommentCounter from './sections/CommentCounter'
import { isMobile } from 'react-device-detect'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
import { useAppSelector } from '../../../../app/hooks'
import {  SearchFilters as SearchFiltersFromStore} from '../../../../app/slices/SearchFilters'
import useScreen from '../../../../helper/hooks/useScreen'

function ArticleResults(props: { results: Article[] }): ReactElement {

	const history = useHistory()
	const varStore = useAppSelector(SearchFiltersFromStore)
	const { results } = props
	const [dataArticles, setDataArticles] = useState<any>()
	const screen = useScreen()

	const imageRatio = () => {
		switch (screen) {
		case 'tablet portrait':
			return 2.8
		case 'desktop':
			return 3.0
		default:
			return 3.5
		}
	}

	// const { triggerTrackingHandler } = useArticleTracking()
	
	const goToArticlePage = (articleId: string) => {
		// triggerTrackingHandler(articleId)
		history.push(`/articles/${articleId}`)
	}
	useEffect(() => {
		// Sort: Publish Date (Latest First)
		if(varStore?.Sort === 'publish_date_latest'){
			setDataArticles(results.sort((a, b) => {
				const dateA: number = parseInt(a.published_date, 10)
				const dateB: number = parseInt(b.published_date, 10)
				return dateB - dateA
			}))
		// Sort: Publish Date (Earliest First)
		} else if(varStore?.Sort === 'publish_date_earliest'){
			setDataArticles(results.sort((a, b) => {
				const dateA: number = parseInt(a.published_date, 10)
				const dateB: number = parseInt(b.published_date, 10)
				return dateA - dateB
			}))
		// Sort: Title
		} else if(varStore?.Sort === 'title'){
			setDataArticles(results.sort((a, b) => {
				const firstLetterA = a.title.charAt(0).toLowerCase()
				const firstLetterB = b.title.charAt(0).toLowerCase()
				return firstLetterA.localeCompare(firstLetterB)
			}))
		} else {
			setDataArticles(results)
		}
	},[results])

	return (
		<>
			{isMobile ?
				<>
					{dataArticles && dataArticles.map((article: Article, key: number) => (
						<ResultBoxContainer key={key} rounded={false} bgColor={'#fff'} flexed={true}>
							<ResultArticleExcerptContainerMobile>
								<ResultImageContainerMobile>
									{article.image && article.image.url && article.image?.url !== ' ' ?
										<div className='image-div-cloud'>
											<CloudImageUtils imgSrc={article.image?.url} alt="image" ratio={imageRatio()} params='&func=fit' onClick={()=> goToArticlePage(article.id)}/>
										</div>
										:  
										<div className='image-div'>
											<img src='img/icons/default_article_banner_sm.jpg' onClick={()=> goToArticlePage(article.id)}/> 
										</div>
									}
								</ResultImageContainerMobile>
								<div className='mobile-content'>
									<div className='article-title' onClick={() => { goToArticlePage(article.id) }}>
										<span>{article.title}</span>
									</div>
									<div className='article-excerpt'>
										<span>{article.excerpt}</span>
									</div>
									<div className='article-details'>
										<div className='author-detail'>
											<span>
											By&nbsp;<Link to={`/author/${article.author.id}`}>{article.author.name}</Link>
											</span>
										</div>
										<div>
											<span>{article.getPublishedDate('display')}</span>
										</div>
										<div>
											<img src="img/icons/black.png" />
											<CounterLabel>{article.tasting_note_count}</CounterLabel>
										</div>
										<CommentCounter articleId={article.id} />
									</div>
									<br />
								</div>
							</ResultArticleExcerptContainerMobile>
						</ResultBoxContainer>
					))}
				</>
				:
				<>
					{dataArticles && dataArticles.map((article: Article, key: number) => (
						<ResultBoxContainer key={key} rounded={false} bgColor={'#fff'} flexed={true}>
							<ResultImageContainer>
								{article.image?.url ?
									<div className='image-div'>
										<CloudImageUtils imgSrc={article.image?.url} alt="image" ratio={1.2} params='' onClick={()=> goToArticlePage(article.id)}/>
									</div>
									
									:
									<div className='image-div'>
										<img src='img/icons/default_article_banner_sm.jpg' onClick={()=> goToArticlePage(article.id)}/>
									</div>
								}
							</ResultImageContainer>
							<ResultArticleExcerptContainer>
								<div>
									<div className='article-title' onClick={() => { goToArticlePage(article.id) }}>
										<span>{article.title}</span>
									</div>
									<div className='article-excerpt'>
										<span>{article.excerpt}</span>
									</div>
								</div>
								<div>
									<div className='article-details'>
										<div className='author-detail'>
											<span>By&nbsp;<Link to={`/author/${article.author.id}`}>{article.author.name}</Link></span>
										</div>
										<div className='date-tasting-note' >
											<div className='date'>
												<span>{article.getPublishedDate('display')}</span>
											</div>
											<div className='tasting-note'>
												<img src="img/icons/black.png" />
												<CounterLabel>{article.tasting_note_count}</CounterLabel>
											</div>
										</div>
										<CommentCounter articleId={article.id} />
									</div>
								</div>
							</ResultArticleExcerptContainer>
						</ResultBoxContainer>
					))}
				</>
			}
		</>
	)
}

export default ArticleResults