/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import Slider from 'rc-slider'

import { RangeInputs, RatingFilterContainer } from '../styles'
import RangeChart from './RangeChart'

type Props = {
	indicator: string;
	minMaxData:{
		min: number;
		max: number;
	},
	rangeData: number[]
	chartData : any
	onSettingRangeHandler: (data: number | number[], reference: string) => void,
	onSettingInputRangeHandler: (event: ChangeEvent<HTMLInputElement>, type: 'max' | 'min', reference: string) => void,
	onInputClicked: (type: 'min' | 'max', reference: string) => void
	rangeError: {
		isShow: boolean;
		message: string
	}
}

type RatingType = {
	min: number,
	max: number
}

const RatingFilter = ({indicator, minMaxData, onSettingRangeHandler, onSettingInputRangeHandler, rangeData, onInputClicked, chartData, rangeError}: Props): ReactElement => {
	let dataArray: { label: string | number; count: number }[] = []
	const [rating, setRating] = useState<RatingType>({
		min: 50,
		max: 100
	})

	if (chartData) {
		dataArray = Object.entries(chartData)
			.filter(([label]) => {
				const numericLabel = parseFloat(label)
				return numericLabel >= 50 && numericLabel <= 100
			})
			.map(([label, count]) => ({
				label,
				count: count as number,
			}))
	}

	useEffect(() => {
		if(rangeData){
			setRating( () => ({
				min: rangeData[0],
				max: rangeData[1],
			}))
		}
	},[rangeData])	
	return<RatingFilterContainer>
		<span className='title'>Robert Parker Ratings</span>
		<div className='slider-container'>
			<RangeChart data={dataArray} reference="rating_computed" />
			<Slider value={rangeData}
				defaultValue={[0, 0]}
				onChange={(data) => onSettingRangeHandler(data, 'rating_computed')}
				min={minMaxData.min} 
				max={minMaxData.max}
				handleStyle={[{ height: 15, width: 15}, { height: 15, width: 15}]} trackStyle={[{ backgroundColor: '#73333f', height: 7 }]} range count={1} allowCross={false} />

			<RangeInputs style={{marginTop: '20px'}}>
				<div className='range-field'>
					<label htmlFor='min'>From</label>
					<div className="input-container">
						<i className='indicator'>{indicator}</i>
						<input className='rp-input' onClick={() => onInputClicked('min', 'rating_computed[0]')} type='number' value={rating.min} onChange={(e) => onSettingInputRangeHandler(e, 'min', 'rating_computed')} id='min'/>
					</div>
				</div>
				<div className='divider'> - </div>
				<div className='range-field'>
					<label htmlFor='max'>To</label>
					<div className="input-container">
						<i className='indicator'>{indicator}</i>
						<input className='rp-input' onClick={() => onInputClicked('max', 'rating_computed[1]')} type='number' value={rating.max} id='max' onChange={(e) => onSettingInputRangeHandler(e, 'max', 'rating_computed')}/>
					</div>
				</div>
			</RangeInputs>	
		</div>
		{rangeError.isShow && <>
			<div className='validation'>{rangeError.message}</div>
		</>}
	</RatingFilterContainer>
	
}

export default RatingFilter