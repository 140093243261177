import styled from 'styled-components'

const commonTextStyles = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
`

export const AwardContainer = styled.div`
	display: flex;

	& div.award_icon {
		height: 80px;
		width: 80px;
		margin-right: 20px;
	}

	& div.award_text {
		padding-top: 24px;
		padding-bottom: 20px;

		height: 24px;
		font-size: 16px;
		font-weight: bold;
		color: #777;
		${commonTextStyles}
	}
`

export const GreenEmblemHeader = styled.div`
	display: flex;
	justify-content: space-between;

	& h1 {
		font-weight: bolder;
	}
`
export const GreenEmblemBackButton = styled.div`
	color: #73333f;
    font-weight: 600;
    text-decoration: underline;
    font-size: 13px;
    cursor: pointer;
	margin-bottom: 20px;
`
export const Banner = styled.div`
	display: flex;
    width: 100%;
    /* height: 350px; */
    position: relative;
    justify-content: start;
    text-align: start;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: start;

	& .image {
		/* background: transparent 50%/cover; */
		flex: 1;
		z-index: 1;
		/* position: absolute; */
		bottom: 40px;
		left: 0;
		width: 100%;
		height: auto;

	}
`

export const CompanyDetailsContainer = styled.div`
	& div.group {
		margin-bottom: 30px;
	}
`

export const ContentParagraphs = styled.div`
	margin-bottom: 30px;
	& p,
	div {
		${commonTextStyles}
		margin: 20px 0 0;
		font-size: 16px;
		font-weight: normal;
		color: #333;
		white-space: break-spaces;
		line-height: 1.75;
	}

	& hr {
		border: none;
		margin: 5px 0;
	}
`

export const ContactContainer = styled.div`
	display: flex;

	& div {
		margin-bottom: 10px;
	}

	& div.icon {
		width: 16px;
		height: 16px;
		margin-right: 5px;
	}

	& div.phone {
		font-size: 16px;
		color: #333;
		${commonTextStyles}
	}
`

export const Logo = styled.div`
	height: 200px;
	width: 200px;
	border: 1px solid #545454;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const SectionTitle = styled.div`
	margin: 0 0 20px;
	font-size: 24px;
	font-weight: bold;
	color: #333;
	${commonTextStyles}
`

export const TabbedMenu = styled.div``

export const TabbedMenuItem = styled.div<{ isActive: boolean }>`
	display: flex;
	height: 40px;
	cursor: pointer;

	& div.bar {
		width: 2px;
		height: 40px;
		margin: 0 10px 0 0;
		background-color: ${(props) => (props.isActive ? '#333' : '#fff')};
	}

	& div.menu {
		font-size: 16px;
		padding: 8px;

		font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
		color: #333;
		${commonTextStyles}
	}
`

export const HeaderImage = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	/* gap: 20px */
`