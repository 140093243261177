/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import { DataContent, DetailContainer, LetterLabel } from './styles'

function MainContent(props: any): ReactElement {

	const display = (content: any, index: number) => {
		const prevStartsWith = index === 0 ? '' : props.data[index - 1].name.charAt(0)
		const startsWith = content.name.charAt(0)
		return (
			<div key={index}>
				{startsWith !== prevStartsWith ? <DetailContainer size={'24px'} ><div className='header'>{startsWith}</div></DetailContainer> : ''}
				<DetailContainer size={'14px'} >
					<div><DataContent><LetterLabel>{content.name}</LetterLabel><p>{content.description}</p></DataContent></div>
				</DetailContainer>
			</div>
		)
	}

	return (
		<div>
			{props.data.map((content: any, index: number) => {
				return display(content, index)
			})}
		</div>
	)
}

export default MainContent