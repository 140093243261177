import styled from 'styled-components'

const basicTextStyle = `
	font-family: Libre Franklin;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
`

const sectionMargin = 'margin-bottom: 60px;'

const sectionHeader = `{
	font-family: Libre Franklin;
	font-size: 22px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
	padding-bottom: 1rem;
}`

export const Chart = styled.div`
	& div.header {
		display: flex;
		justify-content: space-between;
		& div {
			margin-right: 10px;
		}
	}

	& div.row {
		
		display: flex;
		justify-content: space-between;
		height: 63px;
		padding: 10px 0px 10px 0px;

		& div {
			margin-right: 10px;
		}
	}
`

export const ChartRatingDisplay = styled.span<{ color: string }>`
	font-weight: bold;
	color: ${(props) => props.color};
`

export const ChartTableHeader = styled.div<{ width: string }>`
	${basicTextStyle}
	font-weight: bold;
	width: ${(props) => props.width};
`

export const ChartTableData = styled.div<{ width: string }>`
	${basicTextStyle}
	width: ${(props) => props.width};
`

export const ChartHeader = styled.div`
	& h3 ${sectionHeader} & p {
		${basicTextStyle}
		font-style: italic;
	}

	${sectionMargin}
`

export const DownloadButton = styled.button`
	top: 10px;
	height: 50px;
	position: absolute;
	margin: 13px 0 2px 898px;
	padding: 6px 12px;
	border: none;
	border-radius: 8px;
	background-color: #73333f;
	color: #fff;
	font-weight: bold;
	cursor: pointer;

	&:hover {
		background-color: #8E4250;
	}
`

export const DownloadButtonMobile = styled.button`
	border: none;
	color: #fff;
	font-weight: bold;
	cursor: pointer;
	width: 130px;
	height: 33px;
	margin: 20px 244px 0 0;
	padding: 6px 12px;
	border-radius: 8px;
	background-color: #73333f;

	&:hover {
		background-color: #8E4250;
	}
`

export const Maturities = styled.div`
	& h3 ${sectionHeader}
`

export const Maturity = styled.div`
	display: flex;
	& div.label {
		width: 30px;
		margin: 0 20px 0 0;
		${basicTextStyle}
	}

	& div.description {
		font-family: Libre Franklin;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #333;
	}
`

export const RatingRanges = styled.div`
	& h3 ${sectionHeader}
	${sectionMargin}
`

export const RatingRange = styled.div<{ color: string }>`
	display: flex;
	height: 40px;

	& div.color {
		width: 24px;
		height: 24px;
		margin: 0px 10px 0px 0px;
		background-color: ${(props) => props.color};
	}

	& div.rating {
		width: 50px;
		margin-right: 20px;
		${basicTextStyle}
		font-weight: bold;
	}

	& div.comment {
		height: 21px;
		font-family: Libre Franklin;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #333;
	}
`

export const RegionOptions = styled.div`
	& h3 ${sectionHeader}

	${sectionMargin}
`

export const CountryOption = styled.div<{ selected: boolean }>`
	height: 40px;
	padding: 10px;
	background-color: #fff;
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
	margin-bottom: 10px;
	cursor: pointer;

	&:hover {
		color: #A08436;
	}

	border: none;
	border-left: ${(props) => (props.selected ? '2px solid #333' : 'none')};
`

export const SelectCountryVintage = styled.div`
	display: flex;
	& div {
		margin-right: 15px;
		& label {
			margin-bottom: 2px;
		}

		& select {
			width: 316px;
		}
	}

	${sectionMargin}
`

export const TitleAndDownload = styled.div`
	width: 100%;
	height: 149px;
	// padding: 33px 135px 40px 0px;
	// display: flex;
	justify-content: space-between;

	& div.title {
		& h3 {
			font-weight: bold;
		}

		& span {
			font-family: Libre Franklin;
			font-size: 24px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			color: #777;
		}
	}
`

export const CardItems = styled.div`
	display: flex;
	padding-top: 5px;
	padding-bottom: 10px;
	
	& div.label {
		width: 30px;
		margin: 0 5rem 0 0;
		${basicTextStyle}
	}

	& div.description {
		font-family: Libre Franklin;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #333;
	}

	& a {
		margin: 0 5rem 0 0.5rem;
		font-size: 14px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
	 	color: #a08436;
	}
`
