import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

export type UserLocation = { state: string; location: string };

export interface UserLocationInterface {
  userLocation: UserLocation
}

export const initialState: UserLocationInterface = {
	userLocation: {
		location: '',
		state: ''
	},
}

const UserLocationSlice = createSlice({
	name: 'UserLocationSlice',
	initialState,
	reducers: {
		setUserLocationDetails: (state, action) => {
			state.userLocation = action.payload
		}
	},
})

export const { setUserLocationDetails } = UserLocationSlice.actions

export default UserLocationSlice.reducer

export const UserLocation = (state: RootState): UserLocationInterface => state.UserLocation
