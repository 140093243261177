import styled from 'styled-components'

const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
`

export const TitleContainer = styled.div`
	color: white;
	${commonTextStyle}
	font-weight: bold;
	font-size: 18px;
`
