import React, { ReactElement } from 'react'
import { useParams, useLocation } from 'react-router'
import NonExistPage from '../404-page/NonExistPage'
import SuccessPage from './success'
import SuccessActivation from '../../../../components/success-page/SuccessPage'

const bodyContent = [
	{
		header: 'Gift subscription activated',
		bodyOne:'Your Gift subscription has been activated. The subscription will end on',
		bodyTwo: 'You now have access to all articles and tasting notes on the site.',
	},
	{
		header: 'Gift subscription activated',
		bodyOne:'Your Gift subscription has been activated. Your current subscription has been extended to',
		bodyTwo: '',
	},
]

function GiftSub(): ReactElement {
	const {type} = useParams<{ type: string }>()
	const location = useLocation()

	if(type === 'success') return <SuccessPage />
	if(type === 'activate-success'){
		if(location.search === '?type=new')
			return <SuccessActivation header={bodyContent[0].header} bodyOne={bodyContent[0].bodyOne} bodyTwo={bodyContent[0].bodyTwo}  />

		if(location.search === '?type=exist')
			return <SuccessActivation header={bodyContent[1].header} bodyOne={bodyContent[1].bodyOne} bodyTwo={bodyContent[1].bodyTwo}  />
	}
	return <NonExistPage />
}

export default GiftSub
