import React, { ReactElement, ReactNode } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { CardContainer } from './styles'

interface RegionCardProps {
	name: string;
	url: string;
	imgUrl: string;
}

interface LinkButtonProps {
	href: string;
	children: ReactNode;
}

export function LinkButton({ href, children }: LinkButtonProps): ReactElement {
	return <Link to={href} className='link-anchor'>
		<span>{children}</span>
	</Link>
}

function RegionCard({ name, url, imgUrl}: RegionCardProps): ReactElement {

	const history = useHistory()

	return <CardContainer onClick={() => history.push(url)}>
		<img alt={name} src={imgUrl} />
		<span>{name}</span>
	</CardContainer>
}

export default RegionCard
