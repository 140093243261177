import styled from 'styled-components'

const menuWidth = '70px'

const filterContainerWidth = '262px'


const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
`

export const CheckPriceButton = styled.div`
	width: 86px;
	height: 21px;
	margin: 5px auto 0 auto;
	padding: 5px 12px;
	background-color: #73333f;
	font-family: Libre Franklin;
	font-size: 10px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #fff;
	float: right;
`

export const CompareButton = styled.div`
	height: 33px;
	margin: 0 0 0 10px;
	padding: 6px 12.5px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: #fff;
	font-weight: bold;
`

export const CounterLabel = styled.span`
	/* margin: 0 36px 0 11px; */
	margin-left: 5px;
	font-family: Libre Franklin;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #777;
`

export const DivSearchAndSort = styled.div`
	margin: 0px 0px 0px 0px;
	& > div.search-filters {
		
		& > div {
			padding-bottom: 10px;
		}

		& > div#sort-filter {
			display: flex;
			justify-content: center;
			align-items: center;

			width: 300px;

			& > span {
				width: 100px;
				font-weight: bold;
			}
		}

		& > div#sort-filter-mobile {
			// width: 300px;

			& > div {
				padding-bottom: 10px;
				width: 100px;
				font-weight: bold;
			}
		}
	}
`

export const DivFilters = styled.div`
	flex: 1;
	align-items: center;
	& > div {
		margin-bottom: 10px;
		font-weight: normal;
	}

	@media (max-width: 1000px) {
		display: none;
	}
`

export const DivFiltersMobile = styled.div`
	flex: 1;
	align-items: center;
	padding: 25px;
	& > div {
		margin-bottom: 10px;
		font-weight: normal;
	}

	& > div.advanced-search {
		display: flex;
		justify-content: center;
	}
`

export const FilterButtonMobile = styled.div<{
	type: 'orange' | 'white'
	isEnabled: boolean
}>`
	width: ${filterContainerWidth};
	height: 33px;
	margin-top: 20px;
	padding: 6px 0px 6px 0px;
	text-align: center;
	${(props) => (props.type === 'orange' ? '' : 'border: solid 1px #ccc')};
	border-radius: 8px;
	background-color: ${(props) => getButtonColor(props.isEnabled, props.type)};
	${(props) => (props.isEnabled ? 'cursor: pointer' : '')};

	& > span {
		font-family: Libre Franklin;
		font-size: 12px;
		font-weight: ${(props) => (props.type === 'orange' ? 'normal' : 'bold')};
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		text-align: center;
		color: ${(props) => (props.type === 'orange' ? '#fff' : '#333')};
	}
`

const getButtonColor = (isEnabled: boolean, type: string) => {
	if (isEnabled) {
		return type === 'orange' ? '#e01a00' : '#fff'
	}

	return '#ccc'
}

export const FilterButton = styled.div<{
	type: 'orange' | 'white'
	isEnabled: boolean
}>`
	width: ${filterContainerWidth};
	height: 33px;
	margin: auto;
	padding: 6px 0px 6px 0px;
	text-align: center;
	${(props) => (props.type === 'orange' ? '' : 'border: solid 1px #ccc')};
	border-radius: 8px;
	background-color: ${(props) => getButtonColor(props.isEnabled, props.type)};
	${(props) => (props.isEnabled ? 'cursor: pointer' : '')};

	& > span {
		font-family: Libre Franklin;
		font-size: 12px;
		font-weight: ${(props) => (props.type === 'orange' ? 'normal' : 'bold')};
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		text-align: center;
		color: ${(props) => (props.type === 'orange' ? '#fff' : '#333')};
	}
	& > a {
		color: #a08436 !important;
		font-weight: bold;
	}
`

export const ActionButton = styled.div`
	border: none;
	border-radius: 2px;
	padding: 7px;
	background-color: #73333f;
	font-family: Libre Franklin;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #fff;
	cursor: pointer;
` 

export const FilterResultsContainer = styled.div<{isMobile: boolean}>`
	display: flex;
	justify-content: space-between;
	column-gap: ${(props) => (props.isMobile ? '0px' : '25px')};

	@media(max-width: 768px) {
		column-gap: 0;
	}
`

export const HardcodedOption = styled.div`
	// width: ${filterContainerWidth};
	height: 24px;
	// margin: auto;
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
	display: flex;
	margin: 1em 0 1.5em 0;

	& > input {
		margin-top: -5px;
	}
	& > label {
		font-weight: normal;
		margin-left: 10px;
	}
`

export const GeneratedFiltersContainer = styled.div`
	& div.title {
		font-weight: bold;
		font-size: 16px;
		margin: 17px 0px 10px 0px;
		text-transform: uppercase;
	}

	& div.filter-options-container > div {
		display: flex;
		justify-content: space-between;
	}

	& div.selector-container > input {
		cursor: pointer;
	}

	& div.selector-container > label {
		font-family: Libre Franklin;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		margin-left: 10px;
		letter-spacing: normal;
		color: #333;
		cursor: pointer;
	}

	& div.count-container {
		font-family: Libre Franklin;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: #777;
	}

	& div.view-all {
		margin-top: 5px;
		margin-bottom: 15px;
		color: #a08436;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
	
	& img.logo {
		width: 20px;
		height: 20px;
		object-fit: contain;
		margin-left: 5px;
	}

	& img.remove {
		width: 10px;
		height: 10px;
		object-fit: contain;
		margin: 7px 0 7px 4px;
	}

	& span.clear {
		${commonTextStyle}
		font-size: 12px;
		font-weight: normal;
		float: right;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
`


export const GroupLabel = styled.div`
	${commonTextStyle}
	font-size: 24px;
	font-weight: bold;
	color: #333;
	margin-bottom: 20px;
`

export const GroupDataRow = styled.div`
	display: flex;
	justify-content: space-between;
	min-width: 797px;
	height: 42px;
	margin-top: 20px;
	cursor: pointer;

	& div.name {
		${commonTextStyle};
		color: 333;
	}

	& div.count {
		${commonTextStyle}
		color: #e01a00;
	}
`

export const IconAndDetailsContianer = styled.div`
	display: float;
	justify-content: space-between;

	& div.wine-icon-container {
		margin-right: 10px;
	}
`

export const NavHighlight = styled.div<{ isHighlighted: boolean }>`
	width: ${menuWidth};
	height: 4px;
	background-color: ${(props) => (props.isHighlighted ? '#a08436' : '#fafafa')};
`

export const NavSeparator = styled.div`
	width: 100%;
	height: 5px;
	margin: 20px 0 0 0;
	background-color: #fafafa;
	display: flex;
`

export const ProducerInfoContainer = styled.div`
	min-width: 700px;
	padding-left: 30px;
`

export const ProducerListLinks = styled.div`
	display: flex;
`

export const ResultBoxContainer = styled.div<{
	rounded: boolean
	bgColor: string
	flexed: boolean
	justify?: string
	width?: string
}>`
	margin: 20px auto 20px auto;
	${(props) => (props.rounded ? '' : 'border-radius: 6px;')}
	background-color: ${(props) => props.bgColor};
	${(props) => (props.flexed ? 'display: flex;' : '')}
	justify-content: ${(props) => (props.justify ? props.justify : '')};

	& .mobile-content {
		padding-top: 20px;
	}
`

export const ResultBoxSection = styled.div<{ position: string }>`
	display: flex;
	flex-direction: ${(props) => (props.position === 'top' ? 'row' : 'column')};
	padding: 20px;
	${(props) =>
		props.position === 'top' ? 'justify-content: space-between;' : ''};

	& > div {
		margin-bottom: 20px;
	}
`


export const ResultBoxColumnContainer = styled.div`
	display: flex;
	@media only screen and (max-width: 1200px) {
		flex-flow: row wrap;
	}

	justify-content: space-between;

	& > div {
		width: 236px;

		@media only screen and (max-width: 1200px) {
			width: 200px;
		}
	}

	& > div > div.header {
		height: 21px;
		font-family: Libre Franklin;
		font-size: 14px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #333;
	}
`

export const ResultBoxWineLabelContainer = styled.div`
	& label {
		margin: 0 0px 0px 10px;
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #e01a00;
	}
`

export const ResultSortContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& .vintage-title-free, .vintage-title-auth {
		display: flex;
		gap: 0.2rem;
		font-weight: bold;
		cursor: pointer;
	}

	& .vintage-title-free {
		margin-left: 1rem;

		@media (max-width: 550px) {
			margin-left: 0;
		}
	}

	& .vintage-title-auth {
		margin-left: 3rem;

		@media (max-width: 550px) {
			margin-left: 2rem;
		}
	}

	& .rating-title{
		display: flex;
		gap: 0.2rem;
		font-weight: bold;
		justify-content: flex-end;
		margin-right: 2.7rem;
		cursor: pointer;
	}

	@media (max-width: 1200px) {
		& .rating-title{
			margin-right: 4rem;
		}
	}

	@media (max-width: 992px) {
		& .rating-title{
			margin-right: 5rem;
		}
	}

	@media (max-width: 768px) {
		& .rating-title{
			margin-right: 1.7rem;
		}
	}
`

export const ResultsContainer = styled.div`
	width: 100%;
	padding-left: 9px;
	padding-right: 9px;

	& .separator{
		display: block;
	}

	& div.Sustainability-Rober {
		padding-top: 3em;
		font-size: 12px;
	}

	& span.vintage-title {
		${commonTextStyle}
		width: 49px;
		height: 21px;
		padding-left: 15px;
		font-size: 14px;
		font-weight: bold;
		color: var(--black);
		cursor: pointer;
	}

	@media only screen and (min-width: 1000px) {	
		width: 70%;

		& .separator{
			display: none;
		}
	}
`

export const ResultArticleExcerptContainer = styled.div`
	width: 80%;
	font-family: Libre Franklin;
	color: #777;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	& div.article-title {
		
		margin-bottom: 10px;
		font-size: 21px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.52;
		letter-spacing: normal;
		color: #333;
		cursor: pointer;
	}

	& div.article-excerpt {
		margin-bottom: 10px;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
	}

	& div.article-details {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center; 

		@media only screen and (max-width: 500px) {
			flex-direction: column;
		}

		& div.author-detail > a {
			font-weight: bold;
			color: #333333;
			cursor: pointer;
			margin-right: auto;
		}
	}
	
	& div.date-tasting-note {
		display: flex;
		justify-content: flex-end;
		text-align: right;
		margin-left: auto;
	}
	& div.date {
		padding-right: 20px;
	}

	@media(max-width: 1200px) {
	

		& div.article-title {
			font-size: 18px;
		}
	}
`

export const ResultArticleExcerptContainerMobile = styled.div`
	max-width: 100%;
	font-family: Libre Franklin;
	color: #777;

	& div.article-title {
		margin-bottom: 10px;
		font-size: 21px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.52;
		letter-spacing: normal;
		color: #333;
		cursor: pointer;
	}

	& div.article-excerpt {
		margin-bottom: 10px;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
	}

	& div.article-details {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@media only screen and (max-width: 500px) {
			flex-direction: column;
		}

		& div.author-detail > a {
			font-weight: bold;
			color: #333333;
			cursor: pointer;
		}
	}
`

export const ResultImageContainer = styled.div`
	width: 260px;
	margin: 0 20px 0 0;
	mix-blend-mode: multiply;
	background-image: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0.5),
		rgba(0, 0, 0, 0.5)
	);
	

	& .image-div {
		width: 260px;
		height: 210px;
	}

	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		overflow: hidden;
		cursor: pointer;
	}	

	@media(max-width: 1200px) {
		width: 200px;

		& .image-div {
			width: 210px;
			height: 140px;
		}
	}

	@media(max-width: 500px) {
		width: 140px;
	}
`

export const ResultImageContainerMobile = styled.div`
	width: 100%;
	// height: 195px;
	margin: 0 20px 0 0;
	mix-blend-mode: multiply;

	& .image-div-cloud{
		width: 767px;
	}
	
	
	& .image-div {
		width: 767px;
		height: 200px;

		& img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			overflow: hidden;
			cursor: pointer;
		}	
	}

	@media(max-width: 1200px) {
		& .image-div-cloud{
			width: 650px;
		}
		
		& .image-div {
			width: 650px;
			height: 160px;
		}
	}

	@media(max-width: 991px) {
		& .image-div-cloud{
			width: 700px;
		}

		& .image-div {
			width: 700px;
			height: 198px;
		}
		
	}

	@media(max-width: 767px) {
		& .image-div-cloud{
			width: 500px;
		}

		& .image-div {
			width: 500px;
			height: 160px;
		}
		
	}

	@media(max-width: 575px) {
		& .image-div-cloud{
			width: 535px;
		}

		& .image-div {
			width: 535px;
		}
		
	}

	@media(max-width: 557px) {
		& .image-div-cloud{
			width: auto;
		}

		& .image-div {
			width: auto;
		}
	}

`

export const ResultOptions = styled.div`
	display: flex;

	& div {
		align-items: center;
	}

	& span.icon-container {
		margin-right: 20px;
	}

	& div.row-options-container {
		text-align: center;
		display: flex;
		justify-content: flex-end;
		width: 300px;

		& div {
			display: flex;
		}

		& div.maturity {
			width: 60px;
		}

		& div > button {
			border: none;
			width: 86px;
			height: 23px;
			margin: 0px 20px 0px;
			padding: 5px 12px;
			background-color: #73333f;

			font-family: Libre Franklin;
			font-size: 10px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: #fff;
		}
	}

	& span.check-price {
		width: 73px;
		height: 20px;
		font-family: Libre Franklin;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #a08436;
		cursor: pointer;
	}
`
export const ResultTitle = styled.div`
	height: 33px;
	margin-bottom: 18px;
	font-family: Libre Franklin;
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	color: #333;
`

export const ResultSummary = styled.div`
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: right;
	color: #333;
`
export const LinearResultContainer = styled.div`
	@media only screen and (min-width: 1200px) {
		min-width: 555px;
	}
	flex: 3;

	& .wine-name-selector {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding-left: 1rem;

		& .wine-check {
			display: flex;
			gap: 1rem;
			align-items: flex-start;

			& input {
				cursor: pointer;
			}
		}

		& .wine-ratings {
			margin-right: 1rem;

			& .ratings {
				font-weight: bold;

				& span{
					font-size: 14px;
					min-width: 85px;
					display: inline-block;
				}
			}
		}

		@media (max-width: 1200px) {
			& .wine-ratings {
			
				& .ratings {
					margin-right: 1.5rem;
				}
			}
		}

		@media( max-width: 992px) {
			& .wine-ratings {
			
				& .ratings {
					margin-right: 2.4rem;
					display: flex;
					justify-content: flex-end;
				}
			}
		}

		@media( max-width: 768px) {
			& .wine-ratings {
			
				& .ratings {
					margin-right: 0rem;
				}
			}
		}

		@media( max-width: 550px) {
			padding: 0rem;
		}
	}

	& div.icon-container {
		@media only screen and (max-width: 542px) {
			padding-left: 2%;
		}
	}

	& div > a.subscribe-link {
		margin-left: 27px;
	}

	& label > a.no-style {
		text-decoration: none;
		color: black
	}
	
	& div.rating {
		margin-left: 30px;
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #333;
		
	}

	& button.subscribe-button {
		width: 118.9px;
		height: 26px;
		padding: 2.5px 0px 2.5px 0px;
		border-radius: 8px;
		border: solid 1px #ccc;
		background-color: var(--white-three);
	}

	& span.subscribe-title {
		${commonTextStyle}
		width: 73px;
		height: 21px;
		margin: 0 7.9px 0 0;
		font-size: 14px;
		font-weight: normal;
		color: #000;
	}

	& div > label.wine-name-display {
		width: 100%;

		@media only screen and (max-width: 320px) {
			width: 42%;
		}
	}
`

export const LinearResultContainerSub = styled.div`
	@media only screen and (max-width: 350px) {
		width: 70%;
	}

	// @media only screen and (min-width: 351px) and (max-width: 410px) {
	// 	width: 300px;
	// }

	display: flex;
	justify-content: space-between;
	margin: 10px 25px 5px;
	
	& div.view-details {
		color: #fc3c2d;
		font-weight: normal;
		font-familu: Libre Franklin;
	}

	& div.icon-maturity {
		display: flex;
		font-style: italic;
	}

	& div.maturity {
		margin-left: 10px;
	}
	
	& div.rating {
		margin-left: 5px;
	}

	& div > a {
		margin-left: 3px !important;
	}

	& button {
		border: none;
		width: 86px;
		height: 23px;
		padding: 5px 12px;
		background-color: #73333f;

		font-family: Libre Franklin;
		font-size: 10px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #fff;
	}
`

export const SearchTabs = styled.div`
	width: ${menuWidth};
	height: 21px;
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
	text-align: center;
	cursor: pointer;
`

export const SmallerSearchBox = styled.div`
	display: flex;
	justify-content: start;
	width: 262px;
	height: 33px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: var(--white-three);

	& div {
		margin: auto;
		padding: 5px 5px 5px 10px;
	}

	& input {
		border: none;
		-moz-box-shadow: none;
		-goog-ms-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	& input:focus {
		outline: none;
		border: none;
		-moz-box-shadow: none;
		-goog-ms-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
`

export const TabbedMenu = styled.div`
	& > div.menus,
	div.indicator {
		display: flex;
	}
`

export const OneRow = styled.div`
	padding-left: 10px;
	
	& div {
		padding-top: 5px;
	}

	@media (max-width: 1000px) {
		display: block;
	}
	
	@media only screen and (max-width: 350px) {
		width: 230px;
	}
	

`

export const TwoSidedRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 20px 0px 20px;

	& > div.flexed {
		display: flex;

		& > span {
			padding-top: 5px;
			padding-right: 10px;
			font-style: italic;
			color: #777;
			font-size: 14px;
			line-height: 1.2;
			font-family: Libre Franklin;
		}
	}

	& > div.result {
		margin-top: 10px;
	}

	@media(max-width: 1000px) {
		display: none;
	}

	
`

export const TwoSidedRowOption = styled.div`
	display: none;
	justify-content: space-between;
	margin: 20px 0px 20px;

	& > div.flexed {
		display: flex;

		& > span {
			padding-top: 5px;
			padding-right: 10px;
			font-style: italic;
			color: #777;
			font-size: 14px;
			line-height: 1.2;
			font-family: Libre Franklin;
		}
	}

	& > div.result {
		margin-top: 10px;
	}

	@media(max-width: 1000px) {
        display: flex;
    }

	@media only screen and (max-width: 350px) {
		width: 100%;
	}
`

export const SearhBoxContainer = styled.div`
	& div {
		& > div.autocomplete {
			width: 969px;
			height: 68px;
			margin: 20px 236px 40px 63px;
			padding: 20px;
			}
		& > div.label-bread {
			margin: 20px 0px 0px 0px;
		}
}
`

export const FilterOptions = styled.div`
    display: none;
	height: 33px;
	margin: 10px 0px 10px 0px;
	padding: 6px 13px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: var(--white-three);
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	cursor: pointer;
	& > a {
		color: #333;
		text-decoration: none;
	}

	@media(max-width: 1000px) {
        display: block;
    }
`

export const RatingDisplay = styled.div`
	margin-left: 14px !important;

	& div {
		width: 20px;
		height: 20px;
		margin: 0 10px 0 0;
	}

	& > span {
		font-size: 16px;
		
	}

	@media(max-width: 768px) {
		width: 100%;
	}
`

export const FilterButtonSticky =  styled.div`
	width: 320px;
	height: 73px;
	margin: 65px 94px 0 0;
	padding: 0 0 20px;
	background-color: #fafafa;
	border-top: 1px solid #cccccc;
	position: sticky;
	bottom: 0;

	& span {
		margin: 19px 20px 0;
		padding: 6px 89.5px;
		border-radius: 8px;
		color: #fff;
		font-size: 14px;
		font-weight: bold;
		background-color: #73333f;
	}
`

export const ButtonStickySearch =  styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.6rem;
	padding: 1rem;
	background-color: #fafafa;
	border-top: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;

	& div {
		margin: 0;
		width: 80%;
	}
`

export const HeaderSticky =  styled.div`
	width: 320px;
	height: 8.5rem;
	position: sticky;
	background-color: #fff;
	top: 0;
	z-index: 2;
	
`

export const HeaderFilterMobile = styled.div`
	font-size: 14px;
	font-weight: bold;
	color: #333;
	margin: 0 0 0 25px;
    padding-top: 20px;
	height: 3.5rem;
`

export const CloseButton = styled.a`
	height: 30px;
	width: 30px;
	position: absolute;
	top: 15px;
	right: 12px;
	bottom: auto;
	left: auto;
	padding: 0;
	background: transparent;
	color: rgba(0, 0, 0, 0.4) !important;
	font-size: 18px;
	line-height: 28px;
	text-align: center;
	text-decoration: none !important;
	overflow: hidden;
`

export const WineListContainer = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	margin-bottom: 1rem;
	margin-right: 1rem;

	@media (max-width: 550px) {
		width: 100%;
		justify-content: flex-start;

		& button {
			width: 100%;
		}
	}
`

export const VerticalSeparator = styled.div<{ leftMargin?: string, rightMargin?:string }>`
	width: 1px;
	min-height: 300px;
	margin: 0px ${props => props.rightMargin? props.rightMargin: '0px'} 0px ${props => props.leftMargin? props.leftMargin: '0px'};
	background-color: #e5e5e5;

	@media (max-width: 1000px) {
		display: none;
	}
`