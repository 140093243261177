import styled from 'styled-components'

const commonTextStyles = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
`

export const ManifestoHeader = styled.div`
    display: flex;
    flex-direction: column;

    & .manifesto-title {
        margin-bottom: 2rem;

        & h3 {
            font-size: 2.5rem;
            font-weight: bold;
            margin: 0.5rem 0;
        }
    }

    & img {
        margin: auto;
    }
`

export const ManifestoContent = styled.div`
	& hr {
		height: 1px;
		background-color: #ccc;
	}

	& .manifesto-action {
		width: 100%;
		display: flex;
		justify-content: center;
	}
`

export const HalvedContent = styled.div`
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	padding: 0 1rem;

	& .content {
		max-width: 661px;
	}

	& .content_left {
		padding-right: 20px;
	}

	& .content_right {
		padding-left: 20px;
	}

	& .illustration {
		height: 380px;
		width: 390px;

		& img {
			width: 100%;
			height: 100%;
		}
	}

	@media(max-width: 992px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 2rem;
	}
`

export const SectionHeader = styled.div`
	margin-bottom: 20px;
	font-size: 24px;
	font-weight: bold;
	color: #333;

	${commonTextStyles}
`

export const ParagraphContainer = styled.p<{
	fontSize: string
	marginBottom: string
}>`
	${commonTextStyles}
	font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
	margin-bottom: ${(props) =>
		props.marginBottom ? props.marginBottom : 'none'};
`

export const GreenEmblemCriteria = styled.div`
	padding: 40px 40px 79px;
	margin-bottom: 30px;
	border: solid 1px #e5e5e5;
	background-color: #fafafa;

	& .title {
		margin: 0 0 40px;
		font-size: 40px;
		font-weight: bold;
		text-align: center;
		color: #333;
	}

	& .sub-title {
		font-size: 19px;
	}

	& .section_content {
		display: flex;
		justify-content: space-between;

		& .content {
			font-size: 14px;

			& span {
				font-weight: bold;
			}

			& p {
				font-weight: normal;
				margin-bottom: 30px;
			}

			& .title-child {
				font-weight: bold;
				margin-bottom: 10px;
			}
		}

	}
`

export const ActionButton = styled.button`
	height: 33px;
	border-radius: 8px;
	background-color: #73333f;
	color: #fff;
	cursor: pointer;
	text-align: center;
	font-weight: bold;
	font-size: 14px;
	${commonTextStyles}
	border-color: transparent;
	padding: 6px 16px;

	&:hover {
		background-color: #8E4250;
	}
`