/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement } from 'react'
import { isMobile } from 'react-device-detect'

import CheckoutButton from '../../../../../../components/form-components/buttons/CheckoutButton'

import { GiftCardContainer, GiftCardFormBody } from '../../styles'

type Props = {
	endDate: string | undefined;
	onActivateGiftExistingAccount: () => void;
	onCancelActivation: () => void;
}

function ExistingAccount({ endDate, onActivateGiftExistingAccount, onCancelActivation }: Props): ReactElement {
	return <GiftCardContainer isMobile={isMobile}>
		<GiftCardFormBody isMobile={isMobile}>
			<div className='head-body'>
				<h3 style={{ fontSize: '1.5rem', fontWeight: 800 }}>You have been gifted a 1 Year Personal Subscription.</h3>
			</div>
			<div className='form' style={{ marginTop: '0.5rem', fontSize: 17 }}>
				<div className='first-input'>
					<p>Activating this subscription will extend your current Personal subscription until {endDate}.</p>
				</div>
				<div>
					<p>Do you want to activate this gift subscription now?</p>
				</div>
				<div className='activation-sub-button'>
					<a href='#!' onClick={e => {
						e.preventDefault()
						onCancelActivation()
					}}>MAYBE LATER</a>
					<CheckoutButton width='40%' name='ACTIVATE GIFT' type='button' onClick={onActivateGiftExistingAccount} />
				</div>
			</div>
		</GiftCardFormBody>
	</GiftCardContainer>
	
}

export default ExistingAccount
