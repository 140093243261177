import React from 'react'

// Section
import { default as TheWineAdvocate } from '../../sections/the-wine-advocate'
import { default as FreeToRead } from '../../sections/free-to-read'
import Social from '../../sections/social'
import WinesToLookoutfor from '../../sections/wines-to-lookout-for'
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'

const HomePage: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>{'Robert Parker - Wine Advocate'}</title>
				<meta name="description" content='The worlds most trusted authority in wine for over 30 years. Unbiased, professional tasting notes, reviews, articles, videos, daily news and much more.' />
			</Helmet>
			<div className="mnmd-layout-split mnmd-block mnmd-block--fullwidth">
				<div className="container">
					<div className="row">
						{isMobile ? <br /> : ''}
						<TheWineAdvocate />
					</div>
				</div>
			</div>
			<FreeToRead />
			<div className="mnmd-layout-split mnmd-block mnmd-block--fullwidth">
				<div className="container">
					<div className="row row--space-between">
						<div className='col-12 col-md-8' style={{paddingLeft: 24, paddingRight: 24}}>
							<WinesToLookoutfor />
						</div>
						<div className="col-12 col-md-4" style={{paddingLeft: 24, paddingRight: 24}}>
							<Social />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default HomePage