/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// Selectors
import { WIDGET_CONFIG } from '../../../../configs'
import { useAppSelector } from '../../../../app/hooks'
import { Authentication as AuthFromStore } from '../../../../app/slices/Authentication'

import { TranslationsUtil } from '../../../../utils'

// Styles
import { ProducerWidgetListEntry, WineButton } from './styles'

//i18n
import englishTranslation from './translations/en.json'
import { ClearActiveAdvanceFilters, SetActiveFilters, SetSearchDisplayType } from '../../../../app/slices/SearchFilters'

const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

function ProducersFromArticle(props: { articleId: string, producersData: any, totalProducers: number }): ReactElement {

	const translate = translationUtil.getTranslator()
	const { articleId, producersData, totalProducers } = props

	const authentication = useAppSelector(AuthFromStore)

	const [producers, setProducers] = React.useState<any>()

	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {
		setProducers(producersData)
	}, [authentication.User.accessToken, producersData])

	const articleDispatch = () => {
		dispatch(ClearActiveAdvanceFilters())
		dispatch(SetSearchDisplayType('producer'))
	}

	const searchRoute = (link: string) => {
		dispatch(ClearActiveAdvanceFilters())
		history.push(link)
	}

	return (
		<>
			{producers ? (
				<>
					<div className="widget__title">
						<h4 className="widget__title-text"><i className="mdicon mdicon-thumbs_up"></i>
							<span>
								<span className="first-word">{translate('PRODUCERS')}:&nbsp;</span>{producers.length > WIDGET_CONFIG.PRODUCERS.MAX_PER_PAGE ? WIDGET_CONFIG.PRODUCERS.MAX_PER_PAGE : producers.length} {translate('of')} {totalProducers}
							</span>
						</h4>
					</div>
					<div className="mnmd-widget-most-commented mnmd-widget widget widget__inner">
						<ol className="posts-list list-space-md list-seperated list-unstyled">
							{producers && producers.slice(0, WIDGET_CONFIG.PRODUCERS.MAX_PER_PAGE).map((producer: any, key: number) => producer ? (
								<ProducerWidgetListEntry key={key} onClick={() => searchRoute(`/search/wine?&producer=${encodeURIComponent(producer.label)}&article_id=${articleId}`)}>
									<article className="post cat-4">
										<h3 className="post__title typescale-0">
											<span style={{ fontWeight: 600 }}>{producer.label}</span>
										</h3>
										<div className="rectangle">
											<WineButton className="buy-button post__cat post__cat--bg cat-theme-bg">{producer.value + ' ' + translate('Wines')}</WineButton>
										</div>
									</article>
								</ProducerWidgetListEntry>
							) : (<></>))}
						</ol>
					</div>
				</>) : null}

			<nav onClick={()=> dispatch(SetActiveFilters({}))} className="mnmd-pagination text-center" style={{ marginTop: '-30px' }}>
				<Link to={`/search/wine?article_id=${articleId}`} onClick={articleDispatch} className="btn btn-default">{translate('View All Producers')}<i className="mdicon mdicon-arrow_forward mdicon--last"></i></Link>
			</nav>
		</>
	)
}

export default ProducersFromArticle