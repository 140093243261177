
import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { SUSTAINABILITY_PAGES } from '../../../../../configs'

import { useHistory } from 'react-router-dom'

// Styles
import { ContentParagraph, FeatureContainer, OrangeButton } from './styles'
import { imageConvertion } from '../../../../../utils/CloudImageUtils'

function FreePubsSustainabilityAndWine(): ReactElement {

	const history = useHistory()

	return (
		<div className="single-entry">
			<div className="mnmd-block mnmd-block--fullwidth featured-with-overlap-posts mnmd-block--contiguous">

				<article className="main-post post cat-1">
					<div className="main-post__inner">
						<div className="background-img" style={{ backgroundImage: `url( "${imageConvertion(SUSTAINABILITY_PAGES.BANNER)}" )` }}></div>
						<div className="post__text inverse-text">
							<div className="container">
								<div className="post__text-inner max-width-sm text-center" style={{ paddingBottom: '200px' }}>
									<h3 className="post__title typescale-5">Sustainability And Wine</h3>
								</div>
							</div>
						</div>
					</div>
				</article>
			</div>


			<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
				<div className="container">
					<div>
						<ContentParagraph>
							<b>Wine is an agricultural product</b> and 	<b>sustainability</b>  is a hot topic nowadays. At its core, the movement addresses the needs of the current generation while being respectful of future generations. In other words, it aims to encompass a set of practices that are fundamentally practical while being mindfully maintainable over many years to come.
						</ContentParagraph>
						<ContentParagraph>
							With more and more wine consumers actively seeking out wines that are sustainably produced, the team at Robert Parker Wine Advocate has added two sustainability filters/symbols to our database to help consumers identify producers that do their part for the environment.
						</ContentParagraph>
					</div>
				</div>
			</div>

			<div className="mnmd-block mnmd-block--fullwidth featured-with-overlap-posts mnmd-block--contiguous">
				<div style={{ backgroundColor: '#fafafa' }}>
					<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
						<div className="container">
							<div className="row">
								<FeatureContainer className='reverse'>
									<div className={'content'} >
										<div className='illustration' style={{ paddingBottom: '40px' }}>
											<img src={SUSTAINABILITY_PAGES.GREEN_EMBLEM_LOGO} height={120} width={120} />
										</div>
										<span>ROBERT PARKER GREEN EMBLEM</span>
										<p>
											Robert Parker Wine Advocate has developed another level of sustainability that we believe should be highlighted within our industry.
										</p>
										<Link to={'/free-publications/green-emblem'} >Learn more about the Green Emblem</Link>
										<br />
										<br />
										<br />
										<OrangeButton onClick={() => { history.push('/sustainability-and-wine/green-emblem-list') }} >BROWSE THE WINERIES</OrangeButton>
									</div>
									<div className='illustration'>
										<img src={SUSTAINABILITY_PAGES.HOW_REWARDS_PRESENTED_LUSTRATION} height={441} width={428} />
									</div>
								</FeatureContainer>
							</div>

						</div>
					</div>
				</div>
			</div>

			<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
				<div className="container">
					<div className="row">
						<FeatureContainer>
							<div className='illustration'>
								<img src={SUSTAINABILITY_PAGES.CERTIFIED_ORGANIC_ILLUSTRATION} />
							</div>
							<div className={'content'} >
								<span>CERTIFIED ORGANIC / BIODYNAMIC</span>

								<div className='illustration' style={{ paddingTop: '20px' }}>
									<img src={SUSTAINABILITY_PAGES.CERTIFIED_ICON} height={80} width={80} />
								</div>
								<p>
									This symbol next to a wine means the wine has been certified as organic or biodynamic by one of the internationally recognized third-party organizations.
								</p>
								<Link to={'/free-publications/certification'} >Learn more about certification</Link>
								<br />
								<br />
								<br />
								<OrangeButton onClick={() => { history.push('/search/wine') }}>SEARCH FOR CERTIFIED WINES</OrangeButton>
							</div>
						</FeatureContainer>

					</div>
				</div>
			</div>
		</div>
	)
}

export default FreePubsSustainabilityAndWine