/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, ReactNode } from 'react'
import { CSSTransition } from 'react-transition-group'
import './styles.css'

type ModalType = {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode
}

const CommonModalContainer = ({ isOpen, onClose, children}:ModalType): ReactElement => {
	const handleBackdropClick = (e: any) => {
		if (e.target === e.currentTarget) {
			onClose()
		}
	}

	return (
		<CSSTransition
			in={isOpen}
			timeout={300}
			classNames="modal-transition"
			unmountOnExit
		>
			<>
				<div className="modal-transition" style={{width: 'auto', height: 'auto'}}>
					{children}
				</div>
				<div className="backdrop-transition" onClick={handleBackdropClick}></div>
			</>
		</CSSTransition>
	)
}

export default CommonModalContainer
