/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react'

// Page Contents
import PaymentHistory from './PaymentHistory'
import PaymentMethods from './PaymentMethods'
import SubscriptionDetail from './SubscriptionDetail'
import SubscriptionHistory from './SubscriptionHistory'

// import { isMobile } from 'react-device-detect'

// Styles
// import {
// 	TabbedMenuContainer,
// 	TabbedMenu,
// 	TabbedMenuItem
// } from './styles'
// import { dataCySelector } from '../../../../app/services/constant'
import { IsUserAuthenticated } from '../../../../utils'
import { useHistory } from 'react-router-dom'
import { SubscriptionHeader } from '../subscriptions/styles'
import MenuSelections from '../../../../components/menu-selections/MenuSelections'

const menuOptions = [
	{
		
		label: 'Subscription Details',
		component: <SubscriptionDetail />,
		disabled: false,
	},
	{
		label: 'Subscription History',
		component: <SubscriptionHistory />,
		disabled: false,
	},
	{
		label: 'Payment History',
		component: <PaymentHistory />,
		disabled: false,
	},
	{
		label: 'Manage Payment Methods',
		component: <PaymentMethods />,
		disabled: false,
	}
]

function MySubscription(): ReactElement {
	const [activeMenu, setActiveMenu] = useState(0)
	const history = useHistory()

	useEffect(() => {
		if(!IsUserAuthenticated()){
			history.push('/login')
		}
	}, [])

	const onMenuChangeHandler = (index: number) => {
		setActiveMenu(index)
	}

	return (
		<div className="single-entry">
			<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
				<div className="container">
					<SubscriptionHeader>My Subscription</SubscriptionHeader>
					<MenuSelections className='sub-selection' selectedMenu={activeMenu} menuItems={menuOptions.map(menu => menu.label)} onMenuChange={onMenuChangeHandler}/>
					<article>
						{menuOptions[activeMenu].component}
					</article>
				</div>
				
			</div>
		</div>


	// <div className="single-entry">
	// 	<div className="mnmd-block mnmd-block--fullwidth mnmd-block--contiguous page-heading page-heading--has-background" style={{ marginBottom: '30px' }}>
	// 		<div className="container">
	// 			{isMobile ? <h2 className="page-heading__title" style={{ paddingTop: '20px' }}>My Subscription</h2> : <h2 className="page-heading__title">My Subscription</h2>}
	// 		</div>
	// 	</div>
	// 	<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
	// 		<div className="container">
	// 			<div className="row">
	// 				{isMobile ?
	// 					<div className='container'>
	// 						<div className={'form-label font-weight-bold'} >Menu(s):</div>
	// 						<select id='menu' name='menu-selection' className='component-design' onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { 
	// 							const selectedValue = parseInt(event.target.value, 10)
	// 							setActiveMenu(selectedValue) }} {...dataCySelector('mysubs-select-field')}>
	// 							{menuOptions.map((option, key: number) => (<option key={key} value={key}>{option.label}</option>))}
	// 						</select>
	// 					</div>
	// 					:
	// 					<><TabbedMenuContainer className="mnmd-sub-col mnmd-sub-col--left sidebar js-sticky-sidebar">
	// 						<div className={'group'}>
	// 							<TabbedMenu>
	// 								{menuOptions.map((option, key: number) => (
	// 									<TabbedMenuItem
	// 										key={key}
	// 										isActive={activeMenu == key ? true : false}
	// 										onClick={() => changeMenu(key)} {...dataCySelector(`mysubs-menu-${key}`)}>
	// 										<div className={'bar'}></div>
	// 										<div className={'menu'}>{option.label}</div>
	// 									</TabbedMenuItem>
	// 								))}
	// 							</TabbedMenu>
	// 						</div>
	// 					</TabbedMenuContainer></>}
						
	// 				<div className="mnmd-main-col has-left-sidebar" role="main">
	// 					<article className="mnmd-block post post--single post-10 type-post status-publish format-standard has-post-thumbnail hentry category-abroad tag-landscape cat-5" itemScope itemType="https://schema.org/Article">
	// 						{menuOptions[activeMenu].component}
	// 					</article>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</div>
	// </div>
	)
}

export default MySubscription