/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { FieldValues, useForm } from 'react-hook-form'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import SenderDetails from './components/SenderDetails'
import RecipientDetails from './components/RecipientDetails'
import SenderPaymentCard from './components/SenderPaymentCard'
import SubscriptionDetails from './components/SubscriptionDetails'
import { useAppSelector } from '../../../../../app/hooks'
import { Users as UsersFromStore} from '../../../../../app/slices/Users'
import ErrorModal from '../../../../../components/error-modal'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import { getCSURFToken } from '../../../../../utils/CSRFToken'
import { useCreateGiftRequestMutation, useGetCustomerPaymentMethodQuery } from '../../../../../app/services'
import { sendGiftInfo } from '../../../../../app/slices/GiftSubscription'
import ModalContainer from '../../../../../components/modal-container/ModalContainer'
import AddNewCard from './components/AddNewCard'

import { GiftSubMainContainer, GiftSubTitle, PlanLabel } from '../styles'
import { trimmedEmails } from '../../../../../utils/Constant'
import CustomModal from '../../../../../components/custom-modal/CustomModal'
import { ModalContent } from '../../checkout/styles'

function GiftSubscription(): ReactElement {

	const [giftSubError, setGiftSubError] = useState({
		isBuy: false,
		message: ''
	})
	const [showAddModal, setShowAddModal] = useState(false)
	const [newCardDetails, setNewCardDetails] = useState<{ isNewCard: boolean, data: any }>({
		isNewCard: false,
		data: null
	})

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [formData, setFormData] = useState({})

	const { userData } = useAppSelector(UsersFromStore)
	const dispatch = useDispatch()
	const history = useHistory()
	const recaptchaRef = useRef(null)

	const [createGiftRequestMutation, { data: creatGiftRequestData, isError, isLoading: giftSubLoading, error }] = useCreateGiftRequestMutation()
	const giftError = error as any
	const { data: paymentData, isLoading: paymentListLoading} = useGetCustomerPaymentMethodQuery(userData?._id, { skip: !userData?._id})

    const { control, setValue, handleSubmit, getValues, setError } = useForm()
	const { control: cardControl, handleSubmit: cardHandleSubmit, reset: cardReset} = useForm()
	
	useEffect(() => {
		if(paymentData && paymentData.success){
			const paymentToken = paymentData.data[0].token
			setValue('paymentMethodToken', paymentToken)
		}
	}, [paymentData])

	useEffect(() => {
		if(creatGiftRequestData){
			if(creatGiftRequestData.success){
				
				dispatch(sendGiftInfo({
					sender: getValues('senderEmail'),
					recipient:getValues('recipientEmail'),
					sendDate: moment(getValues('sendDate')).format('MMMM DD, YYYY')
				}))
				history.push('/gift-subscription/success')
			} else {
				setValue('cvv', '')
				setValue('expiryMonth', '')
				setValue('expiryYear', '')
				handleRecaptchaReset()
				setGiftSubError({
					isBuy: true,
					message: creatGiftRequestData?.message || creatGiftRequestData?.data?.message || JSON.stringify(creatGiftRequestData)
				})
			}
		}
		
		if(isError){
			handleRecaptchaReset()
			setGiftSubError({
				isBuy: true,
				message: giftError?.message || giftError?.data?.message || JSON.stringify(giftError)
			})
		}

	},[creatGiftRequestData, isError])

	
	
	const onSubmitHandler = async (formData: FieldValues) => {
		if (!formData.recaptcha)
			return setError('isAggrement', {
						type: ' custom',
						message: 'Recaptcha token is required.'
					})
		setFormData(formData)
		setIsModalOpen(true)
	}

	const handleProceed = async(formData: any) => {
		const isLogged = userData?._id ? true : false
		
		const promoCode = formData.promoCode || ''

		let payload = {
			sender: {
				_id: '',
				title: '',
				firstName: formData.senderFirstName,
				lastName: formData.senderLastName,
				email: trimmedEmails(formData.senderEmail)
			},
			recipient: {
				title: '',
				firstName: formData.recipientFirstName,
				lastName: formData.recipientLastName,
				email: trimmedEmails(formData.recipientEmail)
			},
			sendDate: moment(formData.sendDate).format('YYYY-MM-DD'),
			message: formData.message,
			promoCode: promoCode,
			card: {
				name: formData.cardHolderFirstName + ' ' + formData.cardHolderLastName,
				number: formData.cardNumber,
				expirationDate: formData.expiryMonth + '/' + formData.expiryYear,
				cvv: formData.cvv,
				postalCode: formData.zipCode,
				paymentMethodToken: formData.paymentMethodToken
			},
			recaptcha_token: formData.recaptcha
		}

		if(isLogged && !newCardDetails.isNewCard)
			payload = {
				...payload,
				sender: {
					_id: userData?._id,
					title: '',
					firstName: userData.profile.name.first_name,
					lastName: userData.profile.name.last_name,
					email: userData.emails[0].address
				},
				card: {
					name: '',
					number: '',
					expirationDate: '',
					cvv: '',
					postalCode: '',
					paymentMethodToken: formData.paymentMethodToken
					}
			}

		if(isLogged && newCardDetails.isNewCard)
			payload = {
				...payload,
				sender: {
					_id: userData?._id,
					title: '',
					firstName: userData.profile.name.first_name,
					lastName: userData.profile.name.last_name,
					email: userData.emails[0].address
				},
				card: {
					name: newCardDetails.data.cardHolderFirstName + ' ' + newCardDetails.data.cardHolderLastName,
					number: newCardDetails.data.cardNumber,
					expirationDate: newCardDetails.data.expiryMonth + '/' + newCardDetails.data.expiryYear,
					cvv: newCardDetails.data.cvv,
					postalCode: newCardDetails.data.zipCode,
					paymentMethodToken: ''
				}
			}

		try {
			const csrf_token = await getCSURFToken()
			await createGiftRequestMutation({params: payload, token: csrf_token})
		} catch (error: any) {
			handleRecaptchaReset()
			return setGiftSubError({
				isBuy: true,
				message: error?.message || error?.data.message || JSON.stringify(error)
			})
		}
	}

	const cardFormSubmit = (data: FieldValues) => {
		if(data){
			setNewCardDetails({
				data,
				isNewCard: true,
			})
			setShowAddModal(false)
			setValue('paymentMethodToken', '')
			cardReset()
		}
	}

	const handleRecaptchaReset = () => {
		if (recaptchaRef.current) {
			(recaptchaRef.current as any).reset()
		}
	}

	return <>
	{ giftSubLoading && <LoadingOverlay /> }
	{ paymentListLoading && <LoadingOverlay /> }
		<div className="single-entry">
			<div className="container" style={{ padding: isMobile ? 0 : 15 }}>
				<GiftSubMainContainer isMobile={isMobile}>
					<GiftSubTitle isMobile={isMobile}>
						<p>
							<PlanLabel leftAdjustment={417} onClick={() => history.goBack()} >Personal Offers</PlanLabel>
							Gift Subscription Checkout - $129
						</p>
					</GiftSubTitle>
					<form className='gift-grid-container' onSubmit={handleSubmit(onSubmitHandler)}>
						<div className='gift-grid'>
							<SenderDetails control={control} setValue={setValue} userData={userData} />
							<RecipientDetails control={control} />
                        <SenderPaymentCard control={control} isLogged={userData?._id ? true : false} paymentData={paymentData} onAddNewCard={() => setShowAddModal(true)} newCardDetails={newCardDetails} />
					</div>
					<div className='gift-grid'>
                        <SubscriptionDetails control={control} setValue={setValue} getValues={getValues} setError={setError} recaptchaRef={recaptchaRef} />
					</div>
				</form>
			</GiftSubMainContainer>
		</div>
	
		{isModalOpen &&
				<CustomModal
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					onProceed={() => handleProceed(formData)}
					rigthButtonLabel='COMPLETE YOUR PURCHASE'
					width={564}
				>
					<ModalContent>
						<div className='header'>Order Review</div>
						<div className='sub-header'>You will be paying for the following products</div>
						<div className='plan-container flex-container'>
							<div className='plan-label common-styles'>Gift Subscription - 1 Year</div>
							<div className='plan-value common-styles'>$129</div>
						</div>

						{/* <div className='promo-container flex-container'>
							<div className='promo-label common-styles'>Promo code ({(formData as any)?.promoCode || 'none'})</div>
							<div className='promo-value common-styles'>${(formData as any)?.promoCode ? promoDetails?.value : 0}</div>
						</div> */}

						<div className='separator' />
						<div className='total flex-container'>
							<div className='total-label'>Total</div>
							<div className='total-value'>$129</div>
						</div>
					</ModalContent>
				</CustomModal>
			}

		{ giftSubError.isBuy && <ErrorModal messageBody={giftSubError.message} isOpen={giftSubError.isBuy} onClose={() => setGiftSubError({ isBuy: false, message: '' })} bgColor='#73333F' closeTextColor='white' /> }
		<ModalContainer isOpen={showAddModal} onClose={() => setShowAddModal(false)}>
			<AddNewCard control={cardControl} handleSubmit={cardHandleSubmit} onSubmit={cardFormSubmit} onClose={() => setShowAddModal(false)} />
		</ModalContainer>
	</div>
	</>
}

export default GiftSubscription
