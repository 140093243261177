import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`

export const ModalContentWrapper = styled.div`
    width: 100%;
    max-width: 500px;
`

export const ModalContent = styled.div`
    background-color: white;
    padding: 3rem;
    border-radius: 8px;
    width: 100%;
    position: relative;

    & .close-icon {
        position: absolute;
        font-size: 2.5rem;
        font-weight: bold;
        top: 0;
        right: 1.5rem;
        cursor: pointer;
    }
`

export const BackdropTransition = styled(CSSTransition)`
    &.backdrop-enter {
        opacity: 0;
    }
    &.backdrop-enter-active {
        opacity: 1;
        transition: opacity 200ms ease-in;
    }
    &.backdrop-exit {
        opacity: 1;
    }
    &.backdrop-exit-active {
        opacity: 0;
        transition: opacity 200ms ease-in;
    }
`

export const ModalTransition = styled(CSSTransition)`
    &.modal-enter {
        opacity: 0;
        transform: translateY(-50%);
    }
    &.modal-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 300ms, transform 300ms;
    }
    &.modal-exit {
        opacity: 1;
        transform: translateY(0);
    }
    &.modal-exit-active {
        opacity: 0;
        transform: translateY(-50%);
        transition: opacity 300ms, transform 300ms;
    }
`
