/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-mixed-spaces-and-tabs */

import React, { ReactElement, useState } from 'react'
import moment from 'moment'

interface IPrintLayout {
	wine: any,
	tastingNote: any[],
	drinkDate: string,
	layoutType: string,
	refs: any
}

const PrintTastingNotes = ({ layoutType = 'single', wine, tastingNote, drinkDate, refs }: IPrintLayout): ReactElement => {
	
	// only get the first tastingNote
	const latestNote: any[] = tastingNote && [tastingNote[0]]

	const generateLocationTagClouds = () => (<>
		{wine.country && wine.country.name ? wine.country.name + ', ' : null}
		{wine.region && wine.region.name ? wine.region.name + ', ' : null}
		{wine.location && wine.location.name ? wine.location.name + ', ' : null}
		{wine.locale && wine.locale.name ? wine.locale.name + ', ' : null}
		{wine.site && wine.site.name ? wine.site.name : null}

	</>)

	const getVarietyLabel = () => {
		if (wine.varieties) {
			return wine.varieties?.length === 1 ? 'Variety' : 'Varieties'
		}
		return ''
	}

	const getIssueDate = (format: string, note: any) => {
		if (note.tasted_date) {
			return moment(note.tasted_date).format(format)
		}
		return ''
	}

	const segregateTastingNotes = (tastingNotes: any[], notesPerPage: number) => {
		const pages: any[][] = []
		let currentPage: any[] = []

		for (let i = 0; i < tastingNotes?.length; i++) {
			currentPage.push(tastingNotes[i])

			if (currentPage?.length === notesPerPage || i === tastingNotes?.length - 1) {
				pages.push(currentPage)
				currentPage = []
			}
		}

		return pages
	}

	const wineTitle = () => {
		if(wine?.producer?.display_name === wine?.name) return `${wine?.vintage} ${wine?.name}`
		else return `${wine?.vintage} ${wine?.producer?.display_name} ${wine?.name}`
	}

	const singlePrintLayout = () => {
		return (
			<>
				{tastingNote?.length > 0 &&
					latestNote.map((note, index) => (
						<div key={`tasting-notes-${index}`} className="tasting-notes print-friendly print-friendly-search">
							<div className="text-center">
								<img className="print-img-header" src="img/logo-mark-color.png" alt="logo" />
							</div>
							<div>
								<div className="row">
									<div className="col-12">
										<h3 className="print-title print-title-1x">
											{wineTitle()}
										</h3>
									</div>
								</div>
								<div className="row">
									<div className="col-8">
										{/* <div className="row"> */}
										<div className="row-container">
											<div className="col-4">
												<div className="form-group">
													<label>Rating</label>
													<p className="print-font-small">
														{note && note.rating && note.rating.display && note.rating.display}
													</p>
												</div>
											</div>
											<div className="col-4">
												<div className="form-group">
													<label>Release Price</label>
													<p className="print-font-small">
														{note && note.price && note.price.low ? '$' + note.price.low : 'NA'}
													</p>
												</div>
											</div>
											<div className="col-4">
												<div className="form-group">
													<label>Drink Date</label>
													<p className="print-font-small">{drinkDate}</p>
												</div>
											</div>
										</div>
										<div className="row-container">

											<div className="col-4">
												<div className="form-group">
													<label>Reviewed by</label>
													<p className="print-font-small">
														{note &&
															note.author &&
															note.author.id &&
															note.author.name ? (
																note.author.name
															) : (
																<>N/A</>
															)}
													</p>
												</div>
											</div>
											<div className="col-4">
												<div className="form-group">
													<label>Issue Date</label>
													<p className="print-font-small">{getIssueDate('MMM DD, YYYY', note)}</p>
												</div>
											</div>
											<div className="col-4">
												<div className="form-group">
													<label>Source</label>
													<p className="print-font-small">{note?.issue?.title}</p>
												</div>
											</div>
										</div>

										<div className="col-12">
											{note && note.content && <p className='print-font-mid'>{note.content}</p>}
										</div>
										{/* </div> */}
									</div>

									<div className="col-4 right-side-list content-container-single">
										<p>
											<strong>Producer:</strong> <span className='print-font-mid'>{wine.producer?.display_name}</span>
										</p>
										<p>
											<strong>From:</strong> <span className='print-font-mid'>{generateLocationTagClouds()}</span>
										</p>
										<p>
											<strong>Color:</strong> <span className='print-font-mid'>{wine.color_class?.label}</span>
										</p>
										<p>
											<strong>Type:</strong> <span className='print-font-mid'>{wine.type?.label}</span>
										</p>
										<p>
											<strong>Sweetness:</strong> <span className='print-font-mid'>{wine.dryness?.label}</span>
										</p>
										<p className='print-font-mid'>
											<strong>{getVarietyLabel()}:</strong> <span className='print-font-mid'>{wine.GetVarietiesDisplay()}</span>
											<br />
										</p>
									</div>
								</div>
							</div>
						</div>
					))}
			</>
		)
	}

	const doublePrintLayout = () => {
		const tastingNotesPerPage = 2
		const segregatedTastingNotes = segregateTastingNotes(latestNote, tastingNotesPerPage)

		return (
			<>
				{segregatedTastingNotes.map((pages, pageIndex) => (
					<div key={`tasting-notes-page-${pageIndex}`} className="tasting-notes x31">
						<div className="flex-row text-center">
							{pages.map((tastingNote, noteIndex) => (
								<div key={`tasting-note-${noteIndex}`} className="flex-container tasting-note col-6">
									<div className={tastingNote?.content?.length >= 650 ? 'thumbnail' : 'border-container-short'}>
										<div className="print-content">
											<div className="print-header">
												<div className='print-title-double'>
													<div>
														<img className='print-tile-image' src="img/logo-mark-color.png" alt="logo" />
													</div>
													<div>
														<h3 className="wineRating">{tastingNote.rating && tastingNote.rating.display ? tastingNote.rating.display : ''}</h3>
													</div>
												</div>
												<div className="col-12">
													<h3 className="print-title-2x">{wineTitle()}</h3>
												</div>
												<div className='col-12 '>
													<hr className='print-double-line-header'/>
												</div>
											</div>
											<div className="row text-left tastingnote-container">
												<div className="col-12 content-tastingnote">
													{tastingNote.content && <p className="p-tastingnote-double">{tastingNote.content}</p>}
												</div>
											</div>
											<div className="print-footer">
												<div className="row text-right">
													<div className="col-12">
														<p className="author-name">
															<strong>
																- {tastingNote.author && tastingNote.author.name ? tastingNote.author.name : 'N/A'}
																&nbsp;({getIssueDate('MMMM, YYYY', tastingNote)})</strong>
														</p>
													</div>
												</div>
												<div className="row">
													<div className="col-12 text-right footer-cr padding-footer">
														<p><small>Copyright {moment().year()}, The Wine Advocate, Inc. - Reprinted with permission</small></p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				))}
			</>
		)
	}

	const triplePrintLayout = () => {
		const tastingNotesPerPage = 3
		const segregatedTastingNotes = segregateTastingNotes(latestNote, tastingNotesPerPage)
		const contentLength = segregatedTastingNotes[0][0].content?.length
		
		let firstContent = ''
		let secondContent = ''
		const changeLayout = contentLength > 1000

		if(changeLayout){
			const count = 1050
			const words = segregatedTastingNotes[0][0].content.split(' ')
			const content: string[] = []
			let currentContent = ''
	
			for (const word of words) {
				if ((currentContent + word)?.length <= count) {
					currentContent += word + ' '
				} else {
					content.push(currentContent.trim())
					currentContent = word + ' '
				}
			}
	
			if (currentContent) {
				content.push(currentContent.trim())
			}
			firstContent = content[0]
			secondContent = content[1]
		}
		

		return (
			<>
				{segregatedTastingNotes.map((pages, pageIndex) => (
					<div key={`tasting-notes-page-${pageIndex}`} className="tasting-notes x31">
						<div className="flex-row text-center">
							{pages.map((tastingNote, noteIndex) => (
								<div key={`tasting-note-${noteIndex}`} className="flex-container tasting-note col-4">
									<div className='border-title triple-thumbnail'>
										<div className="print-content">
											<div className='print-header'>
												<div className="col-1" />
												<div>
													<img className="print-logo" src="img/logo-mark-color.png" alt="logo" />
												</div>
												<div className="col-1" />
												<div className="col-12">
													<h3 className="wineRating">{tastingNote.rating && tastingNote.rating.display ? tastingNote.rating.display : ''}</h3>
												</div>
												<div className="col-12">
													<h3 className="print-title-3x">{wineTitle()}</h3>
												</div>
												<div className='col-12'>
													<hr />
												</div>
											</div>
											<div className="row tastingnote-container">
												<div className="col-12 content-tastingnote">
													<p className="p-tastingnote-3x1">
														{changeLayout ?
															<>
																<div>
																	{firstContent}
																</div>
																<div className='tasting-note-2-content'>
																	{secondContent}
																</div>
															</>
														
															: 
															<>
																{tastingNote.content}
															</>
														}	
													</p>
												</div>
											</div>
											<div className="print-footer">
												<div className="row text-right">
													<div className="col-12">
														<p className="author-name">
															<strong>
																- {tastingNote.author && tastingNote.author?.name
																	? tastingNote.author?.name
																	: 'N/A'}
																&nbsp;({getIssueDate('MMMM, YYYY', tastingNote)})
															</strong>
														</p>
													</div>
												</div>
												<div className="row">
													<div className="col-12 text-right footer-cr-3x">
														<p>
															<small>Copyright {moment().year()}, The Wine Advocate, Inc. - Reprinted with permission</small>
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				))}
			</>
		)
	}

	const getPrintLayout = () => {
		if (layoutType === '2x2') return doublePrintLayout()
		else if (layoutType === '3x1') return triplePrintLayout()
		else return singlePrintLayout()
	}

	return (
		<div ref={refs} className="print-layout">
			{getPrintLayout()}
		</div>
	)
}

export default PrintTastingNotes