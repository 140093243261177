/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import CertificationFilter from './CertificationFilter'
import ColorFilter from './ColorFilter'
import CountryRegionAppellationFilter from './CountryRegionAppellationFIlter'
// import IssueFilter from './IssueFilter'
import KeywordFilter from './KeywordFilter'
import ProducerFilter from './ProducerFilter'
// import RatingFilter from './RatingFilter'
import ReviewerFilter from './ReviewerFilter'
import SearchFilterField from './SearchFilterField'
import SweetnessFilter from './SweetnessFilter'
import VarietyFilter from './VarietyFilter'
import VintageFilter from './VintageFilter'
import WineTypeFilter from './WineTypeFilter'

export const TitleMargin = '0px 0px 20px 0px'

export const vintages: string[] = [
	'NV',
	'2021',
	'2020',
	'2019',
	'2018/19',
	'2018',
	'2017',
	'2016',
	'2015',
	'2014',
	'2013',
	'2012',
	'2011',
	'2010',
	'2009',
	'2008',
	'2007',
	'2006',
	'2005',
	'2004',
	'2003',
	'2002',
	'2001',
	'2000',
	'1999',
	'1998',
	'1997',
	'1996',
	'1995',
	'1994',
	'1993',
	'1992',
	'1991',
	'1990',
	'1989',
	'1988',
	'1987',
	'1986',
	'1985',
	'1984',
	'1983',
	'1982',
	'1981',
	'1980',
	'1979',
	'1978',
	'1977',
	'1976',
	'1975',
	'1974',
	'1973',
	'1972',
	'1971',
	'1970',
	'1969',
	'1968',
	'1967',
	'1966',
	'1965',
	'1964',
	'1963',
	'1962',
	'1961',
	'1960',
	'1959',
	'1958',
	'1957?',
	'1957',
	'1956',
	'1955',
	'1954',
	'1953',
	'1952',
	'1951',
	'1950',
	'1949',
	'1948',
	'1947',
	'1946',
	'1945',
	'1944',
	'1943',
	'1942',
	'1941',
	'1940',
	'1939',
	'1938',
	'1937',
	'1936',
	'1935',
	'1934',
	'1933',
	'1932',
	'1931',
	'1930',
	'1929',
	'1928',
	'1927',
	'1926',
	'1925',
	'1924',
	'1923',
	'1922',
	'1921',
	'1920',
	'1919',
	'1918',
	'1917',
	'1916',
	'1915',
	'1914',
	'1913',
	'1912',
	'1911',
	'1910',
	'1909',
	'1908',
	'1907',
	'1906',
	'1905',
	'1904',
	'1903',
	'1902',
	'1901',
	'1900',
	'1899',
	'1898',
	'1896',
	'1895',
	'1893',
	'1892',
	'1891',
	'1890',
	'1889',
	'1888',
	'1886',
	'1883',
	'1881',
	'1880',
	'1875',
	'1874',
	'1872',
	'1870',
	'1869',
	'1868',
	'1865',
	'1864',
	'1863',
	'1862',
	'1858',
	'1852',
	'1850',
	'1848',
	'1847',
	'1846',
	'1834',
	'1832',
	'1825',
	'1814',
	'1812',
	'1811',
	'1802',
	'1800',
	'1795',
	'1790',
	'1760',
	'(2017)',
	'(2012)',
]

export const WineColors: string[] = ['Red', 'Rose', 'White']

export const WineCertifications: string[] = ['Biodynamic', 'Organic']

export const WineSweetness: string[] = [
	'Dry',
	'Medium Dry',
	'Medium Sweet',
	'Sweet',
]

export const WineTypes: string[] = [
	'Fortified',
	'Spirit',
	'Sake',
	'Sweet',
	'Sparkling',
	'Table',
]

export {
	CertificationFilter,
	ColorFilter,
	CountryRegionAppellationFilter,
	// IssueFilter,
	KeywordFilter,
	ProducerFilter,
	// RatingFilter,
	ReviewerFilter,
	SearchFilterField,
	SweetnessFilter,
	// VarietyFilter,
	VintageFilter,
	WineTypeFilter,
}

export const dataArray: {range: number; count: number}[] = []

for (let i = 1; i <= 100; i++) {
	const range = i
	const count = Math.floor(Math.random() * 1001) // Random number between 0 and 1000

	const dataObject = {
		range: range,
		count: count
	}

	dataArray.push(dataObject)
}

export const convertedItem = (data: any): {label: string | number; count: number}[]  => {
	if(data){
		return  Object.keys(data).map(key => {
			return {
				label: key,
				count: data[key]
			}
		})
	}
	return []
}