import React, { ReactElement, useEffect, useState } from 'react'
import {
	PaginationContainer,
	PaginationItem,
	PaginationNextPrev
} from './style'



function Pagination(props: { totalRecords: number, render: any, limit: number }): ReactElement {

	const defaultPage = 1, defaultPageGroup = 1, paginationLimit = props.limit, paginationMaxShow = 5

	const numberOfRecords = props.totalRecords // default based on props;
	const totalPages = Math.ceil(numberOfRecords / paginationLimit)
	const totalPageGroups = Math.ceil(totalPages / paginationMaxShow)

	const [currentPage, setCurrentPage] = useState(defaultPage)
	const [activePageGroup, setPageGroup] = useState(defaultPageGroup)

	useEffect(() => {
		let mounted = true
		if (mounted) {
			setCurrentPage(defaultPage)
			setPageGroup(defaultPageGroup)
		}
		return () => { mounted = false }
		//eslint-disable-next-line
	}, [numberOfRecords])


	const getPageNumbers = () => {
		const pageNumbers = []
		for (let x = defaultPage; x <= totalPageGroups; x++) {
			const items = []
			const isLast = totalPageGroups === x
			let lastPages = 0

			if (isLast) {
				lastPages = (paginationMaxShow * x) - totalPages
				lastPages = (paginationMaxShow * x) - lastPages
			}

			for (let i = (x === 1 ? 1 : ((paginationMaxShow * (x - 1)) + 1)); i <= (isLast ? lastPages : x === 1 ? paginationMaxShow : (paginationMaxShow * x)); i++) {
				items.push(i)
			}

			pageNumbers.push({ value: items, label: x })
		}
		return pageNumbers
	}

	const handleSelect = (selectedPage: number) => {
		setCurrentPage(selectedPage)
		props.render(((selectedPage - 1) * paginationLimit))
	}

	const handleNext = (isNextLast: boolean) => {
		if (isNextLast) {
			setCurrentPage(totalPages)
			setPageGroup(totalPageGroups)
			props.render(((totalPages - 1) * paginationLimit))
		} else {
			const nextPage = (currentPage + defaultPage)
			if ((currentPage + 1) > (paginationMaxShow * activePageGroup)) {
				setCurrentPage(nextPage)
				setPageGroup(activePageGroup + defaultPageGroup)
				props.render(((nextPage - 1) * paginationLimit))
			} else {
				setCurrentPage(nextPage)
				props.render(((nextPage - 1) * paginationLimit))
			}
		}
	}

	const handlePrev = (isPrevLast: boolean) => {
		if (isPrevLast) {
			setCurrentPage(defaultPage)
			setPageGroup(defaultPageGroup)
			props.render(((defaultPage - 1) * paginationLimit))
		} else {
			const prevPage = (currentPage - defaultPage)
			const pages = getPageNumbers().filter(ele => ele.label === activePageGroup)

			if (pages[0].value.filter(ele => { return ele === prevPage }).length === 0) {
				setCurrentPage(prevPage)
				setPageGroup(activePageGroup - defaultPage)
				props.render(((prevPage - 1) * paginationLimit))
			} else {
				setCurrentPage(prevPage)
				props.render(((prevPage - 1) * paginationLimit))
			}
		}
	}

	return (
		totalPages === 0 ? <></> :
			<PaginationContainer>
				{activePageGroup === defaultPageGroup ? '' : <PaginationNextPrev onClick={() => handlePrev(true)}>{'<<'}</PaginationNextPrev>}
				{currentPage === defaultPage ? '' : <PaginationNextPrev onClick={() => handlePrev(false)}>{'<'}</PaginationNextPrev>}
				{getPageNumbers().filter(page => page.label === activePageGroup).map(page => {
					return (
						page.value.map((item, page_key) => {
							return (
								<PaginationItem key={page_key} selected={item === currentPage} onClick={() => { handleSelect(item) }}>
									{item}
								</PaginationItem>
							)
						})
					)
				}
				)}
				{currentPage === totalPages ? '' : <PaginationNextPrev onClick={() => handleNext(false)}>{'>'}</PaginationNextPrev>}
				{activePageGroup === totalPageGroups ? '' : <PaginationNextPrev onClick={() => handleNext(true)}>{'>>'}</PaginationNextPrev>}
			</PaginationContainer>
	)
}


export default Pagination
