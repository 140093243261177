import styled from 'styled-components'

export const Button = styled.button<{buttonType?: 'main' | 'sub'}>`
    width: 100%;
	border-radius: 8px;
	background-color: ${(props) => props.buttonType === 'main' ? '#73333f' : 'white' };
	color: ${(props) => props.buttonType === 'main' ? 'white' : 'black' };;
	cursor: pointer;
	text-align: center;
	font-weight: bold;
	font-size: 14px;
	padding: 6px 16px;
    text-transform: uppercase;
    border: 1px solid ${(props) => props.buttonType === 'main' ? 'transparent' : '#ccc' };

	&:hover {
		background-color: ${(props) => props.buttonType === 'main' ? '#8E4250' : '#ccc' };
	}
`

export const PageContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 1110px;
    padding: 0 0 0 1rem;

    & .page-info {
        & h1 {
            font-weight: bold;
            margin-bottom: 1.5rem;
            line-height: 1.2;
        }

        & p, ul {
            font-size: 18px;
            line-height: 2
        }

        & ul {
            margin-left: 1.5rem;
        }

        & .page-actions {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 1rem;
            width: 250px;
            
        }
    }

    & img {
        height: 450px;
    }

    @media (max-width: 1000px) {
        flex-direction: column-reverse;
        align-items: center;
        gap: 1rem;

        & .page-info {
            & .page-actions {
                width: 100%;
            }
        }
    }

    @media (max-width: 500px) {
        & img {
            height: 300px;
            object-fit: cover;
        }
    }
`