/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { APP_CONFIG, RP_ENV } from '../../../../configs'

import { SetSort } from '../../../../app/slices/SearchFilters'

import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../app/hooks'
import { Authentication as AuthFromStore } from '../../../../app/slices/Authentication'
import { SearchFilters as SearchFiltersFromStore } from '../../../../app/slices/SearchFilters'
import { IsUserAuthenticated } from '../../../../utils'
import { DivSearchAndSort } from './common/styles'
import { Users as UsersFromStore } from '../../../../app/slices/Users'
import { isFreeUser } from '../../../../utils/AuthenticationUtil'


function SearchAndSort(props: { searchType: string, isProducerList: boolean, sortProducer: (value:string) => void, sortProducerValue: string }): ReactElement {
	const { isProducerList, sortProducer, sortProducerValue } = props
	const authentication = useAppSelector(AuthFromStore)
	const sort = useAppSelector(SearchFiltersFromStore).Sort

	const dispatch = useDispatch()
	const { searchType } = props

	const initialSort = searchType === APP_CONFIG.SEARCH.TYPES.WINE
		?
		(IsUserAuthenticated() || authentication?.User?.accessToken ?
			APP_CONFIG.SEARCH.SORT_FILTERS.WINE
			:
			APP_CONFIG.SEARCH.SORT_FILTERS.WINE.filter(ele => {
				return ele.value !== 'latest_review' &&
				ele.value !==  'rating_high' && ele.value !== 'rating_low'
			})
		)
		:
		APP_CONFIG.SEARCH.SORT_FILTERS.ARTICLES

	const [sortFilters, setSortFilters] = useState<any>(initialSort)
	const { userData } = useAppSelector(UsersFromStore)

	useEffect(() => {
		if (!isFreeUser(userData)) {
			const sortingFilters = searchType === APP_CONFIG.SEARCH.TYPES.WINE ? APP_CONFIG.SEARCH.SORT_FILTERS.WINE : APP_CONFIG.SEARCH.SORT_FILTERS.ARTICLES
			setSortFilters(sortingFilters)
		} else {
			const sortOptionsWine = [
				{
					'label': 'Vintage (Latest first)',
					'value': 'vintage_high',
					'order': 'desc',
					'query_param': 'vintage'
				},
				{
					'label': 'Vintage (Lowest first)',
					'value': 'vintage_low',
					'order': 'asc',
					'query_param': 'vintage'
				},
			]
			
			if(searchType === 'wine'){
				setSortFilters(sortOptionsWine)
			} else if(searchType === 'article'){
				setSortFilters(APP_CONFIG.SEARCH.SORT_FILTERS.ARTICLES)
			}	
		}

		if(isProducerList) setSortFilters(APP_CONFIG.SEARCH.SORT_FILTERS.PRODUCERS)
		//eslint-disable-next-line
	}, [userData?._id, isProducerList, searchType])

	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target
		if (!value) {
			return
		}
		if(isProducerList) return sortProducer(value)
		else return dispatch(SetSort(event.target.value))
	}

	// const vintageSort = (event: any) => {
	// 	if (!event.target.value) {
	// 		return
	// 	}

	// 	dispatch(SetSort(event.target.value))
	// }


	return (
		<div>
			<DivSearchAndSort>
				<div className="search-filters">

					<div className='end' id={isMobile ? 'sort-filter-mobile' : 'sort-filter'}>
						{isMobile ? <div>SORT BY</div> : <span>SORT BY</span>}
						<select value={isProducerList ? sortProducerValue : sort } onChange={handleChange} id="sorter" name="sort-filter" className='component-design'>
							{sortFilters.map((filter: any, key: number) => (<option key={key} value={filter.value}>{filter.label}</option>))}
						</select>
					</div>
				</div>
			</DivSearchAndSort>
		</div>
	)
}

export default SearchAndSort