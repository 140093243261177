import React, { ReactElement } from 'react'

import { Link } from 'react-router-dom'

function UnderConstruction (props: { label: string }): ReactElement { 
	const { label } = props
	return (
		<div>
			<div className="single-body single-body--wide entry-content typography-copy" itemProp="articleBody">
				<h4>{ label }</h4>
				<h1>Oops! This page is not ready. </h1>
				<blockquote style={{ fontSize:'1.2em' }} >
					<p>{'Dear valued customer,'}</p>
					<p>We are still working on our website revamp. Please bare with us and we apologize for the inconvenience.</p>
					<p>
					Please stay tuned to updates as we have lots in stock for you all. There will be a reorganization on how our platform operates and they are all geared to provide you with a much more enjoyable experience.
					The new website will have an enhance subscription management scheme that is flexible to your needs. 
					</p>
					<p>We hope to see you soon, and until then please click on the home button to go back to the main page.</p>
					<footer>
						<cite>The Wine Advocate</cite>
					</footer>
					<Link to={ '/' }>back to the main page</Link>
				</blockquote>
			</div>
		</div>
	)
}

export default UnderConstruction