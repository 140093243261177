/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect } from 'react'
import { TitlePage } from '../styles'
import { useHistory } from 'react-router-dom'
import {GiftSubscription as GiftSubscriptionFromStore} from '../../../../../app/slices/GiftSubscription'
import { useAppSelector } from '../../../../../app/hooks'

function SuccessPage(): ReactElement {
	const history = useHistory()
	const giftSubscription = useAppSelector(GiftSubscriptionFromStore)

	useEffect(() => {
		if(!giftSubscription.sendDate){
			history.push('/')
		}
	}, [])

	return <div className="single-entry">
		<div className="container" >
			<TitlePage style={{ margin: '0 3rem' }}>
				<p className='main-title'>Payment Successful</p>
				<div className='success-content'>
					<p className='success-subTitle'>Your payment is successful. A receipt has been sent to <span>{giftSubscription.sender}</span>.</p>
					<p>An email notification will be sent to {giftSubscription.recipient} on {giftSubscription.sendDate}.</p>
				</div>
				<a 
					href='#!' 
					onClick={(e) => {
						e.preventDefault()
						history.push('/')
					}}
				>
					RETURN TO HOMEPAGE
				</a>
			</TitlePage>
		</div>
	</div>
}

export default SuccessPage
