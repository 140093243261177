import React, { ReactElement } from 'react'

// Styles
import { BannerContainer, BannerContainerMobile } from './styles'
import { isMobile } from 'react-device-detect'

import { useHistory } from 'react-router-dom'
import { imageConvertion } from '../../utils/CloudImageUtils'
import { useGetFeaturedBannerQuery } from '../../app/services'
import { RP_ENV } from '../../configs'
import { reverseObfuscateConstant } from '../../utils/Constant'

function FeaturedBanner(): ReactElement {

	const { data, isLoading } = useGetFeaturedBannerQuery()

	const history = useHistory()

	const goToBannerContent = (url: string): void => {

		const baseUrl = reverseObfuscateConstant(RP_ENV.API_URL_V2) as string

		if (url.includes(baseUrl)){
			url = url.toLowerCase().replace(baseUrl, '')
			history.push(url)
		}
		else
			window.open(url, '_self')
	}

	return (
		<>
			{data ?
				(isMobile ?
					<BannerContainerMobile image={imageConvertion(data.media.url)} onClick={() => { goToBannerContent(data.button.url) }} ></BannerContainerMobile>
					:
					<BannerContainer image={imageConvertion(data.media.url)} onClick={() => { goToBannerContent(data.button.url) }} ></BannerContainer>
				)
				: isLoading ? (<>Loading ...</>)
					: null
			}
		</>
	)
}

export default FeaturedBanner