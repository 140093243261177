import styled from 'styled-components'

export const ModalContent = styled.div`
   & .header {
        height: 29px;
        font-family: Libre Franklin;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        color: #311820;
   }

   & .sub-header {
        margin-top: 16px;
        font-family: Libre Franklin;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #333;
   }

   & .subcription-plan {
        margin-top: 32px;
   }

   & .promo-code {
        margin-top: 16px;
   }

   & .common-styles {
        font-family: Libre Franklin;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: #311820;
   }

   & .separator {
        width: 484px;
        height: 1px;
        margin: 16px 0 24px;
        background-color: #a18436;
   }

   & .total {
        font-family: Libre Franklin;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: #311820;
        margin-bottom: 32px;
   }

   & .flex-container {
        display: flex;
        justify-content: space-between;
   }

   & .plan-container {
        margin-top: 32px;
   }

   & .promo-container {
        margin-top: 16px;
   }

   & .trial-color {
     color: #BCA76F;
   }

   @media (max-width: 550px) {
     & .separator {
        width: 100%;
        height: 1px;
        margin: 16px 0 24px;
        background-color: #a18436;
   }
   }
`