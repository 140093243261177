import styled from 'styled-components'
export const UserName = styled.div``

const commonFontAttributes = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
`

export const PageTitle = styled.div`
	width: 350px;
	margin: 0 0 40px 0;
	font-size: 40px;
	font-weight: bold;
	color: #333;
	${commonFontAttributes}
`

export const HeaderParag = styled.div`
	font-size: 24px;
  	font-weight: normal;
 	font-stretch: normal;
	font-style: normal;
  	line-height: 1.2;
  	letter-spacing: normal;
  	margin-top: -15px;
	margin-bottom: 10px;

	@media (max-width: 768px) {
		font-size: 18px;
	}
`

export const Container = styled.div`
	display: flex;

	& p {
		font-size: 16px;
	}

	@media (max-width: 992px) {
		display: grid;
		grid-row: auto;
	}
`

export const DetailContainer = styled.div<{ size: string }>`
		width: 804px;
		margin-top: -17rem;

	& div.header {
		margin: 0 0 12px;
		font-size:  ${(props) => props.size};
		font-weight: bold;
		color: #333;
		${commonFontAttributes}
	}

	& div.required-header {
		margin: 0 0 12px;
		font-size:  ${(props) => props.size};
		font-weight: bold;
		color: #333;
		&:after {
			content:" *";
			color: red;
		  }
		${commonFontAttributes}
	}

	& div.value {
		margin: 12px 27px 27px 0;
		font-size:  14px;
		font-weight: normal;
		color: #333;
		${commonFontAttributes}
		max-width: 623px;
		text-align: justify;
	}

	& p {
		margin-bottom: 5px;
	}

	@media (max-width: 992px) {
		margin-top: 1rem;
	}
`

export const FormFieldContianer = styled.div`
	margin-bottom: 20px;
	
	& div.form-label {
		font-size: 14px;
		font-weight: bold;
		color: #333;
		margin-bottom: 5px;
	}

	& div.form-label-required {
		font-size: 14px;
		font-weight: bold;
		color: #333;
		margin-bottom: 5px;

		&:after {
			content:" *";
			color: red;
		  }
	}

	& div.form-value {
		margin-bottom: 5px;
		max-width: 554px;
		font-weight: normal;
		line-height: 1.2;
		color: #333;
		${commonFontAttributes}

		@media (max-width: 420px) {
			max-width: 368px;
		}
	}
`

export const SendMessage = styled.div`
	height: 33px;
	cursor: pointer;
	margin: 0 0 10px;
	padding: 6px 14.5px;
	border-radius: 8px;
	background-color: #e01a00;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	color: white;
	
`

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
`

export const WidgetHeader = styled.div`
	height: 44px;
	padding: 10px 20px 10px 20px;
	background-color: #73333f;

	& span {
		width: 197px;
  		height: 24px;
  		font-family: Libre Franklin;
  		font-size: 16px;
  		font-weight: bold;
  		font-stretch: normal;
  		font-style: normal;
  		line-height: 1.2;
  		letter-spacing: normal;
  		color: #ffffff;
	}

`

export const WidgetBody = styled.div`
  	height: 340px;
  	padding: 20px 20px 40px;
  	background-color: #fafafa;
`

export const WidgetInfo = styled.div`
	font-size: 16px;  
	font-weight: bold;
`

export const WidgetSubInfo = styled.div`
	font-size: 16px;  
	color: #a08436;
	margin-bottom: 20px;
`