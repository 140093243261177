/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { useHistory } from 'react-router-dom'
import { dataCySelector } from '../../../app/services/constant'

const BoldedText = styled.div`
	font-family: Libre Franklin;
	font-size: 22px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	color: #333;
`

const SubText = styled.div`
	margin-top: 20px;
	text-align: center;	
`

const OrangeButton = styled.div`
	height: 33px;
	margin-top: 30px;
	padding: 6px 30px;
	border-radius: 8px;
	background-color: #73333f;
	color: #fff;
	text-align: center;
	font-weight: bold;
	cursor: pointer;

	&:hover {
		background-color: #8E4250 !important;
	}
`

function FreeTrialForm(props: { closeWindow: any, isShown: boolean}): ReactElement {

	const { closeWindow, isShown } = props

	const history = useHistory()

	const triggerRedirect = () => {
		closeWindow()
		history.push('/subscriptions')
	}

	return (
		<div role="tabpanel" className={`tab-pane fade ${isShown && 'show active'}`} id="trial-tab" >
			{/* <BoldedText>
				Select any of our personal subscription options and try 30 days of subscription for free
			</BoldedText> */}
			<BoldedText>
				Select any of our personal subscription options
			</BoldedText>
			<SubText>
				Get access to over 450,000 wine tasting notes and scores from our international team of wine critics
			</SubText>
			<OrangeButton onClick={triggerRedirect} {...dataCySelector('view-plan-options')}>
				VIEW PLAN OPTIONS
			</OrangeButton>
		</div>
	)
}

export default FreeTrialForm