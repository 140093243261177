import React from 'react'

// import Breadcrumbs from '../../../sections/breadcrumbs'

import styled from 'styled-components'

import { PageTitle, PageSubTitle, Separator } from '../common'

import { isMobile } from 'react-device-detect'

const Paragraph = styled.p<{ isBold?: boolean }>`
	width: 100%;
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: ${props => props.isBold ? 'bold' : 'normal'};
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
	padding-top: 10px;
`

const Score = styled.span`
	font-family: Libre Franklin;
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: normal;
	color: #333;
	margin-top: 20px;
`

const ItalicText = styled.span<{ isBold?: boolean }> `
	font-style: italic;
	font-weight: ${props => props.isBold ? 'bold' : 'normal'}
`

const AboutRPWARatingSystem: React.FC = () => (
	<div className="single-entry" style={{ marginTop: 5 }}>
		<div className="mnmd-block mnmd-block--fullwidth mnmd-block--contiguous page-heading page-heading--has-background">
			<div className="container">
				{isMobile ? <h2 className="page-heading__title" style={{ paddingTop: '20px' }}>TWA Rating System</h2> : <h2 className="page-heading__title">TWA Rating System</h2>}
			</div>
		</div>

		<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
			<div className="container">
				<Paragraph>
					{'Nearly every wine review produced by'} <ItalicText>The Wine Advocate</ItalicText> {'carries a rating, otherwise known as a score. The rating is, in essence, our reviewers’ quantitative commentary on the relative quality of the wine at the time of tasting. Readers can immediately recognize our scores when used by wineries or retailers for marketing purposes, by the inclusion of the letters "RP" before the score. Although these letters happen to be the initials for Robert Parker, they do not necessarily denote a score given by Robert Parker himself. RP is in fact short for Robert Parker Wine Advocate, our umbrella company, and therefore the score may have been given by any member of our official reviewer team.'}
				</Paragraph>
				<Paragraph>
					{'Robert Parker created our original rating system with the first issue of'} <ItalicText>The Wine Advocate,</ItalicText> {' and it remains unchanged to this day. It employs a 100-point quality scale (in which wines are given a rating of 50 to 100 points), as Robert Parker believes that the various 20-point rating systems do not provide enough flexibility and often result in compressed and inflated wine ratings. Since its inception, Robert Parker’s 100-point scale has become the wine industry’s standard.'}
				</Paragraph>
				<Paragraph>
					<ItalicText>The Wine Advocate</ItalicText> {'takes a hard, very critical look at wine, since we would prefer to underestimate the wine\'s quality than to overestimate it. The numerical ratings are utilized only to enhance and complement the thorough tasting notes, which are our primary means of communicating our judgments.'}
				</Paragraph>


				<div style={{ paddingTop: '25px' }}>
					<PageSubTitle>The score ranges correlate to the following assessments:</PageSubTitle>
				</div>

				<Separator></Separator>
				<Score>96 - 100:</Score>
				<Paragraph>
					{'An'} <ItalicText isBold={true}>extraordinary</ItalicText> {'wine of profound and complex character displaying all the attributes expected of a classic wine of its variety. Wines of this caliber are worth a special effort to find, purchase and consume.'}
				</Paragraph>

				<Separator></Separator>
				<Score>90 - 95:</Score>
				<Paragraph>
					{'An'} <ItalicText isBold={true}>outstanding</ItalicText> {'wine of exceptional complexity and character. In short, these are terrific wines.'}
				</Paragraph>

				<Separator></Separator>
				<Score>80 - 89:</Score>
				<Paragraph>
					{'A'} <ItalicText isBold={true}>barely above average to very good</ItalicText> {'wine displaying various degrees of finesse and flavor as well as character with no noticeable flaws.'}
				</Paragraph>

				<Separator></Separator>
				<Score>70 - 79:</Score>
				<Paragraph>
					{'An'} <ItalicText isBold={true}>average</ItalicText> {'wine with little distinction except that it is a soundly made. In essence, a straightforward, innocuous wine.'}
				</Paragraph>

				<Separator></Separator>
				<Score>60 - 69:</Score>
				<Paragraph>
					{'A'} <ItalicText isBold={true}>below average</ItalicText> {'wine containing noticeable deficiencies, such as excessive acidity and/or tannin, an absence of flavor or possibly dirty aromas or flavors.'}
				</Paragraph>

				<Separator></Separator>
				<Score>50 - 59:</Score>
				<Paragraph>
					{'A wine deemed to be unacceptable.'}
				</Paragraph>
				<Separator></Separator>

				<div style={{ paddingRight: '15px', paddingBottom: '25px' }}>
					<PageTitle>{'Symbols Accompanying Scores'}</PageTitle>
				</div>

				<Paragraph>
					{'A score in parentheses indicates that the wine was tasted from barrel and is presented as an estimated score range—for example, "(90 - 93)". A plus (+) sign may follow a score—for example, "95+"—indicating a wine that the reviewer believes has the potential to improve over a period of time in bottle and may warrant a higher score in a subsequent/future tasting. In exceptional cases, a wine may not carry a rating but a "?" instead. This indicates that the wine was either known to be faulty or otherwise showing peculiar/unusual characteristics that would require further tastings (e.g., from a fresh bottle that was not immediately forthcoming) in order to make a definitive assessment. Alternatively, some older bottles that are deemed to be past their best may receive no score at all.'}
				</Paragraph>
				<Paragraph>
					{'The numeral rating given is a guide to how the reviewer considers the wine to rate vis-à-vis its peer group (e.g., style, region and/or grape variety). The score given reflects the quality of the wine at its best. Wines from obviously badly corked or defective bottles are disregarded and retasted from a fresh bottle whenever possible, since a wine from a single bad bottle does not indicate an entirely spoiled batch. Many of the wines reviewed have been tasted multiple times, in which case the score represents a cumulative average of the wine\'s performance in tastings to date.'}
				</Paragraph>
				<Paragraph isBold={true}>
					{'As a final note, scores do not reveal the important facts about a wine. The written commentary (tasting note) that accompanies the ratings is a better source of information regarding the wine\'s style and personality, its relative quality vis-à-vis its peers, and its value and aging potential than any score could ever indicate.'}
				</Paragraph>
			</div>
		</div>
	</div>
)

export default AboutRPWARatingSystem