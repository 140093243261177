import React, { ReactElement } from 'react'

interface ColumizedStringProps {
	inputString: string
}

const ColumizedString = ({ inputString }: ColumizedStringProps): ReactElement => {
	const columns = inputString.split('_')

	return (
		<div style={{ textAlign: 'center' }}>
			{columns.slice(0, -1).map((column, index) => (
				<span key={index}>{column} </span>
			))}
			<br />
			{columns[columns.length - 1]}
		</div>
	)
}

export default ColumizedString