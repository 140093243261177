import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS, RP_ENV } from '../../configs'
import { MAX_RETRIES } from './constant'
import { reverseObfuscateConstant } from '../../utils/Constant'

const baseQuery = () => {
	const query = fetchBaseQuery({
		baseUrl: reverseObfuscateConstant(RP_ENV.API_URL_V2),
		prepareHeaders: (headers) => {
			headers.set('Content-Type', 'application/x-www-form-urlencoded')
			headers.set('x-api-key', RP_ENV.API_KEY)
			return headers
		},
		mode: 'cors',
	})
	return retry(query, {maxRetries: MAX_RETRIES})
}

export const PreferenceAPI = createApi({
	reducerPath: 'PreferenceAPI',
	baseQuery: baseQuery(),
	endpoints: (builder) => ({
		updateUserPreference: builder.mutation({
			query: (params: string[]) => ({
				url: API_ENDPOINTS.update_user_preference,
				method: 'POST',
				body: params.join('&'),
			}),
		}),
	}),
})

export const {
	useUpdateUserPreferenceMutation,
} = PreferenceAPI
