/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactElement, useState, useEffect} from 'react'
// Components
import FeaturedBanner from '../../../../components/featured-banner'

import { ConvertJSONArticlesCollectionToClass, GetGroupedTaggedArticles, TranslationsUtil } from '../../../../utils'
import { Link } from 'react-router-dom'

// i18n 
import englishTranslation from './translations/en.json'
const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

// Functions
import FeaturedArticle from '../free-to-read/FeaturedArticle'
import { TaggedPreview } from '../../../../components/article-previews'
import CategorizedPreview from './CategorizedPreview'
import CarouselBanners from '../../../../components/carousel-banners'
import NotableWineRegions from '../notable-wine-regions'
import { useGetFeaturedWineAdvocateQuery } from '../../../../app/services'

const generateNumberArray = (start:number, end:number) => Array.from({ length: end - start + 1 }, (_, index) => start + index)

function TheWineAdvocateArticleSummaries(): ReactElement {
	const [articlesData, setarticlesData] = useState<any[]>([])
	
	const translate = translationUtil.getTranslator()

	const {data: wineArticleQuery, isError} = useGetFeaturedWineAdvocateQuery()

	useEffect(() => {
		if(wineArticleQuery){
			if(wineArticleQuery.success){
				const articles = wineArticleQuery.data.map((article:any) => ({
					...article,
					image:{
						url:article.banner?.url
					}
				}))
				setarticlesData(ConvertJSONArticlesCollectionToClass(articles))
			}
		}
		if(isError){
			console.log('Something went wrong. Please try again.')
		}
	}, [wineArticleQuery, isError])

	return (
		<div className="container--adv pt-4" >
			<FeaturedBanner />
			<div className="mnmd-block">
				<div className="block-heading block-heading--lg">
					<h4 className="block-heading__title">
						<span className="first-word">The</span> Wine Advocate</h4>
				</div>
				{
					articlesData.length > 0 && 
					<div className='row row--space-between'>
						<div className="col-12 col-lg-8">
							<FeaturedArticle article={ articlesData[0] } />
						</div>
						<div className='col-12 col-lg-4'>
							<TaggedPreview className='post__height_25' article={articlesData[1]} />
						</div>
					</div>
				}
				<div className="posts-listing">
					{  articlesData ? (
						GetGroupedTaggedArticles(articlesData)
					): null}
				</div>
				<div className='row row--space-between'>
					{articlesData.length > 5  && generateNumberArray(5,8).map((dataIndex:number) => <div key={dataIndex} className="col-12 col-md-6 col-lg-3">
						<CategorizedPreview article={ articlesData[dataIndex] } />
					</div> )
					}
				</div>
				<div style={{ marginLeft: '0px', marginRight: '0px', marginTop: '2rem'}}>
					<br />
					<CarouselBanners />
				</div>
			</div>
			<div className='row row--space-between'>
				<div className="col-12 col-md-8">
					{ articlesData.length > 8  && <div className='row row--space-between' style={{ flexWrap: 'wrap' }}>
						<div className="col-12">
							<FeaturedArticle article={ articlesData[9] } />
						</div>
						<div className="col-12 col-md-6">
							<TaggedPreview className='post__height_29_5' article={articlesData[10]} />
						</div>
						<div className="col-12 col-md-6">
							<TaggedPreview className='post__height_29_5' article={articlesData[11]} />
						</div>
					</div>}
					
				</div>
				<div className="col-12 col-md-4">
					<NotableWineRegions />
				</div>
			</div>
			<div className='row row--space-between' style={{ flexWrap: 'wrap' }}>
				{articlesData.length > 11 && generateNumberArray(12,19).map((dataIndex:number) => <div key={dataIndex} className="col-12 col-md-6 col-lg-3">
					<CategorizedPreview article={ articlesData[dataIndex] } />
				</div> )
				}
			</div>
			<nav className="mnmd-pagination text-center">
				<Link to='/issues/latest' style={{ backgroundColor: '#73333f', color: 'white' }} className="btn btn-default">{ translate('Read All Wine Advocate Articles') }<i className="mdicon mdicon-arrow_forward mdicon--last"></i></Link>
			</nav>
		</div>
					
	)
}

export default TheWineAdvocateArticleSummaries