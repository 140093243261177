import React, { ReactElement } from 'react'
import { SOCIAL_MEDIA_LINKS } from '../../configs'
import { validateURL } from '../../utils/ValidateUrl'

function SocialMediaLinks(props: { containerClasses: string }):ReactElement {
	const { containerClasses } = props

	const menu = Object.entries(SOCIAL_MEDIA_LINKS)
	
	return (
		<ul className={containerClasses}>
			{menu.map(([key, value]) => (
				<li key={key}>
					<a href={validateURL(`${value.url}`) ? `${value.url}` : ''} target={value.target}>
						<i className={`mdicon mdicon-${value.name} `}></i>
					</a>
				</li>
			))}
		</ul>
	)
}

export default SocialMediaLinks