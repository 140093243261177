import React, { useState, useEffect } from 'react'

import { TranslationsUtil } from '../../../utils'
import englishTranslation from './translations/en.json'

const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

import { Link, useLocation } from 'react-router-dom'
import SearchBoxAutoComplete from '../nav-bar/search/SearchBoxAutoComplete'
import CommonModalContainer from '../../modals/ModalContainer'
import LoginForms from '../../content/Common/Login/LoginForms'
import { SearchHeaderIcons } from './styles'

const searchIcon = <i className="mdicon mdicon-search mdicon--last"></i>
const menuIcon = <i className="mdicon mdicon-menu mdicon--last"></i>
const searchIconMobile = <SearchHeaderIcons className="mdicon mdicon-search mdicon--last"></SearchHeaderIcons>
const menuIconMobile = <SearchHeaderIcons className="mdicon mdicon-menu mdicon--last"></SearchHeaderIcons>

const MobileHeader: React.FC = () => {
	const [openSearch, setOpenSearch] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const { pathname } = useLocation()
	const translate = translationUtil.getTranslator()
	
	useEffect(() => {
		if(pathname === '/login') {
			setIsOpen(true)
		}
	}, [pathname])
	
	const handleSetOpenSearch = () => {
		setOpenSearch(!openSearch)
		const topTitle = document.getElementById('searchTitle') as HTMLElement | null
		if(!openSearch) {
			if(topTitle){
				topTitle.style.color = '#73333f'
			}
			
		} else {
			if(topTitle){
				topTitle.style.color = '#000000'

			}
		}
	}

	return (
		<div id="mnmd-mobile-header" className="mobile-header d-lg-none" style={{ marginBottom: 30 }}>
			<div className="mobile-header__inner mobile-header__inner--flex"  style={{ flex: 1, height: 80, backgroundColor: 'white', alignContent: 'center', boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.15)' }}>
				<div className="header-branding header-branding--mobile mobile-header__section text-left">
					<div className="header-logo header-logo--mobile flexbox__item text-left">
						<Link to={'/'} >
							<img src="img/logo-mark-color.png" alt="logo" />
						</Link>
					</div>
				</div>
				<div className="mobile-header__section text-right">
					<button className="mobile-header-btn" onClick={() => handleSetOpenSearch()} id='searchTitle'>
						<span className="d-none d-md-block">{translate('Search')}{searchIcon}</span>{searchIconMobile}
					</button>
					<a href="#mnmd-offcanvas-primary" className="offcanvas-menu-toggle mobile-header-btn js-mnmd-offcanvas-toggle">
						<span className="d-none d-md-block">{translate('Menu')}{menuIcon}</span>{menuIconMobile}
					</a>
				</div>
			</div>
			<CommonModalContainer isOpen={isOpen} onClose={()=> setIsOpen(false)}>
				<LoginForms setIsOpen={setIsOpen} />
			</CommonModalContainer>
			<div style={{flex: 1}}>
				{openSearch ? <SearchBoxAutoComplete isOpen={true} hideSearch={handleSetOpenSearch} /> : ''}
			</div>
		</div>
	)
}

export default MobileHeader