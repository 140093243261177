import styled from 'styled-components'

const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;

`
export const PageTitle = styled.div`
	width: 100%;
	margin: 30px 130px 20px 0px;
	font-size: 40px;
	font-weight: bold;
	color: #333;
	${commonTextStyle}
	
`

export const MenuOptionContainer = styled.div`
	@media(max-width: 920px) {
		display: none;
	}
`

export const MenuOption = styled.button<{ selected: boolean }>`
	height: 21px;
	width: 300px;
	background-color: #fff;
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
	// margin-right: 10px;
	padding: 0px;


	&:hover {
		color: #a08436;
	}

	border: none;
	// border-bottom: ${(props) => (props.selected ? '2px solid #a08436' : 'none')};

	& div.line {
		height: 4px;
		background-color: ${(props) => (props.selected ? '#a08436 !important' : '#fafafa')};
		width: 100%;
	}
`

export const TabbedMenu = styled.div`	
	// background-color: red;
	width: 100%;
	display: flex;
	flex-wrap: no-space;
	overflow: scroll;

	::-webkit-scrollbar {
		display: none;
	  }
`

export const MenuOptionMobile = styled.div<{ selected: boolean }>`
	// height: 100%;
	width: 300px;
	background-color: #fff;
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
	// margin-right: 10px;
	margin-top: 5px;
	padding: 0px;


	&:hover {
		color: #a08436;
	}

	border: none;
	// border-bottom: ${(props) => (props.selected ? '2px solid #a08436' : 'none')};

	& div.line {
		height: 4px;
		background-color: ${(props) => (props.selected ? '#a08436 !important' : '#fafafa')};
		width: 100%;
	}
`

export const SubscriptionsBlurb = styled.div`
	width: 100%;
	margin: 20px 134px 40px 0px;
	font-size: 16px;
	font-weight: normal;
	color: #333;
	${commonTextStyle}
`
export const SectionContainer = styled.div`
	width: 100%;
`
export const Title = styled.div`
  margin: 40px 0px 20px 0px;
  font-family: Libre Franklin;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  ${commonTextStyle}
`
export const SectionDescription = styled.div`
  margin: 	0 0 40px 30px;
  font-size: 16px;
  font-weight: normal;
  color: #333;
  ${commonTextStyle}

  & li {
	margin-bottom: 1em;
	padding-left: 5px;
}
`
export const SectionSubDescription = styled.ol`
	margin-left: 20px !important;
	& li {
		margin-bottom: 0px !important;
		list-style: lower-alpha !important;
	`

export const LabelContainer = styled.div`
	margin: 20px 0px 40px 0px;
  }`

export const Label = styled.p`
	font-size: 16px;
	font-weight: normal;
	color: #333;
	${commonTextStyle}
  }`

export const TextStyle1 = styled.span`
	font-weight: bold;
`
export const TextStyle2 = styled.span`
	font-weight: bold;
	font-style: italic;
`
export const TextStyle3 = styled.span`
	color: #a08436;
`
export const TextStyle4 = styled.span`
	font-style: italic;
`