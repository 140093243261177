/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react'

function SearchFilterField(props: { triggerFilter: any } ): ReactElement {

	const { triggerFilter } = props
	const [ innerSearchValue, setInnerSearchValue ] = useState('')

	const handleChange = (keywords: string) => {
		setInnerSearchValue(keywords)
		triggerFilter(keywords)
	}

	return (
		<div>
			<input className='search-filter-field' value={ innerSearchValue } type='text' onChange={(event) => { handleChange(event.target.value) }} />
		</div>
	)
}

export default SearchFilterField