import i18n, { TFunction } from 'i18next'
import { useTranslation, initReactI18next } from 'react-i18next'

class TranslationsUtil {
	constructor(englishConfig: LanguageDefinitions) {
		i18n.use(initReactI18next).init({
			resources: {
				en: {
					translation: englishConfig,
				},
			},
			lng: 'en', // Change this to read from language option
			fallbackLng: 'en',
			interpolation: {
				escapeValue: false,
			},
		})
	}

	getTranslator(): TFunction {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { t } = useTranslation()

		return t
	}
}

export default TranslationsUtil
