import styled from 'styled-components'

const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
`

export const ProfileContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	align-items: flex-start;

	& .profile-info {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		margin-right: 2.5rem;
	}

	& .misc-info {
		width: 100%;
	}

	@media (max-width: 992px) {
		flex-direction: column;
	}
`

export const PageTitle = styled.div`
	margin:  20px 134px 40px 0px;
	font-size: 40px;
	font-weight: bold;
	color: #333;
	${commonTextStyle}
	
`
export const DefaultPicture = styled.div`
	width: 204px;
	height: 214px;
	border: solid 4px var(--white-three); 
	border-style: dotted;
`
export const Separator = styled.div`
	height: 1px;
	margin: 19px 0 30px;
	background-color: #ccc;
`
export const ImageHolder = styled.div`
	width: 285px;
    height: 285px;
    padding: 20px;
    border: solid 1px #ccc;
    background-color: var(--white-three);

	& img {
		width: 100%;
		height: 100%;
		border: solid 4px var(--white-three);
		object-fit: contain;
	}

	@media (max-width: 1200px) and (min-width: 992px) {
		width: 168px;
		height: 168px;
	}
`

export const Description = styled.div`
	text-align: justify;
	margin: 20px 0 0;
	font-size: 16px;
	font-weight: normal;
	color: #333;
	white-space: break-spaces;
	${commonTextStyle}
`

export const Title = styled.div<{
	margin: string
}>`
	margin: ${(props) => props.margin};
	font-family: Libre Franklin;
	font-size: 21px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.52;
	letter-spacing: normal;
	color: #333;
`
export const Label = styled.div<{
	margin: string
}>`
	margin: ${(props) => props.margin};
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
`
export const Col = styled.div`
	padding-left: 0px;
	padding-right: 0px;
`
export const LeftContent = styled.div`
	width: 310px;
}
`
export const DefaultMap = styled.div`
	width: 750px;
	height: 380px;
	margin-left: 79px;
	padding: 20px;
	border: solid 4px var(--white-three); 
	border-style: dotted;
`
export const WebLink = styled.a`
	cursor: pointer;
	font-size: 16px;

	&:hover {
		text-decoration: none;
	}
`

export const MapTitle = styled.div`
	margin-bottom: 19px;
	font-family: Libre Franklin;
	font-size: 21px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.52;
	letter-spacing: normal;
	color: #333;

	@media (max-width: 768px) {
		margin: 0 0 19px 0;
	}
`

export const MapContainer = styled.div`
	height: 400px;
	width: 795px;
	margin: auto;
	
	@media (max-width: 992px) {
		width: 100%;
	}
`