import styled from 'styled-components'

const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
`

export const Content = styled.div`
	& div.content-title {
		margin-bottom: 10px;
		font-size: 40px;
		font-weight: bold;
		color: #333;
		${commonTextStyle}
	}

	& div.spaced {
		margin-top: 40px;
	}

	& p {
		font-size: 16px;
		font-weight: normal;
		color: #333;

		${commonTextStyle}
	}

	& ul {
		margin-left: 20px;
		& li {
			margin-bottom: 20px;
		}
	}
`

export const TabbedMenu = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: no-space;
	overflow: inherit;
	align-items: center;
	justify-content: center;

	::-webkit-scrollbar {
		display: none;
	}

	@media (max-width: 920px) {
		&.about-menu {
			display: none;
		}
	}
`

export const MenuItem = styled.div<{ filler: boolean; isActive: boolean }>`
	// max-width: ${(props) => (props.filler ? '40%' : '24%')};
	padding-bottom: 10px;
	cursor: pointer;

	& div.menu-label {
		text-align: center;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: normal;
		color: #333;
		${commonTextStyle}
		margin-bottom: 10px;
		width: 300px;
	}

	& div.menu-label-mobile {
		font-size: 14px;
		font-weight: bold;
		letter-spacing: normal;
		color: #333;
		${commonTextStyle}
		margin-top: 5px;
		margin-bottom: 5px;
		width: 300px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& div {
		& .menu-display {
			display: flex;
			width: 100%;
			justify-content: center;
			gap: 1.5rem;
		}
	}

	& div.filler {
		min-width: 30px;
	}

	& div.line {
		height: 4px;
		background-color: ${(props) => (props.isActive ? '#a08436 !important' : '#fafafa')};
		width: 100%;
	}
`

export const PageContent = styled.div`
	width: 100%;
	height: 100%;
	// margin: 20px 20px 0px;
	padding: 20px;
	font-family: Libre Franklin;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
`

export const Quote = styled.div`
	text-align: center;

	& div.quote-content {
		font-size: 20px;
		font-weight: bold;
		line-height: 3;
		text-align: center;
		color: #333;
	}

	& div.bar {
		margin-top: 30px;
		margin-bottom: 30px;
		display: flex;
		width: 100%;
		justify-content: center;

		& div.bar-highlight {
			height: 4px;
			background-color: #a08436;
			width: 163px;
		}
	}
`

export const MenuChildren = styled.div`
	width: 100%;
	position: absolute;
	top: 79%;
	right: auto;
	bottom: auto;
	left: auto;
	padding: 10px;
	background: white;
	opacity: 0;
	visibility: hidden;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.03), 0 6px 6px rgba(0, 0, 0, 0.05);
	transform: scale(1, 0.9);
	transform-origin: top left;
	transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s;
	z-index: 30;

	& p {
		padding: 8px 16px;
		margin: 0;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;

		&:hover {
			background-color: #EDEDED;
		}
	}
`

export const AboutWineMenuContent = styled.div`
	position: relative;

	&:hover {
		& ${MenuChildren}{
			opacity: 1;
			visibility: visible;
			transform: scale(1, 1);
			transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
		}
	}
} 
`


