import React, { ReactElement, useRef, useState, useEffect, Dispatch, SetStateAction } from 'react'
import { UploadButton,ValidationField } from './styles'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
import { dataCySelector } from '../../../../app/services/constant'

type CompanyLogoProps =  {
    image: string | undefined
    onImageChange: (newImage: string) => void
    logoView: string | undefined
	getNewCompanyLogo: (file: File) => Promise<string>
	setIsLogoChanged: Dispatch<SetStateAction<boolean>>
}

const CompanyLogo = ({ image, onImageChange, logoView, setIsLogoChanged, getNewCompanyLogo }: CompanyLogoProps): ReactElement => {
	const fileInputRef = useRef<HTMLInputElement | null>(null)
	const [isShowValidation, setIsShowValidation] = useState(false)
	const [validationText, setValidationText] = useState('')
	const [logo, setLogo] = useState<string | undefined>()

	useEffect(() => {
		setLogo(image)
	}, [image])
	
	

	const handleUploadClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click()
		}
	}

	const handleUploadLogo = (files: FileList | null) => {
		if (files) {
			const fileRef = files[0] || ''
			const fileType = fileRef.type || ''
			const fileSize = fileRef.size
			const maxFileSize = 1024 * 1024
            
			if (fileType === '') {
				setLogo(logoView)
				setIsShowValidation(false)
				setIsLogoChanged(false)
				return
			}
			if (fileType !== 'image/jpg' && fileType !== 'image/jpeg' && fileType !== 'image/png') {
				setLogo(logoView)
				setIsShowValidation(true)
				setValidationText('Invalid File Format')
				setIsLogoChanged(false)
				return
			}
			if (fileSize > maxFileSize) {
				setLogo(logoView)
				setIsShowValidation(true)
				setValidationText('Maximum File Required 1MB')
				setIsLogoChanged(false)
				return
			}

			const reader = new FileReader()
			reader.readAsBinaryString(fileRef)
			reader.onload = (ev: ProgressEvent<FileReader>) => {
				const img = new Image()
				img.src = `data:${fileType};base64,${btoa(ev.target?.result as string)}`
				img.onload = () => {
					if (img.width !== 200 && img.height !== 200) {
						setLogo(logoView)
						setIsShowValidation(true)
						setValidationText('Image must be 200 x 200 px.')
						setIsLogoChanged(false)
						return
					}
					setLogo(img.src)
					setIsShowValidation(false)
					setIsLogoChanged(true)
					getNewCompanyLogo(fileRef).then((fileRef) => {
						onImageChange(fileRef)
					})
				}
			}
			
		}
	}

	return (
		<>
			{logo ?
				(
					<>
						{logo?.startsWith('https://') ? <div className="cloudimage-container" onClick={handleUploadClick}>
							<CloudImageUtils imgSrc={logo} alt="image" ratio={0} params='func=bound&w=800&h=800'/>
						</div> : <img onClick={handleUploadClick} src={logo} alt="image" />}
					</>
				) : (
					<div className="image-container" onClick={handleUploadClick}>
						<div className="plus-icon">+</div>
					</div>
				)}
			<div className={'upload-button'}>
				<input type="file" id="profile-pic" ref={fileInputRef} onChange={(event) => { handleUploadLogo(event.target.files) }} />
				{isShowValidation ? <><ValidationField>{validationText}</ValidationField></> : <></>}
				<div>
					<UploadButton onClick={handleUploadClick} {...dataCySelector('upload-logo-btn')}>UPLOAD LOGO</UploadButton>
					<div>
						<div className={'text-value'}>200 x 200 px</div>
						<div className={'text-value'}>Maximum size: 1MB</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default CompanyLogo
