import styled from 'styled-components'

export const RPColors = {
	primaryColor: '#8f5c65',
	darkPrimary: '#311820',
	borderColor: '#a18436',
	borderColorError: '#ff0000',
	secondaryColor: 'rgba(115, 51, 63, 0.2)',
	buttonColor: '#73333f',
	buttonColorHover: '#79404b',
	goldColor: 'rgba(161, 132, 54, 0.3)'
}

export const InputField = styled.input<{ isError?: boolean, type?: string }>`
    width: 100%;
    height: 100%;   
    padding: 18px 10px 18px 10px !important;
    border-radius: 8px !important;
    border:  ${(props) => (props.isError ? `solid 2px ${RPColors.borderColorError}` : `solid 1px ${RPColors.borderColor}`)} !important;
    margin-bottom: 5px;

    ::placeholder {
        color: black;
    }

    &:focus, :hover {
        border:  ${(props) => (props.isError ? `solid 2px ${RPColors.borderColorError}` : `solid 1px ${RPColors.borderColor}`)} !important;
		transition: border 0.3s ease-in-out !important;
	}
`

export const FieldErrorMessage = styled.div`
    display: flex;
    flex-direction: row;
    color: #c50000;

    & .error-message {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        & img {
            width: 15px;
        }
    }
`