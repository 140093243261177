import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

function TagCloud(props: { tags: string[] } ): ReactElement {

	const { tags } = props

	return (
		<>
			<div className="widget__title">
				<h4 className="widget__title-text">Tags</h4>
			</div>
			{ tags.length > 0 ? (<div className="tagcloud">
				{ tags.map((tag: string, tag_cloud_key:number) => (<Link key={ tag_cloud_key } to={ `/search/wine?keyword=${ tag } `} className="tag-link-77 tag-link-position-1" title="9 topics">{ tag }</Link>)) }
			</div>):(<div>No tags on this article.</div>)}
			
		</>
	)
	
}
export default TagCloud