import styled from 'styled-components'
import { RPColors } from '../../../../styles/CSSConstant'

export const RedeemMainContainer = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Libre Franklin', sans-serif;
`

export const RedeemTitle = styled.div`
    
`

export const FormGroup =styled.div`
    margin: auto;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    & .form-title{
        margin-top: 1.8rem;
        font-weight: bold;
        font-size: 18px;
    }

    & div {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

        & .password-container{
            position: relative;

            & svg{
                position: absolute;
                right: 0;
                margin-right: 0.2rem;
                top: 1px;
            }
        }
    }

    @media(max-width: 992px) {
        max-width: 60%;
    }
    
    @media(max-width: 550px) {
        max-width: 85%;
    }
`

export const ValidatedField = styled.input`
	border: 1px solid black;
	border-radius: 8px;
	padding: 6px 6px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	&:focus {
		border-color: red;
		transition: border-color 0.3s ease-in-out;
		outline: 0;
	}
	&.error {
		border-color: #c50000;
	}
`

export const ValidatedFieldSelect = styled.select`
    border-radius: 8px;
    padding: 6px 6px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 64px;
    &:focus {
        border-color: #ccc;
        transition: border-color 0.3s ease-in-out;
        outline: 0;
    }
    &.error {
        border-color: #c50000;
    }
`

export const FieldErrorMessage = styled.div`
    display: flex;
    flex-direction: row;
    color: #c50000;

    & .error-message {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        & img {
            width: 15px;
        }
    }
`

export const RedeemButton = styled.button`
	width: fit-content;
    height: auto;
    padding: 6px 26px;
    border-radius: 8px;
    background-color: #73333f;
    font-family: Libre Franklin;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
	color: #fff;
	border: none;

	&:hover {
		background-color: #8E4250;
	}
`

export const RedeemActivationContainer = styled.div`
    margin: auto;
    width: 50%;

    & .redeem {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .redeem-title {
            font-weight: bold;
            font-size: 20px;
        }
    }

    & .redeem-content {
        display: flex;
        flex-direction: column;

        & p{
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 0.2rem;
        }

        & div{
            display: flex;
            align-items: center;
            justify-content: space-between;

            & .price{
                font-weight: bold;
                font-size: 18px;
            }
        }
    }

    & .activation-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & div {
            display: flex;
            flex-direction: column;
            gap: 1.5em;

            & .activation-sub {
                font-weight: bold;
                font-size: 18px;
            }

            & .select-amount {
                display: flex;
                gap: 0.4rem;
                flex-direction: row;
                align-items: center;

                & label {
                    font-weight: normal;
                }
            }
        }
        
        & .activation-price {
            flex-direction: column;
            align-items: flex-end;

            & .activation-amt {
                font-weight: bold;
                font-size: 16px;
            }
        }
    }

    & .activation-total {
        width: 100%;
        text-align: end;
        font-weight: bold;
        font-size: 16px;
    }

    & .agreement-checkbox-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & .activation-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    @media (max-width: 992px) {
        width: 60%;
    }

    @media(max-width: 550px) {
        width: 85%;

        & .agreement-checkbox-container {
    
            & .activation-buttons {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                gap: 1rem;

                & a {
                    width: 100%;

                    & button {
                        width: 100%;
                    }
                }

                & button {
                    width: 100%;
                    padding: 6px 0;
                }
            }
        }
    }
`

export const BackButton = styled.button`
    
    height: auto;
    padding: 0.5rem 1.5rem;
    border-radius: 8px;
    border: solid 1px #ccc;
    background-color: #ffffff;
    &.mobile {
        padding-right: 12px !important;
    }
`

export const Separator = styled.hr`
    width: 100%;
    display: flex;
    justify-content: center;
    & p {
        margin: 0;
        font-size: 24px;
        font-weight: 700;
    }
`

export const RedeemCardContainer = styled.div<{ isVerified: boolean, isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '100%' : '600px')};
    height: auto;
    margin: 32px ${({ isMobile }) => (isMobile ? 0 : '400px')} 204px;
    padding: 32px;
    padding-bottom: ${({ isVerified }) => (isVerified ? 0 : '32px')};
    border-radius: ${({ isMobile }) => (isMobile ? 0 : '12px')};
    border: solid 1px ${RPColors.borderColor};
    background-color: 'white';
`
export const VerifyRedeemCardBody = styled.div`
    & .head-body {
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;
        gap: 8px;

        & h3 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
        }

        & p {
            margin: 0;
            font-size: 16px;
        }
    }

    & .redeem-form {
        display: flex;
        align-items: center;
        flex-direction: column;

        & .first-input {
            width: 100%;
        }

    }
`

export const RedeemCardFormBody = styled.div<{isMobile: boolean}>`
    & .head-body {
        display: flex;
        flex-direction: column;
        gap: 8px;

        & h3 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
        }

        & p {
            margin: 0;
            font-size: 16px;
        }
    }

    & .form {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        margin-bottom: 10px;

        & .first-input {
            width: 100%;
            display: flex;
            flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
            justify-content: space-between;
            align-items: start;
            gap: ${({ isMobile }) => isMobile ? 0 : '16px'};
            

            & .password-input {
                width: 100%;
                position: relative;

                & img {
                    z-index: 1;
                    position: absolute;
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    right: 10px;
                    bottom: 15px;
                }
            }
        }

        & .action-input{
            margin: 0.7rem 0 1.5rem 0;
            width: 100%;
            display: flex;
            flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
            justify-content: space-between;
            align-items: end;
            gap: 16px;

            & .recaptcha-style {
                text-align:  ${({ isMobile }) => isMobile ? 'center' : 'start'};
            }
        }
    }

    & .bold-link {
		font-weight: bold;
	}

	& .italic-link {
		font-style: italic;
	}
    
`

export const Button = styled.div<{ width?: string }>`
    display: flex;
    justify-content: center;
    margin: 0;
    width: ${(props) => (props.width)};
    height: 100%;
    padding: 12px 80px;
    align-items: center;
    border-radius: 8px;
    background-color: ${RPColors.buttonColor};

    & span {
        font-size: 14px;
        font-weight: 500;
        color: white !important;
    }

    :hover {
        cursor: pointer;
        background-color: ${RPColors.buttonColorHover};
    }

    @media (max-width: 991px) {
        width: 100%;
    }
`