import React, { ReactElement } from 'react'

// import { CounterLabel } from '../common/styles'

// import { useGetCommentsOfAnArticleQuery } from '../../../../../app/services'

function CommentCounter(props: { articleId: string }): ReactElement {

	// const { articleId } = props

	// const { data } = useGetCommentsOfAnArticleQuery(`article_id=${ articleId }`, { skip: articleId? false: true })
	
	return (
		<div>
			{/* <img src="img/icons/comments.png" />
			<CounterLabel>{ data?.data?.count > 0 ? data?.data?.count: 0 }</CounterLabel>
			<CounterLabel>0</CounterLabel> */}
		</div>
	)
	
}

export default CommentCounter