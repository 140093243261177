import styled from 'styled-components'

export const CardContainer = styled.div`
    cursor: pointer;
    padding: 24px 69px;
    background-color: rgb(115 51 63 / 20%);
    border: 2px solid #A5893E;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`

export const SuccessContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & .head-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & img {
            margin-bottom: 2rem;
        }

        & .head-content-body {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            & h3 {
                font-weight: bold;
            }

            & p {
                margin-bottom: 0;
            }

            & .link-anchor {
                font-size: 1rem;
                margin-top: 1rem;
            }
        }
    }

    & .regions-selection {
        
        & h3 {
            text-align: center;
            margin-bottom: 3rem;
            font-weight: bold;
        }

        & .regions {
            display: flex;
            gap: 1rem;
            justify-content: space-between;
            align-items: center;
        }
    }
`