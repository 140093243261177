/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

// Styles
import { ArticleContainer, ArticleImageContainer } from './styles'

// Slices
import Article from '../../../../structure/classes/Article'
import Pagination from '../../../../components/pagination'
import { slugify } from '../../../../utils/ArticlesUtil'
import { useGetMoreFreeStuffMutation } from '../../../../app/services'
import moment from 'moment'
import LoadingOverlay from '../../../../components/Loading-overlay'
import { isMobile } from 'react-device-detect'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
import useScreen from '../../../../helper/hooks/useScreen'

const getTotalNotes = (articles: Article[]): number => {
	let total = 0
	articles.forEach(article => total += article.tasting_note_count)
	return total
}

const limit = 10 //for pagination

function MoreFreeStuffArticle(): ReactElement {

	const screen = useScreen()

	const imageRatio = () => {
		switch (screen) {
		case 'tablet portrait':
			return 1.1
		case 'desktop':
			return 1.3
		default:
			return 3.5
		}
	}

	const [articlesToDisplay, setArticlesToDisplay] = React.useState<any>()
	const history = useHistory()

	const [searchParams, setSearchParams] = React.useState(['offset=0', `limit=${limit}`])
	const [totalRecords, setTotalRecords] = useState(0)
	const [isLoading, setIsLoading] = useState(false)

	const [searchMoreFreeStuff, {data, isError}] = useGetMoreFreeStuffMutation()

	const handleSearchMoreFreeStuff = async () => {
		try {
			setIsLoading(true)
			await searchMoreFreeStuff(searchParams)
		} catch(error){
			console.log('DEBUG ...', 'fetch error: ', error)
		}  finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		handleSearchMoreFreeStuff()
	}, [searchParams])

	useEffect(() =>{
		if(data){
			if(data.success){
				setArticlesToDisplay(data?.data.data)
				setTotalRecords(data.data.total)
			} else {
				console.log('ERROR: ', data.message)
			}
		}

		if(isError){
			console.log('Something went wrong. Please try again.')
		}
	},[data, isError])


	const goToArticle = (id: string, title: string) => {
		history.push(`/articles/${id}/${slugify(title)}`)
	}

	const handleChangePage = (page: number) => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
		setSearchParams([...searchParams.filter(param => !param.startsWith('offset')), `offset=${page}`])
	}

	return (
		<div>
			<div className="mnmd-block mnmd-block--fullwidth mnmd-block--contiguous page-heading page-heading--has-background">
				<div className="container">
					{isMobile ? <h2 className="page-heading__title" style={{ paddingTop: '20px' }}>Free to read Highlights</h2> : <h2 className="page-heading__title">Free to read Highlights</h2>}
					{articlesToDisplay ? (
						<div className='page-heading__subtitle'>{articlesToDisplay.length} articles * {getTotalNotes(articlesToDisplay)} wines tasted</div>
					) : null}
					
				</div>
			</div>
			<div className="mnmd-block mnmd-block--fullwidth">
				<div className="container">
					{articlesToDisplay ? 
						<>
							{articlesToDisplay.map((article: any, key: number) => (
								<ArticleContainer key={key} onClick={() => goToArticle(article._id, article.slug)} >
									<ArticleImageContainer>
										<CloudImageUtils imgSrc={article?.banner?.url} alt="image" ratio={imageRatio()} params=''/>
									</ArticleImageContainer>
									<div className='details-container'>
										<Link to={`/articles/${article._id}/${slugify(article.slug)}`} className='title'>
											{article.title}
										</Link>
										<Link to={`/articles/${article._id}/${slugify(article.slug)}`} className='excerpt'>{article.excerpt}</Link>

										<div className="post__meta details" style={{width: '100%'}}>
											<span className="entry-author">By:&nbsp;
												<Link to={`/author/${article.author.id}`} href="#" className="entry-author__name">{article.author.name}</Link>
											</span>
											<time className="time published detail-container" dateTime={article.published_date} title={moment(article.published_date).format('MMM DD, YYYY')}>
												<i className="mdicon mdicon-schedule"></i>
												<span className='date'>{moment(article.published_date).format('MMM DD, YYYY')}</span>
											</time>
											<Link to={`/search/wines?&article_id=${article.id}`} title={`${article.tasting_note_count} tasting notes`}><i className="mdicon mdicon-chat_bubble_outline"></i>{article.tasting_note_count || 0}</Link>
										</div>
									</div>
								</ArticleContainer>

							))}
							<Pagination totalRecords={totalRecords} limit={limit} render={handleChangePage} />
						</>
						: (<LoadingOverlay />)}
				</div>
			</div>
		</div>)
}

export default MoreFreeStuffArticle

