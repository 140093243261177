/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as _ from 'lodash'
import React, {  ChangeEvent, FocusEvent, KeyboardEvent, ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Sections
import SectionSeparator from '../../../sections/section-separator'

// Styles
import { CheckboxLabelCombo, SearchAndCheckboxFilterContainer, SearchAndCheckboxOptionsContainer, SearchFieldContainerDesktop, SearchFieldContainerMobile, Title } from '../styles'

import { TitleMargin } from '.'
import { useAppSelector } from '../../../../../app/hooks'

import { SearchFilters as SearchFiltersFromStore, SetActiveAdvanceFilters, SetFacetsFilters } from '../../../../../app/slices/SearchFilters'
import algoliasearch from 'algoliasearch'
import { APP_CONFIG, RP_ENV } from '../../../../../configs'
import { lowerCaseId, updateFiltersFromSearch } from '../../../../../utils/FilterUtils'
// import PriceAndRangeFilter from './PriceAndRangeFilter'
// import RangeChart from './RangeChart'
// import { IsUserAuthenticated } from '../../../../../utils'
import { useGetPriceRatingRangeQuery } from '../../../../../app/services'
import { reverseObfuscateConstant } from '../../../../../utils/Constant'
// import LoadingOverlay from '../../../../../components/Loading-overlay'

type FilterOption = {
	value: string,
	label: string,
	isSelected: boolean
}

type PriceRatingRangeType = {
	price_low: number[],
	rating_computed: number[]
}

type CustomFilterType = {
	title: string, 
	childData: any, 
	reference: string, 
	showSearch: boolean, 
	type: string, 
	items: {label: string | number; count: number}[],
}

const appId = reverseObfuscateConstant(RP_ENV.ALGOLIA_APPLICATION_ID) as string
const apiKey = reverseObfuscateConstant(RP_ENV.ALGOLIA_API_KEY) as string
const searchClient = algoliasearch(appId, apiKey)

const titleDisplay = (title: string): string => {
	switch (title) {
	case 'Release Price':
		return 'Release Price in USD'
	case 'Rating':
		return 'Robert Parker Ratings'
	default:
		return title
	}
}

// const indicatorDisplay = (title: string): string => {
// 	switch (title) {
// 	case 'Release Price':
// 		return '$'
// 	case 'Rating':
// 		return 'RP'
// 	default:
// 		return title
// 	}
// }

// const addClass = (title: string): string => {
// 	switch (title) {
// 	case 'Release Price':
// 		return 'rPrice-input'
// 	case 'Rating':
// 		return 'rp-input'
// 	default:
// 		return title
// 	}
// }

function CustomFiters({title, childData, reference, showSearch, type, items}: CustomFilterType): ReactElement {

	const minMaxBody = {
		facetFilters: [],
		filters: '',
		query: ''
		
	}

	const [priceRatingRangeData, setPriceRatingRangeData] = useState<PriceRatingRangeType>({
		rating_computed: [80, 100],
		price_low: [0, 110000],
	})
	
	const isPriceAndRatings = reference === 'price_low' || reference === 'rating_computed'
	// const isAuthenticated = IsUserAuthenticated()

	const [filteredOption, setFilteredOption] = useState<any>([])

	const [newFilteredOption, setNewFilteredOption] = useState<any>([])

	const [keyword, setKeyword] = useState('')
	const [isSearch, setIsSearch] = useState(false)
	
	const disMount = useRef(false) 

	// const { data } = useGetPriceRatingRangeQuery(minMaxBody, { skip: !isAuthenticated, refetchOnReconnect: true })
	const { data } = useGetPriceRatingRangeQuery(minMaxBody, { skip: true })

	const {AdvanceFilter: advanceFilter} = useAppSelector(SearchFiltersFromStore)
	const dispatch = useDispatch()
	const priceRateRangeData = useSelector(SearchFiltersFromStore)
	const [isLoading, setLoading] = useState<boolean>()

	const [range, setRange] = useState({
		price_low: {
			min: 0,
			max: 10000
		},
		rating_computed: {
			min: 0,
			max:100
		}
	})

	const allOptions = () => {
		if(reference === 'vintage') {
			return childData.map((data: any) => {
				const option: FilterOption = {
					value: data.value,
					label: data.label,
					isSelected: false
				}
				return option
			})
		} else {
			const selectedData = childData.map((data: any) => {
				const option: FilterOption = {
					value: data.value,
					label: data.label,
					isSelected: false
				}
				return option
			})

			return selectedData.sort((a:any, b: any) => a.label.localeCompare(b.label))
		}
	}

	useEffect(() => {
		if (advanceFilter[reference] && Object.keys(advanceFilter[reference]).length !== 0) {
			setFilteredOption(advanceFilter[reference])
		} else {
			setKeyword('')
			setNewFilteredOption([])
			setFilteredOption(allOptions())
		}
		//eslint-disable-next-line
	}, [advanceFilter])

	useEffect(() => {
		if(data) {
			setRange({
				price_low: {
					min: data.data.price_low.min,
					max: data.data.price_low.max,
				},
				rating_computed: {
					min: data.data.rating_computed.min,
					max: data.data.rating_computed.max,
				}
			})
		}
	}, [data])

	useEffect(() => {
		if(disMount.current){
			const timer = setTimeout(() => {
				dispatch(SetFacetsFilters({
					facetFilter: priceRatingRangeData,
				}))
				setLoading(false)
			}, 1000)
			
			return () =>{
				clearTimeout(timer)
			}
		}
		disMount.current = true
	},[priceRatingRangeData])

	useEffect(() => {
		const valueChange = priceRateRangeData.FacetFilters
		if(valueChange?.price_low !== undefined && valueChange?.rating_computed !== undefined) {
			const newData = {
				price_low: [
					parseFloat(valueChange?.price_low[0]),
					parseFloat(valueChange?.price_low[1])
				],
				rating_computed: [
					parseFloat(valueChange?.rating_computed[0]),
					parseFloat(valueChange?.rating_computed[1])
				],
			}
			setPriceRatingRangeData(newData)
		}
	},[range])

	const toggleChoice = (event: React.ChangeEvent<HTMLInputElement>, isAll: boolean) => {

		// updates the collection

		if (newFilteredOption.length > 0) {
			let label = ''
			const updatedFilters = newFilteredOption.map((option: any) => {
				if (isAll) {
					option = { ...option, isSelected: event.target.checked }
				} else if (option.value === event.target.value.trim()) {
					label = option.label
					option = { ...option, isSelected: event.target.checked }
				}


				return option
			})
			setNewFilteredOption(updatedFilters)
			let newFilters = filteredOption as any

			if (isAll) {
				updatedFilters.map((ele: any) => { 
					newFilters = updateFiltersFromSearch(newFilters, updatedFilters, ele.value, ele.label)
				})
			} else {
				newFilters = updateFiltersFromSearch(filteredOption, updatedFilters, event.target.value, label)
			}

			dispatch(SetActiveAdvanceFilters({
				advanceFilter: { [reference]: newFilters }
			}))

		} else {
			const updatedFilters = filteredOption.map((option: any) => {
				if (isAll) {
					option = { ...option, isSelected: event.target.checked }
				} else if (option.value === event.target.value.trim()) {
					option = { ...option, isSelected: event.target.checked }
				}
				return option

			})
			setFilteredOption(updatedFilters)
			dispatch(SetActiveAdvanceFilters({
				advanceFilter: { [reference]: updatedFilters }
			}))
		}

	}

	const updateKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
		setKeyword(event.target.value)
		if(event.target.value === ''){
			setNewFilteredOption([])
			setIsSearch(false)
		}
	}

	const searchForFacetValue = async (indexName: string, facetName: string, query: any) => {
		const index = searchClient.initIndex(indexName)
		const { facetHits } = await index.searchForFacetValues(facetName, query, {
			maxFacetHits: 100
		})
		return facetHits
	}

	const handleApply = async () => {
		try {
			if (keyword) {
				const wineIndex =  reverseObfuscateConstant(RP_ENV.WINE_INDEX) || ''
				const articleIndex =  reverseObfuscateConstant(RP_ENV.ARTICLE_INDEX) || ''
				const myFacetHits = await searchForFacetValue(type === APP_CONFIG.SEARCH.TYPES.WINE ? wineIndex : articleIndex, reference, keyword)
				if (myFacetHits.length > 0) {
	
					const options = myFacetHits.map(facet => {
						const value = lowerCaseId(`${reference}:${facet.value}`)
						const index = advanceFilter[reference]?.findIndex((data: any) => data.value === value && data.isSelected === true)
						return { value: value, label: facet.value, isSelected: index ? (index > - 1) : false, isFromSearch: true }
					})
					setNewFilteredOption(options)

				} else {
					setNewFilteredOption([])
				}
				setIsSearch(true)
			}else{
				setNewFilteredOption([])
			}
		} catch (error) {
			console.log(error)
		}
	}

	const enterPressedHandler = (event: KeyboardEvent<HTMLInputElement>) => {
		if(event.key === 'Enter' || event.keyCode == 13) return handleApply()
		else return
	}

	const updateListByBlur = async (event:FocusEvent<HTMLInputElement>) => {
		const {value} = event.target
		if(!value){
			setNewFilteredOption([])
			setIsSearch(false)
			return
		}else{
			try {
				setIsSearch(true)
				const wineIndex =  reverseObfuscateConstant(RP_ENV.WINE_INDEX) || ''
				const articleIndex =  reverseObfuscateConstant(RP_ENV.ARTICLE_INDEX) || ''
				const myFacetHits = await searchForFacetValue(type === APP_CONFIG.SEARCH.TYPES.WINE ? wineIndex : articleIndex, reference, value)
				if (myFacetHits.length > 0) {
		
					const options = myFacetHits.map(facet => {
						const value = lowerCaseId(`${reference}:${facet.value}`)
						const index = advanceFilter[reference]?.findIndex((data: any) => data.value === value && data.isSelected === true)
						return { value: value, label: facet.value, isSelected: index ? (index > - 1) : false, isFromSearch: true }
					})
					setNewFilteredOption(options)
				} else {
					setNewFilteredOption([])
				}
			} catch (error) {
				console.log(error)
			}finally{
				setIsSearch(false)
			}
		}
	}

	const displayData = newFilteredOption.length > 0 ? newFilteredOption : filteredOption

	const body = (data: any, index: any) => (
		data.map((option: FilterOption, innerIndex: number) => (
			<CheckboxLabelCombo key={innerIndex} isBoldedLabel={false} >
				<input checked={option.isSelected} className='vintage-options' name={reference} onChange={(e) => toggleChoice(e, false)} type='checkbox' id={`${reference}_${index}_${innerIndex}`} value={option.value} />
				<label htmlFor={`${reference}_${index}_${innerIndex}`} >{option.label}</label>
			</CheckboxLabelCombo>
		))
	)

	// const rangeInputClickedHandler = async (type: 'min' | 'max', reference: string, value=0) => {
	// 	if(reference === 'rating_computed'){
	// 		if(type === 'max') {
	// 			return setPriceRatingRangeData(prevState => ({
	// 				...prevState,
	// 				rating_computed: [prevState.rating_computed[0], value]
	// 			}))
	// 		}
	
	// 		if(type === 'min') {
	// 			return setPriceRatingRangeData(prevState => ({
	// 				...prevState,
	// 				rating_computed: [value, prevState.rating_computed[1]]
	// 			}))
	// 		}
	// 	}

	// 	if(reference === 'price_low') {
	// 		if(type === 'max') {
	// 			return setPriceRatingRangeData(prevState => ({
	// 				...prevState,
	// 				price_low: [prevState.price_low[0], value]
	// 			}))
	// 		}
	
	// 		if(type === 'min') {
	// 			return setPriceRatingRangeData(prevState => ({
	// 				...prevState,
	// 				price_low: [value, prevState.price_low[1]]
	// 			}))
	// 		}
	// 	}
	// }

	// const resetRangeHandler = async (reference: string) => {
	// 	if(reference === 'price_low') {
	// 		return setPriceRatingRangeData(prevState => ({
	// 			...prevState,
	// 			price_low: [range.price_low.min, range.price_low.max]
	// 		}))
	// 	}
	// 	if(reference === 'rating_computed') {
	// 		return setPriceRatingRangeData(prevState => ({
	// 			...prevState,
	// 			rating_computed: [range.rating_computed.min, range.rating_computed.max]
	// 		}))
	// 	}
	// }

	// const setInputRangeHandler = async (event: ChangeEvent<HTMLInputElement>, type: 'max' | 'min', reference: string) => {

	// 	const {value} = event.target

	// 	const convertValue = parseFloat(value)
	// 	rangeInputClickedHandler(type, reference, convertValue)
		
	// }

	// const setRangeHandler = async (data: number | number[], reference: string) => {
	// 	// setLoading(true)
	// 	if(reference === 'rating_computed' && typeof data !== 'number') {
	// 		return setPriceRatingRangeData(prevState => ({
	// 			...prevState,
	// 			rating_computed: data
	// 		}))
	// 	}

	// 	if(reference === 'price_low' && typeof data !== 'number') {
	// 		return setPriceRatingRangeData(prevState => ({
	// 			...prevState,
	// 			price_low: data
	// 		}))
	// 	}
	// }

	return (
		<SearchAndCheckboxFilterContainer>
			{/* {(isLoading) ? (<LoadingOverlay />) : ''} */}
			<Title margin={TitleMargin} >{titleDisplay(title)} {title === 'Certified' && <img className='certified-logo' src='/img/icons/certified-icon.png' />} </Title>
			{/* <SearchFilterField triggerFilter={triggerFilter} /> */}
			{showSearch && !isPriceAndRatings && <>
				<SearchFieldContainerDesktop>
					<input onKeyUp={enterPressedHandler} type='text' value={keyword} onChange={updateKeyword} placeholder='Search for Filter' className='component-design'/>
					<button onClick={handleApply}>Apply</button>
				</SearchFieldContainerDesktop>
				<SearchFieldContainerMobile>
					<i className="mdicon mdicon-search mdicon--last"></i>
					<input type='text' onBlur={updateListByBlur} onKeyUp={enterPressedHandler} onChange={updateKeyword} />
				</SearchFieldContainerMobile>
			</>
			}
			{!isPriceAndRatings &&  <div className='separator'>
				<SectionSeparator position={'horizontal'}></SectionSeparator>
			</div>}
			
			<SearchAndCheckboxOptionsContainer>
				{!isPriceAndRatings && (newFilteredOption.length === 0 && isSearch ? <label style={{ fontSize: 'small', color: '#73818f' }}>No results found.</label>
					:
					<>
						<CheckboxLabelCombo isBoldedLabel={true} className='top'>
							<input type='checkbox' id='filter-all-vintages' onChange={(e) => toggleChoice(e, true)} />
							<label htmlFor='filter-all-vintages'>All {title}</label>
						</CheckboxLabelCombo>
						<div className='vintage-container'>
							{displayData.length > 1 ?
								_.chunk(displayData, (displayData.length / 2)).map((part: any[], index: number) => body(part, index)) : body(displayData, 1)
							}
						</div>
					</>)}
				{/* {isPriceAndRatings && isAuthenticated &&
				<PriceAndRangeFilter onInputClicked={rangeInputClickedHandler} onReset={resetRangeHandler} rangeData={reference === 'price_low' ? priceRatingRangeData.price_low : priceRatingRangeData.rating_computed} onSettingInputRangeHandler={setInputRangeHandler} onSettingRangeHandler={setRangeHandler} reference={reference} indicator={indicatorDisplay(title)} addClass={addClass(title)} minMaxData={reference === 'price_low' ? range.price_low : range.rating_computed}><RangeChart data={items} reference={reference} /></PriceAndRangeFilter>
				} */}
			</SearchAndCheckboxOptionsContainer>
		</SearchAndCheckboxFilterContainer>
	)
}

export default CustomFiters