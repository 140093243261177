/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { MouseEvent, MutableRefObject, ReactElement, ReactNode, useEffect, useState } from 'react'
import { GiftCardContainer, GiftCardFormBody } from '../../../gift-subscription/styles'
import { isMobile } from 'react-device-detect'
import { Seperator } from '../../../subscriptions/styles'
import Input from '../../../../../../components/form-components/input/Input'
import CheckoutButton from '../../../../../../components/form-components/buttons/CheckoutButton'
import Checkbox from '../../../../../../components/form-components/checkbox/Checkbox'
import ReCAPTCHA from 'react-google-recaptcha'
import { reverseObfuscateConstant } from '../../../../../../utils/Constant'
import { RP_ENV } from '../../../../../../configs'
import { Control, FieldValues, RegisterOptions, UseFormClearErrors, UseFormGetValues, UseFormSetError, UseFormSetValue } from 'react-hook-form'
import { useGetPromoCodeValidationQuery } from '../../../../../../app/services'
import { useHistory } from 'react-router-dom'

type Props = {
    recaptchaRef: MutableRefObject<null>;
    control: Control<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    setError: UseFormSetError<FieldValues>;
	getValues: UseFormGetValues<FieldValues>;
    headerText: string;
	children: ReactNode;
	total: number;
	clearErrors: UseFormClearErrors<FieldValues>;
	aggreementText?: string;
	planType?: string;
	isLogged?: boolean;
	onPromoDetails?: (promoDetails: any) => void;
}

const aggrementCheckboxRules: RegisterOptions = {
	required: 'Please check aggrement to proceed.',
	validate: (value) => value !== false || 'Please check the aggrement'
}

const trialAggrement: RegisterOptions = {
	required: 'Please check trial aggrement to proceed.',
	validate: (value) => value !== false || 'Please check the trial aggrement'
}

function SubscriptionCard({recaptchaRef, control, setValue, setError, getValues, headerText, children, total, clearErrors, aggreementText = 'Personal', planType, isLogged, onPromoDetails}: Props): ReactElement {

	const [promoCode, setPromoCode] = useState('')
	const [promoCodeMessage, setPromoCodeMessage] = useState('')
	const [promoDetails, setPromoDetails] = useState({
		description: '',
		value: 0
	})

	const history = useHistory()

	const { data } = useGetPromoCodeValidationQuery(promoCode, { skip: !promoCode })

	useEffect(() => {
		if (data) {
			if (!data.success) {
				setError('promoCode', {
					type: 'custom',
					message: data.message
				})
				setPromoDetails({
					value: 0,
					description: ''
				})
				if (onPromoDetails) {
					onPromoDetails({
						value: 0,
						description: ''
					})
				}
				setPromoCodeMessage('')
			} else {
				setPromoDetails({
					value: data.data.discountValue,
					description: data.data.giftName
				})

				if (onPromoDetails) {
					onPromoDetails({
						value: data.data.discountValue,
						description: data.data.giftName
					})
				}

				setPromoCodeMessage(data.data.message)
				clearErrors('promoCode')
			}
		}
	}, [data])

	const onVerifyCode = () => {
		const promoCode = getValues('promoCode')
		if (promoCode) {
			setPromoCode(promoCode)
		} else {
			setPromoDetails({
				value: 0,
				description: ''
			})
			if (onPromoDetails) {
				onPromoDetails({
					value: 0,
					description: ''
				})
			}
			setPromoCodeMessage('')
		}
	}

	const triggerLink = (e: MouseEvent<HTMLAnchorElement>, url: string) => {
		e.preventDefault()
		history.push(url)
	}

	const showPromo = () => {
		if(planType === 'personal-1-year' && !isLogged)
			return false
		return true
	}

	return <GiftCardContainer isMobile={isMobile}>
		<GiftCardFormBody isMobile={isMobile}>
			<div className='head-body'>
				<h3>{headerText}</h3>
			</div>
			<div className='form'>
				{children}
				{
					(promoDetails.description && aggreementText == 'Personal') && 
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<span>{promoDetails.description}</span>
						<span style={{ color: '#BCA76F' }}>-${promoDetails.value}</span>
					</div>
				}
				{
					showPromo() && <>
						<Seperator />
						<div className='text-field' style={{ marginTop: '1.5rem' }}>
							<Input isError={promoCodeMessage ? false : true} control={control} name='promoCode' type='text' placeholder='Promo Code(optional)' />
							<CheckoutButton name='Apply' type='button' width='8rem' height='3.9rem' bgColor='white' textColor='black' onClick={() => onVerifyCode()} />
						</div>
						{promoCodeMessage && <p style={{ color: 'green', paddingLeft: '0.5rem' }}>{promoCodeMessage}</p>}
					</>
				}
				<Seperator />
				<div className='text-field' style={{ marginBottom: '1rem', marginTop: '1rem', fontSize: 18 }}>
					<span>Total</span>
					<span>${total - promoDetails.value}</span>
				</div>
				{
					planType === 'personal-1-year' && !isLogged && <div className='first-input'>
						<Checkbox name='isTrialAggrement' control={control} rules={trialAggrement}>
							<span>I understand that after the 1 week free trial, my card will be automatically charged $129 for the annual subscription unless canceled. Cancel anytime.</span>
						</Checkbox>
					</div>
				}
				<div className='first-input'>
					{/* <Checkbox name='isAggrement' control={control} rules={aggrementCheckboxRules}>
						<span>I have read and agreed to the terms of the
							<a href='/privacy-notice' onClick={e => triggerLink(e, '/privacy-notice')} > Privacy Notice</a> and the
							<a href={aggreementText === 'Personal'
								? '/subscription-agreement?selectedPlan=personal'
								: '/subscription-agreement?selectedPlan=commercial'}>{` ${aggreementText}`} Subscription Agreement</a>.
						</span>
					</Checkbox> */}
					<Checkbox name='isAggrement' control={control} rules={aggrementCheckboxRules}>
						<span>
							By checking this box and subscribing, I agree to the Robert Parker Wine Advocate <a
								className="bold-link"
								href={aggreementText === 'Personal'
									? '/subscription-agreement?selectedPlan=personal'
									: '/subscription-agreement?selectedPlan=commercial'}>{` ${aggreementText}`} Subscription Agreement</a>.
							I understand that I am requesting immediate access to digital content and therefore waive my right to withdraw from the subscription.<br />
						</span>
					</Checkbox>
				</div>
				<div style={{ display:'flex', justifyContent:'center' }}>
					<div className='action-input'>
						<div className='recaptcha-style'>
							<i>Please check the box below to proceed.</i>
							<ReCAPTCHA
								sitekey={`${reverseObfuscateConstant(RP_ENV.CAPTCHA_KEY)}`}
								onChange={(token) => setValue('recaptcha', token)}
								ref={recaptchaRef}
							/>
						</div>
						<CheckoutButton name='PROCEED TO CHECKOUT' type='submit' />
					</div>
				</div>
				<i style={{ marginTop: '1.5rem', fontWeight: 600 }}>
					To find out how we use and process the data you entrust to us, please refer to our <a className='bold-link italic-link' href='/privacy-notice' onClick={e => triggerLink(e, '/privacy-notice')} > privacy policy</a>.
				</i>
			</div>
		</GiftCardFormBody>
	</GiftCardContainer>
}

export default SubscriptionCard
