import React, {ReactElement} from 'react'
import { CancelRenewalBody, CancelRenewalCancel, CancelRenewalCancelButton, CancelRenewalHeader, CancelRenewalProceed, CancelRenewalProceedButton, CancelRenewalSeparator, CancelRenewalSeparatorContainer, CancelRenewalTitle, ModalDeleteContainer, ModalFooter, Row } from '../../styles'
import { isMobile } from 'react-device-detect'

type DeleteModalType = {
	ending:string;
	onDelete:()=>void
	isModalOpen: (value: boolean) => void;
}

const DeletePaymentMethodModal = ({ ending, onDelete, isModalOpen }: DeleteModalType): ReactElement=> {

	const deleteHandler = () => {
		onDelete()
		isModalOpen(false)
	}

	const modalCloseHandler = () => {
		isModalOpen(false)
	}
	return (<div>
		<ModalDeleteContainer>
			<div className='delete-container'>
				<div className='header'>
					<div className='delete-tab'>
						<span>
							<h4 className="modal-title" id="delete-payment-method-modal-label">
								<CancelRenewalTitle>DELETE PAYMENT METHOD</CancelRenewalTitle>
							</h4>
						</span>
					</div>
					
					<span className="img-close" onClick={()=> modalCloseHandler()}><img src="img/icons/close.png" /></span>
				</div>
				<div className="tab-content" style={{width: isMobile ? '350px' : ''}}>
					<p>
						<CancelRenewalBody style={{width: isMobile ? '290px' : ''}}>
							<>
						Are you sure you want to remove <b><span className="text-style-1">VISA ending in {ending}</span></b>?
								<span>
							&nbsp;Robert Parker Wine Advocate will not be able to process any transaction through this card once deleted, including refunds.
								</span>
							</>
						</CancelRenewalBody>
					</p>
				
				</div>
				<div>
					<ModalFooter>
						<CancelRenewalCancel>
							<CancelRenewalCancelButton onClick={()=> modalCloseHandler()}>CANCEL</CancelRenewalCancelButton>
						</CancelRenewalCancel>
						<CancelRenewalProceed>
							<CancelRenewalProceedButton onClick={deleteHandler}>PROCEED</CancelRenewalProceedButton>
						</CancelRenewalProceed>
	
					</ModalFooter>
				</div>
			</div>
		</ModalDeleteContainer>
	</div>)
}

export default DeletePaymentMethodModal
