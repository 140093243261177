/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { WineRegion } from './style'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { SetFacetsFilters } from '../../../../app/slices/SearchFilters'
import { filtersFromQueryString } from '../../../../utils/FilterUtils'

const NotableRegions = [
	{
		region: 'Bordeaux',
		country: 'France',
		image: 'img/400x300/france-bordeaux-pauillac-chateau-latour400x300.jpg',
		color_class: 'cat-1'
	},
	{
		region: 'Burgundy',
		country: 'France',
		image: 'img/400x300/france-burgundy-Irancy-cherries400x300.jpg',
		color_class: 'cat-2'
	},
	{
		region: 'California',
		country: 'USA',
		image: 'img/400x300/banner_usa-california-napa-2018-vintage-400x300.jpg',
		color_class: 'cat-3'
	},
	{
		region: 'Champagne',
		country: 'France',
		image: 'img/400x300/france-champagne-new-releases-400x300.jpg',
		color_class: 'cat-4'
	},
	{
		region: '',
		country: 'Italy',
		image: 'img/400x300/tuscan-twenty-marchesi-antinori-vineyard400x300.jpg',
		color_class: 'cat-5'
	}
]

function NotableWineRegions(): ReactElement {
	const history = useHistory()
	const dispatch = useDispatch()

	const triggerSearch = (countryName: string, regionName?: string) => {
		const container: string[] = []

		container.push(`country=${ countryName }`)

		if (regionName) {
			container.push(`region=${regionName}`)
		}

		const queryStrings = queryString.parse(container.join('&')) as any

		dispatch(SetFacetsFilters({
			activeFilters: filtersFromQueryString(queryStrings),
		}))

		history.push('/search/wine?' + container.join('&'))
	}

	return (
		<div className="mnmd-widget-categories mnmd-widget widget">
			<div className="widget__title">
				<h4 className="widget__title-text">Notable Wine Regions</h4>
			</div>
			<ul className="list-unstyled list-space-sm">
				{ NotableRegions.map((appellation: any, notable_region_key: number) => (
					<li key={ notable_region_key }>
						<div className={`category-tile category-tile--sm ${ appellation.color_class }`}>
							<WineRegion className="category-tile__wrap" onClick={() => { triggerSearch( appellation.country, appellation.region ) }}> 
								<div className="background-img background-img--darkened" style={{ backgroundImage: `url('${ appellation.image }')` }}></div>
								<div className="category-tile__inner">
									<div className="category-tile__text inverse-text">
										<div className="category-tile__name cat-theme-bg">{appellation.region ? appellation.region : appellation.country}</div>
										<div className="category-tile__description">Articles and Wines</div>
									</div>
								</div>
							</WineRegion>
						</div>
					</li>

				))}

			</ul>
		</div>
	)
}

export default NotableWineRegions