import styled from 'styled-components'

const commonTextStyles = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
`

export const ContentSection = styled.div<{ isFlexed: boolean }>`
	margin-bottom: 20px;
	display: ${(props) => (props.isFlexed ? 'flex' : 'block')};
	${(props) => (props.isFlexed ? 'justify-content: space-between;' : '')}

	font-weight: normal;

	${commonTextStyles}
	
	& a.download-pdf {
		color: white !important;
	}

	& h4 {
		font-size: 24px;
		font-weight: bold;
		${commonTextStyles}
	}

	& h6 {
		font-size: 16px;
		font-weight: bold;
	}

	& p {
		font-size: 16px;
	}

	& p.smaller {
		font-size: 12px;
		font-weight: normal;
		${commonTextStyles}
	}

	& div.sticker-container {
		height: 145px;
		margin-bottom: 80px;
	}

	& a.download-pdf:hover {
		text-decoration: none !important;
	}
`

export const ImageContentSection = styled.div`
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	font-weight: normal;

	${commonTextStyles}

	& div.sticker-container {
		height: 200px;
		width: 200px;
	}
`

export const OrangeButton = styled.div`
	align-items: center;
	padding: 6px 15px;
	border-radius: 8px;
	background-color: #73333f;
	font-weight: bold;
	cursor: pointer;
	text-align: center;

	&:hover {
		background-color: #8E4250;
	}

	& > a {
		text-decoration: none;
		color: #fff;
	}
`

export const PageTitle = styled.h3`
	font-size: 22px;
	font-weight: bold;
	color: #333;
	${commonTextStyles}
`

export const SideBarContainer = styled.div`
	& div {
		margin-bottom: 20px;

		& a.download-pdf {
			color: white !important;
		}

		& p {
			font-size: 16px;
			font-weight: normal;
			${commonTextStyles}
		}

		& a.download-pdf:hover {
			text-decoration: none !important;
		}
	}
`
