// Classes
import Assignment from './Assignment'
import Category from './Category'
import Image from './Image'
import Issue from './Issue'
import Publication from './Publication'

import Content from './Content'
import { Key } from 'react'

export default class Article extends Content {
	id = ''

	// Asssignment (Object)
	assignment: Assignment

	author: AuthorReference = { id: '', name: '' }

	// Category (Object)
	category: Category

	// Full Content
	full_article = ''

	// Content (String)
	excerpt = ''

	// Image (Object)
	image: Image

	banner: { url: string}

	// Highlight flag
	is_highlighted_order = 0

	is_recommended_order = 0

	// Issue (Object)
	issue: Issue

	publication: Publication

	// Publishing
	published_date = ''

	slug = ''
	sort_order = 0

	// Others
	tasting_note_count = 0
	title = ''
	url = ''
	private _id: Key | null | undefined

	constructor(data: ArticleJSON) {
		super()
	
		this.id = data.id

		this.assignment = new Assignment(data.assignment.id, data.assignment.label)

		this.author = { id: data.author.id, name: data.author.name }

		this.category = new Category(data.category?.id, data.category?.name)

		this.full_article = data.full_article

		this.excerpt = data.excerpt

		this.image = new Image(data.image.id, data.image.url)

		this.banner = { url: data.banner?.url}

		this.is_highlighted_order = parseInt(data.is_highlighted_order)

		this.is_recommended_order = parseInt(data.is_recommended_order)

		this.issue = new Issue(
			data.issue.id,
			data.issue.published_date,
			data.issue.title
		)

		this.publication = new Publication(
			data.publication.id,
			data.publication.title
		)

		super.published_date = data.published_date
		this.slug = data.slug
		this.sort_order = data.sort_order ? parseInt(data.sort_order) : 0
		this.tasting_note_count = data.tasting_note_count
			? parseInt(data.tasting_note_count)
			: 0
		this.title = data.title
		this.url = data.url
	}
}
