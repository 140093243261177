import styled from 'styled-components'

const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
`

export const ArticleContainer = styled.div`
	width: auto;
	display: flex;
	margin-bottom: 25px;
	margin-left: auto;
	margin-right: auto;
	cursor: pointer;

	& div.background-img {
		border: 1px solid red;
		height: 195px;
		width: 200px;
	}

	& div.details-container {
		width: 100%;
		padding: 10px 0px 0px 0px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		& a.title {
			margin-bottom: 5px;
			font-size: 21px;
			font-weight: bold;
			${commonTextStyle}
			color: var(--black-two);
			display: block;
			text-decoration: none;
		}

		& a.excerpt {
			margin: 5px 0;
			font-size: 14px;
			font-weight: normal;
			${commonTextStyle}
			height: 61px;
			color: #777;
			overflow: hidden;
			-webkit-line-clamp: 3;
			text-overflow: ellipsis;
			text-decoration: none;
		}

		& div.details {
			color: #777;
			margin-top: 4px;
			width: 50%;
			display: flex;

			& div.author {
				margin-right: 7px;
				& span {
					font-weight: bold;
				}
			}

			& .date {
				font-weight: 100 !important;
			}

			& div.detail-container {
				margin-right: 7px;
				& img {
					margin-right: 3px;
				}
			}
		}
	}
	@media (max-width: 768px) {
		flex-direction: column;

		& div.details-container {
			width: 100%;
			margin-top: 20px;
		}

		& div.title {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
`

export const ArticleImageContainer = styled.div`
	width: 360px;
	height: 195px;
	margin: 0 30px 0 0;
	mix-blend-mode: multiply;
	background-image: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0.5),
		rgba(0, 0, 0, 0.5)
	);

	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		overflow: hidden;
		cursor: pointer;
	}
	@media (max-width: 768px) {
		width: auto;
		height: 100%;
		margin: 0;
	}
`

export const PageHeader = styled.div`
	background-color: #fafafa;
	margin: -60px 0px 60px 0px;
	padding: 40px 0px 40px 0px;

	& div.title {
		margin: 10px 0 0 10px;

		font-size: 24px;
		font-weight: bold;
		${commonTextStyle}
		color: #000000;
	}

	& div.summary {
		margin: 10px 0 0 10px;
		font-size: 14px;
		font-weight: normal;
		${commonTextStyle}
		color: #777;
	}
`
