import React, { ReactElement, useState, useEffect, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import moment from 'moment'
import { NoSubscriptionLabel, PaymentHistoryCardAccountLabel, PaymentHistoryContainer, PaymentHistoryDateLabel, PaymentHistoryDownloadButton, PaymentHistoryDownloadLabel, PaymentHistoryMainContainer, PaymentHistoryPaymentLabel, PaymentHistoryStatusLabel, PaymentHistoryUsdLabel, Row, SubscriptionRow } from './styles'
import { useGetPaymentHistoryQuery } from '../../../../app/services'
import { useAppSelector } from '../../../../app/hooks'
import LoadingOverlay from '../../../../components/Loading-overlay'
import PrintReciept from './components/printComponent/PrintReciept'

import './components/printComponent/style.css'
import './style.css'
import {Users as UsersFromStore} from '../../../../app/slices/Users'
import { isMobile } from 'react-device-detect'
import ColumizedString from './components/ColumnizedString'

interface PaymentInfo {
	_id: string;
	currency: string;
	amount: number;
	transaction_date: Date;
	created_at: Date;
	credit_card: {
		number:string;
		type:string;
	}
	transaction_status:string;
	payment_gateway:string;
}

interface IPaymentHistoryState {
	list: PaymentInfo[]
	noPaymentHistory: boolean
}

function PaymentHistory(): ReactElement {
	const componentRef = useRef<null | HTMLDivElement>(null)
	const { userData } = useAppSelector(UsersFromStore)
	const { data, isLoading: getPaymentLoading, isError  } = useGetPaymentHistoryQuery(userData._id, { skip: !userData._id })
	const [paymentHistoryState, setpaymentHistoryState] = useState<IPaymentHistoryState>({
		list: [],
		noPaymentHistory: false,
	})
	const [selectedHistory, setselectedHistory] = useState<PaymentInfo>()

	useEffect(() => {
		setpaymentHistoryState(state => ({ ...state }))
		if(data){
			if (data.success) {
				const list = data.data.map((payment:PaymentInfo ) => ({
					_id: payment._id,
					currency: payment?.currency,
					amount: payment?.amount,
					created_at: payment.created_at,
					credit_card: {
						number:payment?.credit_card?.number,
						type:payment?.credit_card?.type
					},
					transaction_status:payment?.transaction_status,
					payment_gateway: payment?.payment_gateway
				}))
				setpaymentHistoryState(state => ({ ...state, list: data.data, noPaymentHistory: list.length > 0 ? false : true }))
			} else if (data) {
				setpaymentHistoryState(state => ({ ...state, noPaymentHistory: true, }))
			}
		}

		if(isError){
			console.log('Something went wrong. Please try again.')
		}
	}, [data, isError])

	const handlePrint = useReactToPrint({
		content: () => componentRef.current
	})

	const selectedPaymentToPrint = (data:PaymentInfo) => {
		setselectedHistory(data)
		setTimeout(() => {
			handlePrint()
		}, 100)
	}

	const paymentHistoryLabelhandler = (data: any) => {
		const payment_gateway = data.payment_gateway
		const creditCardNumber = data?.credit_card?.number?.slice(-4)

		switch (payment_gateway) {
		case 'braintree':
			return <>
				<span><b>{payment_gateway?.credit_card?.type ?? 'Visa'}</b></span>&nbsp;
				<span>ending in {creditCardNumber}</span>
			</>
		case 'ios':
			return 'Mobile app subscription'

		case 'android':
			return 'Mobile app subscription'

		case 'complimentary':
			return 'Complimentary'

		default:
			return 'N/A'
		}
	}

	return (
		<div>
			{getPaymentLoading ? <LoadingOverlay /> :
				paymentHistoryState.list && paymentHistoryState.list.length > 0 ?
					<>
						<PaymentHistoryMainContainer>
							{paymentHistoryState?.list.map((ele: PaymentInfo, index: number) => {
								const transactionDate = moment(ele?.transaction_date).format('MMM DD, YYYY')
								const createdDate = moment(ele?.created_at).format('MMM DD, YYYY')
								const paymentId = ele?._id.toUpperCase()
								const currency = ele?.currency
								const amount = ele?.amount
								const amountByFixedBy2 = amount.toFixed(2)
								const transactionStatus = ele?.transaction_status
								const paymentGateway = ele?.payment_gateway
								return (
									<PaymentHistoryContainer key={index} isMobile={isMobile}>
										<Row><PaymentHistoryDateLabel isMobile={isMobile}>{transactionDate ? transactionDate : createdDate}</PaymentHistoryDateLabel></Row>
										<SubscriptionRow>
											<PaymentHistoryPaymentLabel isMobile={isMobile}><span>Payment ID: </span><span>{paymentId}</span></PaymentHistoryPaymentLabel>
											<PaymentHistoryUsdLabel isMobile={isMobile}><span>{currency} {(typeof (amount) == 'number') ? amountByFixedBy2 : amount}</span></PaymentHistoryUsdLabel>
										</SubscriptionRow>
										<SubscriptionRow>
											<PaymentHistoryCardAccountLabel isMobile={isMobile}>{paymentHistoryLabelhandler(ele)}</PaymentHistoryCardAccountLabel>
											{
												isMobile
													? <PaymentHistoryStatusLabel isMobile={isMobile}><ColumizedString inputString={transactionStatus} /> </PaymentHistoryStatusLabel>
													: <PaymentHistoryStatusLabel isMobile={isMobile}><span>{transactionStatus}</span></PaymentHistoryStatusLabel>
											}
										</SubscriptionRow>
										<Row><PaymentHistoryDownloadButton>
											<PaymentHistoryDownloadLabel isDisable={paymentGateway !== 'braintree'} disabled={paymentGateway !== 'braintree'} onClick={() => selectedPaymentToPrint(ele)} >
												Download receipt
											</PaymentHistoryDownloadLabel>
										</PaymentHistoryDownloadButton></Row>
									</PaymentHistoryContainer>
								)
							})}
						</PaymentHistoryMainContainer>
					</>
					: (<NoSubscriptionLabel>No payment history available</NoSubscriptionLabel>)
			}
			<div className='reciept-hide'>
				<PrintReciept refs={componentRef} recieptData={selectedHistory} />
			</div>
		</div>
	)
}

export default PaymentHistory