/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import { isMobile } from 'react-device-detect'
import { Control, FieldValues } from 'react-hook-form'

import Input from '../../../../../../components/form-components/input/Input'
import Dropdown from '../../../../../../components/form-components/dropdown/Dropdown'
import { generateMonthOptions, generateYearOptions, validCards } from './constants'

import { GiftCardContainer, GiftCardFormBody, MethodCard, MethodContainer } from '../../styles'

type Props = {
    control: Control<FieldValues>;
	isLogged: boolean;
	paymentData: any;
	onAddNewCard: () => void;
	newCardDetails: { isNewCard: boolean, data: any }
}

function SenderPaymentCard({ control, isLogged, paymentData, onAddNewCard, newCardDetails }: Props):ReactElement {

	const months = generateMonthOptions()
	const years = generateYearOptions()

	function NewUserMethod(): ReactElement {
		return <><div className='cc-cards'>
			{ validCards.map((card, index) => {
				if(card.name === 'new')
					return
				return <img key={index} alt={card.name} src={card.imgUrl} />
			})}
		</div>
		<div className='form'>
			<div className='first-input'>
				<Input control={control} name='cardHolderFirstName' type='text' placeholder='*Cardholder First Name' rules={{ required: isLogged ? false : 'Cardholder first name is required' }} />
				<Input control={control} name='cardHolderLastName' type='text' placeholder='*Cardholder Last Name' rules={{ required: isLogged ? false : 'Cardholder last name is required' }} />
			</div>
			<div className='first-input'>
				<Input control={control} name='cardNumber' type='text' placeholder='*Card Number' rules={{ required: isLogged ? false : 'Card number is required' }} />
			</div>
			<div className='first-input'>
				<Dropdown control={control} rules={{required: isLogged ? false : 'Expiration month is required'}} options={months} placeholder='*Exp. Month' name='expiryMonth' />
				<Dropdown control={control} rules={{required: isLogged ? false : 'Expiration year is required'}} options={years} placeholder='*Exp. Year' name='expiryYear' />
			</div>
			<div className='first-input'>
				<Input control={control} name='cvv' type='text' placeholder='*CVV' rules={{ required: isLogged ? false : 'CVV is required' }} />
				<Input control={control} name='zipCode' type='text' placeholder='*ZIP / Postal Code' rules={{ required: isLogged ? false : 'Zip / Postal code is required' }} />
			</div>
		</div>
		<div className='footer'>
			<p>All payments are secured and encrypted.</p>
		</div></>
	}

	function ExistingMethod(): ReactElement {
		let cards = (paymentData && paymentData.success) && paymentData.data

		if(newCardDetails.isNewCard)
			cards = [{
				cardType: 'New',
				last4: newCardDetails.data.cardNumber.slice(-4)

			}]
		return <MethodContainer>
			{ cards?.map(( card: any, index: number ) => {
				const cardDetails =  validCards.find(validCard => validCard.cardType === card.cardType)
				return <MethodCard key={index}>
					<img alt={cardDetails?.name} src={cardDetails?.imgUrl} />
					<div className='content'>
						<span>xx{card.last4}</span>
						<a href='#!' onClick={(e) => {
							e.preventDefault()
							onAddNewCard()
						}}
						>CHANGE
						</a>
					</div>
				</MethodCard>
			}) }
		</MethodContainer>
	}

	return <GiftCardContainer isMobile={isMobile}>
		<GiftCardFormBody isMobile={isMobile}>
			<div className='head-body'>
				<h3>3. Payment method</h3>
			</div>
			{
				isLogged ? <ExistingMethod /> : <NewUserMethod />
			}
		</GiftCardFormBody>
	</GiftCardContainer>
}

export default SenderPaymentCard
