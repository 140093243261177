import React, { ReactElement, useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useDispatch } from 'react-redux'

import { ToggleAdvancedSearchChoice } from '../../../../../utils/SearchUtil'

// Services
import { useGetAppellationsFromKeywordQuery } from '../../../../../app/services'

// Styles
import { CheckboxLabelCombo, ResultsContainer, SearchFieldContainer, Title } from '../styles'

let appellations: unknown[][]

function CountryRegionAppellationFilter(): ReactElement {

	const [keyword, setKeyword] = useState('')
	const [runSearch, setRunSearch] = useState(false)

	const { data, isLoading, isError } = useGetAppellationsFromKeywordQuery(keyword, { skip: runSearch === false ? true : false })

	useEffect(() => {
		if (data) {
			if(data.success){
				appellations = _.chunk(data.data, (data.data.length / 3))
				setRunSearch(false)
			}
		}

		if(isError){
			console.log('Something went wrong. Please try again.')
		}
	}, [data, isError])

	const updateKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
		setKeyword(event.target.value)
	}

	const triggerSearch = () => setRunSearch(true)

	const displayResults = () => {

		if (data && appellations) {
			return (
				<>
					{appellations.map((grouping: any, index: number) => (
						<div className='options-container' key={index} >
							{grouping.map((appellation: any, key: number) => (
								<div key={key} >
									<CheckboxLabelCombo isBoldedLabel={false}>
										<input onChange={toggleChoice} type='checkbox' value={appellation['_id']} id={`appellation_${appellation['_id']}`} />
										<label htmlFor={`appellation_${appellation['_id']}`}>{getAppellationDisplayName(appellation)}</label>
									</CheckboxLabelCombo>
								</div>
							))}
						</div>
					))}

				</>
			)
		}
		return (<></>)
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getAppellationDisplayName = (appelation: any) => {
		const fieldsToFetch = ['country', 'region', 'location', 'locale']

		const container: string[] = []

		Object.keys(appelation).forEach(element => {
			if (fieldsToFetch.includes(element)) {
				container.push(appelation[element].name)
			}
		})

		return container.join('/')
	}

	const dispatch = useDispatch()
	const toggleChoice = (event: React.ChangeEvent<HTMLInputElement>) => ToggleAdvancedSearchChoice(event.target.checked, dispatch, 'appellations', event.target.value)

	return (
		<div>
			<Title margin={'0px 0px 0px 0px'}>Country / Region /Appellation</Title>
			<SearchFieldContainer>
				<input type='text' value={keyword} onChange={updateKeyword} />
				<button onClick={triggerSearch}>Apply</button>
			</SearchFieldContainer>
			<ResultsContainer>
				{isLoading ? (<>Loading...</>) : null}
				{displayResults()}
			</ResultsContainer>
		</div>
	)
}

export default CountryRegionAppellationFilter