import styled from 'styled-components'

const commonTextStyles = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
`

export const ControlButtons = styled.div`
	display: flex;
	justify-content: flex-end;
`

export const CertificationContainer = styled.div`
	& .row {
		flex-direction: column;
	}

	& .row.certificate-content {
		& div {
			flex-direction: row;
			margin: auto;		
		}
	}

	@media (max-width: 1200px) {
		& .row.certificate-content {
			& div {
				flex-direction: column-reverse;		
			}

			& .section {
				flex-direction: column;
			}
		}
	}
`

export const ContentSection = styled.div`
	display: flex;
	margin-bottom: 50px;
	font-size: 16px;
	color: #333;
	text-align: justify;
	
	& div.content {
		max-width: 668px;

		& span {
			font-weight: bold;
		}

		& p {
			margin-bottom: 30px;
		}
	}

	@media (max-width: 992px) {
		flex-direction: column-reverse;
		
		&.right {
			flex-direction: column;
		}

		& .illustration {
			margin: auto;
		}
	}
`


export const ImageContentSection = styled.div`
	display: flex;
	margin-bottom: 50px;
	font-size: 16px;
	color: #333;

	& div.content {
		max-width: 668px;

		& span {
			font-weight: bold;
		}

		& p {
			margin-bottom: 30px;
		}
	}

	@media (max-width: 992px) {
		flex-direction: column-reverse;
		
		&.right {
			flex-direction: column;
		}

		& .illustration {
			margin: auto;
		}
	}
`

export const GrayButton = styled.div`
	width: 87px;
	height: 33px;
	padding: 6px 13px 6px 12px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: #fff;
	font-weight: bold;
	cursor: pointer;
`

export const LogoContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 80px;
`

export const OrangeButton = styled.div`
	height: 33px;
	padding: 6px 15px;
	border-radius: 8px;
	background-color: #e01a00;
	color: #fff;
	font-weight: bold;
	cursor: pointer;
`

export const ParagraphContainer = styled.p<{
	fontSize: string
	marginBottom: string
}>`
	${commonTextStyles}
	font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
	margin-bottom: ${(props) =>
		props.marginBottom ? props.marginBottom : 'none'};

	& em {
		font-weight: bold;
		font-style: normal;
	}
`

export const SectionHeader = styled.div`
	margin-bottom: 20px;
	font-size: 24px;
	font-weight: bold;
	color: #333;

	${commonTextStyles}
`

export const BlueButton = styled.div`
	width: fit-content;
	padding: 6px 15px;
	border-radius: 8px;
	background-color: #73333f;
	color: #fff;
	font-weight: bold;
	cursor: pointer;
	text-align: center;
`

export const CertificationBannerTitle = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	& .container {
		& .post__text-inner.text-center {
			padding: 0;
		}
	}
`