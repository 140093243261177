import styled from 'styled-components'

export const ModalBody = styled.div<{ maxHeight: string }>`
	max-height: 450px;
	overflow-y: auto;

	& div.counter {
		margin-bottom: 30px;
	}

	& div.price-list-item-container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& div.first {
			min-width: 300px;
		}

		& div > div > a.name {
			margin: 10px 64px 10px 0px;
			font-family: Libre Franklin;
			font-size: 14px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			color: #a08436;
		}

		& div > div.retailer-detail {
			margin: 5px 0 0;
			font-family: Libre Franklin;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: italic;
			line-height: 1.2;
			letter-spacing: normal;
			color: #333;
		}

		& div > span.price-container {
			font-weight: bold;
		}

		& div.bottle-size {
			font-family: Libre Franklin;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			color: #333;
		}
	}
`

export const ModalResultsCount = styled.div`
	padding-top: 1rem;
	padding-left: 1rem;
`

export const ModalFooter = styled.div`
	padding: 24px;
	border-top: 1px solid #e5e5e5;
	display: flex;
	align-items: center;
	gap: 1rem;

	& .logo {
		padding: 0.6rem;
		border-radius: 10px;
		border: 1.5px solid gray;
		display: flex;
		align-items: flex-end;
		gap: 0.7rem;

		& img{
			height: 25px;
			width: 40px
		}

		& div{
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			line-height: 1.2;

			& .sub{
				font-size: 10px
			}

			& .title{
				font-size: 18px
			}
		}
	}
`

export const Separator = styled.div`
	height: 1px;
	background-color: #ccc;
	margin-top: 10px;
	margin-bottom: 10px;
`

export const ContentContainer = styled.div`
    max-width: 600px;

    & .pricelist-container {
        & .header {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;
            padding: 20px 30px;
            border-bottom: 1px solid #e5e5e5;

            & .modal-title {
                display: flex;
                gap: 8px;

                & a {
                    color: black;
                    text-decoration: none;
                    font-size: 16px;
                }
            }

            & .modal-close {
                cursor: pointer;
                font-size: 15px;
            }
        }

        & .tab-content {
            padding: 24px;
			width: auto;
    		height: auto;
			max-height: 300px;
			overflow-y: auto;
			position: relative;
			flex: 1 1 auto;
        }
    }
    

    & .modal-error {
        background-color: rgb(224, 26, 0);
        padding: 12px;
        text-align: center;

        border-radius: 0  0 5px 5px;

        & span {
            font-size: 12px;
            color: white;
        }
    }

	& div.counter {
		margin-bottom: 30px;
	}

	& div.price-list-item-container {
		display: flex;
		justify-content: space-between;

		& div.first {
			min-width: 300px;
		}

		& div > div > a.name {
			margin: 10px 64px 10px 0px;
			font-family: Libre Franklin;
			font-size: 14px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			color: #a08436;
		}

		& div > div.retailer-detail {
			margin: 5px 0 0;
			font-family: Libre Franklin;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: italic;
			line-height: 1.2;
			letter-spacing: normal;
			color: #333;
		}

		& div > span.price-container {
			font-weight: bold;
		}

		& div.bottle-size {
			font-family: Libre Franklin;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			color: #333;
		}
	}
`

export const ModalHeader = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
`