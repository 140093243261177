import styled from 'styled-components'

export const NameContainer = styled.div<{ isAuthenticated?: boolean }>`
	display: flex;
	justify-content: space-between;

	& > div.name {
		width: 95%;
		margin: 0 0 6.7px;
		font-family: Libre Franklin;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #333;
		${(props) => (!props.isAuthenticated ? 'max-width: 230px;' : '')}
	}
	& div.indicator { 
		margin-top: 5px;
		height: 25px;
		width: 25px;
	}
	
`

export const WineSummary = styled.div`
	& div > a.buy-button {
		margin-right: 30px;
	}
`

export const BuyOnlineButton = styled.a`
	cursor: pointer;
	background-color: #008185 !important; 

	font-size: 10px  !important;
	font-family: Libre Franklin !important;
	font-size: 10px !important;
	font-weight: normal !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: normal !important;
	letter-spacing: normal !important;
	text-align: center !important;
	color: var(--white-three) !important;
`

export const StyledRatingIcon = styled.div`
	width: 100px;
	height: 24px;
	font-family: Libre Franklin;
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #986b00;
	`
