import React, { ReactElement, useEffect, useState } from 'react'
import { PreferencesTitle } from '../styles'
import Dropdown from '../../../../../components/form-components/dropdown/Dropdown'
import { Control, FieldValues, RegisterOptions, UseFormSetValue } from 'react-hook-form'
import { useGetTradeDirectoryLookupCountriesQuery } from '../../../../../app/services'
import Checkbox from '../../../../../components/form-components/checkbox/Checkbox'
import LoadingOverlay from '../../../../../components/Loading-overlay'

interface PreferencesProps {
	control: Control<FieldValues>;
	setValue:  UseFormSetValue<FieldValues>
}

const countryDropdownRules: RegisterOptions = {
	required: 'Country of residence is required',
	validate: (value) => value !== '' || 'Please select a country'
}

const ProfilePreferences = ({control, setValue}: PreferencesProps): ReactElement => {
	const [countries, setCountries] = useState<string[]>([])
	const { data: countryData, isLoading: fetchCountries } = useGetTradeDirectoryLookupCountriesQuery('')

	useEffect(() => {
		if (countryData?.success) {
			const countryList = countryData.data.map((country: { _id: string; code: string; name: string; }) => country.name)
			setCountries(countryList)
		}
		
	}, [countryData])

	const checkboxChecker = (value: boolean, name: string) => {
		switch (name) {
		case 'isNewsLetter':
			if(!value)
				setValue('isNotNewsLetter', false)
			else
				setValue('isNotNewsLetter', true)
			break
		case 'isNotNewsLetter':
			if(!value)
				setValue('isNewsLetter', false)
			else
				setValue('isNewsLetter', true)
			break
		case 'isMarketing':
			if(!value)
				setValue('isNotMarketing', false)
			else
				setValue('isNotMarketing', true)
			break
		case 'isNotMarketing':
			if(!value)
				setValue('isMarketing', false)
			else
				setValue('isMarketing', true)
			break
		}
	}

	return (
		<div>
			{fetchCountries && <LoadingOverlay />}
			<PreferencesTitle title={'title'} description={'description'}>
				<span className='title'>Weekly Newsletter</span>
				<span className='description'>Get updates about Robert Parker Wine Advocate’s publications and events</span>
				<div className='first-input mt-3'>
					<Checkbox name='isNewsLetter' control={control} onChangeValueChecker={checkboxChecker}>
						<span>Subscribe to the newsletter</span>
					</Checkbox>
				</div>
				<div className='first-input'>
					<Checkbox name='isNotNewsLetter' control={control} onChangeValueChecker={checkboxChecker}>
						<span>Do not subscribe to the newsletter</span>
					</Checkbox>
				</div>
			</PreferencesTitle>
			<PreferencesTitle title={'title'} description={'description'}>
				<span className='title'>Deals and promotions</span>
				<span className='description'>Stay informed about promotions and receive marketing communications from Robert Parker Wine Advocate</span>
				<div className='first-input mt-3'>
					<Checkbox name='isMarketing' control={control} onChangeValueChecker={checkboxChecker}>
						<span>I would like to receive marketing updates and commnuications</span>
					</Checkbox>
				</div>
				<div className='first-input'>
					<Checkbox name='isNotMarketing' control={control} onChangeValueChecker={checkboxChecker}>
						<span>Do not send me marketing updates and communication</span>
					</Checkbox>
				</div>
			</PreferencesTitle>
			<PreferencesTitle title={'title'} description={'description'}>
				<span className='title'>Location preferences</span>
				<span className='description'>Select your location of residence to curate events and updates you might be interested in. This will also affect the currency displayed in  results for <span style={{ fontWeight: 'bold' }}>Check price.</span></span>
			</PreferencesTitle>
			<div className='first-input mt-5'>
				<Dropdown disabled={false} control={control} rules={countryDropdownRules} options={countries} placeholder='Country of Residence' name='country' />
			</div>
		</div>
	)
}

export default ProfilePreferences