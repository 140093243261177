import React, { ChangeEvent, ReactElement, ReactNode, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { MenuSelection, MenuSelectionContainer } from '.'

type SelectionMenuTypes = {
    title: string;
    onSelectionMenu: (event: ChangeEvent<HTMLSelectElement>) => void;
    children: ReactNode;
    defaultValue: number | string
}

const SelectionMenu = ({title, onSelectionMenu, children, defaultValue}: SelectionMenuTypes): ReactElement => {
	useEffect(() => {
		window.addEventListener('load', truncateSelectOptions)
	}, [])

	function truncateSelectOptions() {
		const select = document.querySelector('.about-selection') as HTMLSelectElement
		const options = select.options
		
		for (const option of options) {
		
			const textWidth = option.text.length * 10
		
			if (textWidth > select.clientWidth) {
				option.text = option.text.substring(0, (select.clientWidth) / 10) + '...'
			}
		}
	}

	return <MenuSelectionContainer>
		<span style={{ marginTop: isMobile ? '1rem' : 0 }}>{title}</span>
		<MenuSelection className='about-selection component-design' onChange={onSelectionMenu} defaultValue={defaultValue}>
			{children}
		</MenuSelection>
	</MenuSelectionContainer>
}

export default SelectionMenu
