import styled from 'styled-components'

const commonFontAttributes = `
	font-family: Libre Franklin;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
	font-size: 14px;
	text-align: center;
	font-weight: bold;
`

export const ProceedButton = styled.button`
	height: 33px;
	padding: 6px 13.5px;
	border-radius: 8px;
	background-color: #73333f;
	border: 0px;
	cursor: pointer;
	color: rgb(255, 255, 255);

	&:hover {
		background-color: #8E4250;
	}
	
	${commonFontAttributes}
`


export const CloseButton = styled.button`
	height: 33px;
	padding: 6px 13.5px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: var(--white-three);
	cursor: pointer;

	&:hover {
		background-color: #efefef;
	}
	${commonFontAttributes}
`

export const NoticeModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
  
`