/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import FeaturedArticleMongo from './FeaturedArticleMongo'

import CategorizedPreviewMongo from '../the-wine-advocate/CategorizedPreviewMongo'
import { Link } from 'react-router-dom'
import { TranslationsUtil } from '../../../../utils'
import englishTranslation from './translations/en.json'
import { useGetArticleHighlightsQuery } from '../../../../app/services'

const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

const FreeToReadArticleSummaries: React.FC = () => {

	const translate = translationUtil.getTranslator()

	const {data: articleHighlights, isLoading: articleLoading} = useGetArticleHighlightsQuery({offset: 0, limit: 6})

	return (
		<div className="mnmd-block mnmd-block--fullwidth grid-gutter-20">
			<div className="container" style={{paddingLeft: 24, paddingRight: 24}}>
				<div className="block-heading block-heading--lg">
					<h4 className="block-heading__title">
						<span className="first-word">Free</span> To Read
					</h4>
				</div>
				{articleLoading ? (<span>Loading...</span>) : null}
				{articleHighlights?.data ?
					<>
						<div className="row row--space-between">
							<div className="col-xs-12 col-md-6">
								{articleHighlights.data.data[0] ? <FeaturedArticleMongo article={articleHighlights.data.data[0]} /> : ''}
							</div>
							<div className="col-xs-12 col-sm-6 col-md-3">
								{articleHighlights.data.data[1] ? <CategorizedPreviewMongo article={articleHighlights.data.data[1]} /> : ''}
							</div>
							<div className="col-xs-12 col-sm-6 col-md-3">
								{articleHighlights.data.data[2] ? <CategorizedPreviewMongo article={articleHighlights.data.data[2]} /> : ''}
							</div>
						</div>
						<div className="row row--space-between">
							<div className="col-xs-12 col-sm-6 col-md-3">
								{articleHighlights.data.data[3] ? <CategorizedPreviewMongo article={articleHighlights.data.data[3]} /> : ''}
							</div>
							<div className="col-xs-12 col-sm-6 col-md-3">
								{articleHighlights.data.data[4] ? <CategorizedPreviewMongo article={articleHighlights.data.data[4]} /> : ''}
							</div>
							<div className="col-xs-12 col-md-6">
								{articleHighlights.data.data[5] ? <FeaturedArticleMongo article={articleHighlights.data.data[5]} /> : ''}
							</div>
						</div>
					</>
					: null
				}
			</div>
			<nav className="mnmd-pagination text-center"><Link to='/more-free-stuff' className="btn btn-default">{translate('View all free articles')}<i className="mdicon mdicon-arrow_forward mdicon--last"></i></Link>
			</nav>
		</div>
	)
}

export default FreeToReadArticleSummaries