/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
// import { useDispatch } from 'react-redux'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import { TranslationsUtil } from '../../../../../../utils'

import englishTranslation from './translations/en.json'
const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

// import { useGetCurrentNextAndPreviousIssueQuery } from '../../../../../../app/services'
import { ArticleContainer, ArticleImageContainer } from '../styles'
import LoadingOverlay from '../../../../../../components/Loading-overlay'
// import { Truncate } from './styles'
// import { SetActiveFilters } from '../../../../../../app/slices/SearchFilters'
// import IssueArchives from '../../../../sections/issue-archives'
import { GetGroupedArticles, slugify } from '../../../../../../utils/ArticlesUtil'
// import Pagination from '../../../../../../components/pagination'
import useScreen from '../../../../../../helper/hooks/useScreen'
import { CloudImageUtils } from '../../../../../../utils/CloudImageUtils'
import { useGetFeaturedWineJournalArticlesQuery } from '../../../../../../app/services'
// import { RP_ENV } from '../../../../../../configs'
// import WineJournalTags from './wine-tags-menu'
// import TagCloud from './wine-tags-menu'

function FeaturedWineJournal(): ReactElement {

	// const [isLoading, setLoading] = useState<boolean>()

	// const disMount = useRef(true)
	const screen = useScreen()
	// const { triggerTrackingHandler } = useArticleTracking()
	const translate = translationUtil.getTranslator()
	const [data, setData] = useState<any>()
	// const [offset, setOffset] = useState(0)
	const {data: articleData, isFetching, isError} = useGetFeaturedWineJournalArticlesQuery({offset: 0, limit: 10})

	const history = useHistory()
	// const limit = 10
	// const [searchParams, setSearchParams] = useState(['offset=0', `limit=${limit}`])
	// const { search } = useLocation()
	// const [urlTags, setUrlTags] = useState<string>()
	// const [articleData, setArticleData] = useState({
	// 	data: [],
	// 	totalRecords: 0,
	// 	currentPage: 0,
	// })

	// useEffect(() => {
	// 	console.log(search)
	// 	if(search){
	// 		const queryString = search.startsWith('?') ? search.slice(1) : search
	// 		const urlSearchParams = new URLSearchParams(queryString)
	// 		const tagsValue = urlSearchParams.get('tags')
	// 		fetchDataByTags(tagsValue)
	// 	} else {
	// 		if(disMount.current){
	// 			fetchData()
	// 		}
	// 		disMount.current = false
	// 	}
	// }, [search, searchParams])

	// const fetchData = async () => {
	// 	setLoading(true)
	// 	const url = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/article/list-wine-journals?${searchParams[0]}&${searchParams[1]}`
	// 	const data = await fetch(url, {
	// 		headers:{
	// 			'x-api-key': RP_ENV.API_KEY,
	// 		}
	// 	})
	// 	const response = await data.json()
	// 	if (response) {
	// 		setArticleData(articleData => ({ ...articleData, data: response?.articles, totalRecords: response.meta.total }))
	// 		setLoading(false)
	// 	}
	// }

	// const fetchDataByTags = async (tags: any) => {
	// 	setLoading(true)
	// 	const url = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/article/list-wine-journals?${searchParams[0]}&${searchParams[1]}&tags=${tags}`
	// 	const data = await fetch(url, {
	// 		headers:{
	// 			'x-api-key': RP_ENV.API_KEY,
	// 		}
	// 	})
	// 	const response = await data.json()
	// 	if (response) {
	// 		setArticleData(articleData => ({ ...articleData, data: response?.articles, totalRecords: response.meta.total }))
	// 		setLoading(false)
	// 	}
	// }

	// useEffect(() => {
	// 	searchTradeDirectory(searchParams.filter(parameter => parameter != '')).then((results: any) => {
	// 		setTradePartnerPageState(tradePartnerPageState => ({ ...tradePartnerPageState, tradePartners: results?.data.data, totalRecords: results?.data?.total }))
	// 	})
	// }, [searchParams])

	useEffect(() => {
		if(articleData){
			if(articleData.success){
				setData(articleData.data)
			}
		}
		if(isError){
			console.log('Something went wrong. Please try again.')
		}
	}, [articleData, isError])
	
	useEffect(() => {
		if (data && data.articles) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const groupedContents: any[] = []
			const container = GetGroupedArticles(data.articles, 'category.id')

			Object.keys(container).forEach(index => {
				const groupedContent: wineJournalCategory = {
					id: container[index][0].category.id,
					name: container[index][0].category.name,
					contents: container[index]
				}
				groupedContents.push(groupedContent)
			})
		}
	}, [data])

	const goToArticle = (id: string, title: string) => {
		// triggerTrackingHandler(id)
		history.push(`/articles/${id}/${slugify(title)}`)
	}

	// const handleChangePage = (page: number) => {
	// 	const newOffset = page * 10
	// 	setOffset(newOffset)
	// 	window.scrollTo({
	// 		top: 0,
	// 		behavior: 'smooth'
	// 	})
	// }



	// const [articleTags, setArticleTags] = useState({
	// 	'tags': ['Hello', 'World', 'Test']
	// })

	// const [allTags, setAllTags] = useState(['Hello', 'World', 'Test', 'News', 'Golden', 'New Tag'])

	const imageRatio = () => {
		switch (screen) {
		case 'tablet portrait':
			return 1.1
		case 'desktop':
			return 1.3
		default:
			return 3.5
		}
	}

	return (
		<>
			{isFetching && <LoadingOverlay />}
			<div className="mnmd-block mnmd-block--fullwidth mnmd-block--contiguous page-heading page-heading--has-background">
				<div className="container">
					{data
						?
						<>
							{isMobile ? <h2 className="page-heading__title" style={{ paddingTop: '20px' }}>Books Roundup 2023</h2> : <h2 className="page-heading__title">Books Roundup 2023</h2>}
							<div className="page-heading__subtitle">{data.length} {translate('articles')}</div>
						</>
						:
						<>
							<div className="page-heading__subtitle">{'No articles found.'}</div>
						</>
					}
				</div>
			</div>
			<div className="mnmd-block mnmd-block--fullwidth">
				<div className="container">
					<div className="mnmd-block mnmd-block--fullwidth">
						<div className="container">
							{data && data.map((article: any, key: number) => (
								<ArticleContainer key={key} onClick={() => { goToArticle(article.id, article.title) }} >
									<ArticleImageContainer>
										<Link className='linktitle' to={`/articles/${article.id}/${slugify(article.title)}`}><CloudImageUtils imgSrc={article.image?.url} alt="image" ratio={imageRatio()} params='func=cropfit'/></Link>
									</ArticleImageContainer>
									<div className='details-container'>
										<div className='title'>
											<Link className='linktitle' to={`/articles/${article.id}/${slugify(article.title)}`}>{article.title}</Link>
										</div>
										<div className='excerpt'>{article.excerpt}</div>
										<div className='filter-category'>{article.category.name}</div>
										<div className="post__meta details" style={{ width: '100%' }}>
											<span className="entry-author">By:&nbsp;
												<span className="entry-author__name">{article.author.name}</span>
											</span>
											<time className="time published detail-container" dateTime={article.published_date} title={moment(article.published_date).format('MMM DD, YYYY')}>
												<i className="mdicon mdicon-schedule"></i>
												<span className='date'>{moment(article.published_date).format('MMM DD, YYYY')}</span>
											</time>
											<Link to={`/search/wines?&article_id=${article.id}`} title={`${article.tasting_note_count} tasting notes`}><i className="mdicon mdicon-chat_bubble_outline"></i>{article.tasting_note_count || 0}</Link>
											{/* <div className='tags-style'>
															{articleTags.tags.length > 0 ? (
																<div className="tagcloud">
																	<div className='tags-label'>Tags:</div>
																	{articleTags.tags.map((tag, tagCloudKey) => (
																		<Link
																			key={tagCloudKey}
																			to={`/search/article?keyword=${tag}`}
																			className="tag-link-77 tag-link-position-1"
																			title={`${tag}`}
																		>
																			{tag}
																		</Link>
																	))}
																</div>
															) : (
																<div>No tags on this article.</div>
															)}
														</div> */}
										</div>
									</div>
								</ArticleContainer>
							))}

							{/* <Pagination totalRecords={16} limit={1} render={handleChangePage} /> */}

						</div>
						{/* <nav className="mnmd-pagination mnmd-pagination--next-n-prev">
									<div className="mnmd-pagination__inner">
										{data?.previous ? (
											<div className="mnmd-pagination__links">
												<Link to={`/issues/${data?.previous?._id}`} className="mnmd-pagination__item mnmd-pagination__item-prev">{data?.previous?.title}</Link>
											</div>
										) : (<div className="mnmd-pagination__links"></div>)}
										{data?.next ? (
											<div className="mnmd-pagination__links">
												<Link to={`/issues/${data?.next?._id}`} className="mnmd-pagination__item mnmd-pagination__item-next">{data?.next?.title}</Link>
											</div>
										) : null}
									</div>
								</nav> */}
					</div>
				</div>
				{/* <div className="mnmd-sub-col mnmd-sub-col--right sidebar js-sticky-sidebar" role="complementary">
							<div className="widget widget_archive">
								<TagCloud tags={allTags} />
							</div>
						</div> */}
			</div>
		</>
	)
}

export default FeaturedWineJournal
