/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

// Pages accessible from top-bar and footer menu
import VintageChart from './pages/vintage-chart'
import MarketingMaterials from './pages/marketing-materials'
import WineSubmission from './pages/wine-submission'
import EditorialCalendar from './pages/editorial-calendar'
import ContactUs from './pages/contact-us'
import Contributors from './pages/contributors'
import FAQ from './pages/faq'
import CookiePolicy from './pages/cookie-policy'
import PrivacyPolicy from './pages/privacy-policy'
import SubscriptionAgreement from './pages/subscription-agreement'

// Free Publications
import FreePubsWineJournal from './pages/free-publications/wine-journal'
import FreePubsWineDiscoveries from './pages/free-publications/wine-discoveries'
import FreePubsSustainabilityAndWine from './pages/free-publications/sustainability-and-wine'

// Events
import EventsWebminars from './pages/events/webminars'

// RP Cellar
import RPCellar from './pages/rp-cellar'

// Under About-us
import AboutEditorialTeam from './pages/about-us/editorial-team'
import AboutOurReviewers from './pages/about-us/our-reviewers'
import AboutRPWARatingSystem from './pages/about-us/RPWA-rating-system'

// Under Resources 
import ResourcesAboutWines from './pages/resources/about-wines'

// Under trade Partners
import TradePartnersPreferredRetailers from './pages/trade-partners/preferred-retailers'
import TradePartnersPreferredWineSchools from './pages/trade-partners/preferred-wine-schools'
import TradePartnersTradeDirectory from './pages/trade-partners/trade-directory'

// Under trade Partners - Trade Directory
import TradePartnersTradeDirectoryProfile from './pages/trade-partners/trade-directory/directory-profile'

// Pages accessible from header
import Subscriptions from './pages/subscriptions'
import SubscriptionBuffer from './pages/subscriptions/buffer-page'
import MyProfile from './pages/my-profile'

// General Pages
import AdvancedSearch from './pages/advanced-search'
import ArticlePage from './pages/article'
import ArticleComments from './pages/comments'
import AuthorPage from './pages/author'
import CategoryPage from './pages/category'
import HomePage from './pages/home'
import IssuePage from './pages/issue'
import MoreFreeStuffArticle from './pages/more-free-stuff-articles'
import WinePage from './pages/wine'
import SearchResults from './pages/search-results'

// My Company
import MyCompany from './pages/my-company'
import CompanyManagement from './pages/my-company/manage-group'
import JoinGroup from './pages/my-company/join-group'
import SuccessJoinPage from './pages/my-company/success-page/'

// Subscription and Payment
// import NoCCPA from './pages/no-ccpa'
import MySubscription from './pages/my-subscription'

// Sustainability Pages
import CertificationPage from './pages/free-publications/certification'
import GreenEmblemCompany from './pages/green-emblem-company'
import GreenEmblemList from './pages/green-emblem-list'

import { Switch } from 'react-router-dom'
import TitleRoute from '../../components/route'
import ScrollToTop from '../../components/scrollToTop'
import ForgotPassword from './pages/reset-password'
import SavePaymentMethods from './pages/my-subscription/SavePaymentMethod'
import EditPaymentMethods from './pages/my-subscription/EditPaymentMethod'
import SuccessSubscription from './pages/success-subscription'
import { isMobile } from 'react-device-detect'
import GiftSub from './pages/gift-subscription'
import NewGiftSub from './pages/gift-subscription/new-sub'
import NonExistPage from './pages/404-page/NonExistPage'
import GreenEmblemManifesto from './pages/free-publications/green-emblem-manifesto'
import FeaturedWineJournal from './pages/free-publications/wine-journal/featured-wine-journal'
import RedemptionSub from './pages/redemption-codes'
import { About, Origins, Reports } from './pages/about-us/the-wine-advocate/contents'
import BuyAddOns from './pages/manage-subscription/add-ons'
import ChangePlan from './pages/manage-subscription/change-plan'
import UpgradeToCommercial from './pages/manage-subscription/upgrade-to-commercial'
import GiftSubscription from './pages/gift-subscription/gift-sub'
import Checkout from './pages/checkout'

const Content: React.FC = () => {
	return (
		<div className={isMobile ? '' : 'site-content'}>
			<ScrollToTop>
				<Switch>
					{/* <TitleRoute path="/no-ccpa" component={NoCCPA} title="Do not sell my Personal Infomation" /> */}
					<TitleRoute path="/vintage-chart" component={VintageChart} title="Vintage Chart" />
					<TitleRoute path="/marketing-materials" component={MarketingMaterials} title="Marketing Materials" />
					<TitleRoute path="/wine-submission" component={WineSubmission} title="Wine Submission" />
					<TitleRoute path="/editorial-calendar" component={EditorialCalendar} title="Editorial Calendar" />
					<TitleRoute path="/contact-us" component={ContactUs} title="Contact Us" />
					<TitleRoute path="/contributors" component={Contributors} title="Contributors" />
					<TitleRoute path="/cookie-notice" component={CookiePolicy} title="Cookie Notice" />
					<TitleRoute path="/privacy-notice" component={PrivacyPolicy} title="Privacy Notice" />
					<TitleRoute path="/subscription-agreement" component={SubscriptionAgreement} title="Subscription Agreement" />
					<TitleRoute path="/free-publications/wine-journal/books-review-roundup-2023" component={FeaturedWineJournal} title="Free Publication - Wine Journal - Books Roundup 2023" />
					<TitleRoute path="/free-publications/wine-journal" component={FreePubsWineJournal} title="Free Publication - Wine Journal" />
					<TitleRoute path="/free-publications/wine-discoveries" component={FreePubsWineDiscoveries} title="Free Publication - Wine Discoveries" />
					<TitleRoute path="/free-publications/sustainability-and-wine" component={FreePubsSustainabilityAndWine} title="Free Publication - Sustainability and Wine" />
					<TitleRoute path='/sustainability-and-wine/green-emblem-list' component={GreenEmblemList} title="Sustainability and Wine - Green Emblem List" />
					<TitleRoute path="/green-emblem/:companyId" component={GreenEmblemCompany} title="Green Emblem" />
					<TitleRoute path="/free-publications/green-emblem" component={GreenEmblemManifesto} title="Free Publications - Green Emblem Mission and Objective" />
					<TitleRoute path="/free-publications/certification" component={CertificationPage} title="Free Publications - Certification" />
					<TitleRoute path="/events/webminars" component={EventsWebminars} title="Events - Webminars" />
					<TitleRoute path="/rp-cellar" component={RPCellar} title="RP - Cellar" />
					<TitleRoute path="/about/editorial-team" component={AboutEditorialTeam} title="About - Editorial Team" />
					<TitleRoute path="/about/reviewers" component={AboutOurReviewers} title="About - Our Reviewers" />
					<TitleRoute path="/about/the-rating-system" component={AboutRPWARatingSystem} title="About - RPWA Rating System" />
					<TitleRoute path="/about/the-wine-advocate" component={About} title="About - The Wine Advocate" />
					<TitleRoute path="/about/origins-of-the-wine-advocate" component={Origins} title="About - The Wine Advocate" />
					<TitleRoute path="/about/reports" component={Reports} title="About - The Wine Advocate" />
					<TitleRoute path="/resources/about-wines" component={ResourcesAboutWines} title="Resources - Glossary of Terms" />
					<TitleRoute path="/trade-partners/preferred-retailers" component={TradePartnersPreferredRetailers} title="Trade Partners - Preferred Retailers" />
					<TitleRoute path="/trade-partners/preferred-wine-schools" component={TradePartnersPreferredWineSchools} title="Trade Partners - Preferred Wine Schools" />
					<TitleRoute path="/trade-partners/trade-directory" component={TradePartnersTradeDirectory} title="Trade Partners - Trade Directory" />
					<TitleRoute path="/trade-directory/directory-profile/:companyId" component={TradePartnersTradeDirectoryProfile} title="Trade Partners - Trade Directory Profile" />
					<TitleRoute path="/faq" component={FAQ} title="FAQ" />
					<TitleRoute path="/subscriptions/:type" component={Subscriptions} title="Subscriptions" />
					<TitleRoute path="/subscriptions" component={SubscriptionBuffer} title="Subscriptions" />
					<TitleRoute path="/more-free-stuff" component={MoreFreeStuffArticle} title="More free to read" />
					<TitleRoute path="/my-company" component={MyCompany} title="My Company" exact isAuthRequired/>
					<TitleRoute path="/my-company/:type" component={CompanyManagement} title="Company Management" isAuthRequired />
					<TitleRoute path="/join-group/:type" component={JoinGroup} title="Join Company Group" />
					<TitleRoute path="/join-group-success" component={SuccessJoinPage} title="Success Page" isAuthRequired />
					<TitleRoute path="/my-profile/:type" component={MyProfile} title="My Profile Tabs" isAuthRequired />
					<TitleRoute path="/my-profile" component={MyProfile} title="My Profile" isAuthRequired />
					<TitleRoute path="/my-subscription" component={MySubscription} title="My Subscription" isAuthRequired />
					<TitleRoute path="/author/:authorID" component={AuthorPage} title="AuthorPage" />
					<TitleRoute path="/article/comments" component={ArticleComments} title="Article - Comments" />
					<TitleRoute path="/article/categories" component={CategoryPage} title="Article - Categories" />
					<TitleRoute path="/search/:type" component={SearchResults} title="Search" />
					<TitleRoute path="/sign-up/:type" component={Checkout} title="SignUp" />
					<TitleRoute path="/payment-method" component={SavePaymentMethods} title="Payment Method" isAuthRequired/>
					<TitleRoute path="/edit-payment-method" component={EditPaymentMethods} title="Edit Payment Method" isAuthRequired/>
					<TitleRoute path="/advanced-search/:type" component={AdvancedSearch} title="Advanced Search" />
					<TitleRoute path="/articles/:articleID" component={ArticlePage} title="Article" />
					<TitleRoute path="/issues/:issueID" component={IssuePage} title="Issue" />
					<TitleRoute path="/wines/:wineID" component={WinePage} title="Wine" />
					<TitleRoute path="/reset-password" component={ForgotPassword} title="Reset Password" />
					<TitleRoute exact path="/" component={HomePage} title=" " />
					<TitleRoute exact path="/success-subscription/:subscriptionType" component={SuccessSubscription} title="Successful Subscription" isAuthRequired/>
					<TitleRoute path='/redemption-codes/:type' component={RedemptionSub} title='Redemption Subscription' />
					<TitleRoute exact path="/gift-subscription" component={GiftSubscription} title="Gift Subscription Main" />
					<TitleRoute exact path="/gift-subscription/:type" component={GiftSub} title="Gift Subscription" />
					<TitleRoute exact path="/buy-addons/:managerID" component={BuyAddOns} title="Buy Add-Ons" isAuthRequired/>
					<TitleRoute exact path="/gift-subscription/activation/:token" component={NewGiftSub} title="Gift Subscription Activation" />
					<TitleRoute exact path="/change-plan" component={ChangePlan} title="Change Plan" isAuthRequired/>
					<TitleRoute exact path="/upgrade-to-commercial" component={UpgradeToCommercial} title="Upgrade To Commercial" isAuthRequired/>
					<TitleRoute exact path="/login" component={HomePage} title="Login to Robert Parker.com" />
					<TitleRoute exact path="/sign-in" component={HomePage} title="Login to Robert Parker.com" />
					<TitleRoute exact path='*' component={NonExistPage} title="404 Page" />
				</Switch>
			</ScrollToTop>
		</div>
	)
}

export default Content 