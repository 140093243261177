/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi,  } from '@reduxjs/toolkit/query/react'
import { sprintf } from 'sprintf-js'
import { GetBaseQuery } from '../../utils'
import { API_ENDPOINTS, RP_ENV } from '../../configs'
import { MAX_RETRIES } from './constant'
import { reverseObfuscateConstant } from '../../utils/Constant'

const URL = reverseObfuscateConstant(RP_ENV.API_URL_V2)

export const WinePriceSearcherAPI = createApi({
	reducerPath: 'WinePriceSearcherAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, URL),
	endpoints: (builder) => ({
		getWinesByLocation: builder.query<any, string>({
			query: (param) =>
				sprintf(API_ENDPOINTS.get_wines_by_location, param),
		}),
		getWineDetails: builder.query<any, string>({
			query:(param) => sprintf(API_ENDPOINTS.get_wine_details, param)
		})
		
	}),
})

export const {
	useGetWinesByLocationQuery,
	useGetWineDetailsQuery
} = WinePriceSearcherAPI
