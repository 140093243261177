/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { GiftCardContainer, GiftCardFormBody } from '../../../gift-subscription/styles'
import Input from '../../../../../../components/form-components/input/Input'
import { Control, FieldValues, RegisterOptions, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import Dropdown from '../../../../../../components/form-components/dropdown/Dropdown'
import Checkbox from '../../../../../../components/form-components/checkbox/Checkbox'
import MultiSelect from '../../../../../../components/form-components/dropdown/MultiSelectDropdown'
import { useGetUserCompanyByManagerQuery } from '../../../../../../app/services'

type Props = {
    isLogged: boolean;
    control: Control<FieldValues>;
    countries: string[];
    getValues: UseFormGetValues<FieldValues>;
    headerText: string;
	userData: any;
	setValue: UseFormSetValue<FieldValues>;
	isCommercial?: boolean;
}

const countryDropdownRules: RegisterOptions = {
	required: 'Country of residence is required',
	validate: (value) => value !== '' || 'Please select a country'
}

const passwordRules: RegisterOptions = {
	required: 'Password is required',
	minLength: {
		value: 10,
		message: 'Password must be at least 10 characters long'
	}
}

function ProfileCard({isLogged, control, countries, getValues, headerText, userData, setValue, isCommercial = false}: Props): ReactElement {
	const { data: userCompanyDetails } = useGetUserCompanyByManagerQuery(userData?._id, {skip: !userData?._id })

	const confirmPasswordRules: RegisterOptions = {
		required: 'Confirm password is required',
		validate: (value) => {
			return value === getValues('password') || 'The passwords do not match'
		}
	}

	const setSenderFields = (): void => {
		if(userData){
			setValue('firstName', userData?.profile?.name?.first_name)
			setValue('lastName', userData?.profile?.name?.last_name)
			setValue('email', userData.emails[0].address)
			setValue('country', userData?.profile?.address?.country?.name)
			setValue('isNewsLetter', userData?.profile?.preferences?.receive_newsletter)
			setValue('isMarketting', userData?.profile?.preferences?.receive_marketingletter)
		}
	}

	const setUserCompanyFields = () => {
		if(userCompanyDetails && userCompanyDetails.data){
			setValue('companyName', userCompanyDetails.data?.name)
			setValue('businessType', userCompanyDetails.data?.type.map((type: string) => {
				return {
					value: type,
					label: type
				}
			}))
		}
	}

	useEffect(() => {
		setSenderFields()
	}, [userData])

	useEffect(() => {
		setUserCompanyFields()
	}, [userCompanyDetails])

	const businessTypeOptions = [
		{ value: 'Agent', label: 'Agent', },
		{ value: 'Auction House', label: 'Auction House' },
		{ value: 'Broker', label: 'Broker', },
		{ value: 'Distributor', label: 'Distributor', },
		{ value: 'Educator', label: 'Educator', },
		{ value: 'F&B', label: 'F&B' },
		{ value: 'Hotel', label: 'Hotel', },
		{ value: 'Hotel-Restaurant', label: 'Hotel-Restaurant' },
		{ value: 'Importer', label: 'Importer', },
		{ value: 'Negociant', label: 'Negociant', },
		{ value: 'Producer', label: 'Producer', },
		{ value: 'Restaurant', label: 'Restaurant', },
		{ value: 'Retailer', label: 'Retailer', },
		{ value: 'Vineyard', label: 'Vineyard', },
		{ value: 'Wholesaler', label: 'Wholesaler', },
		{ value: 'Wine School', label: 'Wine School' },
		{ value: 'Others', label: 'Others', }
	]

	return <GiftCardContainer isMobile={isMobile}>
		<GiftCardFormBody isMobile={isMobile}>
			<div className='head-body'>
				<h3>{headerText}</h3>
			</div>
			<div className='form'>
				<div className='first-input'>
					<Input disabled={isLogged && getValues('firstName')} control={control} name='firstName' type='text' placeholder='*First Name'rules={{ required: 'First name is required' }} />
					<Input disabled={isLogged && getValues('lastName')} control={control} name='lastName' type='text' placeholder='*Last Name' rules={{ required: 'Last name is required' }} />
				</div>
				{
					isCommercial && <>
						<div className='first-input'>
							<Input disabled={isLogged && getValues('companyName')} control={control} name='companyName' type='text' placeholder='*Company Name' rules={{ required: 'Company name is required' }} />
						</div>
						<div className='first-input'>
							<MultiSelect disabled={isLogged && getValues('businessType')} control={control} name='businessType' placeholder='*Business Type' rules={{ required: 'Business types are required' }} options={businessTypeOptions}/>
						</div>
					</>
				}
				<div className='first-input'>
					<Input disabled={isLogged} control={control} name='email' type='email' placeholder='*Email Address' rules={{ required: 'Email address is required' }} />
				</div>
				{
					!isLogged && <div className='first-input'>
						<Input control={control} name='password' placeholder='*Password' rules={!isLogged ? passwordRules : {}} type='password' />
						<Input control={control} name='passwordConfirm' placeholder='*Confirm Password' rules={!isLogged ? confirmPasswordRules : {}} type='password' />
					</div>
				}
				<div className='first-input'>
					<Dropdown disabled={isLogged && getValues('country')} control={control} rules={countryDropdownRules} options={countries} placeholder='*Country of Residence' name='country' />
				</div>
				<div className='first-input'>
					<Checkbox name='isNewsLetter' control={control}>
						<span>Set up a weekly alert to notify me about newly published ratings and articles.</span>
					</Checkbox>
				</div>
				<div className='first-input'>
					<Checkbox name='isMarketting' control={control}>
						<span>Include me in your mailing list to receive exclusive promotions, updates, and wine recommendations from Robert Parker Wine Advocate.</span>
					</Checkbox>
				</div>
			</div>
		</GiftCardFormBody>
	</GiftCardContainer>
}

export default ProfileCard
