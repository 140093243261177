/* eslint-disable @typescript-eslint/no-explicit-any */
// import { copyFileSync } from 'fs'

export const FilterCountriesFromScores = (scores: any[]): string[] => {
	const container = scores.map((data) => {
		if (data.label.country) {
			return data.label.country
		}
	})

	return [...new Set(container)]
}

export const FilterCountryGroupFromScores = (
	scores: any[],
	country: string
): string => {
	const container = scores.filter((data) => {
		if (data.label.country === country) {
			return data.label.group
		}
	})

	return container.length > 0 ? container[0].label.group : ''
}

export const FilterVintageScoresFromGroupScores = (
	scores: any[],
	country: string,
	region: string,
	vintage?: string
): VintageScore[] => {
	const vintageScores: VintageScore[] = []

	const container = scores.filter(
		(data) => data.label.country === country && data.label.row === region
	)

	if (container.length > 0) {
		// Builds a better structure for the scores
		container[0].scores.map((score: any) => {
			Object.entries(score).forEach((values) => {
				if (vintage) {
					// Execute this if there is a vintage filter
					if (Number(vintage) === Number(values[0])) {
						vintageScores.push({
							year: values[0],
							label: values[1] ? `${values[1]}` : '',
						})
					}
				} else {
					// Just push it without testing for vintage match
					vintageScores.push({
						year: values[0],
						label: values[1] ? `${values[1]}` : '',
					})
				}
			})
		})
	}

	return vintageScores
}

export const GetRowListFromScoresAndCountry = (
	scores: any[],
	country: string
): string[] => {
	const matchingScores = scores.filter(
		(data) => data.label.country === country && data.label.row
	)

	return matchingScores.map((score) => score.label.row)
}

export const SplitRatingSegments = (rating: string): ChartRating => {
	if (rating === 'NT') {
		return { rating: '', label: 'NT' }
	}

	const result = rating.match(/['C'|'E'|'NV'|'I'|'NT'|'R'|'T']/)

	if (result) {
		const alphaValue = result[0]
		const numericValue = result.input?.replace(alphaValue, '')

		return {
			rating: `${numericValue}`,
			label: `${alphaValue}`,
		}
	}
	return { rating: '', label: '' }
}

export const SortingData = (scores:any[], type:string): string[] => {
	const filteredArray:string[] = []
	
	for(const score of scores){
		if (score[type] && !filteredArray.includes(score[type])) {
			filteredArray.push(score[type])
		}
	}
	return filteredArray.sort()
}

export const FilterRegionsByCountry = (scores:any[], country:string): string[] => {
	const filteredRegion = scores.filter(score => score.country === country)
	return SortingData(filteredRegion, 'regionLabel') 
}

export const FilterVintagesByRegion = (scores:any[], region:string, country:string): {year:string; label:string}[] => {
	let allScoresFromRegion:string[]

	if(region === 'N/A'){
		allScoresFromRegion = scores.filter(score => score.country === country)
	}else{
		allScoresFromRegion = scores.filter(score => score.regionLabel === region)
	}

	return allScoresFromRegion.map((score:any) => ({
		year: score.year,
		label: score.rating
	}))
}

export const GetVintagesByCountryAndRegion = (scores: any[], country: string, region:string): string[] => {
	let allVintages: string[]
	if(region === 'N/A'){
		allVintages = scores.filter(score => score.country === country)
	}else{
		allVintages = scores.filter(score => score.country === country && score.regionLabel === region)
	}
	return SortingData(allVintages, 'year')
}

export const FilterScoresByTrinity = (scores: any[], country: string, region: string, vintage: string): { year: string; label: string }[] => {
	let filteredScores: any[]

	if (region === 'N/A' && vintage === 'all') {
		filteredScores = scores.filter(score => score.country === country)
	} else if (region === 'N/A') {
		filteredScores = scores.filter(score => score.country === country && score.year === vintage)
	} else if (vintage === 'all') {
		filteredScores = scores.filter(score => score.country === country && score.regionLabel === region)
	} else {
		filteredScores = scores.filter(score => score.country === country && score.regionLabel === region && score.year === vintage)
	}

	return filteredScores.map((score: any) => ({
		country: score.country,
		region: score.region,
		location: score.location,
		variety: score.variety,
		locale: score.locale,

		color_class: score.color_class,
		dryness: score.dryness,
		label: score.rating,
		year: score.year,
		regionLabel: score.regionLabel,
		type: score.type
	}))
}
