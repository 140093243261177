import styled from 'styled-components'

export const WineAlternativeContainer = styled.div`
    & .result-container{
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
`

export const WineSelectionActions = styled.div`
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    & .checkbox-all{
        display: flex;
        gap: 0.5rem;
        align-items: start;

        & label{
            cursor: pointer;
            font-weight: 500;
        }
    }

    & .actions{
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    @media(max-width: 550px){
        flex-direction: column;
        align-items: flex-start;

        & .checkbox-all{
            padding: 0.5rem 0;
        }

        & .actions{
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;

            & button{
                width: 100%;
                margin: 0;
            }
        }
    }
`

export const WineRowContainer = styled.div`

    & .name{
        padding: 1rem;
        background-color: #FAFAFA;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        & .title{
            display: flex;
            gap: 0.7rem;
            align-items: flex-start;
            width: 85%;
            
            & label{
                cursor: pointer;
                font-weight: 500;
                padding: 0;
                margin: 0;
            }
        }
    }
`

export const WineRowInfo = styled.div<{isMobile: boolean}>`
    padding: ${(props) => (props.isMobile ? '24px' :'32px')};
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    background-color: #FAFAFA;

    & .details{
        display: flex;
    }

    & .footer{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    @media(max-width: 550px){

        & .details{
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
        }
    }
`

export const InfoDisplayContainer = styled.div`
    width: 33%;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    & .title{
        font-weight: bold;
    }

    & .content{
        max-width: 200px;
    }

    @media(max-width: 550px){
        gap: 0.3rem;
        width: 100%;
    }
`

export const PrintNotesButton = styled.button`
    height: 33px;
    margin: 0 0 0 10px;
    padding: 6px 12px;
    border-radius: 8px;
    background-color: #73333f;
    cursor: pointer;
    color: #fff;
    text-align: center;
    border: solid 1px white;

    &:hover {
        background-color: #8E4250;
    }

    &:disabled{
        background-color: #CCCCCC;

        &:hover{
            background-color: #CCCCCC;
        }
    }
`

export const ViewWineListButton = styled.button`
    height: 33px;
    margin: 0 0 0 10px;
    padding: 6px 12px;
    border-radius: 8px;
    background-color: #73333f;
    cursor: pointer;
    color: white;
    text-align: center;
    border: solid 1px white;

    &:hover {
        background-color: #8E4250;
    }
`

export const TriplePrintContainer = styled.div`
    page-break-after: always !important;

    body {
        width: 210mm;
        height: 282mm;
        overflow: visible;
    }

    & img {
        height: 60%;
        width: 60%;
    }

    & .content-padding {
        padding: 18px 18px 18px 18px !important;
    }
    
    & .border-container-all {
        margin: 0;
        padding: 0;
        text-align: center;
        border-radius: 0;
        border: solid 2px #0f0f0f;
        height: 1360px  !important;
        /* height: 100% !important; */
    }

    & .border-container-firefox {
        margin: 0;
        padding: 0;
        text-align: center;
        border-radius: 0;
        border: solid 2px #0f0f0f;
        height: 1360px  !important;
    }

    & .p-tastingnote-3x1{
        font-size: 15px !important;
        font-family: 'Libre Franklin', sans-serif;
    }
    
    & .tasting-note-2-content{
        font-size: 15px;
    }

    & .layout-footer {       
        & p {
            line-height: 1.2;
            font-size: 15px !important;
        }
    }    
`

export const DoublePrintContainer = styled.div<{ BrowserType?: string }>`
    page-break-after: auto !important;
    page-break-inside: avoid; 
    page-break-before: auto; 
    
    width: 100%;
    height: ${(props) => (props.BrowserType?.includes('edge-chromium') || props.BrowserType?.includes('firefox') || props.BrowserType?.includes('chrome') ? '1360px' : '1260px')};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${(props) => (props.BrowserType?.includes('edge-chromium') || props.BrowserType?.includes('firefox') || props.BrowserType?.includes('chrome') ? '20px' : '10px')};;

    body, html {
        height: 100%;
        width: 100%;
        overflow: visible;
    }

    & .border-container {
        height: 100%;
        border-radius: 0;
        border: solid 2px #0f0f0f;
        padding: 18px;
    }
    & .header-container {
        & hr {
            border: none !important;
            border-top: 1px solid black !important;
        }

    }
    
    & .top-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & .rating-display{
            & h3 {
                display: inline-block;
                font-size: 40px;
                font-weight: 700;
                line-height: 120%;
                color: #b08883;
                border-bottom: solid 5px #0f0f0f;
            }
            
        }
    }

    & .title-display {
        display: flex;
        justify-content: start;
        & h3 {
            font-size: 20px !important;
            text-align: start;
            font-weight: 500;
            font-family: 'Playfair Display', serif;
        }
    }


    & .content-container-small {

        & p {
            font-size: 14.5px !important;
            font-weight: 400;
            line-height: 1.2;
            font-family: 'Libre Franklin', sans-serif;
        }
    }


    & .content-container-long {
        & p {
            font-size: 14.5px !important;
            font-weight: 400;
            line-height: 1.2;
            font-family: 'Libre Franklin', sans-serif;
        }
    }

    & .footer-container {
        display: flex;
        flex-direction: column;
        justify-content: end;
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 20px;

        & p {
            font-size: 14.5px;
        }
    }

    @media print {
        @page {
            size: Letter;
            margin: 0 !important;
        }

        @page {
            size: Legal;
            margin: 0 !important;
        }

        @page {
            size: A4;
            margin: 0 !important;
        }
    }
`

export const FourLayoutContainer = styled.div`
    page-break-after: auto !important;
    page-break-inside: avoid; 
    page-break-before: auto; 
    width: 100%;
    height: 100%;

    body, html {
        height: 100%;
        width: 100%;
        overflow: visible;
    }

    & .p-tastingnote-2x2{
        font-size: 14.5px !important;
        font-weight: 400;
        line-height: 1.2;
        font-family: 'Libre Franklin', sans-serif;
    }

    & .box-padding{
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    
    & .border-container-all {
        padding-top: 20px;
        text-align: center;
        border-radius: 0;
        border: solid 2px #0f0f0f;
        height: 650px !important;
    }

    & .border-container-safari {
        padding-top: 10px;
        text-align: center;
        border-radius: 0;
        border: solid 2px #0f0f0f;
        height: 610px !important;
    }

    & .content-container {
        height: 100%;
        display: flex !important;
        flex-direction: column !important;
        justify-content: end !important;
    }

    & .tastingnote-container{
        padding: 0;
    }

    & .footer-content{
        height: 100%;
        margin: 20px;
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex !important;
        flex-direction: column !important;
        align-items: end !important;
        justify-content: end !important;
        text-align: end !important;

        & p {
            font-size: 14.5px !important;
        }
    }

    @media print {
        @page {
            size: Letter;
            margin: 0 !important;
        }

        @page {
            size: Legal;
            margin: 0 !important;
        }

        @page {
            size: A4;
            margin: 0 !important;
        }
    }
`

export const SinglePrintContainer = styled.div`
    page-break-after: always;
`