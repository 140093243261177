/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Article from '../../../../structure/classes/Article'

// i18n
import { TranslationsUtil } from '../../../../utils'
import englishTranslation from './translations/en.json'
import {Issues as IssueFromStore} from '../../../../app/slices/Issues'
import { useAppSelector } from '../../../../app/hooks'
import { useGetFeaturedWineAdvocateQuery } from '../../../../app/services'
import LoadingOverlay from '../../../../components/Loading-overlay'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'

const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

let previousArticleContainer: ReactElement
let nextArticleElement: ReactElement
let previousArticleID: string
let nextArticleID: string

const getArticlePreview = (id:string , title: string, imageURL: string): ReactElement => (
	<article className="post--overlay post--overlay-bottom post--overlay-floorfade">
		
		<div className="background-img"><CloudImageUtils imgSrc={imageURL} alt="image" ratio={2} params=''/></div>
		<div className="post__text inverse-text">
			<div className="post__text-wrap">
				<div className="post__text-inner">
					<h3 className="post__title typescale-1">{ title }</h3>
				</div>
			</div>
		</div>
		<Link to={ `/articles/${ id }` } className="link-overlay"></Link>
	</article>)

function IssueSlider(props: { referringArticle: Article }): ReactElement {
	const translate =  translationUtil.getTranslator()
	const { referringArticle } = props
	
	const {latestIssue: newIssue} = useAppSelector(IssueFromStore)
	// const { triggerTrackingHandler } = useArticleTracking()

	const {data: wineArticleQuery, isLoading, isError} = useGetFeaturedWineAdvocateQuery()
	const [articlesV2, setArticlesV2] = useState<any>()
	const [latestIssue, setLatestIssue] = useState<any>({})

	useEffect(() => {
		if(wineArticleQuery) {
			if(wineArticleQuery.success){
				setArticlesV2((prevState: any) => ({
					...prevState,
					TheWineAdvocate: wineArticleQuery.data
				}))
			}
		}
		
		if(isError){
			console.log('Something went wrong. Please try again.')
		}
	}, [wineArticleQuery, isError])

	useEffect(() => {
		if(newIssue._id) {
			setLatestIssue(newIssue)
		}
	}, [newIssue])

	useEffect(() => {

		if(referringArticle && articlesV2) {
			const articlePool = articlesV2[referringArticle.publication.getPublicationShortName()]
			articlePool?.forEach((articleBasis: any, index: number) => {
				if (referringArticle.id === articleBasis.id) {
					let articleDetail
					
					articleDetail = (index == 0)? articlePool[articlePool.length -1]:articlePool[index - 1]
					previousArticleContainer = getArticlePreview(articleDetail.articleId, articleDetail.title, articleDetail.banner?.url)
					previousArticleID = articleDetail.articleId

					articleDetail = (index === (articlePool.length -1))? articlePool[0]:articlePool[index + 1]
					nextArticleElement = getArticlePreview(articleDetail.articleId, articleDetail.title, articleDetail.banner?.url)
					nextArticleID = articleDetail.articleId
				}
			})
		}

	},[articlesV2, referringArticle])

	return (
		<>
			<div className="posts-navigation single-entry-section clearfix">
				{isLoading && <LoadingOverlay />}
				<div className="posts-navigation__prev">
					{ previousArticleContainer }
					{ referringArticle && previousArticleID ? (
						<Link className="posts-navigation__label" to={ `/articles/${ previousArticleID }` }>
							<span><i className="mdicon mdicon-arrow_back"></i>{ translate('Previous Article' )}</span>
						</Link>): (<></>) }
				</div>
	
				<div className="posts-navigation__next">
					{ nextArticleElement }
					{ referringArticle && nextArticleID ? (
						<Link className="posts-navigation__label" to={ `/articles/${ nextArticleID }` }>
							<span>{ translate('Next Article') }<i className="mdicon mdicon-arrow_forward"></i></span>
						</Link>
					): (<></>) }
				</div>
			</div>
	
			<nav className="mnmd-pagination text-center">
				{ latestIssue? (<Link to={ '/issues/latest' } className="btn btn-default">{ translate('View the latest issue') }<i className="mdicon mdicon-arrow_forward mdicon--last"></i></Link>): (<>Loading latest issue...</>)}
			</nav>
		</>
	)
}

export default IssueSlider