/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookie from 'universal-cookie'

const cookie = new Cookie()
// const currentDay = new Date()
const newDay = new Date()
const oneMonth = new Date(newDay.setMonth(newDay.getMonth()+1))
// const oneMonth = new Date(currentDay.setDate(currentDay.getDate()+1))

export const getCookie = (name: string): any => {
	return cookie.get(name)
}

export const setCookie = (name: string, value:any, expireDate: any): any =>  {
	cookie.set(name, value, { path: '/', expires: expireDate === true ? oneMonth : new Date(expireDate) })
}

export const setExpireLogin = (): any =>  {
	cookie.set('LastLogin', oneMonth, { path: '/', expires: oneMonth })
}

export const removeCookie = (name: string): any => {
	cookie.remove(name)
}

export function setCookieV2(name:string, value:string, options:any = {}): void {

	options = {
		path: '/',
		// add other defaults here if necessary
		...options
	}
	
	if (options.expires instanceof Date) {
		options.expires = options.expires.toUTCString()
	}
	
	let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value)
	
	for (const optionKey in options) {
		updatedCookie += '; ' + optionKey
		const optionValue = options[optionKey]
		if (optionValue !== true) {
			updatedCookie += '=' + optionValue
		}
	}
	document.cookie = updatedCookie
}

export const clearCookie = async (): Promise<void> => {
	setCookie('LastLogin', '' , {
		'max-age': -1
	})
	setCookie('RPWA_AUTH', '' , {
		'max-age': -1
	})

}