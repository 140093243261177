import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'

import { Link, useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import { useGetVintagesForAWineQuery } from '../../../../../app/services'
import { slugify } from '../../../../../utils/ArticlesUtil'

function WineVintages(props: { wineId: string, title: string; wineName: string, onVintageSelected: () => void }): ReactElement {
	const [defaultValue, setDefaultValue] = useState<string>('')
	

	const history = useHistory()

	const { title, wineId, wineName, onVintageSelected } = props

	const { data, isError } = useGetVintagesForAWineQuery(`${wineId}`, { skip: (wineId) ? false : true })

	useEffect(() => {
		if(data){
			if (data.success){
				const findWine = data.data && data.data.find((vintage:{wine_id:string; vintage:string}) => wineId === vintage.wine_id)
				if(findWine){
					setDefaultValue(findWine.vintage)
				}
			}
		}
		if(isError){
			console.log('Something went wrong. Please try again.')
		}
	}, [wineId, data, isError])
	

	// const DesktopVintages = () => {
	// 	return <div className="tagcloud">
	// 		{data.map((wine: { wine_id: string, vintage: string }, wine_vintage_key: number) => (<Link style={{color:'#333'}} onClick={onVintageSelected} to={`/search/wine?keyword=${wine.vintage}`} key={wine_vintage_key}>{wine.vintage}</Link>))}
	// 	</div>
	// }

	// const MobileVintages = () => {

	// 	const selectedVintageHandler = (event:ChangeEvent<HTMLSelectElement>) => {
	// 		const selectedVintage = data.find((vintage:{wine_id:string; vintage:string}) => event.target.value === vintage.vintage)
	// 		if(selectedVintage){
	// 			history.push(`/search/wine?vintage=${selectedVintage.vintage}`)
	// 			onVintageSelected()
	// 		}
	// 	}

	// 	return <div className={'form-value'} >
	// 		<select defaultValue={defaultValue} id='title' name='userInfo.title' onChange={selectedVintageHandler}>
	// 			{data.map((wine: { wine_id: string, vintage: string }, wine_vintage_key: number) => <option value={wine.vintage} key={wine_vintage_key}>{wine.vintage}</option> )}
	// 		</select>
	// 	</div>
	// }

	const DesktopVintages = () => {
		return <div className="tagcloud">
			{data?.data.map((wine: { wine_id: string, vintage: string }, wine_vintage_key: number) => (<Link style={{color:'#333'}} onClick={onVintageSelected} to={`/wines/${wine.wine_id}/${slugify(wineName + '-' + wine.vintage)}`} key={wine_vintage_key}>{wine.vintage}</Link>))}
		</div>
	}

	const MobileVintages = () => {

		const selectedVintageHandler = (event:ChangeEvent<HTMLSelectElement>) => {
			const selectedVintage = data?.data.find((vintage:{wine_id:string; vintage:string}) => event.target.value === vintage.vintage)
			if(selectedVintage){
				history.push(`/wines/${selectedVintage.wine_id}/${slugify(wineName + '-' + selectedVintage.vintage)}`)
				onVintageSelected()
			}
		}

		return <div className={'form-value'} >
			<select defaultValue={defaultValue} id='title' name='userInfo.title' onChange={selectedVintageHandler}>
				{data?.data.map((wine: { wine_id: string, vintage: string }, wine_vintage_key: number) => <option value={wine.vintage} key={wine_vintage_key}>{wine.vintage}</option> )}
			</select>
		</div>
	}

	if (data) {
		return (
			<div>
				<div className={'attribute-header'}>{title}:</div>
				{ isMobile ? <MobileVintages /> : <DesktopVintages /> }
				
			</div>
		)
	}

	return (<></>)
}

export default WineVintages