import React, { ReactElement } from 'react'

// Styles
import { ContentSection, ImageContentSection, OrangeButton, SideBarContainer } from './styles'
import SectionSeparator from '../../sections/section-separator'
import { MARKETING_MATERIAL_REFERENCES } from '../../../../configs'
import { isMobile } from 'react-device-detect'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
import { validateURL } from '../../../../utils/ValidateUrl'
import { sanitizeUrl } from '@braintree/sanitize-url'
// Sections

function MarketingMaterials(): ReactElement {
	return (
		<>
			<div className="mnmd-block mnmd-block--fullwidth mnmd-block--contiguous page-heading page-heading--has-background">
				<div className="container">
					{isMobile ? <h2 className="page-heading__title" style={{ paddingTop: '20px' }}>Marketing Materials</h2> : <h2 className="page-heading__title">Marketing Materials</h2>}
				</div>
			</div>
			<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
				<div className="container">
					<div className="row">
						<SideBarContainer className="mnmd-sub-col mnmd-sub-col--left sidebar js-sticky-sidebar">
							<div>
								<a href={validateURL(`${MARKETING_MATERIAL_REFERENCES.GUIDELINE_PDF}`) ? `${MARKETING_MATERIAL_REFERENCES.GUIDELINE_PDF}` : ''} download="guieline.pdf" className='download-pdf'><OrangeButton>DOWNLOAD OUR FULL GUIDELINES PDF</OrangeButton></a>
							</div>
							<div>
								<p>Please contact us at <a href={sanitizeUrl('mailto:support@robertpaker.com')}>support@robertparker.com</a> if {'you\'re'} unsure or would like to use any of our trademarked materials.</p>
							</div>
							<div>
								<SectionSeparator position={'horizontal'} ></SectionSeparator>
							</div>
						</SideBarContainer>
						<div className="mnmd-main-col has-left-sidebar" role="main">
							<article className="mnmd-block post post--single post-10 type-post status-publish format-standard has-post-thumbnail hentry category-abroad tag-landscape cat-5" itemScope itemType="https://schema.org/Article">
								<ContentSection isFlexed={false} >
									<h6>{'Hello! We’re glad you’re here. '} </h6>

									<p>At Robert Parker Wine Advocate {'we’re'} happy to help our commercial subscribers promote and reference quality wines reviewed by our professional critics.</p>

									<p>To help with marketing your products on the web or in store, {'we\'ve'} created some guidelines to help you use our brand and assets, including our logo, content and trademarks.</p>
									<p>If you want to know more about our commercial subscription, please click here.</p>
									<p className={'smaller'}>As indicated in our terms and conditions the rights usage of our scores, notes and other brand assets is strictly reserved for the promotion of wines carried in the physical inventories of our commercial subscribers for the sole purpose of sales promotion. This right is not transferable, therefore you are not allowed to transmit content or brand assets to any third parties such as your clients and distributors. They are welcome to gain access to them by becoming our commercial subscribers.</p>
								</ContentSection>
								<ContentSection isFlexed={false} >
									<SectionSeparator position={'horizontal'} ></SectionSeparator>
								</ContentSection>
								<ContentSection isFlexed={false} >
									<h4>Robert Parker Wine Advocate Logos for Websites, Social Media and In-Store</h4>
									<p>
										{'The Robert Parker Wine Advocate (RPWA) Logo include the names ‘Robert Parker Wine Advocate’ and ‘Wine Advocate’\' logo, and any word, phrase, image, or other designation that identifies the source or origin of any of Robert Parker Wine Advocate\'s products. Please don\'t modify the logos or use them in a confusing way, including suggesting sponsorship or endorsement by Robert Parker Wine Advocate. The Robert Parker Wine Advocate logo is free to use for all commercial subscribers. Please use the latest Robert Parker Wine Advocate for all online and in-store marketing materials.'}
									</p>
								</ContentSection>
								<ContentSection isFlexed={false} >
									<div>
										{/* <img src={MARKETING_MATERIAL_REFERENCES.LOGOS} /> */}
										<CloudImageUtils imgSrc={MARKETING_MATERIAL_REFERENCES.LOGOS} alt="image" ratio={0} params=''/>
									</div>
								</ContentSection>
								<ContentSection isFlexed={false} >
									<p>If {'you’re'} unsure what logo to use please contact us at <a href={sanitizeUrl('mailto:support@robertparker.com')} >support@robertparker.com</a></p>
									<p>View the guidelines for details on how to apply the RPWA logo and in store.</p>
								</ContentSection>
								<ContentSection isFlexed={false} >
									<a href={validateURL(`${MARKETING_MATERIAL_REFERENCES.LOGO_TOOLKIT}`) ? `${MARKETING_MATERIAL_REFERENCES.LOGO_TOOLKIT}` : ''} download className='download-pdf'><OrangeButton>DOWNLOAD ROBERT PARKER WINE ADVOCATE LOGO</OrangeButton></a>
								</ContentSection>
								<ContentSection isFlexed={false} >
									<SectionSeparator position={'horizontal'} ></SectionSeparator>
								</ContentSection>
								<ContentSection isFlexed={false} >
									<h4>Robert Parker Wine Advocate Rating Points for Websites, Social Media and In-Store</h4>
									<p>As a commercial subscriber, you are able to use the rating points to promote your wines by the inclusion of the letters “RP” before the score (e.g. RP90).</p>

									<p>Additionally, as a wine producer with a RPWA commercial subscription, you are also able to order official Robert Parker Wine Advocate rating bottle stickers to promote your wines.</p>

									<p>Parker Points®️ stickers help provide customers with a quick and easy reference to the quality of your wines through Robert Parker Wine Advocate {'reviewers\''} professional judgment and helps your wine stand out from the rest on the shelves.</p>

									<p>Commercial subscribers can print shelf talkers containing the RPWA logo, rating points and tasting notes through a button available on each {'wine’s'} page.</p>

									<p>These are the only official rating stickers and materails we produce, provide and authorise for the representation of our scores, if {'you’re'} unsure please contact us at <a href={sanitizeUrl('mailto:commercial@robertparker.com')}>commercial@robertparker.com</a></p>

									<p>View the guidelines on how to apply RPWA rating points to bottles, labels etc online and in store.</p>
								</ContentSection>
								<ImageContentSection>
									<div className={'sticker-container'}>
										<CloudImageUtils imgSrc={MARKETING_MATERIAL_REFERENCES.STICKER_90} alt="image" ratio={0} params='w=300&h=300'/>
									</div>
									<div className={'sticker-container'} >
										<CloudImageUtils imgSrc={MARKETING_MATERIAL_REFERENCES.STICKER_95} alt="image" ratio={0} params='w=300&h=300'/>
									</div>
									<div className={'sticker-container'} >
										<CloudImageUtils imgSrc={MARKETING_MATERIAL_REFERENCES.STICKER_99} alt="image" ratio={0} params='w=300&h=300'/>
									</div>
									<div className={'sticker-container'} >
										<CloudImageUtils imgSrc={MARKETING_MATERIAL_REFERENCES.STICKER_100} alt="image" ratio={0} params='w=300&h=300'/>
									</div>
								</ImageContentSection>
								
								
								<ContentSection isFlexed={false} >
									<SectionSeparator position={'horizontal'} ></SectionSeparator>
								</ContentSection>
								<ContentSection isFlexed={false} >
									<h4>Website Screenshots, Wine Reviews & Other Copyrighted Content</h4>
									<p>Our professional tasting notes are copyrighted content that are made available only to commercial subscribers for marketing and promotional purposes within certain limits. These are the only official tasting notes available to be associated with the Robert Parker Wine Advocate brand and modification of the tasting notes is prohibited.</p>
									<p>We generally recommend that you do not use website screengrabs, but if you must please follow the guidelines below and include our logo and branding colours to screengrab.</p>
									<p>If you’re unsure, please email <a href={sanitizeUrl('mailto:support@robertparker.com')}>support@robertparker.com</a> if you would like to use any of our tasting notes, points or our professional wine reports and articles.</p>
									<p>View the guidelines for details on how to apply RPWA screengrabs online and in store.</p>
									<div>
										{/* <img src={MARKETING_MATERIAL_REFERENCES.GUIDELINE} /> */}
										<CloudImageUtils imgSrc={MARKETING_MATERIAL_REFERENCES.GUIDELINE} alt="image" ratio={0} params=''/>
									</div>
								</ContentSection>
							</article>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default MarketingMaterials