import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface GiftSubscription {
    sender: string;
    recipient: string;
    sendDate: string;
}

const initialState: GiftSubscription = {
	sender: '',
	recipient: '',
	sendDate: ''
}

const GiftSubscriptionSlice = createSlice({
	name: 'GiftSubscriptionSlice',
	initialState,
	reducers: {
		sendGiftInfo(state, action){
			return {
				...state,
				sender: action.payload.sender,
				recipient: action.payload.recipient,
				sendDate: action.payload.sendDate,
			}
		},
	},
	
})

export const {sendGiftInfo} = GiftSubscriptionSlice.actions
export default GiftSubscriptionSlice.reducer

export const GiftSubscription = (state: RootState): GiftSubscription =>
	state.GiftSubscription