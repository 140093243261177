import React, { ReactElement } from 'react'
import * as _ from 'lodash'
import { useDispatch } from 'react-redux'

import { ToggleAdvancedSearchChoice } from '../../../../../utils/SearchUtil'

// Sections
import SectionSeparator from '../../../sections/section-separator'

// Styles
import { CheckboxLabelCombo, SearchAndCheckboxFilterContainer, SearchAndCheckboxOptionsContainer, Title } from '../styles'

import {  TitleMargin, WineTypes } from '.'


function WineTypeFilter(): ReactElement {
	const wineTypes = _.chunk(WineTypes, (WineTypes.length / 3))

	const dispatch = useDispatch()
	const toggleChoice = (event: React.ChangeEvent<HTMLInputElement>) => ToggleAdvancedSearchChoice(event.target.checked, dispatch, 'wine_types', event.target.value)

	return (
		<SearchAndCheckboxFilterContainer>
			<Title margin={ TitleMargin } >Wine Type</Title>
			<SectionSeparator position={ 'horizontal' }></SectionSeparator>
			<SearchAndCheckboxOptionsContainer>
				<CheckboxLabelCombo  isBoldedLabel={ true } className='top'>
					<input type='checkbox' id='filter-all-vintages'/>
					<label htmlFor='filter-all-vintages'>All Wine Types</label>
				</CheckboxLabelCombo>
				<div className='vintage-container'>
					{ wineTypes.map((part: string[], index: number) => (
						<div key={ index } >
							{ part.map((type:string, innerIndex: number) => (
								<CheckboxLabelCombo key={ innerIndex } isBoldedLabel={ false } >
									<input onChange={ toggleChoice } type='checkbox' id={ `type_${ index }_${ innerIndex }` } value={ type } />
									<label htmlFor={ `type_${ index }_${ innerIndex }`} >{ type }</label>
								</CheckboxLabelCombo>
							))}
						</div>
					))}
				</div>
			</SearchAndCheckboxOptionsContainer>
		</SearchAndCheckboxFilterContainer>
	)
}

export default WineTypeFilter