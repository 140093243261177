import React, { ReactElement, useEffect, useState } from 'react'
import CategorizedPreview from '../../../sections/the-wine-advocate/CategorizedPreview'
import { useGetPublishedWineArticlesQuery } from '../../../../../app/services'
import { ConvertJSONArticlesCollectionToClass } from '../../../../../utils'
import { Indicator, IndicatorWrapper } from '../../subscriptions/styles'
import { isMobile } from 'react-device-detect'

type Props = {
	wineId: string
}

const PublishedArticlesv2 = ({ wineId }: Props): ReactElement => {
	const { data, isLoading, isError } = useGetPublishedWineArticlesQuery(wineId)
	const [articles, setArticles] = useState<any[]>([])
	const [currentSlide, setCurrentSlide] = useState(0)
	const [touchStartX, setTouchStartX] = useState<number | null>(null) // For swipe detection
	const dotLenght = isMobile ? 1 : 3

	useEffect(() => {
		if (data) {
			if (data.success) {
				const formatData = data.data.filter((article: any) => article !== null).map((article: any) => ({
					...article,
					image: {
						url: article.banner?.url || ''
					}
				}))
				setArticles(ConvertJSONArticlesCollectionToClass(formatData))
			} else {
				console.log('Error: ', data.message)
			}
		}
		if (isError) {
			console.log('Something went wrong. Please try again.')
		}
	}, [data, wineId, isError])

	const clickIndicatorHandler = (slideNum: number) => {
		setCurrentSlide(slideNum)
	}

	// Number of slides for desktop (3 articles per slide) and mobile (1 article per slide)
	const numberOfSlidesDesktop = Math.ceil(articles.length / 3)
	const numberOfSlidesMobile = articles.length

	const handleTouchStart = (e: React.TouchEvent) => {
		setTouchStartX(e.touches[0].clientX)
	}

	const handleTouchEnd = (e: React.TouchEvent) => {
		if (touchStartX !== null) {
			const touchEndX = e.changedTouches[0].clientX
			const touchDifference = touchStartX - touchEndX

			if (Math.abs(touchDifference) > 50) { // If swipe is more than 50px
				if (touchDifference > 0) {
					// Swipe left
					setCurrentSlide((prev) => Math.min(prev + 1, numberOfSlidesMobile - 1))
				} else {
					// Swipe right
					setCurrentSlide((prev) => Math.max(prev - 1, 0))
				}
			}
			setTouchStartX(null)
		}
	}

	const DesktopView = () => (
		<>
			<h5 style={{ fontWeight: 600, marginBottom: '1.5rem' }}>PUBLISHED IN</h5>
			<div className='mnmd-block mnmd-carousel' style={{ position: 'relative' }}>
				{articles.length > 0 ? (
					<>
						<div style={{ display: 'flex' }}>
							{articles.slice(currentSlide * 3, currentSlide * 3 + 3).map((article: any, index: number) => (
								<div style={{ display: 'block', padding: '5px', width: '255px' }} key={index}>
									<CategorizedPreview article={article} />
								</div>
							))}
						</div>

						{currentSlide > 0 && (
							<div
								style={{
									position: 'absolute',
									top: '45%',
									left: '-30px',
									transform: 'rotate(180deg)',
									cursor: 'pointer',
									backgroundColor: 'white',
									borderRadius: '50%',
									padding: '5px',
									zIndex: 1,
								}}
								onClick={() => setCurrentSlide((prev) => Math.max(prev - 1, 0))}
							>
								<img src='img/icons/arrow-back-ios@2x.png' className='img-arrow' />
							</div>
						)}

						{currentSlide < numberOfSlidesDesktop - 1 && (
							<div
								style={{
									position: 'absolute',
									top: '45%',
									right: '-30px',
									cursor: 'pointer',
									backgroundColor: 'white',
									borderRadius: '50%',
									padding: '5px',
									zIndex: 1,
								}}
								onClick={() => setCurrentSlide((prev) => Math.min(prev + 1, numberOfSlidesDesktop - 1))}
							>
								<img src='img/icons/arrow-back-ios@2x.png' className='img-arrow' />
							</div>
						)}
					</>
				) : (
					<p>No article found.</p>
				)}
			</div>
			{articles.length > 3 && (
				<IndicatorWrapper>
					{Array.from({ length: numberOfSlidesDesktop }).map((_, index) => (
						<Indicator
							key={index}
							isActive={currentSlide === index}
							onClick={() => clickIndicatorHandler(index)}
						/>
					))}
				</IndicatorWrapper>
			)}
		</>
	)

	const MobileView = () => {
		return <>
			<h5 style={{ fontWeight: 600, marginBottom: '1.5rem' }}>PUBLISHED IN</h5>
			<div className='mnmd-block mnmd-carousel' style={{ position: 'relative' }} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
				{articles.length > 0 ? (
					<>
						<div
							style={{
								display: 'flex',
								whiteSpace: 'nowrap',
								transition: 'transform 0.5s ease',
								transform: `translateX(-${currentSlide * 100}%)`,
							}}
						>
							{articles.map((article: any, index: number) => (
								<div style={{ display: 'block', padding: '5px', width: '100%' }} key={index}>
									<CategorizedPreview article={article} />
								</div>
							))}
						</div>
					</>
				) : (
					<p>No article found.</p>
				)}
			</div>
			{articles.length > dotLenght && (
				<IndicatorWrapper>
					{Array.from({ length: numberOfSlidesMobile }).map((_, index) => (
						<Indicator
							key={index}
							isActive={currentSlide === index}
							onClick={() => clickIndicatorHandler(index)}
						/>
					))}
				</IndicatorWrapper>
			)}
		</>
	}

	return (
		<div>
			{isLoading ? 'Searching for published articles...' : !isMobile ? DesktopView() : MobileView()}
		</div>
	)
}

export default PublishedArticlesv2
