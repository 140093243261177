import styled from 'styled-components'

export const SubmitActionContainer = styled.div`
    & div {
        & div {
            visibility: hidden;
        }
    }
`

export const ForgotPasswordContainer = styled.div`
    text-align: center;
    align-items: center;
        
    & a {
        text-decoration: underline !important;
        cursor: pointer;
    }
`

export const TabPanelContainer = styled.ul`
    gap: 0.5rem;
    
    & a.anchor-style {
        &:active, &:focus, &:hover, &:visited {
        border-bottom: 1px solid rgba(0, 0, 0, 0.8) !important;
        text-decoration: none;
        color: black;
        }
    }


`
export const AuthExpiredContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    border-radius: 8px;
    border: 1px solid red;
    background-color: #FFCECE;
    margin-bottom: 10px;

    & p {
        color: black;
        text-align: center;
        line-height: 1.2;
        margin: 0;
        padding: 15px;
    }
`