import React, {ReactElement} from 'react'
import { SuccessContainer } from './styles'

function SuccessModal(props: { isOpen: boolean, onClose: ()=> void, messageBody: string, title:string }): ReactElement {
	return (
		<SuccessContainer>
			<div className='backdrop-transition'>
				<div className={props.isOpen ? 'modal' : 'modal fade'} style={{ display: props.isOpen ? 'block' : 'none', }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header" style={{ backgroundColor: '#8E4250', color: 'white', display: 'flow'}}>
								<button type="button" className="close" onClick={props.onClose}>
									<span style={{ color: 'red'}} aria-hidden="true">&#10005;</span>
								</button>
								<h3 className="modal-title" style={{ backgroundColor: '#8E4250', textAlign: 'left'}}>{props.title}</h3>
							</div>
							<div className="modal-body">
								<div className="subscribe-form subscribe-form--horizontal text-center max-width-sm">
									<p className="typescale-1">{props.messageBody || 'No Error message'}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</SuccessContainer>
	)
}

export default SuccessModal

SuccessModal.defaultProps = {
	title: 'Resend Invite',
}