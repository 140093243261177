/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, ReactNode, ChangeEvent } from 'react'
import { CardInformationField, FieldErrorMessage } from '../styles'

type InputFormType = {
    label: ReactNode;
    inputId:string;
    formErrors:string;
    value: any;
    handleCardChange: (event:ChangeEvent<HTMLInputElement>) => void;
    handleBlur:(event: React.ChangeEvent<HTMLInputElement>)=> void;
}

const InputForm = ({ label, inputId, formErrors,handleCardChange, value,handleBlur }: InputFormType):ReactElement => {
	return <>
		<div>
			<label>{label}</label><br />
			<CardInformationField type='text' id={inputId} className={formErrors ? 'error component-design' : 'component-design'} value={value} onChange={handleCardChange} onBlur={handleBlur} />
			<FieldErrorMessage>{formErrors ? <><img style={{ width: '15px' }} src={'img/icons/icon_error_warning.svg'} />&ensp;{formErrors}</> : <></>}</FieldErrorMessage>
		</div><br />
	</>
}

export default InputForm
