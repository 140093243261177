import styled from 'styled-components'

export const ProfileContent = styled.div``

const leftMargin = '13px'

export const CommentCounter = styled.div`
	width: 100%;
	display: flex;
	justify-content: right;

	& div {
		width: 59px;
		height: 33px;
		margin: 20px 0px 40px 0px;
		padding: 6px 13px 6px 12px;
		border-radius: 8px;
		background-color: #73333f;
		color: #fff;

		& img {
			margin-right: 2px;
		}
	}
`

export const PreviewContainer = styled.div`
	display: flex;
	justify-content: space-between;

	& div {
		margin: 5px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		& div.title {
			min-height: 100px;
			font-family: Libre Franklin;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			color: #333;
			valign: top;
		}

		& div.publish-date {
			white-space: nowrap;
			margin: 0 0 0 10px;
			font-family: Libre Franklin;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			color: #777;
			display: inline;

			& img {
				height: 12px;
				width: 12px;
				margin-right: 5px;
			}
		}
	}

	@media (max-width: 768px) {
		flex-direction: column;
	}
`

export const ProfileSummary = styled.div`
	& div {
		margin-bottom: 50px;
	}

	& div.profile-pic {
		height: 250px;
		width: 250px;
		margin: auto;
	}

	& div.socials {
		display: flex;
	}

	& div > span.label {
		font-family: Libre Franklin;
		font-size: 21px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.52;
		color: #333;
	}

	& div > span.value {
		margin-left: ${leftMargin};
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		color: #333;
	}

	& div.counters > span {
		margin-left: ${leftMargin};
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #e01a00;
	}

	& div.socials > img {
		margin-left: ${leftMargin};
		margin-right: 30px;
	}
`

export const Separator = styled.div`
	height: 1px;
	margin: 11px 0 11px 0px;
	background-color: #ccc;
`
