/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import React, { ReactElement } from 'react'
import { DoublePrintContainer, FourLayoutContainer, SinglePrintContainer, TriplePrintContainer } from './styles'
import { deviceType } from '../../../../../helper/constants'

type Props = {
    wineData: any;
    refs: any;
	type: string;
};

function PrintNotes({wineData, refs, type}: Props): ReactElement {

	const device = deviceType()

	const allCheckedWines = wineData.filter((wine: any) => wine.checked)
	const detailedWines = allCheckedWines.map((wine: any) =>wine.wine_details)
	const maxLength = 1000

	const generateLocationTagClouds = (wine: any) => (<>
		{wine.country ? wine.country + ', ' : null}
		{wine.region ? wine.region + ', ' : null}
		{wine.sub_region ? wine.sub_region + ', ' : null}
	</>)

	const getVarietyLabel = (wine: any) => {
		if (wine.varieties) {
			return wine.varieties?.length === 1 ? 'Variety' : 'Varieties'
		}
		return ''
	}

	const segregateTastingNotes = (tastingNotes: any[], notesPerPage: number) => {
		const pages: any[][] = []
		let currentPage: any[] = []

		for (let i = 0; i < tastingNotes.length; i++) {
			currentPage.push(tastingNotes[i])

			if (currentPage.length === notesPerPage || i === tastingNotes.length - 1) {
				pages.push(currentPage)
				currentPage = []
			}
		}
		return pages
	}

	const segregatedContent = (tastingNotes: any[]) =>{
		const pages: any[] = []
		const longContent: any[] = []
		const shortContent: any[] = []

		const longContentSegregate: any[] = []

		tastingNotes.forEach(data => {
			if (data.latest_note.content.length > 1000) {
				longContent.push(data)
			} else {
				const shortFormat = { ...data, isLong: false }
				shortContent.push(shortFormat)
			}
		})

		if(longContent.length){
			longContent.forEach((wine) => {
				const content = wine.latest_note.content
				if (content.length <= 1650) {
					const longTastingNote = { ...wine, isLong: true }
					longContentSegregate.push(longTastingNote)
				} else {
					const firstPartContent = content.slice(0, device.includes('safari') ? 2420 : 2500)
					const secondPartContent = content.slice(device.includes('safari') ? 2420 : 2500)
					
					const firstPartWine = { ...wine,  isLong: true, latest_note: { ...wine.latest_note, content: firstPartContent + '...' } }
					const secondPartWine = { ...wine, isLong: true, latest_note: { ...wine.latest_note, content: secondPartContent } }
			
					longContentSegregate.push(firstPartWine)
					longContentSegregate.push(secondPartWine)
				}
			})


		}

		if(shortContent.length){
			const isShortContent = { data: [...shortContent], isLong: false}
			pages.push(isShortContent)
		}

		if(longContentSegregate.length){
			const isLongContent = { data: [...longContentSegregate], isLong: true}
			pages.push(isLongContent)
		}

		const finalPages: any[]= []

		pages.forEach(array => {
			if (array.data.length > 4 && !array.isLong) {
				for (let i = 0; i < array.data.length; i += 4) {
					finalPages.push(array.data.slice(i, i + 4))
				}
			} else if (array.data.length > 2 && array.isLong) {
				for (let i = 0; i < array.data.length; i += 2) {
					finalPages.push(array.data.slice(i, i + 2))
				}
			} else {
				finalPages.push(array.data)
			}
		})

		return finalPages
	}

	const isLong = (data: any[]): boolean => {
		let totalCount = 0

		if(data.length === 1 && data[0]?.latest_note?.content?.length >= maxLength){
			return true
		}
		
		data.forEach(item => {
			if (item.latest_note && item.latest_note.content) {
				const contentLength: number = item.latest_note.content.length

				totalCount = totalCount + contentLength
			}
		})

		if(totalCount >= maxLength * 2){
			return true
		}

		return false
	}

	const isShort = (contentLength: number): boolean => {
		if(contentLength < 1100){
			return true
		}
		return false
	} 

	const singlePrint = () => {
		return <>
			{detailedWines.map((note: any, index: number) => {
				return <SinglePrintContainer key={`tasting-notes-${index}`} className="tasting-notes print-friendly print-friendly-search">
					<div className="text-center">
						<img className="print-img-header" src="img/logo-mark-color.png" alt="logo" />
					</div>
					<div>
						<div className="row">
							<div className="col-12">
								<h3 className="print-title print-title-1x">
									{note.display_name}
								</h3>
							</div>
						</div>
						<div className="row">
							<div className="col-8 content-container-single">
								<div>
									<div className="row-container">
										<div className="col-4">
											<div className="form-group">
												<label>Rating</label>
												<p className="print-font-small">
													{note.rating_display}
												</p>
											</div>
										</div>
										<div className="col-4">
											<div className="form-group">
												<label>Release Price</label>
												<p className="print-font-small">
													{note.price_low ?? 'NA'}
												</p>
											</div>
										</div>
										<div className="col-4">
											<div className="form-group">
												<label>Drink Date</label>
												<p className="print-font-small">{note.drink_date_low} - {note.drink_date_high}</p>
											</div>
										</div>
									</div>
									<div className="row-container">
										<div className="col-4">
											<div className="form-group">
												<label>Reviewed by</label>
												<p className="print-font-small">
													{note.reviewers.length ? note.reviewers.map((reviewer:string, index:number)=>{
														if(note.reviewers.length -1 === index) return <span style={{ margin: 0, padding: 0, lineHeight: 1.5 }} key={index} className='print-font-small'>{reviewer}</span>
														else return <span style={{ margin: 0, padding: 0, lineHeight: 1.5 }} key={index} className='print-font-small'>{reviewer}, </span> 
													}) : <span  className='print-font-small'>N/A</span>}
												</p>
											</div>
										</div>
										<div className="col-4">
											<div className="form-group">
												<label>Issue Date</label>
												<p className="print-font-small">{moment(note.latest_note.published_at).format('MMM DD, YYYY')}</p>
											</div>
										</div>
										<div className="col-4">
											<div className="form-group">
												<label>Source</label>
												{note.issue_appearances.length ? note.issue_appearances.map((issue:string, index:number)=>{
													if(note.issue_appearances.length -1 === index) return <p style={{ margin: 0, padding: 0, lineHeight: 1.5 }} key={index} className='print-font-small'>{issue}</p>
													else return <p style={{ margin: 0, padding: 0, lineHeight: 1.5 }} key={index} className='print-font-small'>{issue}, </p> 
												}) : <p  className='print-font-small'>N/A</p>}
											</div>
										</div>
									</div>
									
									<div className="col-12">
										{note?.latest_note?.content && <p className='print-font-mid'>{note?.latest_note?.content}</p>}
									</div>
								</div>
							</div>
							<div className="col-4 right-side-list content-container-single">
								<p>
									<strong>Producer:</strong> <span className='print-font-mid'>{note.producer}</span>
								</p>
								<p>
									<strong>From:</strong> <span className='print-font-mid'>{generateLocationTagClouds(note)}</span>
								</p>
								<p>
									<strong>Color:</strong> <span className='print-font-mid'>{note.color_class}</span>
								</p>
								<p>
									<strong>Type:</strong> <span className='print-font-mid'>{note.type}</span>
								</p>
								<p>
									<strong>Sweetness:</strong> <span className='print-font-mid'>{note.dryness}</span>
								</p>
								<p className='print-font-mid'>
									<strong>{getVarietyLabel(note)}:</strong> <span className='print-font-mid'>{note?.varieties?.length && note.varieties.join(', ')}</span>
									<br />
								</p>
							</div>
						</div>
					</div>
				</SinglePrintContainer>
			})}
		</>
	}

	const doublePrint = () => {
		const segration = segregatedContent(detailedWines)
		return <>
			{segration.map((pages, pageIndex) => {

				if ((pages.length === 1 || pages.length === 2) && isLong(pages)) {
					return (
						<DoublePrintContainer key={`tasting-notes-page-${pageIndex}`} className='col-12' BrowserType={device}>
							{pages.map((note: any, noteIndex: any) => {
								return (
									<div key={`tasting-note-${noteIndex}`} className='col-6'>
										<div className='border-container'>
											<div className='header-container'>
												<div className='top-container'>
													<div className='logo-img'>
														<img className='print-tile-image' src="img/logo-mark-color.png" alt="logo" />
													</div>
													<div className='rating-display'>
														<h3>{note.rating_display}</h3>
													</div>
												</div>
												<div className='title-display col-12'>
													<h3>{note.display_name}</h3>
												</div>
												<div className='col-12 '>
													<hr/>
												</div>
											</div>

											<div className={isShort(note?.latest_note?.content.length) ? 'content-container-small' : 'content-container-long'}>
												<p>
													{note?.latest_note?.content}
												</p>
											</div>

											<div className='col-12 footer-container'>
												<p><strong>- {note.reviewers.length ? note.reviewers[0] : 'N/A'}&nbsp;({moment(note.latest_note.published_at).format('MMM DD, YYYY')})</strong>
													<br/>
													<small>Copyright {moment().year()}, The Wine Advocate, Inc. - Reprinted with</small>
													<br/>
													<small> permission</small>
												</p>
											</div>
										</div>
									</div>
								)})}
						</DoublePrintContainer>
					)
				} else {
					return (
						<FourLayoutContainer key={`tasting-notes-page-${pageIndex}`} className="tasting-notes x31">
							<div className="flex-row text-center">
								{pages.map((note: any, noteIndex: any) => (
									<div key={`tasting-note-${noteIndex}`} className="col-6 box-padding">
										<div className={`${device.includes('safari') ? 'border-container-safari' : 'border-container-all'}`}>
											<div>
												<div className="print-header">
													<div className='print-title-double'>
														<div className='img-logo'>
															<img className='print-tile-image' src="img/logo-mark-color.png" alt="logo" />
														</div>
														<div>
															<h3 className="wineRating">{note.rating_display}</h3>
														</div>
													</div>
													<div className="col-12">
														<h3 className="print-title-2x">{note.display_name}</h3>
													</div>
													<div className='col-12 '>
														<hr className='print-double-line-header'/>
													</div>
												</div>
												<div className='content-container'>
													<div className="row text-left tastingnote-container">
														<div className="col-12 content-tastingnote">
															<p className="p-tastingnote-2x2">
																{note?.latest_note?.content}
															</p>
														</div>
													</div>
	
													<div className="col-12 footer-content">
														<p className="author-name">
															<strong>
																	- {note.reviewers.length ? note.reviewers[0] : 'N/A'}
																	&nbsp;({moment(note.latest_note.published_at).format('MMM DD, YYYY')})
															</strong>
														</p>
	
														<p>
															<small>Copyright {moment().year()}, The Wine Advocate, Inc. - Reprinted with</small>
															<br/>
															<small> permission</small>
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</FourLayoutContainer>
					)
				}
			})}
		</>
	}

	const triplePrint = () => {
		const tastingNotesPerPage = 3
		const segregatedTastingNotes = segregateTastingNotes(detailedWines, tastingNotesPerPage)

		return (
			<>
				{segregatedTastingNotes.map((pages, pageIndex) => {
					return (
						<TriplePrintContainer key={`tasting-notes-page-${pageIndex}`} className="tasting-notes x31">
							<div className="flex-row text-center">
								{pages.map((note, noteIndex) => {
									const contentLength = note.latest_note.content.length
									let firstContent = ''
									const changeLayout = contentLength > 1260

									if (changeLayout) {
										const count = 1260
										const words = note.latest_note.content.split(' ')
										const content = []
										let currentContent = ''

										for (const word of words) {
											if ((currentContent + word).length <= count) {
												currentContent += word + ' '
											} else {
												content.push(currentContent.trim())
												currentContent = word + ' '
											}
										}

										if (currentContent) {
											content.push(currentContent.trim())
										}
										firstContent = content[0]
									}
									return (
										<div key={`tasting-note-${noteIndex}`} className="flex-container tasting-note col-4">
											<div className={`${device.includes('firefox') ? 'border-container-firefox' : 'border-container-all'}`}>
												<div className="print-content content-padding">
													<div className="print-header">
														<div className="col-1" />
														<div>
															<img className="print-logo" src="img/logo-mark-color.png" alt="logo" />
														</div>
														<div className="col-1" />
														<div className="col-12">
															<h3 className="wineRating">{note.rating_display}</h3>
														</div>
														<div className="col-12">
															<h3 className="print-title-3x">{note.display_name}</h3>
														</div>
														<div className='col-12'>
															<hr />
														</div>
													</div>
													<div className="row tastingnote-container">
														<div className="col-12 content-tastingnote">
															<div className="p-tastingnote-3x1">
																{changeLayout ?
																	<div>
																		{firstContent}.
																	</div>
																	:
																	<div>
																		{note.latest_note.content}
																	</div>
																}
															</div>
														</div>
													</div>

													<div className="d-flex col-12 flex-column layout-footer text-right justify-content-end">
														<p className="author-name">
															<strong>
																- {note.reviewers.length ? note.reviewers[0] : 'N/A'}
																&nbsp;({moment(note.latest_note.published_at).format('MMM DD, YYYY')})
															</strong>
														</p>

														<p>
															<small>Copyright {moment().year()}, The Wine Advocate, Inc. - Reprinted with permission</small>
														</p>

													</div>
												</div>
											</div>
										</div>
									)})}
							</div>
						</TriplePrintContainer>
					)})}
			</>)
	}

	const shoppingList = () => {
		const tastingNotesPerPage = 19
		const segregatedTastingNotes = segregateTastingNotes(detailedWines, tastingNotesPerPage)
		return  <>
			{ segregatedTastingNotes.map((page, index) => <SinglePrintContainer key={index} className="tasting-notes print-friendly print-friendly-search">
				<div className="text-center">
					<img className="print-img-header-shopping" src="img/logo-mark-color.png" alt="logo" />
				</div>
				<div className="row shopping-list-header">
					<div className="col-1">Vintage</div>
					<div className="col-7 wine-name">Wine Name</div>
					<div className="col-1">Color</div>
					<div className="col-2">Rating</div>
					<div className="col-1">Price</div>
				</div>
				<hr className='shopping-list-main-separator'/>
				{page.map((note: any, index: number) => {
					return <div key={index} className='row shopping-content'>
						<div className="col-1">{note.vintage}</div>
						<div className="col-7 wine-name">{note.producer === note.name ? note.name : note.producer + ' ' + note.name}</div>
						<div className="col-1">{note.color_class}</div>
						<div className="col-2">{note.rating_display}</div>
						<div className="col-1">{note.price_low ?? 'N/A'}</div>
					</div>
				})}
				<div>
					<p className='shopping-footer'>Copyright 2023, The Wine Advocate, Inc. Reprinted with permission</p>
				</div>
			</SinglePrintContainer>)}
		</>
	}

	const getPrintLayout = () => {
		if (type === '2x2') return doublePrint()
		else if (type === '3x1') return triplePrint()
		else if (type === '1x1') return singlePrint()
		else return shoppingList()
	}

	return <div ref={refs} className="print-layout">
		{getPrintLayout()}
	</div>
}

export default PrintNotes
