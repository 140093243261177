/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import * as _ from 'lodash'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { FilterCollection, ToggleAdvancedSearchChoice } from '../../../../../utils'

// Services
import { useGetListOfAuthorsQuery } from '../../../../../app/services'

// Sections
import SectionSeparator from '../../../sections/section-separator'
import SearchFilterField from './SearchFilterField'

// Styles
import { CheckboxGrouping, CheckboxLabelCombo, Title } from '../styles'

import { TitleMargin  } from '.'
 
const ReviewerFiltersContainer = styled.div`
	width: 100%;
	& div > input.reviewer-search {
		width: 262px;
		height: 33px;
		margin: 0px 554px 7px 0px;
		padding: 6px 8px 6px 12px;
		border-radius: 8px;
		border: solid 1px #ccc;
		background-color: var(--white-three);
	}
`

let pastAuthors: unknown[][]
let presentAuthors: unknown[][]	

function ReviewerFilter(): ReactElement {

	const [ filteredPast, setFilteredPast ] = useState(pastAuthors)
	const [ filteredPresent, setFilteredPresent ] = useState(presentAuthors)

	const { data, isLoading } = useGetListOfAuthorsQuery('')

	const dispatch = useDispatch()
	const toggleChoice = (event: React.ChangeEvent<HTMLInputElement>) => ToggleAdvancedSearchChoice(event.target.checked, dispatch, 'authors', event.target.value)

	const getAuthorsCheckbox = (collection: any[]) => (
		collection.map((author: any, index: number ) => (
			<CheckboxLabelCombo key={ index } isBoldedLabel={ false } >
				<input onChange={ toggleChoice } type='checkbox' value={ author._id } id={ `author_${ author._id }` }/>
				<label htmlFor={ `author_${ author._id }` }>{ `${ author?.profile?.name?.first_name} ${ author?.profile?.name?.last_name }` }</label>
			</CheckboxLabelCombo>	
		))
	)

	const getGrouping = (collection: unknown[]) => _.chunk(collection, (collection.length / 2))

	useEffect(() => {
		if(data && data.past && data.present ) {
			setFilteredPast(getGrouping(data.past))
			setFilteredPresent(getGrouping(data.present))
		}
	}, [data])

	const triggerFilter = (keywords: string) => {
		if(keywords) {
			setFilteredPast([[...FilterCollection(keywords, data.past, 'first_name', true)]])
			setFilteredPresent([[...FilterCollection(keywords, data.present, 'first_name', true)]])
		} else if (data && data.past && data.present) {
			setFilteredPast(getGrouping([...data.past]))
			setFilteredPresent(getGrouping([...data.present]))
		}
	}


	return (
		<ReviewerFiltersContainer>
			<Title margin={ TitleMargin } >Reviewer</Title>
			<SearchFilterField triggerFilter={ triggerFilter } />
			<SectionSeparator position={ 'horizontal' }></SectionSeparator>
			<CheckboxLabelCombo className='all-reviewers' isBoldedLabel={ true } >
				<input type='checkbox' id='filter-all-reviewers' />
				<label htmlFor='filter-all-reviewers'>All Reviewers</label>
			</CheckboxLabelCombo>
			{ isLoading? (<p>Loading ...</p>): null}
			{ filteredPresent ? (
				<CheckboxGrouping className='grouping'>
					<div><Title margin={ '0px 0px 0px 0px'} >Current Reviewers</Title></div>
					<div className='options-container'>
						{ filteredPresent.map((collection: any[], key: number) => (
							<div key={ key } >
								{ collection.map((author: any, index: number ) => (
									<CheckboxLabelCombo key={ index } isBoldedLabel={ false } >
										<input onChange={ toggleChoice } type='checkbox' value={ author._id } id={ `author_${ author._id }` }/>
										<label htmlFor={ `author_${ author._id }` }>{ `${ author?.profile?.name?.first_name} ${ author?.profile?.name?.last_name }` }</label>
									</CheckboxLabelCombo>	
								))}
							</div>
						))}
						
					</div>
				</CheckboxGrouping>
			):null}

			{ data && filteredPast ? (
				<CheckboxGrouping className='grouping'>
					<div><Title margin={ '0px 0px 0px 0px'} >Past Reviewers</Title></div>
					<div className='options-container'>
						{ filteredPast.map((collection: any[], key: number) => (
							<div key={ key } >
								{ getAuthorsCheckbox(collection) }
							</div>
						))}
						
					</div>
				</CheckboxGrouping>
			):null}
			

		</ReviewerFiltersContainer>
	)
}

export default ReviewerFilter