import styled from 'styled-components'

export const ProducerWidgetListEntry = styled.div`
	cursor: pointer;
	& article {
		display: flex;
	}

	& article > div.rectangle {
		margin-left: auto;
	}
`


export const WineButton = styled.span`
	background-color: #008185 !important; 
	color: white;
	font-size: 10px  !important;
	font-family: Libre Franklin !important;
	font-size: 10px !important;
	font-weight: normal !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: normal !important;
	letter-spacing: normal !important;
	text-align: center !important;
`